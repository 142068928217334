import clsx from 'clsx';
import React from 'react';
import { SHORT_DATE, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { AttendanceType } from '../../../types/AttendanceType';
import moment from 'moment';

interface Props {
	attendance: AttendanceType;
	showDate?: boolean;
	rxKey: string | number;
	onClick?: (att: AttendanceType) => void;
	onActionClick?: (att: AttendanceType, action: 'edit'|'delete') => void;
}

export function ConnectionHistoryAttendanceRow({
																								 attendance,
																								 showDate = true,
																								 rxKey,
																								 onActionClick,
																								 onClick,
																							 }: Props) {
	const isExcused = attendance.reason.isExcused;
	return (
		<tr
			className="cursor-pointer"
			onClick={() => {
				if (onClick) {
					onClick(attendance);
				}
			}}
			key={rxKey}>
			<td>{showDate && moment(attendance.date, SHORT_DATE).format(US_FRIENDLY_DATE)}</td>
			<td>
				<span
					className={clsx(
						'me-2 fs-7 fw-bold px-3 rounded text-uppercase',
						{
							'text-muted badge-light-warning': !isExcused,
							'text-primary': isExcused,
						},
					)}>
						{isExcused ? 'Excused' : 'Attendance'}
				</span>
			</td>
			<td>{attendance.occurrence}</td>
			<td>
				{!isExcused && (
						<span className="pe-5 text-danger">{attendance.pointsAssigned} point(s)</span>
					)
				}
				{attendance.reason?.content}
			</td>
			<td>{attendance.creator?.firstName} {attendance.creator?.lastName}</td>
			<td className="d-flex">
				<button
					className="btn btn-secondary btn-active-light-primary btn-sm"
					onClick={(e) => {
						if (onActionClick) {
							e.stopPropagation();
							onActionClick(attendance, 'edit');
						}
					}}
				><i className="bi bi-pencil-square"></i>
				</button>
				<button
					className="btn btn-sm"
					onClick={(e) => {
						if (onActionClick) {
							e.stopPropagation();
							onActionClick(attendance, 'delete');
						}
					}}
				><i className="fas fa-trash text-danger fs-4"></i></button>
			</td>
		</tr>
	);
}
