import axios, { AxiosPromise } from 'axios';
import { axiosTransformErrorResponseHandler } from '../../common/api/common.api';
import { AttendanceGetParamsModel } from '../models/AttendanceGetParamsModel';
import { Employee } from '../modules/employee/models/Employee.model';

const API_URL = process.env.REACT_APP_API_URL

interface EmployeeModel {
  firstName: string,
  lastName: string,
  birthDate: string,
  hireDate: string,
  employeeId: string,
  phone: string,
  isAgency: boolean,
  isTerminated: boolean,
}

export const EMPLOYEE_URL = `${API_URL}/employees`
export const UPLOAD_EMPLOYEE_AVATAR_URL = `${API_URL}/employees`
export const COMPANIES_URL = `${API_URL}/companies`
export const TEAM_KPI_URL = `${API_URL}/kpi/team`
export const NOTIFICATIONS_URL = `${API_URL}/notifications`

export function deleteTeam(team_id: string, token: string) {
  return Promise.reject('Team deletion disabled temporarily');
  /*// disabled until database team deletion migration processed, leaving example here to go off for
   testing/implementing via redux
  return axios({
    method: 'delete',
    url: `${TEAMS_URL}/${team_id}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  */
}
export function createEmployee(
  firstName: string,
  lastName: string,
  birthDate: string,
  hireDate: string,
  employeeId: string,
  isAgency: boolean,
  phone: string,
  teamId: string,
  token: string
) {
  const data = {
    firstName,
    lastName,
    birthDate,
    hireDate,
    isAgency,
    employeeId,
    teamId,
  };
  if (phone) {
    // @ts-ignore: implement actual interface; can't have this passed back or it will fail if you don't provide a
    // valid phone number
    data['phone'] = phone;
  }

  return axios({
    method: 'post',
    url: EMPLOYEE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error creating employee'));
}

export function setEmployeeTeams(employeeId: number, teamIds: number[], token: string): AxiosPromise<Employee> {
  return axios({
    method: 'patch',
    url: `${EMPLOYEE_URL}/${employeeId}/set-teams`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: {
      teamIds,
    }
  })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error reassigning employee'));
}

export function updateEmployee(employeeId: number, data: EmployeeModel, token: string) {
  return axios({
    method: 'patch',
    url: `${EMPLOYEE_URL}/${employeeId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: data
  })
  .catch(err => axiosTransformErrorResponseHandler(err, 'Error updating employee'));
}

export function updateEmployeeNotes(employeeId: number, newNotes: string, token: string) {
  return axios({
    method: 'patch',
    url: `${EMPLOYEE_URL}/${employeeId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: {
      misc: {
        details: {
          notes: newNotes
        }
      }
    }
  })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error updating employee notes'));
}


export function getPagination(url: string, token: string) {
  return axios({
    method: 'get',
    url: `${API_URL}${url.substring(3)}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getEmployeeAttendances(employeeId: number, token: string, opts: AttendanceGetParamsModel = {}) {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/${employeeId}/attendances`,
    params: opts,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getEmployeeCoaching(employeeId: number, token: string, opts: AttendanceGetParamsModel = {}) {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/${employeeId}/coachings`,
    params: opts,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
export function getEmployeeRecognition(employeeId: number, token: string, opts: AttendanceGetParamsModel = {}) {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/${employeeId}/recognitions`,
    params: opts,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getEmployeeDisciplines(employeeId: number, token: string, opts: AttendanceGetParamsModel = {}) {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/${employeeId}/disciplines`,
    params: opts,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getEmployee(employeeId: number, token: string) {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/${employeeId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getSupervisors(companyId: string, token: string) {
  return axios({
    method: 'get',
    url: `${COMPANIES_URL}/${companyId}/supervisors`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getTeamKpi(teamId: number, fromDate: string, toDate: string, token: string) {
  return axios({
    method: 'get',
    url: `${TEAM_KPI_URL}/${teamId}?fromDate=${fromDate}&toDate=${toDate}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getNotifications(token: string) {
  return axios({
    method: 'get',
    url: NOTIFICATIONS_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function markOneNotificationRead(id: number, token: string) {
  return axios({
    method: 'patch',
    url: `${NOTIFICATIONS_URL}/${id}`,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: { isRead: true }
  })
}

export function markAllNotificationsAsRead(token: string) {
  return axios({
    method: 'patch',
    url: `${NOTIFICATIONS_URL}/mark-all-as-read`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}

export function deleteOneNotification(notification_id: number, token: string) {
  return axios({
    method: 'delete',
    url: `${NOTIFICATIONS_URL}/${notification_id}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function deleteAllNotifications(token: string) {
  return axios({
    method: 'delete',
    url: `${NOTIFICATIONS_URL}/delete-all`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}

export function deleteTeamMember(employee_id: number, token: string) {
  return axios({
    method: 'delete',
    url: `${EMPLOYEE_URL}/${employee_id}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
