import axios, { AxiosResponse } from 'axios';
import {
	GridFilterItem,
	GridSortItem,
	SkeResponseWithManyPaginatedResults,
} from '../../common/interfaces/response-format.model';
import {
	ConnectionFilterableFields,
	ConnectionSortableFields,
} from './interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from './interfaces/vw_connection.model';

const API_URL = process.env.REACT_APP_API_URL;
export const CONNECTIONS_URL = `${API_URL}/connections`;

export interface GetConnectionPayload {
	page: number;
	pageSize: number;
	fromDate?: string;
	toDate?: string;
	sort?: GridSortItem<ConnectionSortableFields>[];
	filters?: GridFilterItem<ConnectionFilterableFields>[];
	detailed?: boolean;
	deleted?: boolean;
}

export function getConnections(token: string,
															 opts: GetConnectionPayload): Promise<AxiosResponse<SkeResponseWithManyPaginatedResults<VwConnection<VwConnectionDetails>>>> {
	return axios.post<SkeResponseWithManyPaginatedResults<VwConnection<VwConnectionDetails>>>(CONNECTIONS_URL, opts,
		{
			headers: {
				'Authorization': `Bearer ${token}`,
			},
		});
}
