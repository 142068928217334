import logo from '../../../../images/logos/simple_logo.png'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      {/* <img src={toAbsoluteUrl('/media/logos/logo-compact.svg')} alt='Start logo' /> */}
      <img src={logo} alt='Start logo' width="100" />
      <span>Loading ...</span>
    </div>
  )
}
