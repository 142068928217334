import React from 'react'
import { CategoryModel } from '../../../../../features/category/interfaces/category.model'
import CategoriesList from './CategoriesList'

type Props = {
    categories: CategoryModel[]
    setCategories: (categories: CategoryModel[]) => void
}

export default function DisciplineCategories({ categories, setCategories }: Props) {

    return (
        <>
            <div className='min-h-300px'>
                <CategoriesList
                  categories={categories}
                  setCategories={setCategories}
                />
            </div>
        </>
    )
}
