import { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import "react-color-palette/lib/css/styles.css"
import { disciplineStepSlice } from '../../../../../features/discipline/discipline-step.api'
import { KTSVG } from '../../../../../_library/helpers'
import { ColorPickerComponent } from '../../../../components/ColorPickerComponent'

interface IStep {
	stepName: string,
	stepType: 'Attendance' | 'Performance',
	stepNumber: number,
}

type Props = {
	setShowPage: (page: string) => void
}

export function CreateDisciplineStep({ setShowPage }: Props) {
	const [createDisciplineStep, ] = disciplineStepSlice.useCreateDisciplineStepMutation();
	const [isLoading, setIsLoading] = useState(false)
	const [color, setColor] = useState<string>('');

	const initValues: IStep = {
		stepName: '',
		stepType: 'Attendance',
		stepNumber: 0,
		// stepColor: '',
	}

	const profileSchema = Yup.object().shape({
		stepName: Yup.string()
			.required('Step name is required'),
		stepType: Yup.string()
			.required('Type is required'),
		stepNumber: Yup.number()
			.min(1, 'Minimum 1 Step Number'),
		// stepColor: Yup.string()
		//     .required('Step Color is required'),
	})

	const handleColorChange = (e: any) => {
		// console.log('handling color change', e);
		setColor(e.hex)
	}

	const handleSubmit = (values: IStep, actions: FormikValues) => {
		setIsLoading(true);
		//swap with discipline step mutation from discipline-step.api.ts at createDisciplineStep
		createDisciplineStep({
			name: values.stepName,
			stepColor: color,
			stepNumber: values.stepNumber,
			type: values.stepType,
			// tenant_id: values.tenant,
		}) .then(() => {
				setIsLoading(false);
				setShowPage('list')
			})
			.catch(err => {
				setIsLoading(false);
				console.log(err)
			})
	}

	return (
		<div className='w-100'>
			<Formik
				validationSchema={profileSchema}
				initialValues={initValues}
				onSubmit={handleSubmit}
			>
				{({ submitForm, isValid, dirty, handleChange, values }) => (
					<Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
						<div className='fv-row mb-10'>
							<label className='d-flex align-items-center fs-5 fw-bold mb-2'>
								<span className='required'>Step Name</span>
							</label>

							<Field
								type='text'
								className='form-control form-control-lg form-control-solid'
								name='stepName'
								placeholder=''
							/>
							<div className='text-danger'>
								<ErrorMessage name='stepName' />
							</div>
						</div>

						<div className="row mb-10">
							<div className='col-12 fv-row'>
								<label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
									<span className='required'>Step Type</span>
								</label>

								<Field
									as='select'
									name='stepType'
									className='form-select form-select-solid'
								>
									<option value='Attendance'>Attendance</option>
									<option value='Performance'>Performance</option>
								</Field>
								<div className='text-danger'>
									<ErrorMessage name='stepType' />
								</div>
							</div>
						</div>

						<div className='row mb-10'>
							<div className='col-6 fv-row'>
								<label className='d-flex align-items-center fs-5 fw-bold mb-2'>
									<span className='required'>Step Number</span>
								</label>

								<Field
									type='number'
									className='form-control form-control-lg form-control-solid'
									name='stepNumber'
									placeholder=''
								/>
								<div className='text-danger'>
									<ErrorMessage name='stepNumber' />
								</div>
							</div>
							<div className='col-6 fv-row'>
								<label className='d-flex align-items-center fs-5 fw-bold mb-2'>
									<span className='required'>Step Color</span>
								</label>

								<div className="picker_wrapper position-relative">
									<div className="position-absolute">
										<ColorPickerComponent
											initialColor={color}
											onChange={handleColorChange}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='d-flex flex-stack pt-10'>
							<div className='me-2'>
							</div>

							<div>
								<button
									type='button'
									className='btn btn-lg btn-secondary me-3'
									onClick={() => setShowPage('list')}
								>
									Cancel
								</button>
								<button
									type='submit'
									className='btn btn-lg btn-primary me-3'
								>
									{!isLoading && <span className='indicator-label'>
										Add
										<KTSVG
											path='/media/icons/duotune/arrows/arr064.svg'
											className='svg-icon-3 ms-2 me-0'
										/>
									</span>}
									{isLoading && (
										<span className='indicator-progress' style={{ display: 'block' }}>
											Please wait...
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}
