import { Icon, IconSizeUnion } from './Icon';
import { Filter } from './IconList';

interface Props {
	title?: string;
	alt?: string;
	size?: IconSizeUnion;
	classes?: string;
}

export function FilterIcon({
														 title = 'Filter results',
														 alt = 'Filter results',
														 size = 'xs',
														 classes = 'me-1',
													 }: Props) {
	return (
		<Icon
			type="svg"
			classes={classes}
			alt={alt}
			icon={Filter}
			title={title}
			size={size}></Icon>
	);
}
