import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../setup';
import { AvatarSize } from '../../../components/Avatar';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { setEmployeeTeams } from '../../../CRUD/TeamCRUD';
import { Employee } from '../../../modules/employee/models/Employee.model';
import { Team } from '../../../modules/team/models/Team.model';
import { EmployeeType, TeamType } from '../../../types/EmployeeType';

interface Props {
	employee: Employee | EmployeeType;
	teams: (Team | TeamType)[];
	refresh: (newTeam: Team | TeamType | null) => void;
}

export function ChangeTeamModal({ employee, teams, refresh }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const cancelButtonRef = useRef<HTMLButtonElement>(null);
	const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);

	const handleUpdateTeam = () => {
		if (selectedTeamId === -1) {
			return console.error(`Need to select a team or unassign to update employee assignments`);
		}
		const teamsList = (selectedTeamId === 0) ? [] : [selectedTeamId];
		// currently only handling 1 team, future update to process for multiple teams with multiselect
		setEmployeeTeams(employee.id, teamsList, token)
			.then((emp) => {
				cancelButtonRef.current?.click();
				refresh(emp.data.teams.length ? emp.data.teams[0] : null);
			})
			.catch(err => {
				console.log(err.response);
				toast.error(err.response.data.message, {
					position: 'top-right',
					theme: 'colored',
					autoClose: 3500,
				});
			});
	}

	useEffect(() => {
		setSelectedTeamId(employee.teams?.[0]?.id || -1);
	}, [employee]);

	return (
		<div className='modal fade' id="change_team_modal"
				 data-bs-backdrop="static" aria-hidden='true'>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header d-flex align-items-center justify-content-between">
						<EmployeeBadge
							employee={employee}
							size={AvatarSize.md}
							/>
						<h1>
							Change Team
						</h1>
						<button
							className="btn close_button"
							data-bs-dismiss="modal"
						>
							<CloseButtonIcon />
						</button>
					</div>
					<div className="modal-body mx-0">
						<label className='col-form-label fw-bold fs-6'>Available Teams</label>
						<div>
							<select
								className='form-select form-select-solid'
								data-kt-select2='true'
								data-placeholder='Select option'
								defaultValue={-1}
								value={selectedTeamId}
								onChange={e => setSelectedTeamId(+e.target.value)}
							>
								<option
									value={-1}
									disabled
								>
									Select Team
								</option>
								<option
									value={0}
									className="fw-boldest"
								>
									Unassign from all teams
								</option>
								{teams?.map(team => (
									<option
										key={team.id}
										value={team.id}
									>
										{team.name}
									</option>))}
							</select>
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-between">
						<button className="btn btn-outline-danger"
										ref={cancelButtonRef}
										data-bs-dismiss="modal">
							Cancel
						</button>
						<button
							className="btn btn-primary"
							disabled={selectedTeamId === -1}
							onClick={handleUpdateTeam}>Update</button>
					</div>
				</div>
			</div>
		</div>
	)
}
