import axios from 'axios';

const openai = axios.create({
	baseURL: 'https://api.openai.com',
	headers: {
		'Content-Type': 'application/json',
		'OpenAI-Project': `${process.env.REACT_APP_OPENAI_PROJECT}`,
		'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
	},
});

export async function sendToOpenAiForReview(payload: object) {

	try {
		const res = await openai.post('/v1/chat/completions',
			JSON.stringify(payload),
		);

		return {
			content: res.data.choices[0].message.content,
			raw: res.data,
		};

	} catch (err) {
		console.error('Encountered error communicating with OpenAI', err);
		throw err;
	}
};
