import { useState } from 'react';
import { toast } from 'react-toastify'
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'
import { AttendanceRuleModel } from '../../../../../features/attendance/interfaces/attendance-rule.model';
import { attendanceRuleSlice } from '../../../../../features/attendance/attendance-rule.api'


interface Props {
    setShowPage: (page: string) => void;
    setEditRule: (reason: AttendanceRuleModel) => void;
}

export default function RulesList({ setShowPage, setEditRule }: Props) {
    const { useGetAttendanceRulesQuery, useDeleteAttendanceRuleMutation } = attendanceRuleSlice;
    const [deleteAttendanceRule] = useDeleteAttendanceRuleMutation();
    const { data: rules, refetch } = useGetAttendanceRulesQuery({ isArchived: false });
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRule, setSelectedRule] = useState<number | null>(null);

    const handleEditRule = (rule: AttendanceRuleModel) => {
        setEditRule(rule)
        setShowPage('edit')
    }

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedRule !== null) {
            deleteAttendanceRule({ id: selectedRule })
                .unwrap()
                .then(() => {
                    successToast();
                    refetch();
                })
                .catch(err => {
                    console.log(err);
                    errorToast();
                });
        }
    };

    const handleRemoveRule = (ruleId: number) => {
        setShowDeleteDialog(true);
        setSelectedRule(ruleId);
    };

    return (
        <>
        <div>
            <div className="d-flex">
                <button
                    type='button'
                    className='link-primary btn btn_add mt-2 px-0'
                    onClick={() => setShowPage('create')}
                >
                    + Add New Attendance Rule
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th className='fw-bolder'>Name</th>
                        <th className='fw-bolder'>Occurrence</th>
                        <th className='fw-bolder'>Points To Assign</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rules?.map((rule, index) => {
                        return <tr className='action_line' key={index}>
                            <td className='action_name'>{rule.name}</td>
                            <td className='action_name'>{rule.occurrence}</td>
                            <td className='action_name'>{rule.pointsToAssign}</td>
                            <td className="action_buttons">
                                <span className="btn_edit ms-3"
                                    onClick={() => handleEditRule(rule)}
                                >
                                    <i className="fas fa-edit text-primary fs-4"></i>
                                </span>
                                <span className="btn_remove ms-3"
                                    onClick={() => handleRemoveRule(rule.id)}
                                >
                                    <i className="fas fa-trash text-danger fs-4"></i>
                                </span>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        {showDeleteDialog && (
                <SkeDialogDelete
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    successMessage='Attendance rule deleted'
                    message='Are you sure you want to delete this attendance rule?'
                />
            )}
        </>
    )
}
