import { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { KTSVG } from '../../../../../_library/helpers'
import {
    UpdateAttendanceReasonApiRequestModel
} from '../../../../../features/attendance/interfaces/attendance-reason.model';
import { AttendanceReason } from '../../../../modules/attendance/models/AttendanceReason.model';
import { attendanceReasonsSlice } from '../../../../../features/attendance/attendance-reasons.api';

interface Props {
    setShowPage: (page: string) => void;
    editReason: AttendanceReason;
}

export function EditReason({ setShowPage, editReason }: Props) {
    const [loading, setLoading] = useState(false)
    const [updateAttendanceReason] = attendanceReasonsSlice.useUpdateAttendanceReasonMutation();

    const initValues: UpdateAttendanceReasonApiRequestModel = {
        id: editReason.id,
        content: editReason.content,
        isExcused: editReason.isExcused,
        is_fmla: editReason.is_fmla,
    }

    const reasonSchema = Yup.object().shape({
        content: Yup.string()
            .required('Reason content is required'),
    })

    const handleSubmit = (values: UpdateAttendanceReasonApiRequestModel, actions: FormikValues) => {
        setLoading(true)
        updateAttendanceReason({
            id: editReason.id,
            content: values.content,
            isExcused: values.isExcused,
            is_fmla: values.is_fmla,
        })
            .then(() => {
                setShowPage('list')
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <div className='w-100'>
            <Formik
                validationSchema={reasonSchema}
                initialValues={initValues}
                onSubmit={handleSubmit}
                validateOnMount={true}
            >
                {({ submitForm, isValid, dirty, handleChange, values, getFieldProps }) => (
                    <Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Reason Name</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='content'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='content' />
                            </div>
                        </div>

                        <div className='fv-row mb-7'>
                            <div className="form-check form-check-custom form-check-solid">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="is-excused-check"
                                    {...getFieldProps("isExcused")}
                                    checked={values.isExcused}
                                />
                                <label className="form-check-label fw-bolder text-dark fs-6" htmlFor="is-excused-check">
                                    Excused
                                </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid mt-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="is-fmla-check"
                                    {...getFieldProps("is_fmla")}
                                    checked={values.is_fmla}
                                />
                                <label className="form-check-label fw-bolder text-dark fs-6" htmlFor="is-fmla-check">
                                    FMLA
                                </label>
                            </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                            <div className='me-2'>
                            </div>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-secondary me-3'
                                    onClick={() => setShowPage('list')}
                                    disabled={!isValid}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-sm btn-primary me-3'
                                >
                                    {!loading && <span className='indicator-label'>
                                        Update
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                        />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
