import {useState} from 'react';
import AttendanceRules from './AttendanceRules'
import AttendanceAutomations from './AttendanceAutomations'
import AttendanceReasons from './AttendanceReasons'
import {Spinner} from 'react-bootstrap';
import {Icon} from '../../../../components/icons/Icon';
import {Refresh} from '../../../../components/icons/IconList';
import {toast} from 'react-toastify';
import {refreshAttendancePoints} from '../../../../CRUD/AttendanceCRUD';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '../../../../../setup';

export function Attendances() {
    const [refreshingPoints, setRefreshingPoints] = useState<boolean>(false);
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
    const handleRefreshPoints = () =>{
        console.log("Refreshing attendance points.")
        setRefreshingPoints(true)
        refreshAttendancePoints(token).then(() => {
          toast.success("Attendance points have successfully updated!")
          setRefreshingPoints(false)
        }).catch(err => {
          console.error(err);
          toast.error("Attendance points have failed to update!")
          setRefreshingPoints(false)
        });
    }
    return (
        <div className="card">
            {/* <div className="card-header pt-5"></div> */}
            <div className="card-body py-10">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                    <li className="nav-item">
                        <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_1"
                        >
                            Reasons
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_2"
                        >
                            Rules
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_3"
                        >
                            Automations
                        </a>
                    </li>
                </ul>
                <button
                  className="btn btn-outline-primary border-hover text-hover-light fa-pull-right"
                  disabled={refreshingPoints}
                  onClick={handleRefreshPoints}
                >
                    {!refreshingPoints && (
                      <>
                          Refresh Points <span><Icon
                            type={Refresh.iconType}
                            size="sm"
                            icon={Refresh}
                          /></span>
                      </>
                    )}
                    {refreshingPoints && (
                      <>
                          Updating points...
                          <Spinner
                            size="sm"
                            className="ms-2" />
                      </>
                    )}

                </button>
                <div className="tab-content" id="AttendanceTabContent">
                    <div
                      className="tab-pane fade active show"
                      id="atn_tab_pane_1"
                      role="tabpanel"
                    >
                        <AttendanceReasons />
                    </div>
                    <div className="tab-pane fade" id="atn_tab_pane_2" role="tabpanel">
                        <AttendanceRules />
                    </div>
                    <div className="tab-pane fade" id="atn_tab_pane_3" role="tabpanel">
                        <AttendanceAutomations />
                    </div>
                </div>
            </div>
        </div>
    )
}