import axios from 'axios'
import {
    AttendanceAutomationModel,
    AttendanceModel
} from '../models'
import { CreateAttendanceBulkModel } from '../models/AttendanceModel';
import {axiosTransformErrorResponseHandler} from '../../common/api/common.api'

const API_URL = process.env.REACT_APP_API_URL

export const ATTENDANCES_URL = `${API_URL}/attendances`
export const ATTENDANCE_AUTOMATIONS_URL = `${API_URL}/attendance-automations`

// Attendance Automations
export function createAttendanceAutomation(name: string, description: string, triggerPoints: number, disciplineStepId: number, token: string) {
    return axios({
        method: 'post',
        url: ATTENDANCE_AUTOMATIONS_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            name: name,
            description: description,
            triggerPoints: triggerPoints,
            disciplineStepId: disciplineStepId
        }
    })
}

export function getAttendanceAutomations(isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${ATTENDANCE_AUTOMATIONS_URL}?isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


export function updateAttendanceAutomation(automationId: number, data: AttendanceAutomationModel, token: string) {
    return axios({
        method: 'patch',
        url: `${ATTENDANCE_AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removeAttendanceAutomation(automationId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${ATTENDANCE_AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Attendances
export function createAttendance(
    data: AttendanceModel,
    token: string
  ) {
    return axios({
      method: 'post',
      url: ATTENDANCES_URL,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error creating attendance'));
  }

export function createBulkAttendance(
  data: CreateAttendanceBulkModel,
  token: string,
) {
  return axios({
    method: 'post',
    url: `${ATTENDANCES_URL}/bulk`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function refreshAttendancePoints(
  token: string,
) {
  return axios({
    method: 'get',
    url: `${ATTENDANCES_URL}/updatePoints`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  });
}

  export function getAttendances(limit: number, teamId: number, employeeId: number, token: string) {
    return axios({
      method: 'get',
      url: `${ATTENDANCES_URL}?limit=${limit}&teamId=${teamId}&employeeId=${employeeId}`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  export function getOneAttendance(attendanceId: number, token: string) {
    return axios({
      method: 'get',
      url: `${ATTENDANCES_URL}/${attendanceId}`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  export function updateAttendance(attendanceId: number, data: AttendanceModel, token: string) {
    return axios({
      method: 'patch',
      url: `${ATTENDANCES_URL}/${attendanceId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    })
      .catch(err => axiosTransformErrorResponseHandler(err, 'Error updating attendance'));

  }

  export function removeAttendance(attendanceId: number, token: string) {
    return axios({
      method: 'delete',
      url: `${ATTENDANCES_URL}/${attendanceId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
  }
