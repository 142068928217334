import { parseJSON } from 'date-fns';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { aiReviewModalSlice } from '../../../../features/ai/review/ai.review-modal.slice';
import { AiPerformanceReviewModel } from '../../../../features/ai/review/interfaces/ai.performance.review.model';
import { ConnectionDetails, VwConnection } from '../../../../features/connection/interfaces/vw_connection.model';
import { WorkflowStepToken } from '../../../../features/workflow/interfaces/workflow.model';
import { DateFormatUsingDateFns, SHORT_DATE, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { parseDateObjectToNewFormat } from '../../../modules/date/utils/DateHelpers';

interface Props {
	record: VwConnection<ConnectionDetails>;
	showDate?: boolean;
	rxKey: string | number;
}

export function ConnectionHistoryReviewRow({
																						 record,
																						 showDate = true,
																						 rxKey,
																					 }: Props) {
	const dispatch = useDispatch();
	let statusCell;
	switch (record.status) {
		case WorkflowStepToken.Draft:
		case WorkflowStepToken.ChangesRequired:
		case WorkflowStepToken.RequestedApproval:
		case WorkflowStepToken.Approved:
		case WorkflowStepToken.Issued:
		case WorkflowStepToken.Submitted:
			statusCell = (
				<span className="fw-light badge badge-light-danger text-dark rounded">
					{record.status}
				</span>
			);
			break;
		case WorkflowStepToken.Declined:
		case WorkflowStepToken.Filed:
		case WorkflowStepToken.Deleted:
			statusCell = (
				<span className="fw-light badge bg-light text-dark rounded">
					{record.status}: {parseDateObjectToNewFormat(parseJSON(
					record.details?.timestamps?.updated_at), DateFormatUsingDateFns.UsFriendlyTwoYear)}
				</span>
			);
			break;
		default:
			statusCell = null;
			break;
	}

	return (
		<tr
			className="cursor-pointer"
			onClick={() => {
				console.log('clicking to open ai review from connection history', record);
				dispatch(aiReviewModalSlice.actions.show({
					token: record.details.info.workflow?.workflow_token || '',
					employeeId: record?.employee_id || 0,
					mode: ModalDisplayMode.Read,
					record: (record as Omit<AiPerformanceReviewModel, 'data' | 'ai_response' | 'subject_id'>),
					recordId: record.id,
				}));
			}}
			key={rxKey}>
			<td>{showDate && moment(record.date, SHORT_DATE).format(US_FRIENDLY_DATE)}</td>
			<td>
				<span className="me-2 fs-7 fw-bold badge-light-info px-3 rounded text-uppercase text-nowrap">
						Performance Review
				</span>
			</td>
			<td className="d-flex flex-column">
				{statusCell}
			</td>
			<td className="text-break">
				<span className="fst-italic">No note</span>
			</td>
			<td>{record.creator_name}</td>
			<td className="d-flex">
				<button
					className="btn btn-sm ms-16 invisible"
					disabled={true}
				><i className="fas fa-trash text-danger fs-4 "></i></button>
			</td>
		</tr>
	);
}
