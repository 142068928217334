import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { SupportedLanguageEnum, SupportedLanguageReadableEnum } from '../models/Language.model';

const API_URL = process.env.REACT_APP_API_URL;

export function translateText(fromLanguage: string, toLanguage: string, textToTranslate: string, token: string): AxiosPromise<string> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/translate`,
		method: 'post',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			fromLanguage,
			toLanguage,
			content: textToTranslate,
		}
	};
	return axios(config)
}

export function getAbbrFromLanguageName(name: string): string {
	return SupportedLanguageEnum[name as keyof typeof SupportedLanguageEnum];
}

export function getNameFromLanguageAbbr(abbr: string): SupportedLanguageReadableEnum {
	return SupportedLanguageReadableEnum[abbr as keyof typeof SupportedLanguageReadableEnum];
}
