import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { getAndSetBearerToken, SkeResponseApiFormat } from '../../common/api/common.api';
import {
	GetOneWorkflowByTokenApiRequestModel,
	GetOneWorkflowResults,
} from './interfaces/workflow.model';

const workflowURL = process.env.REACT_APP_API_URL + '/workflows'
export enum WorkflowTagTypes {
	AIPerformanceReview= 'AI_PERFORMANCE_REVIEW'
}
export const workflowSlice = createApi({
	reducerPath: 'workflowApi',
	tagTypes: [WorkflowTagTypes.AIPerformanceReview],
	baseQuery: fetchBaseQuery({
		baseUrl: workflowURL,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) =>({
		getOneWorkflow: builder.query<GetOneWorkflowResults, GetOneWorkflowByTokenApiRequestModel>({
			query: (request: GetOneWorkflowByTokenApiRequestModel) => {
				let url = `${request.args.token}`;
				return {
					url,
					params: request.query,
				};
			},
			transformResponse: (response: SkeResponseApiFormat<GetOneWorkflowResults>) => {
				return response.results;
			},
			transformErrorResponse: (response: {status: string | number}, meta, arg) => response.status,
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		})
	})
})
