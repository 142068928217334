import React, { useState } from 'react'
import { KTSVG } from '../../../_library/helpers'
import DatePicker from "react-datepicker"
import moment from 'moment'
import { teamSlice } from '../../../features/team/team.api';
import { getSupervisors } from '../../CRUD/TeamCRUD';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../models'
import { UserRoleEnum } from '../../modules/user/models/User.model';

type ChildProps = {
    applyFilter: (
        e: React.MouseEvent,
        start_date: string,
        end_date: string,
        teamId: number,
        supervisorId: number,
    ) => void;
    modalId:string;
}

export const ConnectionListFilterModal: React.FC<ChildProps> = (props) => {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [supervisors, setSupervisors] = useState<UserModel[]>([])
    const [startDate, setStartDate] = useState<Date | null>(moment().subtract(1, 'years').toDate());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [teamId, setTeamId] = useState(0)
    const [supervisorId, setSupervisorId] = useState(0)
    const { data: teams} = teamSlice.useGetAllTeamsQuery();

    React.useEffect(() => {
        if (user.roles?.includes('SuperAdmin')) {
            if (user.company?.id) {
                getSupervisors(user.company?.id, token)
                    .then(({ data }) => setSupervisors(data.items))
                    .catch(err => console.log(err))
            }
        }
    }, [user, token])

    return (
        <div className='modal fade' id={props.modalId} aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <h2>Filter</h2>
                        <div className='btn btn-sm btn-icon btn-active-color-primary position-absolute modal-close' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='modal-body mx-0'>
                        <div className='card-body pt-0 pb-9'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-6'>Start Date</label>
                                    <div className='col-lg-12 fv-row'>
                                        <DatePicker selected={startDate} onChange={(d) => setStartDate(d)} className="form-control form-control-lg form-control-solid" />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-6'>End Date</label>
                                    <div className='col-lg-12 fv-row'>
                                        <DatePicker selected={endDate} onChange={(d) => setEndDate(d)} className="form-control form-control-lg form-control-solid" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='col-form-label fw-bold fs-6'>Teams</label>
                                    <select
                                        className='form-select form-select-solid'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        defaultValue={''}
                                        onChange={(e) => setTeamId(parseInt(e.target.value))}
                                    >
                                        <option value={0}>All</option>
                                        {
                                            teams && teams.length && teams.map(team => (
                                                <option key={team.id} value={team.id}>{team.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {user.roles?.includes(UserRoleEnum.SuperAdmin) && <div className='col-6'>
                                    <label className='col-form-label fw-bold fs-6'>Supervisors</label>
                                    <select
                                        className='form-select form-select-solid'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        defaultValue={''}
                                        onChange={(e) => setSupervisorId(parseInt(e.target.value))}
                                    >
                                        <option value={0}>All</option>
                                        {
                                            supervisors.map(supervisor => (
                                                <option key={supervisor.id} value={supervisor.id}>{supervisor.firstName} {supervisor.lastName}</option>
                                            ))
                                        }
                                    </select>
                                </div>}
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-white btn-active-light-primary me-2'
                                data-bs-dismiss='modal'
                            >
                                Cancel
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-bs-dismiss='modal' onClick={(e) =>
                                props.applyFilter(
                                    e,
                                    moment(startDate).format('YYYY-MM-DD'),
                                    moment(endDate).format('YYYY-MM-DD'),
                                    teamId,
                                    supervisorId
                                )}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
