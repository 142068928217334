import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { getAttendanceAutomations, removeAttendanceAutomation } from '../../../../CRUD/AttendanceCRUD'
import { AttendanceAutomationType } from '../../../../types/AttendanceAutomationType'
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'


type Props = {
    setShowPage: (page: string) => void
    setEditAutomation: (automations: AttendanceAutomationType) => void
}

export default function AttendanceAutomationsList({ setShowPage, setEditAutomation }: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [automations, setAutomations] = React.useState<AttendanceAutomationType[]>([])
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [selectedAttendanceAutomation, setSelectedAttendanceAutomation] = useState<number | null>(null);


    const handleEditAutomation = (automation: AttendanceAutomationType) => {
        setEditAutomation(automation)
        setShowPage('edit')
    }

    const handleCancelDelete = () => {
        setShowDeleteDialog(false)
    }

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedAttendanceAutomation !== null) {
            removeAttendanceAutomation(selectedAttendanceAutomation, token)
                .then(() => {
                    getAttendanceAutomations(false, token)
                    .then(({ data }) => {
                        successToast();
                        setAutomations(data.items)
                    })
                    .catch(err => {
                        console.error(err);
                    })
                })
                .catch(err => {
                    console.error(err);
                    errorToast();
                })

        }
    }

    const removeSelectedAttendanceAutomation = (attendanceAutomationId: number) => {
        setShowDeleteDialog(true);
        setSelectedAttendanceAutomation(attendanceAutomationId);
    }


    useEffect(() => {
        let abortController = new AbortController();

        getAttendanceAutomations(false, token)
            .then(({ data }) => {
                setAutomations(data.items)
            })
            .catch(err => console.log(err))

        return () => {
            abortController.abort()
        }
    }, [token])

    return (
        <>
        <div>
            <div className="d-flex">
                <button
                    type='button'
                    className='link-primary btn btn_add mt-2 px-0'
                    onClick={() => setShowPage('create')}
                >
                    + Add New Attendance Automation
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th className='fw-bolder'>Name</th>
                        <th className='fw-bolder'>Description</th>
                        <th className='fw-bolder'>Trigger Points</th>
                        <th className='fw-bolder'>Discipline Step</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {automations.map((automation, index) => {
                        return <tr className='action_line' key={index}>
                            <td className='action_name'>{automation.name}</td>
                            <td className='action_name'>{automation.description}</td>
                            <td className='action_name'>{automation.triggerPoints}</td>
                            <td className='action_name'>{automation.disciplineStep?.name}</td>
                            <td className="action_buttons">
                                <span className="btn_edit ms-3"
                                    onClick={() => handleEditAutomation(automation)}
                                >
                                    <i className="fas fa-edit text-primary fs-4"></i>
                                </span>
                                <span className="btn_remove ms-3"
                                    onClick={() => removeSelectedAttendanceAutomation(automation.id)}
                                >
                                    <i className="fas fa-trash text-danger fs-4"></i>
                                </span>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        {showDeleteDialog && (
            <SkeDialogDelete
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                successMessage='Attendance automation deleted'
                message='Are you sure you want to delete this automation?'
            />
            )}
    </>
    )
}
