import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import {
	CreateAttendanceReasonApiRequestModel,
	UpdateAttendanceReasonApiRequestModel,
	GetAttendanceReasonApiRequestModel,
	DeleteAttendanceReasonApiRequestModel,
	AttendanceReasonModel,
} from './interfaces/attendance-reason.model';

export enum AttendanceReasonsTagTypes {
	AttendanceReasons = 'ATTENDANCE_REASONS',
}

export const attendanceReasonsSlice = createApi({
	reducerPath: 'attendanceReasonApi',
	tagTypes: [AttendanceReasonsTagTypes.AttendanceReasons],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/attendance-reasons`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getAttendanceReason: builder.query<AttendanceReasonModel[], GetAttendanceReasonApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponseWithoutPagination<AttendanceReasonModel>) => {
				return response.items.sort((a, b) => a.content.localeCompare(b.content));
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.map(({ id }) => ({
							type: AttendanceReasonsTagTypes.AttendanceReasons,
							id,
						})),
						{
							type: AttendanceReasonsTagTypes.AttendanceReasons,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: AttendanceReasonsTagTypes.AttendanceReasons,
						id: 'LIST',
					},
				];
			},
		}),
		createAttendanceReason: builder.mutation<AttendanceReasonModel, CreateAttendanceReasonApiRequestModel>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			transformResponse: (response: AttendanceReasonModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: [
				{
					type: AttendanceReasonsTagTypes.AttendanceReasons,
					id: 'LIST',
				},
			],
		}),
		updateAttendanceReason: builder.mutation<AttendanceReasonModel, UpdateAttendanceReasonApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			transformResponse: (response: AttendanceReasonModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: AttendanceReasonsTagTypes.AttendanceReasons,
							id: 'LIST',
						},
						{
							type: AttendanceReasonsTagTypes.AttendanceReasons,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteAttendanceReason: builder.mutation<void, DeleteAttendanceReasonApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: AttendanceReasonsTagTypes.AttendanceReasons,
						id: 'LIST',
					},
					{
						type: AttendanceReasonsTagTypes.AttendanceReasons,
						id: args.id,
					},
				];
			},
		}),
	}),
});
