import moment from 'moment'
import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_library/helpers'
import { getPagination } from '../../CRUD/TeamCRUD'
import { DisciplineStatusEnum } from '../../modules/discipline/models/Discipline.models';
import { DisciplineType } from '../../types/DisciplineType'
import { AllFilterType } from '../../types/FilterType'
import * as search from './redux/SearchRedux'

import './SearchForAdvanced.scss'

export function SearchForAdvanced() {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const allFilter: AllFilterType = useSelector<RootState>(({ search }) => search.allFilter, shallowEqual) as AllFilterType
    const dispatch = useDispatch()

    const pageLimit = 100

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        const searchValue = e.currentTarget.value
        // let resSearchText = 'Search: '

        if (e.key === 'Enter') {
            dispatch(search.searchActions.setAllFilter({ ...allFilter, contains: searchValue }))
            let eAppendString = 'search=' + searchValue
            let cStatusAppendString = ''
            if (searchValue === DisciplineStatusEnum.Draft ||
                searchValue === DisciplineStatusEnum.RequestedChange ||
                searchValue === DisciplineStatusEnum.RequestedApproval ||
                searchValue === DisciplineStatusEnum.DisciplineDeclined ||
                searchValue === DisciplineStatusEnum.Approved ||
                searchValue === DisciplineStatusEnum.IssuedToEmployee ||
                searchValue === DisciplineStatusEnum.SubmittedToHr ||
                searchValue === DisciplineStatusEnum.ReceivedByHr ||
                searchValue === DisciplineStatusEnum.DocumentationFiled) {
                cStatusAppendString = 'status=' + searchValue
            }
            if (!!allFilter.start && !!allFilter.end) {
                cStatusAppendString += '&fromDate=' + moment(allFilter.start).format('YYYY-MM-DD') + '&toDate=' + moment(allFilter.end).format('YYYY-MM-DD')
            }
            let cSearchAppendString = searchValue !== '' ? 'search=' + searchValue : ''
            if (!!allFilter.start && !!allFilter.end) {
                cSearchAppendString += '&fromDate=' + moment(allFilter.start).format('YYYY-MM-DD') + '&toDate=' + moment(allFilter.end).format('YYYY-MM-DD')
            }
            try {
                const employeeResponse = await getPagination(`api/employees?limit=${pageLimit}&${eAppendString}`, token)
                dispatch(search.searchActions.setEmployees(employeeResponse.data.items))
                let statusDiscipline: DisciplineType[] = []
                if (searchValue === DisciplineStatusEnum.Draft ||
                  searchValue === DisciplineStatusEnum.RequestedChange ||
                  searchValue === DisciplineStatusEnum.RequestedApproval ||
                  searchValue === DisciplineStatusEnum.DisciplineDeclined ||
                  searchValue === DisciplineStatusEnum.Approved ||
                  searchValue === DisciplineStatusEnum.IssuedToEmployee ||
                  searchValue === DisciplineStatusEnum.SubmittedToHr ||
                  searchValue === DisciplineStatusEnum.ReceivedByHr ||
                  searchValue === DisciplineStatusEnum.DocumentationFiled) {
                    const statusDisciplineResponse = await getPagination(`api/disciplines?limit=${pageLimit}&${cStatusAppendString}`, token)
                    statusDiscipline = statusDisciplineResponse.data.items
                }
                const searchDisciplineResponse = await getPagination(`api/disciplines?limit=${pageLimit}&${cSearchAppendString}`, token)
                const arr = [...statusDiscipline, ...searchDisciplineResponse.data.items]
                const uniqueIds = new Set();
                dispatch(search.searchActions.setDisciplines(arr.filter(element => {
                    const isDuplicate = uniqueIds.has(element.id);

                    uniqueIds.add(element.id);

                    if (!isDuplicate) {
                        return true;
                    }

                    return false;
                })))

                const searchAttendanceResponse = await getPagination(`api/attendances?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setAttendances(searchAttendanceResponse.data.items))

                const searchCoachingResponse = await getPagination(`api/coachings?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setCoachings(searchCoachingResponse.data.items))

                const searchRecognitionResponse = await getPagination(`api/recognitions?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setRecognitions(searchRecognitionResponse.data.items))

                // resSearchText += `Contains: ${searchValue} `
                // if (!!allFilter.start && !!allFilter.end) {
                //     resSearchText += `before: ${moment(allFilter.start).format('YYYY-MM-DD')} after: ${moment(allFilter.end).format('YYYY-MM-DD')}`
                // }

                // setSearchText(resSearchText)
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        const searchValue = allFilter.contains

        let eAppendString = 'search=' + searchValue
        let cStatusAppendString = ''
        if (searchValue === DisciplineStatusEnum.Draft ||
          searchValue === DisciplineStatusEnum.RequestedChange ||
          searchValue === DisciplineStatusEnum.RequestedApproval ||
          searchValue === DisciplineStatusEnum.DisciplineDeclined ||
          searchValue === DisciplineStatusEnum.Approved ||
          searchValue === DisciplineStatusEnum.IssuedToEmployee ||
          searchValue === DisciplineStatusEnum.SubmittedToHr ||
          searchValue === DisciplineStatusEnum.ReceivedByHr ||
          searchValue === DisciplineStatusEnum.DocumentationFiled) {
            cStatusAppendString = 'status=' + searchValue
        }
        if (!!allFilter.start && !!allFilter.end) {
            cStatusAppendString += '&fromDate=' + moment(allFilter.start).format('YYYY-MM-DD') + '&toDate=' + moment(allFilter.end).format('YYYY-MM-DD')
        }
        let cSearchAppendString = searchValue !== '' ? 'search=' + searchValue : ''
        if (!!allFilter.start && !!allFilter.end) {
            cSearchAppendString += '&fromDate=' + moment(allFilter.start).format('YYYY-MM-DD') + '&toDate=' + moment(allFilter.end).format('YYYY-MM-DD')
        }
        try {
            (async () => {
                const employeeResponse = await getPagination(`api/employees?limit=${pageLimit}&${eAppendString}`, token)
                dispatch(search.searchActions.setEmployees(employeeResponse.data.items))
                let statusDiscipline: DisciplineType[] = []
                if (searchValue === DisciplineStatusEnum.Draft ||
                  searchValue === DisciplineStatusEnum.RequestedChange ||
                  searchValue === DisciplineStatusEnum.RequestedApproval ||
                  searchValue === DisciplineStatusEnum.DisciplineDeclined ||
                  searchValue === DisciplineStatusEnum.Approved ||
                  searchValue === DisciplineStatusEnum.IssuedToEmployee ||
                  searchValue === DisciplineStatusEnum.SubmittedToHr ||
                  searchValue === DisciplineStatusEnum.ReceivedByHr ||
                  searchValue === DisciplineStatusEnum.DocumentationFiled) {
                    const statusDisciplineResponse = await getPagination(`api/disciplines?limit=${pageLimit}&${cStatusAppendString}`, token)
                    statusDiscipline = statusDisciplineResponse.data.items
                }
                const searchDisciplineResponse = await getPagination(`api/disciplines?limit=${pageLimit}&${cSearchAppendString}`, token)
                const arr = [...statusDiscipline, ...searchDisciplineResponse.data.items]
                const uniqueIds = new Set();
                const resCons = arr.filter(element => {
                    const isDuplicate = uniqueIds.has(element.id);

                    uniqueIds.add(element.id);

                    if (!isDuplicate) {
                        return true;
                    }

                    return false;
                })
                dispatch(search.searchActions.setDisciplines(resCons))

                const searchAttendanceResponse = await getPagination(`api/attendances?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setAttendances(searchAttendanceResponse.data.items))

                const searchCoachingResponse = await getPagination(`api/coachings?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setCoachings(searchCoachingResponse.data.items))

                const searchRecognitionResponse = await getPagination(`api/recognitions?limit=${pageLimit}&${cSearchAppendString}`, token)
                dispatch(search.searchActions.setRecognitions(searchRecognitionResponse.data.items))

                // resSearchText += `Contains: ${searchValue} `
                // if (!!allFilter.start && !!allFilter.end) {
                //     resSearchText += `before: ${moment(allFilter.start).format('YYYY-MM-DD')} after: ${moment(allFilter.end).format('YYYY-MM-DD')}`
                // }

                // setSearchText(resSearchText)
            })()
        } catch (error) {
            console.log(error)
        }
    }, [allFilter, dispatch, token])

    return (
        <div className="search_for_advanced_wrapper d-flex align-items-center w-100">
            <div className='search_text_wrapper h-50px justify-content-start position-relative ms-auto'
            >
                <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 me-3 position-absolute' />
                <input
                    type="text"
                    className='mw-lg-300px w-100 h-100 ps-15'
                    defaultValue=''
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    )
}
