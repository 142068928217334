import { Icon, IconSizeUnion } from './Icon';
import { Translate } from './IconList';

interface Props {
	title?: string;
	alt?: string;
	size?: IconSizeUnion;
	classes?: string;
}

export function TranslateIcon({
														 title = 'Content was translated',
														 size = 'md',
														 classes = 'me-1',
													 }: Props) {
	return (
		<Icon
			type="svg"
			classes={classes}
			use="decorative"
			icon={Translate}
			title={title}
			size={size}></Icon>
	);
}
