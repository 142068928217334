import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import { format, subYears } from 'date-fns';
import { get, map } from 'lodash';
import moment from 'moment/moment';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AvatarSize } from '../../../../app/components/Avatar';
import { DatePicker } from '../../../../app/components/DatePicker';
import { EmployeeBadge } from '../../../../app/components/EmployeeBadge';
import { Icon } from '../../../../app/components/icons/Icon';
import { Printer, Refresh, Save, Trash } from '../../../../app/components/icons/IconList';
import { SkeTextbox } from '../../../../app/components/Textbox';
import { ToggleSwitch } from '../../../../app/components/ToggleSwitch';
import {
	getEmployeeAttendances,
	getEmployeeCoaching,
	getEmployeeDisciplines,
	getEmployeeRecognition,
} from '../../../../app/CRUD/TeamCRUD';
import { DateFormatUsingDateFns, SHORT_DATE } from '../../../../app/modules/date/DateFormat.const';
import { parseDateObjectToNewFormat, parseYmdDatesToDateObject } from '../../../../app/modules/date/utils/DateHelpers';
import { Employee } from '../../../../app/modules/employee/models/Employee.model';
import { User } from '../../../../app/modules/user/models/User.model';
import { CoachingType } from '../../../../app/types/CoachingType';
import { ConnectionTypeEnum } from '../../../../app/types/ConnectionType';
import { RecognitionType } from '../../../../app/types/RecognitionType';
import { SortDateEnum } from '../../../../app/types/SortDateEnum';
import { SkeLegal } from '../../../../common/components/legal';
import { SkeModal } from '../../../../common/modals/generic/SkeModal';
import { SkePdf } from '../../../../common/pdf/SkePdf';
import clipboardPlus from '../../../../images/icons/clipboard-plus.svg';
import logo from '../../../../images/logos/secchi_light_logo.png';
import { RootState } from '../../../../setup';
import { connectionSlice } from '../../../connection/connection.api';
import {
	ActionUser,
	AiPerformanceReviewTone,
	Connection,
	CreateAiPerformanceReviewApiRequestModel,
	CreateAiPerformanceReviewPayload,
	UpdateAiPerformanceReviewApiRequestModel,
	UpdateAiPerformanceReviewPayload,
} from '../../../connection/interfaces/connection.model';
import {
	ConnectionSettingTokenEnum,
	WorkflowAction,
	WorkflowActionToken,
	WorkflowCompactedUser,
	WorkflowDisplayMethod,
	WorkflowStep,
	WorkflowStepToken,
} from '../../../workflow/interfaces/workflow.model';
import { workflowSlice } from '../../../workflow/workflow.api';
import { sendToOpenAiForReview } from '../../services/OpenAIService';
import { maskData } from '../../utils/MaskData';
import { aiReviewModalSlice } from '../ai.review-modal.slice';
import { aiSystemPrompt } from '../generative/ai_system_prompt';
import { aiUserPrompt } from '../generative/user_prompt_template';

interface Props {
	show: boolean;
	employee: Employee;
	token: string;
	onClose: (action: boolean) => void;
	onSubmit: () => void;
	reviewId?: number;
}

interface FooterSettings {
	owner: DropDown<WorkflowCompactedUser>;
	reviewer: DropDown<WorkflowCompactedUser>;
	dropdown: DropDown<WorkflowAction>;
	save: Button;
	discard: Button;
	print: Button;
	noActions?: boolean;
}

interface DropDown<T> {
	name: string;
	show: boolean;
	items: T[];
}

interface Button {
	name: string;
	show: boolean;
	style?: string;
	hint?: string;
}


export function AIReviewModal({
																show,
																employee,
																token,
																onClose,
																reviewId,
															}: Props) {
	const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User;
	const [showModal, setShowModal] = useState<boolean>(show);
	const [showGeneratedReview, setShowGeneratedReview] = useState<boolean>(false);
	const [restricted, setRestricted] = useState<boolean>(false);
	const [isRecognitionSelected, setIsRecognitionSelected] = useState<boolean>(true);
	const [isCoachingSelected, setIsCoachingSelected] = useState<boolean>(true);
	const [isAttendanceSelected, setIsAttendanceSelected] = useState<boolean>(true);
	const [isCorActionSelected, setIsCorActionSelected] = useState<boolean>(true);
	const [reviewTone, setReviewTone] = useState<AiPerformanceReviewTone>(AiPerformanceReviewTone.Neutral);
	const [reviewStartDate, setReviewStartDate] = useState<Date>(subYears(new Date(), 1));
	const [reviewEndDate, setReviewEndDate] = useState<Date>(new Date());
	const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
	const [showGeneratingSpinner, setShowGeneratingSpinner] = useState<boolean>(false);
	const [maskMap, setMaskMap] = useState(new Map());
	const [employeeReview, setEmployeeReview] = useState<string>();
	const [changesRequested, setChangesRequested] = useState<string>();
	const [reviewTextRows, setReviewTextRows] = useState<number>(80);
	const [progressMessage, setProgressMessage] = useState<string>('Loading');
	const [reviewers, setReviewers] = useState<WorkflowCompactedUser[]>([]);
	const [selectedReviewer, setSelectedReviewer] = useState<WorkflowCompactedUser>();
	const [owners, setOwners] = useState<WorkflowCompactedUser[]>([]);
	const [selectedOwner, setSelectedOwner] = useState<WorkflowCompactedUser>();
	const [rawAiResponse, setRawAiResponse] = useState<any>();
	const {
		data: workflowResponse,
		refetch,
		isUninitialized,
	} = workflowSlice.useGetOneWorkflowQuery(show && !reviewId ? {
		// TODO: probably should put filter in state to switch things. only needed for NEW reviews; this should not run
		//  for existing
		args: {
			token: ConnectionSettingTokenEnum.AiPerformanceReview,
		},
		query: {
			team_id: employee.teams?.[0]?.id,
			defaultStatus: true,
		},
	} : skipToken);
	const [selectedWorkflowStep, setSelectedWorkflowStep] = useState<WorkflowStep>();
	const [availableActions, setAvailableActions] = useState<WorkflowAction[]>([]);
	const [selectedAction, setSelectedAction] = useState<WorkflowAction>();
	const [aiPerformanceReview, setAiPerformanceReview] = useState<Partial<Connection>>();
	const [footerSettings, setFooterSettings] = useState<FooterSettings>();
	const [createConnection, createStatus] = connectionSlice.useCreateConnectionMutation();
	const [updateConnection, updateStatus] = connectionSlice.useUpdateConnectionMutation();
	const [deleteConnection] = connectionSlice.useDeleteConnectionMutation();
	const [disableSaveReview, setDisableSaveReview] = useState<boolean>(false);
	const {data: savedReview} = connectionSlice.useGetOneQuery(reviewId ? {
		id: +reviewId,
		token: ConnectionSettingTokenEnum.AiPerformanceReview,
	} : skipToken);
	const [showPdfModal, setShowPdfModal] = useState<boolean>(false);
	const dispatch = useDispatch();

	const resetModal = () => {
		setIsSubmitEnabled(false);
		setIsRecognitionSelected(true);
		setIsCoachingSelected(true);
		setIsAttendanceSelected(true);
		setIsCorActionSelected(true);
		setShowGeneratedReview(false);
		setRawAiResponse(undefined);
		setAvailableActions([]);
		setReviewTone(AiPerformanceReviewTone.Neutral);
		setEmployeeReview('');
		setReviewStartDate(subYears(new Date(), 1));
		setReviewEndDate(new Date());
		setMaskMap(new Map());
		// setAIPerformanceWorkflow(undefined);
		if (isUninitialized) {
			return;
		}
		refetch();
	};

	const handleReviewStartDateChange = (date: Date | null) => {
		if (date) {
			setReviewStartDate(date);
		}
	};

	const handleReviewEndDateChange = (date: Date | null) => {
		if (date) {
			setReviewEndDate(date);
		}
	};

	const getConnection = async (connection: ConnectionTypeEnum): Promise<any> => {
		const opts: any = {
			fromDate: moment(reviewStartDate).format(SHORT_DATE),
			toDate: moment(reviewEndDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			limit: 100,
		};

		if (!employee.id) {
			throw new Error('No employee id set');
		}

		try {
			switch (connection) {
				case ConnectionTypeEnum.Recognition: {
					const {data} = await getEmployeeRecognition(employee.id, token, opts);
					return data.items;
				}
				case ConnectionTypeEnum.Coaching: {
					const {data} = await getEmployeeCoaching(employee.id, token, opts);
					return data.items;
				}
				case ConnectionTypeEnum.Attendance: {
					opts['includeFuture'] = true;
					const {data} = await getEmployeeAttendances(employee.id, token, opts);
					return data.items;
				}
				case ConnectionTypeEnum.Discipline: {
					const {data} = await getEmployeeDisciplines(employee.id, token, opts);
					return data.items;
				}
			}
		} catch (err: any) {
			return [];
		}

	};

	const getConnectionData = async (connection: ConnectionTypeEnum) => {
		let filteredData = [{}];
		let filteredItem: any = {
			type: '',
			date: '',
		};
		switch (connection) {
			case ConnectionTypeEnum.Recognition: {
				if (isRecognitionSelected) {
					setProgressMessage('Gathering recognitions');
					const data: RecognitionType[] = await getConnection(ConnectionTypeEnum.Recognition);
					let filteredData = [{}];
					data.forEach((item) => {
						filteredItem.type = item.category.name;
						filteredItem.date = item.date;
						filteredData.push(filteredItem);
					});
					return JSON.stringify(filteredData);
				} else {
					return 'Data Not Provided';
				}
			}
			case ConnectionTypeEnum.Coaching: {
				if (isCoachingSelected) {
					setProgressMessage('Checking coachings');
					const data: CoachingType[] = await getConnection(ConnectionTypeEnum.Coaching);
					data.forEach((item) => {
						filteredItem.type = item.category.name;
						filteredItem.date = item.date;
						filteredData.push(filteredItem);
					});
					return JSON.stringify(filteredData);
				} else {
					return 'Data Not Provided';
				}
			}
			case ConnectionTypeEnum.Attendance: {
				if (isAttendanceSelected) {
					setProgressMessage('Observing attendances');
					const data = await getConnection(ConnectionTypeEnum.Attendance);
					data.forEach((item: any) => {
						filteredItem.type = item?.occurrence;
						filteredItem.date = item.date;
						filteredData.push(filteredItem);
					});
					return JSON.stringify(filteredData);
				} else {
					return 'Data Not Provided';
				}
			}
			case ConnectionTypeEnum.Discipline: {
				if (isCorActionSelected) {
					setProgressMessage('Examining disciplines');
					const data = await getConnection(ConnectionTypeEnum.Discipline);
					data.forEach((item: any) => {
						filteredItem.type = item.step.type;
						filteredItem['name'] = item.step.name;
						filteredItem.date = item.date;
						filteredData.push(filteredItem);
					});
					return JSON.stringify(filteredData);
				} else {
					return 'Data Not Provided';
				}
			}
		}
	};

	function mask(data: string) {
		const mapKey = maskData();
		setMaskMap(map => new Map(map.set(mapKey, data)));
		return mapKey;
	}

	const buildJSONMessage = (messageJSON: object) => {
		return {
			model: process.env.REACT_APP_OPENAI_CHATGPT_MODEL,
			frequency_penalty: Number(process.env.REACT_APP_OPENAI_CHATGPT_FREQUENCY_PENALTY),
			max_tokens: Number(process.env.REACT_APP_OPENAI_CHATGPT_MAX_TOKENS),
			presence_penalty: Number(process.env.REACT_APP_OPENAI_CHATGPT_PRESENCE_PENALTY),
			response_format: JSON.parse('{ "type": "json_object" }'),
			temperature: Number(process.env.REACT_APP_OPENAI_CHATGPT_TEMPERATURE),
			top_p: Number(process.env.REACT_APP_OPENAI_CHATGPT_TOP_P),
			messages: messageJSON,
		};
	};

	const buildUserMessage = async () => {
		setProgressMessage('Analyzing data');
		const recognition: any = await getConnectionData(ConnectionTypeEnum.Recognition);
		const coaching: any = await getConnectionData(ConnectionTypeEnum.Coaching);
		const attendance: any = await getConnectionData(ConnectionTypeEnum.Attendance);
		const discipline: any = await getConnectionData(ConnectionTypeEnum.Discipline);
		setProgressMessage('Preparing your report');
		const empName = mask(employee.firstName + ' ' + employee.lastName);
		return aiUserPrompt.replace('{{TONE}}', reviewTone)
											 .replace('{{EMPLOYEE_NAME}}', '{' + empName + '}')
											 .replace('{{SOURCE_RECOGNITION}}', recognition)
											 .replace('{{SOURCE_COACHING}}', coaching)
											 .replace('{{SOURCE_ATTENDANCE}}', attendance)
											 .replace('{{SOURCE_CORRECTIVE_ACTION}}', discipline)
											 .replace('{{CORE_VALUES}}', get(user, 'company.settings.values', 'MISSING COMPANY CORE VALUES'));
	};

	const getReviewRows = (rows: [{header: string, content: string}]) => {
		let reviewRows = '';
		for (let row of rows) {
			reviewRows += row.header + '\n';
			reviewRows += row.content + '\n \n';
		}
		return reviewRows;
	};

	async function callOpenAI(payload: object) {
		try {
			let {
				content: response,
				raw,
			} = await sendToOpenAiForReview(payload);
			maskMap.forEach((value, key) => {
				response = response.replaceAll('{' + key + '}', value);
			});

			const responseObj = JSON.parse(response);
			setEmployeeReview(
				`Employee: ${employee.firstName} ${employee.lastName}\nEmployee ID: ${employee.employeeId}\n\n` +
				`OVERVIEW:\n${responseObj.overview}\n\n` +
				`STRENGTHS:\n${getReviewRows(responseObj.strengths.paragraphs)}\n\n` +
				`OPPORTUNITIES:\n${getReviewRows(responseObj.opportunities.paragraphs)}\n\n` +
				`RECOMMENDATIONS:\n${getReviewRows(responseObj.recommendations.paragraphs)}\n\n`,
			);
			setShowGeneratingSpinner(false);
			setShowGeneratedReview(true);
			setRawAiResponse(raw);
		} catch (e) {
			console.error('AI ERROR : ', e);
		}
	}

	const handleGenerateReview = async () => {
		setEmployeeReview('');
		setShowGeneratedReview(false);
		setRawAiResponse(undefined);
		try {
			setShowGeneratingSpinner(true);
			const userMessage = await buildUserMessage();
			const messages = [
				{
					role: 'system',
					content: aiSystemPrompt,
				},
				{
					role: 'user',
					content: userMessage,
				},
			];
			const jsonPayload = buildJSONMessage(messages);
			await callOpenAI(jsonPayload);
		} catch (e) {
			setShowGeneratingSpinner(false);
			toast.error('Error generating performance review.', {autoClose: 2500});
		}
	};

	const handleShowPDF = () => {
		if (showPdfModal) {
			setShowPdfModal(false);
		} else {
			setShowPdfModal(true);
		}

	};

	const handleOnClose = () => {
		if (onClose) {
			onClose(false);
			dispatch(aiReviewModalSlice.actions.hide(true));
			resetModal();
		}
	};

	const handleAllowAiGeneration = () => {
		if (!isAttendanceSelected &&
			!isRecognitionSelected &&
			!isCoachingSelected &&
			!isCorActionSelected) {
			return true;
		}
		return !isSubmitEnabled;
	};

	const handleCopy = () => {
		const textarea = (employeeReview != null ? employeeReview : '');
		navigator.clipboard.writeText(textarea);
		toast.info('Report copied to clipboard!');
	};

	const handleDeleteReview = () => {
		return deleteConnection({
			args: {
				token: ConnectionSettingTokenEnum.AiPerformanceReview,
				//@ts-ignore
				id: reviewId,
			},
		}).then((res) => {
			onClose(false);
			resetModal();
		}).catch(e => {
			console.error('Error deleting AI Performance review!', e);
		});
	};

	const handleSaveButtonClicked = () => {
		if (!employeeReview) return console.error(`Cannot save review without content!`);

		if (!selectedAction) return console.error(`Cannot save review without action selected!`);

		const payload: Omit<CreateAiPerformanceReviewPayload, 'status'> | Omit<UpdateAiPerformanceReviewPayload, 'action'> = {
			reviewer_id: selectedReviewer?.id,
			creator_id: selectedOwner?.id,
			data: {
				content: employeeReview,
			},
			settings: {
				company: {
					values: get(user, 'company.settings.values', 'MISSING COMPANY CORE VALUES'),
				},
				connections: {
					attendance: isAttendanceSelected,
					coaching: isCoachingSelected,
					discipline: isCorActionSelected,
					recognition: isRecognitionSelected,
				},
				dates: {
					start: format(reviewStartDate, DateFormatUsingDateFns.PerfectDate),
					end: format(reviewEndDate, DateFormatUsingDateFns.PerfectDate),
				},
				tone: reviewTone,
			},
			ai_response: rawAiResponse,
		};

		if (reviewId) {
			const updateRequest: UpdateAiPerformanceReviewApiRequestModel = {
				payload: {
					...payload,
					action: selectedAction.token,
				},
				args: {
					token: ConnectionSettingTokenEnum.AiPerformanceReview,
					id: reviewId,
				},
			};
			if (changesRequested && selectedAction?.settings?.text_prompt) {
				updateRequest.payload.changes_required = changesRequested;
			}
			return updateConnection(updateRequest)
				.then(res => {
					if ('error' in res) {
						return console.error(`Update connection failed!`, updateStatus.error);
					}
					dispatch(aiReviewModalSlice.actions.hide(true));
				}).catch(e => {
					console.error('Error updating AI Performance review!', e);
				});

		}

		if (selectedAction?.next_step && !!selectedOwner) {
			const createRequest: CreateAiPerformanceReviewApiRequestModel = {
				args: {
					token: ConnectionSettingTokenEnum.AiPerformanceReview,
				},
				payload: {
					...payload,
					team_id: employee.teams[0].id,
					subject_id: employee.id,
					status: selectedAction.next_step?.token,
				},
			};
			createConnection(createRequest)
				.then(res => {
					if ('error' in res) {
						return console.error(`Update connection failed!`, updateStatus.error);
					}
					dispatch(aiReviewModalSlice.actions.hide(true));
				}).catch(e => {
				console.error('Error creating AI Performance review!', e);
			});
		}
	};

	const handleChangeReviewer = (e: ChangeEvent<HTMLSelectElement>) => {
		const newReviewer = reviewers.find(user => user.id === +e.target.value);
		if (newReviewer) {
			setSelectedReviewer(newReviewer);
		}
	};
	const handleChangeOwner = (e: ChangeEvent<HTMLSelectElement>) => {
		const newOwner = owners.find(user => user.id === +e.target.value);
		if (newOwner) {
			setSelectedOwner(newOwner);
		}
	};

	useEffect(() => {
		if (!!employeeReview && employeeReview.length > 4000) {
			setReviewTextRows(100);
		} else {
			setReviewTextRows(80);
		}
	}, [employeeReview]);

	useEffect(() => {
		if (selectedAction) {
			setDisableSaveReview(false);
		}
	}, [selectedAction]);

	useEffect(() => {
		resetModal();
		if (show && workflowResponse) {
			if (workflowResponse.owners.length > 0) {
				setOwners(workflowResponse.owners);
				const result = workflowResponse.owners.find(({id}) => id === user.id);
				setSelectedOwner(result);
			}
			if (workflowResponse.reviewers.length > 0) {
				setReviewers(workflowResponse.reviewers);
				const result = workflowResponse.reviewers.find(({id}) => id === user.id);
				setSelectedReviewer(result);
			}

			if (reviewId) {
				return;
			}
			const defaultStep: WorkflowStep | undefined = workflowResponse.step || workflowResponse.workflow.steps.find(stp => stp.is_first_step);
			if (defaultStep) {
				const review: Partial<Connection> = {
					status: defaultStep.token,
					creator_id: user.id,
					team_id: employee.teams[0]?.id,
					data: {
						content: '',
					},
					subject_id: employee.id,
					employee_id: employee.id,
				};
				setAiPerformanceReview(review);
				setSelectedWorkflowStep(defaultStep);
				const {actions} = defaultStep;
				const defaultAction = actions.length === 1 ? actions[0] : actions.find(act => act.settings.is_default);
				if (defaultAction) {
					setSelectedAction(defaultAction);
				}
				setAvailableActions(defaultStep.actions);
			} else {
				console.error(`Error finding a default step on client's workflow`, workflowResponse);
			}
		}
		setShowModal(show);
	}, [
		show,
		user,
		workflowResponse,
	]);


	useEffect(() => {
		if (!selectedWorkflowStep) {
			return;
		}
		const newFooterSettings: FooterSettings = {
			owner: {
				items: owners,
				name: 'Owner: ',
				show: true,
			},
			reviewer: {
				items: reviewers,
				name: 'Reviewer: ',
				show: true,
			},
			dropdown: {
				items: [],
				name: 'Status: ',
				show: false,
			},
			save: {
				name: 'Save',
				show: false,
			},
			discard: {
				name: 'Discard',
				show: false,
			},
			print: {
				name: 'Print',
				show: false,
			},
			noActions: false,
		};
		const { actions } = selectedWorkflowStep;
		let actionUsers: {[key in WorkflowActionToken]?: ActionUser} = {};
		if (workflowResponse && workflowResponse.authorizedUsers) {
			actionUsers = workflowResponse.authorizedUsers;
		} else if (savedReview) {
			actionUsers = savedReview.actionUsers;
		}
		if (!actionUsers) {
			newFooterSettings.noActions = true;
			setFooterSettings(newFooterSettings);
			return console.error(`No actions authorized for user`, actions);
		}
		// const actionUsers = (!!workflowResponse) ? workflowResponse.authorizedUsers : savedReview.actionUsers;

		const authorizedActions = actions
			.filter((act: WorkflowAction) => map(get(actionUsers, [act.token, 'authorizedUsers'], []), 'id').includes(user.id));
		console.log('actions ready to filter out users', actions, 'authed', actionUsers, 'down to', authorizedActions);
		newFooterSettings.noActions = (authorizedActions.length === 0);
		const buttons = authorizedActions.filter((act: WorkflowAction) => act.settings.display_method === WorkflowDisplayMethod.Button);
		const dropdowns = authorizedActions.filter((act: WorkflowAction) => act.settings.display_method === WorkflowDisplayMethod.Dropdown);
		let saveAction: WorkflowAction;
		dropdowns.forEach((action) => {
				newFooterSettings.dropdown.items.push(action);
				newFooterSettings.dropdown.show = true;
				return;
		})
		buttons.forEach((action) => {
			if (action.token === WorkflowActionToken.Delete) {
				newFooterSettings.discard.name = action.name;
				newFooterSettings.discard.show = true;
				return;
			}
			if (action.token === WorkflowActionToken.Print) {
				newFooterSettings.print.name = action.name;
				newFooterSettings.print.show = true;
				return;
			}
			newFooterSettings.save.name = action.settings.save_btn_text || 'Save';
			newFooterSettings.save.show = true;
			saveAction = action;
			return;
		});
		if (dropdowns.length > 0) {
			newFooterSettings.save.name = 'Save';
			newFooterSettings.save.show = true;
		}

		setFooterSettings(newFooterSettings);

		let defaultAction = authorizedActions.find((act: WorkflowAction) => act.settings.is_default);
		if (defaultAction) {
			setSelectedAction(defaultAction);
		} else if (actions.length < 2) {
			return setSelectedAction(authorizedActions[0]);
		} else {
			// @ts-ignore
			if(dropdowns.length === 0 && newFooterSettings.save.show && saveAction) {
						setSelectedAction(saveAction);
			}
		}

	}, [
		selectedWorkflowStep,
		savedReview,
		setRawAiResponse,
	]);

	useEffect(() => {
		if (aiPerformanceReview && reviewId) {
			setEmployeeReview(aiPerformanceReview?.data?.content);
			setShowGeneratedReview(true);

			setIsAttendanceSelected(get(aiPerformanceReview, 'settings.connections.attendance', false));
			setIsCoachingSelected(get(aiPerformanceReview, 'settings.connections.coaching', false));
			setIsRecognitionSelected(get(aiPerformanceReview, 'settings.connections.recognition', false));
			setIsCorActionSelected(get(aiPerformanceReview, 'settings.connections.discipline', false));
			if (aiPerformanceReview.settings) {
				setReviewTone(aiPerformanceReview.settings.tone);
				setReviewStartDate(new Date(aiPerformanceReview.settings.dates?.start));
				setReviewStartDate(new Date(aiPerformanceReview.settings.dates?.end));
			}

		}
	}, [aiPerformanceReview]);

	useEffect(() => {
		if (reviewId) {
			if (showModal) {
				if (savedReview) {
					setAiPerformanceReview(savedReview.record);
					setChangesRequested(savedReview.record.misc?.changes_required);
					setSelectedWorkflowStep(savedReview.currentStep);
					setAvailableActions(savedReview.actions);
					setReviewTone(savedReview.record.settings.tone);

					if (savedReview.owners && savedReview.owners.length > 0) {
						setOwners(savedReview.owners);
						const result = savedReview.owners.find(({id}) => id === savedReview.record.creator_id);
						setSelectedOwner(result);
					}
					if (savedReview.reviewers && savedReview.reviewers.length > 0) {
						setReviewers(savedReview.reviewers);
						const result = savedReview.reviewers.find(({id}) => id === savedReview.record.settings.reviewer_id);
						setSelectedReviewer(result);
					}
					const checkStatuses = [
						WorkflowStepToken.Approved,
						WorkflowStepToken.Filed,
						WorkflowStepToken.Declined,
						WorkflowStepToken.Submitted,
						WorkflowStepToken.Issued,
					];

					if (checkStatuses.includes(savedReview.currentStep.token)) {
						setRestricted(true);
					}
				}
			}
		}
	}, [
		reviewId,
		savedReview,
	]);

	// useEffect(() => {
	// 	if (selectedWorkflowStep) {
	// 		let defaultAction = selectedWorkflowStep.actions.find((act: WorkflowAction) => act.settings.is_default);
	// 		if (defaultAction) {
	// 			return setSelectedAction(defaultAction);
	// 		}
	//
	// 		if (selectedWorkflowStep.actions.length < 2) {
	// 			return setSelectedAction(selectedWorkflowStep.actions[0]);
	// 		}
	// 	}
	// }, [selectedWorkflowStep]);

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			backgroundColor: 'white',
			fontFamily: 'Helvetica',
			fontSize: '12px',
			margin: 20,
			padding: 20,
			paddingBottom: 60,
		},
		header: {
			fontFamily: 'Helvetica-Bold',
			fontWeight: 'bold',
			fontSize: '28px',
			marginBottom: '24px',
		},
		section: {
			paddingRight: 40,
			paddingBottom: 40,
			flexGrow: 1,
		},
		body: {
			marginBottom: 40,
		},
		container: {
			width: '100%',
			textAlign: 'center',
			paddingTop: '40px',
		},
		signatureLines: {
			textDecoration: 'underline',
		},
		footer: {
			textAlign: 'right',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 10,
			width: '100%',
		},
	});


	return (
		<SkeModal
			title={`AI Performance Review`}
			subtitle={
				<>
					{selectedWorkflowStep?.token === WorkflowStepToken.Declined && (
						<h1
							className="text-center text-danger "
						>
							{selectedWorkflowStep?.token}
						</h1>
					)}
					{selectedWorkflowStep?.token !== WorkflowStepToken.Declined && (
						<>
							Current status: {aiPerformanceReview?.status}
						</>
					)}
				</>
			}
			size={'lg'}
			beta={true}
			show={showModal}
			onClose={handleOnClose}
			headerContents={
				<div>
					<EmployeeBadge
						employee={employee}
						size={AvatarSize.sm}
					/>
				</div>
			}
			showFooter={showGeneratedReview}
			footerContents={
				<div className="row">
					<div className="col-12">
						<div className="border-top border-top-1 p-5">
							<div className="d-block d-md-inline-block col-12 col-md-6">
								<label
									className={clsx('d-flex align-items-center p-1',
										{
											'text-danger': !showGeneratedReview,
										})}
								>
									{footerSettings?.reviewer.name}
									<select
										className="d-inline-flex form-control form-control-lg ms-5"
										value={selectedReviewer?.id || ''}
										onChange={handleChangeReviewer}
										disabled={restricted}
									>
										<option
											disabled={true}
											value="">Select Reviewer
										</option>
										{reviewers.map((reviewer: WorkflowCompactedUser, idx: number) => {
											return <option
												key={idx}
												value={reviewer.id}>{`${reviewer.firstName} ${reviewer.lastName}`}
											</option>;
										})}
									</select>
									<button
										className={clsx('btn btn-outline-danger border-danger bg-hover-danger text-hover-light', {
											'invisible': !selectedReviewer || restricted,
										})}
										disabled={!selectedReviewer || restricted}
										onClick={() => {
											setSelectedReviewer(undefined);
										}}
									>
										<Icon
											type={Refresh.iconType}
											size="sm"
											icon={Refresh}
										/>
									</button>
								</label>
							</div>
							<div className="d-block d-md-inline-block mt-3 col-12 col-md-6">
								<label
									className={clsx('d-flex justify-content-between align-items-center',
										{
											'text-danger': !showGeneratedReview,
										})}
								>
									{footerSettings?.owner.name}
									<select
										className="d-inline-flex form-control form-control-lg ms-5"
										value={selectedOwner?.id || ''}
										onChange={handleChangeOwner}
										disabled={restricted}
									>
										<option
											disabled={true}
											value="">Select Owner
										</option>
										{owners.map((owner: WorkflowCompactedUser, idx: number) => {
											return <option
												key={idx}
												value={owner.id}>{`${owner.firstName} ${owner.lastName}`}
											</option>;
										})}
									</select>
									<button
										className={clsx('btn btn-outline-danger border-danger bg-hover-danger text-hover-light', {
											'invisible': !selectedOwner || restricted,
										})}
										disabled={!selectedOwner || restricted}
										onClick={() => {
											setSelectedOwner(undefined);
										}}
									>
										<Icon
											type={Refresh.iconType}
											size="sm"
											icon={Refresh}
										/>
									</button>
								</label>
							</div>
						</div>
						{selectedAction?.token === 'REQUIRE_CHANGES' && (
							<>
								<div className="border-bottom border-bottom-1 pb-5 p-1">
									Please type your change request below:
									<div className="d-flex flex-column flex-md-row">
										<SkeTextbox
											inputId="changes_request"
											name="changes_request"
											value={changesRequested}
											onChange={setChangesRequested}
											rows={10}
										></SkeTextbox>
									</div>
								</div>
							</>
						)}
						<div className="border-bottom border-bottom-1 p-5">
							<div className="col-12">
								<div
									className="d-flex justify-content-between w-100 flex-wrap p-5"
								>
									{footerSettings?.discard.show && (
										<>
											<Button
												className="btn btn-danger w-100 w-md-auto mx-0 mx-md-2 my-2"
												onClick={handleDeleteReview}
											>
												<Icon
													type="svg"
													icon={Trash}
													classes="me-1"
													size="sm"></Icon>
												{footerSettings.discard?.name}
											</Button>
										</>
									)}

									<div className="d-flex flex-wrap ms-5 flex-grow-1 justify-content-end">
										{footerSettings?.print.show && (
											<>
												<Button
													className="btn btn-circle w-100 w-md-auto mx-0 mx-md-2 my-2"
													variant="outline-primary"
													onClick={handleShowPDF}
												>
													<Icon
														type="svg"
														icon={Printer}
														classes="me-1"
														size="sm"></Icon>
													{footerSettings.print.name}
												</Button>
											</>
										)}
										{footerSettings?.dropdown.show && (
											<>
												<div className="d-flex flex-column justify-content-center">
													<DropdownButton
														className={'w-100 w-md-auto mx-0 mx-md-2 my-2'}
														id="dropdown-basic-button"
														title={selectedAction?.name || 'Select action'}
														variant="info"
													>
														{footerSettings.dropdown.items.map((action, idx) => (
															<Fragment key={idx}>
																<label
																	className={clsx('d-flex align-items-center',
																		{
																			'text-danger': !showGeneratedReview,
																		})}
																>
																	<Dropdown.Item
																		key={idx}
																		defaultValue={action.id}
																		onClick={() => {
																			setSelectedAction(action);
																		}}
																	>
																		{action.name}
																	</Dropdown.Item>
																	<span
																		className="text-wrap d-block mx-md-2 w-100 w-md-auto text-center text-md-end"
																	>
															</span>
																</label>
															</Fragment>
														))}
													</DropdownButton>
												</div>
											</>
										)}
										{footerSettings?.save.show && (
											<>
												<div className="d-flex flex-column">
													<div className="w-100 d-flex justify-content-end">
														<Button
															className="btn btn-primary mx-0 mx-md-2 my-2"
															onClick={handleSaveButtonClicked}
															disabled={disableSaveReview}
														>
															{(createStatus.isLoading || updateStatus.isLoading) && (
																<>
																	<div
																		className="spinner-border spinner-border-sm me-2"
																		role="status">
																		<span className="sr-only">Loading...</span>
																	</div>
																</>
															)}
															<Icon
																type={Save.iconType}
																size="sm"
																icon={Save}
															/>
															{footerSettings.save?.name}
														</Button>
													</div>
													{selectedAction && (
														<>
														<span className="fst-italic">
														{selectedAction &&  selectedAction?.settings?.hint}
														</span>
														</>
													)}
												</div>
											</>
										)}
										{footerSettings?.noActions && (
											<span className="fst-italic">No authorized actions available</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		>
			{selectedWorkflowStep?.token === WorkflowStepToken.ChangesRequired && changesRequested && changesRequested.length > 1 && (
				<>
					<div className="border-bottom border-bottom-1 pb-5">
							<h3 className="text-danger">Requested Changes:</h3>
							<div className="d-block px-5">
								{changesRequested}
							</div>
					</div>
					<div className="border-top border-top-1 pb-5">
					</div>
				</>
			)}
			<div className="row">
				<div className="col-12 m-auto">
					<div className="accordion">
						<div className="accordion-item">
							<div
								className="accordion-header"
								id="ai-review-section-connection">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#ai-review-section-connection-content"
									aria-expanded="true"
									aria-controls="ai-review-section-connection-content"
								>
									Connections
								</button>
							</div>
							<div
								id="ai-review-section-connection-content"
								className="accordion-collapse collapse show"
								aria-labelledby="ai-review-section-connection"
							>
								<div className="accordion-body">
									<div className="col-lg-8 col-sm-12">
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<ToggleSwitch
													label="Recognition"
													disabled={restricted}
													isChecked={isRecognitionSelected}
													onChange={setIsRecognitionSelected}
												/>
											</div>
										</div>
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<ToggleSwitch
													label="Coaching"
													disabled={restricted}
													isChecked={isCoachingSelected}
													onChange={setIsCoachingSelected}
												/>
											</div>
										</div>
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<ToggleSwitch
													label="Attendance"
													disabled={restricted}
													isChecked={isAttendanceSelected}
													onChange={setIsAttendanceSelected}
												/>
											</div>
										</div>
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<ToggleSwitch
													label="Corrective Action"
													disabled={restricted}
													isChecked={isCorActionSelected}
													onChange={setIsCorActionSelected}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<div
								className="accordion-header"
								id="ai-review-section-criteria">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#ai-review-section-criteria-content"
									aria-expanded="true"
									aria-controls="ai-review-section-criteria-content"
								>
									Criteria & Values
								</button>
							</div>
							<div
								id="ai-review-section-criteria-content"
								className="accordion-collapse collapse show"
								aria-labelledby="ai-review-section-criteria"
							>
								<div className="accordion-body">
									<div className="w-100">
										<div className="">
											<>
												<span className="fs-4 d-block pb-2">Company Values</span>
												<span className="d-block">
													{get(user, 'company.settings.values', 'MISSING COMPANY CORE VALUES')}
												</span>
											</>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<div
								className="accordion-header"
								id="ai-review-section-tone">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#ai-review-section-tone-content"
									aria-expanded="true"
									aria-controls="ai-review-section-tone-content"
								>
									Tone
								</button>
							</div>
							<div
								id="ai-review-section-tone-content"
								className="accordion-collapse collapse show"
								aria-labelledby="ai-review-section-tone"
							>
								<div className="accordion-body">
									<div className="col-lg-8 col-sm-12">
										<div className="row pb-3">
											<div className="flex-column justify-content-start align-items-center">
												<Form.Check
													className='py-1'
													onClick={()=>setReviewTone(AiPerformanceReviewTone.Positive)}
													value={AiPerformanceReviewTone.Positive}
													checked={reviewTone === AiPerformanceReviewTone.Positive}
													disabled={restricted}
													name="Tone"
													type={'radio'}
													label="Positive"
													id={`disabled-default-Positive`}
												/>
												<Form.Check
													className='py-1'
													onClick={()=>setReviewTone(AiPerformanceReviewTone.Neutral)}
													value={AiPerformanceReviewTone.Neutral}
													checked={reviewTone === AiPerformanceReviewTone.Neutral}
													disabled={restricted}
													name="Tone"
													type={'radio'}
													label="Neutral"
													id={`disabled-default-Neutral`}
												/>
												<Form.Check
													className='py-1'
													onClick={()=>setReviewTone(AiPerformanceReviewTone.NeedsImprovement)}
													value={AiPerformanceReviewTone.NeedsImprovement}
													checked={reviewTone === AiPerformanceReviewTone.NeedsImprovement}
													disabled={restricted}
													name="Tone"
													type={'radio'}
													label="Needs Improvement"
													id={`disabled-default-NeedsImprovement`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<div
								className="accordion-header"
								id="ai-review-section-dates">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#ai-review-section-dates-content"
									aria-expanded="true"
									aria-controls="ai-review-section-dates-content"
								>
									Review Dates
								</button>

							</div>
							<div
								id="ai-review-section-dates-content"
								className="accordion-collapse collapse show"
								aria-labelledby="ai-review-section-dates"
							>
								<div className="accordion-body">
									<div className="col-12">
										<div className="row pb-3">
											<div className="col-6">
												<div className="d-flex align-items-center">
													<label className="d-flex justify-content-between align-items-center">
														Start Date &nbsp;
													</label>
													<DatePicker
														id="ai-review-section-dates-start-date"
														dateFormat="MMMM d, yyyy"
														labelClasses="col-form-label fw-light fs-4"
														size="lg"
														selectedDate={reviewStartDate}
														disabled={restricted}
														onChange={(d) => {
															handleReviewStartDateChange(d);
														}}
													/>
												</div>
											</div>
											<div className="col-6">
												<div className="d-flex align-items-center">
													<label className="d-flex justify-content-between align-items-center">
														End Date &nbsp;
													</label>
													<DatePicker
														id="ai-review-section-dates-end-date"
														dateFormat="MMMM d, yyyy"
														labelClasses="col-form-label fw-light fs-4"
														size="lg"
														selectedDate={reviewEndDate}
														disabled={restricted}
														onChange={(d) => {
															handleReviewEndDateChange(d);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{showGeneratedReview &&
							<div className="accordion-item">
								<div
									className="accordion-header"
									id="ai-review-section-output">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#ai-review-section-output-content"
										aria-expanded="true"
										aria-controls="ai-review-section-output-content"
									>
										Generated Performance Review
									</button>

								</div>
								<div
									id="ai-review-section-output-content"
									className="accordion-collapse collapse show"
									aria-labelledby="ai-review-section-output"
								>
									<div className="accordion-body h-100">
										<div className="col-12">
											<div className="row pb-3">
												<div className="clipboard-outer cursor-pointer">
													<div className="w-auto d-inline-block">
														<div className="mb-2 p-1 text-hover-primary d-flex align-items-center rounded-2 border border-1 d-block w-auto">
															<img
																className="clipboard float-right"
																onClick={handleCopy}
																src={clipboardPlus}
																alt="copy to clipboard"
															/><span className="ps-1">Copy to clipboard</span>
														</div>
													</div>
													<SkeTextbox
														inputId="generated-employee-review"
														name="employee-review"
														value={employeeReview}
														onChange={setEmployeeReview}
														disabled={restricted}
														rows={reviewTextRows}
													></SkeTextbox>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						{!restricted && (
							<>
								<SkeLegal
									title="ACKNOWLEDGEMENT"
								>
									This is only a draft and will need to be reviewed and updated before presenting to anyone. Artificial
									Intelligence can have <strong>'hallucinations'</strong>. The supervisor is responsible and accountable
									to the draft.<strong>No proprietary information.</strong>
								</SkeLegal>
								<div className="row pb-3">
									<div className="d-flex align-items-center">
										<ToggleSwitch
											isChecked={isSubmitEnabled}
											label="Accept"
											onChange={setIsSubmitEnabled}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="px-6 flex-column flex-md-row">
											<div className="d-flex justify-content-end flex-column flex-md-row">
												<button
													className="btn btn-primary w-100 w-md-auto mx-0 mx-md-2 my-2"
													onClick={() => handleGenerateReview()}
													disabled={handleAllowAiGeneration()}
													type="button">
													{!showGeneratingSpinner &&
														'Generate'
													}
													{showGeneratingSpinner &&
														<div>
															<div
																className="spinner-border spinner-border-sm"
																role="status">
																<span className="sr-only">Loading...</span>
															</div>
															&nbsp;{progressMessage}...
														</div>
													}
												</button>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<SkePdf
				show={showPdfModal}
				onClose={() => setShowPdfModal(false)}
				title='Print Employee Review'
				height={700}
				width={730}
			>
				<Page
					size="A4"
					style={styles.page}>
					<View style={styles.section}>
						<Text style={styles.header}>
							Performance Review
						</Text>
						<Text>
							{savedReview?.record.date && (
								<>
									Date: {parseDateObjectToNewFormat(parseYmdDatesToDateObject(savedReview.record.date), DateFormatUsingDateFns.UsFriendlyFourYear) + `\n`}
								</>
							)}
							{employeeReview + `\n\n\n\n`}
						</Text>
						<Text style={styles.signatureLines}>
							Employee Signature: _________________________________ Date: _____________
							{`\n\n\n`}
							Supervisor Signature: _________________________________ Date: ____________
						</Text>
						<Text style={styles.footer}>
							<Image
								style={{
									width: '130px',
									height: '60px',
								}}
								src={logo}
								cache={false} />
						</Text>
					</View>
				</Page>
			</SkePdf>
		</SkeModal>
	);
}
