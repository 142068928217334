import { ReactNode, useEffect } from 'react';
import { Tooltip } from 'bootstrap';

export interface TooltipProps {
	targetElementId: string;
	message: string;
	children?: ReactNode;
}

export function SkeTooltip(props: TooltipProps) {
	let targetElement: HTMLElement | null;
	let tooltipInstance: Tooltip | null;

	useEffect(() => {
		targetElement = document.getElementById(props.targetElementId);
		if (!targetElement) {
			return;
		}

		tooltipInstance = Tooltip.getInstance(targetElement);
		if (!tooltipInstance) {
			tooltipInstance = new Tooltip(targetElement, {
				title: props.message,
			});
		}

		return () => {
			targetElement?.removeEventListener('click', handleClick);
			if (tooltipInstance) {
				tooltipInstance.dispose();
			}
		};

	}, [props.targetElementId, props.message]);

	const handleClick = () => {
		if (tooltipInstance) {
			tooltipInstance.hide();
			if (targetElement) {
				tooltipInstance = new Tooltip(targetElement, {
					title: props.message,
				});
			}
		}
	}

	return (
		<>
			{props.children}
		</>
	);
}
