import clsx from 'clsx';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
	phoneNumber?: string;
	label?: string;
	helper?: string;
	error?: string;
	isRequired?: boolean;
	formik?: any;
	setPhoneNumber: (phoneNumber: string | null) => void;
}

export function PhoneNumberInput(
	{
		phoneNumber = '',
		label,
		helper,
		error,
		isRequired = false,
		formik,
		setPhoneNumber,
	}: Props) {

	const handlePhoneNumberChange = (phone: string, country: CountryData | {}) => {
		if (phone === (country as CountryData).dialCode) {
			return setPhoneNumber(null)
		}
		// manually adding plus symbol for backend validator
		return setPhoneNumber(`+${phone}`);
	};

	return (
		<>
			{label &&
		  <label
			  className={clsx('form-label col-form-label fw-bold fs-6',
								{
									'required': isRequired,
								},
							)}>{label}</label>
			}
			<PhoneInput
				country={'us'}
				onlyCountries={[
					'us',
					'ca',
					'mx',
				]}
				preserveOrder={['onlyCountries']}
				countryCodeEditable={false}
				inputClass={clsx('w-100 form-control form-control-lg form-control-solid',
					{
						'is-invalid': formik.touched.phone && formik.errors.phone,
						'is-valid': formik.touched.phone && !formik.errors.phone,
					})}
				value={phoneNumber}
				copyNumbersOnly={false}
				onChange={(phone, country) => handlePhoneNumberChange(phone, country)}
			/>
			{helper &&
		  <span className="fw-light row ps-5">{helper}</span>
			}
			{error &&
		  <span className="text-danger row ps-5">{error}</span>
			}
		</>
	);
}
