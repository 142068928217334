// IAdvancedConnectionSearchResult from api codebase
import { Category } from '@emoji-mart/data';
import { Paths, ValueOf } from 'type-fest';
import { Company } from '../../../app/modules/company/models/Company.model';
import { Employee } from '../../../app/modules/employee/models/Employee.model';
import { Team } from '../../../app/modules/team/models/Team.model';
import { User } from '../../../app/modules/user/models/User.model';
import {
	WorkflowActionToken,
	ConnectionSettingTokenEnum,
	WorkflowStepToken,
	Workflow,
	WorkflowStep,
	WorkflowAction, WorkflowCompactedUser,
} from '../../workflow/interfaces/workflow.model';

export enum ConnectionTypeEnumWithCa {
	Attendance = 'ATTENDANCE',
	Coaching = 'COACHING',
	Recognition = 'RECOGNITION',
	Discipline = 'DISCIPLINE',
	Excused = 'EXCUSED',
	CorrectiveAction = 'CORRECTIVE ACTION',
	Review = 'REVIEW',
}

export interface Connection {
	id: number;
	data: {
		content: string;
	} | null;
	status: WorkflowStepToken;
	date: string;
	tenant_id: string;
	company: Company;
	group_id: null;
	team_id: number | null;
	team: Team | null;
	employee_id: number;
	employee: Employee;
	user_id: number;
	creator: User;
	subject_id: number;
	creator_id: number;
	originator_id: number;
	category_id: number;
	category: Category;
	tags: string[] | null;
	misc: null | {
		step_id: number;
		changes_required?: string;
		history: HistoryItem<Connection>[];
	};
	settings: AiPerformanceReviewConnectionSettings;
	// metadata: unknown | null;
	metadata?: null | {
		// ai performance review only
		ai_response?: ChatGptResponse;
	};
	// ai_response: ChatGptResponse;
	created_at: string;
	updated_at: string | null;
	deleted_at: string | null;
	modified_by: number;
}

// subset of IAdvancedConnectionSearchResult as it's a curated whitelist of allowed fields
export type ConnectionSortableFields =
	'connection_type' |
	'date' |
	'employee_name' |
	'creator_name' |
	'team_name' |
	'category_name' |
	'status' |
	'step_name';

// IAdvancedConnectionSearchParams from api codebase
export type ConnectionFilterableFields =
	| 'category_name'
	| 'connection_type'
	| 'connection_type_array'
	| 'creator_name'
	| 'employee_name'
	| 'employee_ids'
	| 'end_date'
	| 'start_date'
	| 'before_date'
	| 'after_date'
	| 'date'
	| 'status'
	| 'status_array'
	| 'step_name'
	| 'team_ids';

export interface GetOneConnectionRequestModel {
	token: ConnectionSettingTokenEnum;
	id: number;
}

export interface CreateOrUpdateConnectionResults {
	item: Connection;
}

export interface GetOneConnectionResults {
	owners: WorkflowCompactedUser[];
	reviewers: WorkflowCompactedUser[];
	record: Connection;
	workflow: Workflow;
	currentStep: WorkflowStep;
	actions: WorkflowAction[];
	actionUsers: {
		[key in WorkflowActionToken]: ActionUser
	}
}

export interface ActionUser {
	action: Pick<WorkflowAction, 'id' | 'token'>;
	authorizedUsers: WorkflowCompactedUser[];
}

export interface CreateAiPerformanceReviewApiRequestModel {
	args: {
		token: ConnectionSettingTokenEnum;
	};
	payload: CreateAiPerformanceReviewPayload;
}

export interface UpdateAiPerformanceReviewApiRequestModel {
	args: {
		token: ConnectionSettingTokenEnum;
		id: number;
	};
	payload: UpdateAiPerformanceReviewPayload;
}

export interface DeleteAiPerformanceReviewApiRequestModel {
	args: {
		token: ConnectionSettingTokenEnum;
		id: number;
	};
}

export interface CreateAiPerformanceReviewPayload {
	data: {
		content: string;
	};
	settings: Omit<AiPerformanceReviewConnectionSettings, 'reviewer_id'>;
	status: WorkflowStepToken;
	ai_response: ChatGptResponse;
	subject_id: number;
	team_id: number;
	reviewer_id?: number | null;
	creator_id?: number | null;
}

export interface UpdateAiPerformanceReviewPayload extends Omit<CreateAiPerformanceReviewPayload, 'subject_id' | 'team_id' | 'status'> {
	action: WorkflowActionToken;
	changes_required?: string;
}

export interface AiPerformanceReviewConnectionSettings {
	company: {
		values: string;
	};
	dates: {
		start: string;
		end: string;
	};
	connections: {
		attendance: boolean;
		coaching: boolean;
		discipline: boolean;
		recognition: boolean;
	};
	tone: AiPerformanceReviewTone;
	// this is stored in the object, but providing it top level in API calls to validate
	reviewer_id: number | null;
	// TODO: since this is in a jsonb - we need to add that separately, if desired
	// reviewer: User | null;
}

export interface ChatGptResponse {
	id: string;
	object: string;
	created: number;
	model: string;
	choices: ChatGptResponseChoice[];
	system_fingerprint: string;
	usage: {
		prompt_tokens: number;
		completion_tokens: number;
		total_tokens: number;
		completion_token_details: {
			reasoning_tokens: number;
			[key: string]: any;
		};
		[key: string]: any;
	};
	[key: string]: any;
}

export interface ChatGptResponseChoice {
	index: number;
	message: {
		role: 'assistant';
		refusal: null | unknown;
		content: {
			overview: string;
			strengths: {
				paragraphs: {
					header: string;
					content: string;
				}[];
				opportunities: {
					paragraphs: {
						header: string;
						content: string;
					}[];
				};
				recommendations: {
					paragraphs: {
						header: string;
						content: string;
					}[];
				};
			};
		};
		logprobs: null | unknown;
		finish_reason: 'stop' | unknown;
	};
}

export enum AiPerformanceReviewTone {
	Positive = 'POSITIVE',
	Neutral = 'NEUTRAL',
	NeedsImprovement = 'NEEDS_IMPROVEMENT',
}

export interface HistoryItem<T> {
	field: Paths<T>;
	old_value: ValueOf<Paths<T>>;
	new_value: ValueOf<Paths<T>>;
	old_id?: number;
	new_id?: number;
	modified_by: number;
	timestamp: Date | string;
	duration_in_seconds: number;
}
