import {toLower, upperFirst} from 'lodash';

export function capitalizeName(name: string): string {
	if (!name) {
		return name;
	}
	return name
		.split(' ')
		.map((str) => upperFirst(toLower(str)))
		.join(' ')
		.split('-')
		.map((str) => upperFirst(toLower(str)))
		.join('-');
}