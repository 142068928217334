import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { Coaching, CoachingCreate, CoachingsGetAllApiRequestModel, CoachingUpdate } from './interfaces/coaching.model';

export enum CoachingTagTypes {
	Coaching = 'COACHING',
}

export const coachingSlice = createApi({
	reducerPath: 'coachingApi',
	tagTypes: [CoachingTagTypes.Coaching],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/coachings`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getCoachings: builder.query<ApiResponse<Coaching>, CoachingsGetAllApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponse<Coaching>) => {
				return response;
			},
			extraOptions: {
				includeMeta: false,
				includeLinks: false,
			},
			transformErrorResponse: (response: {status: string | number}) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.items.map(({id}) => ({
							type: CoachingTagTypes.Coaching,
							id,
						} as const)),
						{
							type: CoachingTagTypes.Coaching,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: CoachingTagTypes.Coaching,
						id: 'LIST',
					},
				];
			},
		}),
		getOneCoaching: builder.query<Coaching, {id: number}>({
			query: (args: {id: number}) => {
				let url = `${args.id}`;
				return {
					url,
				};
			},
			transformErrorResponse: (response: {status: string | number}, meta, arg) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						{
							type: CoachingTagTypes.Coaching,
							id: result.id,
						},
					];
				}
				return [];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		}),
		createCoaching: builder.mutation<Coaching, CoachingCreate>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			invalidatesTags: [
				{
					type: CoachingTagTypes.Coaching,
					id: 'LIST',
				},
			],
		}),
		updateCoaching: builder.mutation<Coaching, CoachingUpdate>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: CoachingTagTypes.Coaching,
							id: 'LIST',
						},
						{
							type: CoachingTagTypes.Coaching,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteCoaching: builder.mutation<void, {id: number}>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			transformErrorResponse: (response: {status: string | number}) => response.status,
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: CoachingTagTypes.Coaching,
						id: 'LIST',
					},
					{
						type: CoachingTagTypes.Coaching,
						id: args.id,
					},
				];
			},
		}),
	}),
});
