import { addMonths, eachMonthOfInterval, endOfMonth, format, startOfMonth, sub, subMonths } from 'date-fns';
import { DateFormatUsingDateFns } from '../../app/modules/date/DateFormat.const';

export const getMonthMetadata = (opts: MonthMetadataForRangeOpts | MonthMetadataForLookaroundOpts) : MonthMetadataOutput[] => {
	let months: MonthMetadataOutput[];
	let range: Date[];
	const effectiveDate = opts.effectiveDate || new Date();
	if ('monthCount' in opts) {
		if (opts.direction === 'PAST') {
			range = eachMonthOfInterval({
			// subtracting one as we're including this month already
				start: subMonths(effectiveDate, opts.monthCount - 1),
				end: new Date(),
			})
		} else {
			range = eachMonthOfInterval({
				start: effectiveDate,
				// subtracting one as we're including this month already
				end: addMonths(effectiveDate, opts.monthCount - 1)
			})
		}
	} else {
		range = eachMonthOfInterval({
			start: opts.startDate,
			end: opts.endDate,
		})
	}

	months = range.map(itm => ({
		startDate: startOfMonth(itm),
		endDate: endOfMonth(itm),
		yearMonth: format(itm, DateFormatUsingDateFns.FourYearTwoMonth),
	}));

	if (opts.sort === 'DESC') {
		months.reverse();
	}

	return months;
};

export interface MonthMetadataOpts {
	sort: 'ASC' | 'DESC';
	effectiveDate?: Date;
}

export interface MonthMetadataForRangeOpts extends MonthMetadataOpts {
	startDate: Date;
	endDate: Date;
}

export interface MonthMetadataForLookaroundOpts extends MonthMetadataOpts {
	monthCount: number;
	direction: 'FUTURE' | 'PAST';
}

export interface MonthMetadataOutput {
	startDate: Date;
	endDate: Date;
	/**
	 * Format: YYYY-MM
	 */
	yearMonth: string;
}
