import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Icon } from '../../../../app/components/icons/Icon';
import { ArrowUpRightBoxIcon } from '../../../../app/components/icons/IconList';
import { Link } from '../../../../app/components/Link';
import { ToggleSwitch } from '../../../../app/components/ToggleSwitch';
import { DrawerComponent, ScrollComponent, ToggleComponent } from '../../../assets/ts/components';
import { AsideMenuMain } from './AsideMenuMain';

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  // TODO: use const for mobile screen size, and store in Redux
  const isMobile = window.innerWidth <= 767;
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const [showHelpWidget, setShowHelpWidget] = useState<boolean>(!isMobile);

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleToggleHelpWidget = () => {
    setZendeskWidgetVisibility(!showHelpWidget)
    setShowHelpWidget(!showHelpWidget);
  };

  const setZendeskWidgetVisibility = (setToShow: boolean) => {
    window.zE('messenger', setToShow ? 'show' : 'hide');
  }

  useEffect(() => {
    setZendeskWidgetVisibility(showHelpWidget)
  }, []);

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='d-flex flex-column hover-scroll-overlay-y my-5 my-lg-5 py-5 py-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <AsideMenuMain />
      </div>

      <div
        className="mt-auto ps-5"
        id='aside-menu-bottom-container'>
        <Link
          type="external"
          label="FAQ"
          classes="mt-auto ps-3"
          desc="External link to FAQ"
          url="https://secchi.io/customer-help">
          <Icon
            type="svg"
            size='sm'
            classes="ms-3"
            alt="External link to FAQ"
            icon={ArrowUpRightBoxIcon}></Icon>
        </Link>
        <ToggleSwitch
          label={`${showHelpWidget ? 'Show' : 'Hide'} Help Widget`}
          onChange={handleToggleHelpWidget}
          isChecked={showHelpWidget}
        />
      </div>
    </div>
  )
}

export {AsideMenu}
