import { Company } from '../../company/models/Company.model';
import { NotificationSubscriptionUnion } from '../../notification/models/Notification.model';
import { ApiResponse } from '../../shared/models/ApiResponse.model';
import { Team } from '../../team/models/Team.model';

export enum UserRoleEnum {
	SecchiAdmin = 'SecchiAdmin',
	SuperAdmin = 'SuperAdmin',
	Provisioner = 'Provisioner',
	Supervisor = 'Supervisor',
}

export type UserRoleUnion =
	UserRoleEnum.SecchiAdmin |
	UserRoleEnum.SuperAdmin |
	UserRoleEnum.Provisioner |
	UserRoleEnum.Supervisor;

export interface User {
	id: number;
	firstName: string;
	lastName: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	email: string;
	isEmailVerified: boolean;
	password: never;// string
	passwordUpdatedAt: never;// string
	isPasswordExpired: boolean;
	roles: UserRoleUnion[];
	avatarUrl: string;
	phone: string;
	NotificationSubscriptions: NotificationSubscriptionUnion[];
	isLockedOut: boolean;
	company: Company;
	tenant_id: string;
	teams: Team[];
}
