import clsx from 'clsx';
import { addMonths, compareAsc, max, parse } from 'date-fns';
import { get, isNumber, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { shallowEqual, useSelector } from 'react-redux';
import {
	getMonthMetadata,
	MonthMetadataOutput,
} from '../../../../common/utilities/date.utility';
import { ConnectionTypeEnumWithCa } from '../../../../features/connection/interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from '../../../../features/connection/interfaces/vw_connection.model';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { RootState } from '../../../../setup';
import { removeAttendance } from '../../../CRUD/AttendanceCRUD';
import { removeCoaching } from '../../../CRUD/CoachingCRUD';
import { removeDiscipline } from '../../../CRUD/DisciplineCRUD';
import { DateFormatUsingDateFns, SHORT_DATE } from '../../../modules/date/DateFormat.const';
import { Employee } from '../../../modules/employee/models/Employee.model';
import { SkeTooltip } from '../../../modules/shared/components/Tooltip';
import { AttendanceType } from '../../../types/AttendanceType';
import { CoachingType } from '../../../types/CoachingType';
import { ConnectionTypeEnum } from '../../../types/ConnectionType';
import { DisciplineType } from '../../../types/DisciplineType';
import { EmployeeType } from '../../../types/EmployeeType';
import { AttendanceModal } from '../../connections/attendance/AttendanceModal';
import { AttendanceViewModal } from '../../connections/attendance/AttendanceViewModal';
import { CoachingEditModal } from '../../connections/coaching/CoachingEditModal';
import { CoachingViewModal } from '../../connections/coaching/CoachingViewModal';
import { ConnectionHistory } from './ConnectionHistory';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import {DisciplineModal} from '../../../../features/discipline/modals/DisciplineModal';

const initCoaching: CoachingType = {
	id: 0,
	category: {
		id: 0,
		name: '',
	},
	date: '',
	comment: '',
};

export enum ConnectionEventClass {
	Review = 'review-event',
	Recognition = 'recognition-event',
	Attendance = 'attendance-event',
	Excused = 'excused-event',
	CorrectiveAction = 'discipline-event',
	Coaching = 'coaching-event',
}

const connectionToEvent: {[key in ConnectionTypeEnumWithCa]: ConnectionEventClass} = {
	[ConnectionTypeEnumWithCa.CorrectiveAction]: ConnectionEventClass.CorrectiveAction,
	[ConnectionTypeEnumWithCa.Discipline]: ConnectionEventClass.CorrectiveAction,
	[ConnectionTypeEnumWithCa.Attendance]: ConnectionEventClass.Attendance,
	[ConnectionTypeEnumWithCa.Coaching]: ConnectionEventClass.Coaching,
	[ConnectionTypeEnumWithCa.Recognition]: ConnectionEventClass.Recognition,
	[ConnectionTypeEnumWithCa.Review]: ConnectionEventClass.Review,
	[ConnectionTypeEnumWithCa.Excused]: ConnectionEventClass.Excused,
}

const connectionWeight: {[key in ConnectionEventClass]: number} = {
	[ConnectionEventClass.CorrectiveAction]: 100,
	[ConnectionEventClass.Attendance]: 75,
	[ConnectionEventClass.Coaching]: 60,
	[ConnectionEventClass.Recognition]: 50,
	[ConnectionEventClass.Review]: 5,
	[ConnectionEventClass.Excused]: 0,
}

interface Props {
	attendanceSources: any[];
	coachingSources: any[];
	recognitionSources: any[];
	disciplineSources: any[];
	genericSources: any[];
	combinedConnections: (AttendanceType | CoachingType | DisciplineType | Recognition | VwConnection<VwConnectionDetails>)[];
	refresh: () => void;
	employee: EmployeeType;
	showAiReview?: boolean;
	isLoadingConnections: boolean;
}

export function EmployeeCardInformation({
																					attendanceSources,
																					coachingSources,
																					recognitionSources,
																					disciplineSources,
																					genericSources,
																					combinedConnections,
																					employee,
																					refresh,
																					showAiReview,
																					isLoadingConnections,
																				}: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [viewMode, setViewMode] = useState<'year' | 'quarter'>('quarter');
	const [currentAttendance, setCurrentAttendance] = useState<AttendanceType | null>(null);
	const [currentCoaching, setCurrentCoaching] = useState<CoachingType>(initCoaching);
	const [currentDiscipline, setCurrentDiscipline] = useState<DisciplineType | null>(null);
	const [selectedDate, setSelectedDate] = useState<Date | [Date | null, Date | null]>();
	const [areSelectedDatesTheSame, setAreSelectedDatesTheSame] = useState<boolean>(false);
	const [dateSelectionMode, setDateSelectionMode] = useState<'default' | 'single' | 'range'>(
		'default'
	);
	const [showNewDiscipline, setShowNewDiscipline] = useState<boolean>(false);
	const viewAttendanceModalRef = useRef<HTMLDivElement>(null);
	const editAttendanceModalRef = useRef<HTMLDivElement>(null);
	const coachingViewModalRef = useRef<HTMLDivElement>(null);
	const coachingEditModalRef = useRef<HTMLDivElement>(null);
	const correctiveActionModalRef = useRef<HTMLDivElement>(null);
	const activeTimePeriodStart = moment().subtract((viewMode === 'quarter') ? 3 : 12, 'months');
	const [monthsToDisplay, setMonthsToDisplay] = useState<MonthMetadataOutput[]>([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [typeToRemove, setTypeToRemove] = useState<Exclude<ConnectionTypeEnum, ConnectionTypeEnum.Recognition> | null>(null);
	const [showFutureEvents, setShowFutureEvents] = useState<boolean>(false);
	const [futureEventsCount, setFutureEventsCount] = useState<number>(0);
	const startOfToday = moment().startOf('day');
	const [maxFutureEventDate, setMaxFutureEventDate] = useState<Date>();

	useEffect(() => {
		const rollingMonths = getMonthMetadata({
			sort: 'DESC',
			direction: 'PAST',
			monthCount: viewMode === 'quarter' ? 3 : 12,
		});
		let futureMonths: MonthMetadataOutput[] = [];

		if (showFutureEvents) {
			const minForecastDate = addMonths(new Date(), 3);
			futureMonths = getMonthMetadata({
				sort: 'DESC',
				startDate: new Date(),
				// need to ensure that AT LEAST 3 months is shown, so greater of min 3 months or the max event date
				endDate: (!!maxFutureEventDate && compareAsc(maxFutureEventDate, minForecastDate) === 1) ? maxFutureEventDate : addMonths(new Date(), 3),
			})
		}
		// make sure futureMonths is first because each array is already in descending order, just to merge and deduplicate
		setMonthsToDisplay(uniqBy([...futureMonths, ...rollingMonths], 'yearMonth'));
	}, [viewMode, showFutureEvents, maxFutureEventDate]);

	const handleResetDeleteProcess = () => {
		switch (typeToRemove) {
			case ConnectionTypeEnum.Attendance:
				setCurrentAttendance(null);
				break;
			case ConnectionTypeEnum.Coaching:
				setCurrentCoaching(initCoaching);
				break;
			case ConnectionTypeEnum.Discipline:
				setCurrentDiscipline(null);
				break;
		}
		setTypeToRemove(null);
		setShowDeleteDialog(false);
	}

	const handleConfirmDelete = (typeToRemove: Exclude<ConnectionTypeEnum, ConnectionTypeEnum.Recognition> | null, errorToast: Function, successToast: Function ) => {
			if (typeToRemove === null) {
				return handleResetDeleteProcess();
			}

		let removalPromise: Promise<any> | undefined;

			switch (typeToRemove) {
				case ConnectionTypeEnum.Attendance:
					if (typeof currentAttendance?.id === 'number') {
						removalPromise = removeAttendance(currentAttendance.id, token);
					}
					break;
				case ConnectionTypeEnum.Coaching:
					removalPromise = removeCoaching(currentCoaching.id, token);
					break;
				case ConnectionTypeEnum.Discipline:
					if (typeof currentDiscipline?.id === 'number') {
						removalPromise = removeDiscipline(currentDiscipline.id, token);
					}
					break;
				default:
					throw new Error('Invalid type to remove');
			}

		if (removalPromise) {
		  removalPromise
			.then(() => {
				successToast();
			  setTypeToRemove(null);
			  setShowDeleteDialog(false);
			  refresh();
			})
			.catch(err => {
				errorToast();
				setTypeToRemove(null);
				setShowDeleteDialog(false);
			  console.error(err);
			})
			.finally(handleResetDeleteProcess);
		}
	};

	const handleDateClick = (event: Date | Date[]) => {
		if (Array.isArray(event)) {
			setDateSelectionMode('range');
			const dayOne = moment(event[0]);
			const dayTwo = moment(event[1]);
			setAreSelectedDatesTheSame(dayOne.isSame(dayTwo, 'day'));
			if (areSelectedDatesTheSame) {
				return setSelectedDate(event[0]);
			}
			return setSelectedDate([event[0], event[1]]);
		}
		setDateSelectionMode('single');
		setAreSelectedDatesTheSame(true);
		setSelectedDate(event);
	};


	const handleResetDateSelection = () => {
		setAreSelectedDatesTheSame(false);
		setDateSelectionMode('default');
		setSelectedDate(undefined);
	};

	const getFilteredConnections = () => {
		const activeRange = getActiveDateRange();
		const startDateRangeMoment = moment(activeRange.start);
		const endDateRangeMoment = moment(activeRange.end);
		return combinedConnections
			.filter(item => {
				if (!item.date) {
					return false;
				}
				const itemDateMoment = moment(item.date, SHORT_DATE);
				const connType = get(item, 'connectionType', get(item, 'connection_type', undefined));
				if (connType === ConnectionTypeEnum.Attendance) {
					if (showFutureEvents && (item as AttendanceType).reason?.isExcused && itemDateMoment > startOfToday) {
						return true;
					}
				}
				// don't show outside the selected window, to match with the calendars shown
				if (!itemDateMoment.isBetween(startDateRangeMoment, endDateRangeMoment, 'day','[]')) {
					return false;
				}
				if (!selectedDate) {
					return true;
				} else {
					if (dateSelectionMode === 'single' && !Array.isArray(selectedDate)) {
						return item.date === moment(selectedDate).format(SHORT_DATE);
					}
					if (Array.isArray(selectedDate)) {
						return (
							moment(selectedDate[0]).format(SHORT_DATE) <= item.date
							&& item.date <= moment(selectedDate[1]).format(SHORT_DATE)
						);
					}
				}
			});
	};

	const getActiveDateRange = (): {start: Date, end: Date} => {
		let start = activeTimePeriodStart.toDate();
		let end = moment().toDate();
		if (!!selectedDate) {
			if (Array.isArray(selectedDate)) {
				if (selectedDate[0]) {
					start = moment(selectedDate[0]).toDate();
				}
				if (selectedDate[1]) {
					end = moment(selectedDate[1]).toDate();
				}
			} else {
				start = selectedDate;
				end = selectedDate;
			}
		}
		return {
			start,
			end,
		};
	}

	useEffect(() => {
		const excusedAttendances = attendanceSources?.filter(item => item.type === ConnectionTypeEnum.Excused);
		const futureExcused = excusedAttendances.filter(item => compareAsc(item.startDate, new Date()) === 1);
		setFutureEventsCount(futureExcused.length);
		if (futureExcused.length > 0) {
			const maxDate = max(excusedAttendances.map(itm => parse(itm.date, DateFormatUsingDateFns.PerfectDate, new Date())));
			// will throw error if max excused date is prior to today's date
			if (compareAsc(maxDate, new Date()) === 1) {
				setMaxFutureEventDate(maxDate);
			}
		}
	}, [attendanceSources]);

	return (
		<>
		<div className="card card-flush mb-6 mb-xl-9">
			<div className="card-header mt-6">
				<div className="card-title flex-column align-items-center">
					<h2 className="mb-1">Performance Calendar
						<>
							<Spinner
								className={clsx('ms-2', {
									// keep h2 from shifting
									invisible: !isLoadingConnections,
								})}
							/>
						</>
					</h2>

				</div>
				<div>
					<div className="form-check form-switch">
						<SkeTooltip
							targetElementId="employee-card-rolling-calendar"
							message={`"Rolling" means we show the prior period in reverse order so the current month is shown first. May show additional calendars if displaying future excused attendances.`}>
						</SkeTooltip>
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							checked={viewMode === 'year'}
							onChange={e => {
								setViewMode(e.target.checked ? 'year' : 'quarter');
								setSelectedDate(undefined);
							}}
							id="calendar-mode-toggle" />
						<label
							className="form-check-label"
							htmlFor="calendar-mode-toggle">
							Rolling {viewMode === 'year' ? 'Year' : 'Quarter'}
						</label>
						<i
							id="employee-card-rolling-calendar"
							className="ms-1 bi bi-info-circle-fill text-info cursor-pointer"/>
					</div>
					<div className="form-check form-switch mt-2">
						<SkeTooltip
							targetElementId="employee-card-future-events"
							message="Extends the Performance Calendar to show at least 3 months in the future, or further to see future events. Displays any future events recorded under Connection History">
						</SkeTooltip>
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							checked={showFutureEvents}
							onChange={e => setShowFutureEvents(e.target.checked)}
							id="show-future-events-toggle" />
						<label
							className="form-check-label"
							htmlFor="show-future-events-toggle">
							Show future events ({futureEventsCount})
						</label>
						<i
							id="employee-card-future-events"
							className="ms-1 bi bi-info-circle-fill text-info cursor-pointer"/>
					</div>
				</div>
			</div>
			<div className="card-body p-9 pt-4">
				<div className="row">
					<div className="calendar-legend col-12 flex-row d-flex flex-wrap justify-content-md-between justify-content-sm-start">
						<div className="event-label recognition-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
							<span>Recognition</span>
						</div>
						<div className="event-label coaching-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
							<span>Coaching</span>
						</div>
						<div className="event-label attendance-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
							<span>Attendance</span>
						</div>
						<div className="event-label discipline-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
							<span>Corrective Action</span>
						</div>
						<div className="event-label excused-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
							<span>Excused</span>
						</div>
						{showAiReview && (
							<>
								<div className="event-label review-event d-flex justify-content-md-between align-items-center pb-3 pe-3">
									<span>Review</span>
								</div>
							</>
						)}
					</div>
					{/*<div>*/}
					{/* TODO: when enable selection range, find better explanation*/}
					{/*    <span className="text-muted">Click two dates to filter to events from that range.</span>*/}
					{/*</div>*/}
				</div>

				<div className="flex-row d-flex flex-wrap mb-5">
					{
						monthsToDisplay.map((month) => {
							return (
								<Calendar
									key={month.yearMonth}
									className="col-sm-12 col-md-6 col-lg-4 px-5 year-calendar"
									view="month"
									activeStartDate={month.startDate}
									onChange={handleDateClick}
									// onClickDay={handleDateClick}
									value={selectedDate}
									calendarType="US"
									showNeighboringMonth={false}
									nextLabel={null}
									next2Label={null}
									// selectRange={true}
									prevLabel={null}
									prev2Label={null}
									tileClassName={({
																		date,
																		view,
																	}: CalendarTileProperties) => {
										const currDateYMD = moment(date).format(SHORT_DATE);
										let classNames: string[] = [];
										[
											...attendanceSources,
											...coachingSources,
											...disciplineSources,
											...recognitionSources,
											...genericSources,
										].map(itm => {
											if (itm.yearMonth === month.yearMonth && itm.date === currDateYMD) {
												// @ts-ignore
												const cls = connectionToEvent[itm.type];
												if (cls) {
													classNames.push(cls);
												}
											}
										});

										if (!moment(date).isBetween(activeTimePeriodStart, moment(), 'day', '(]')) {
											classNames.push('outside-active-window');
										}
										if (classNames.length > 1) {
											let heaviestClass: ConnectionEventClass;
											let heaviestWeight: number = 0;
											classNames.map((cls: ConnectionEventClass | string) => {
												const weight = connectionWeight[(cls as ConnectionEventClass)];
												if (isNumber(weight)) {
													if (weight > heaviestWeight) {
														heaviestClass = (cls as ConnectionEventClass);
														heaviestWeight = weight;
													}
												}
											});

											// @ts-ignore
											if (heaviestClass) {
												classNames = [heaviestClass];
											}
										}

										return (classNames.length) ? classNames.join(' ') : 'no-events';
									}}
									minDate={
										new Date(2021, 0, 1)
									}
								/>
							);
						})
					}
				</div>

				<div className="border-1 border-top">
					<ConnectionHistory
						combinedConnections={getFilteredConnections()}
						showResetButton={dateSelectionMode !== 'default'}
						startDate={getActiveDateRange().start}
						endDate={getActiveDateRange().end}
						onResetDateSelection={handleResetDateSelection}
						onAttendanceClick={(att, action) => {
							setCurrentAttendance(att);
							switch (action) {
								case 'view':
									viewAttendanceModalRef.current?.click();
									break;
								case 'edit':
									setTimeout(() => {
										editAttendanceModalRef.current?.click();
									}, 1);
									break;
								case 'delete':
									setTypeToRemove(ConnectionTypeEnum.Attendance);
									setCurrentAttendance(att);
									setShowDeleteDialog(true);
									break;
							}
						}}
						onCoachingClick={(coa, action) => {
							setCurrentCoaching(coa);
							switch (action) {
								case 'view':
									coachingViewModalRef.current?.click();
									break;
								case 'edit':
									coachingEditModalRef.current?.click();
									break;
								case 'delete':
									setTypeToRemove(ConnectionTypeEnum.Coaching);
									setCurrentCoaching(coa);
									setShowDeleteDialog(true);
									break;
							}
						}}
						onCorrectiveActionClick={(corr, action) => {
							setCurrentDiscipline((corr as unknown as DisciplineType));
							switch (action) {
								case 'view':
									setShowNewDiscipline(true);
									correctiveActionModalRef.current?.click();
									break;
								case 'delete':
									setTypeToRemove(ConnectionTypeEnum.Discipline);
									setCurrentDiscipline(corr as unknown as DisciplineType);
									setShowDeleteDialog(true);
									break;
							}
						}}
					/>
				</div>
			</div>

			<div
				ref={viewAttendanceModalRef}
				data-bs-toggle="modal"
				data-bs-target="#attendance_view_modal"></div>
			<div
				ref={editAttendanceModalRef}
				data-bs-toggle="modal"
				data-bs-target="#edit-attendance-modal-from-employee-card-information"></div>
			<div
				ref={coachingViewModalRef}
				data-bs-toggle="modal"
				data-bs-target="#coaching_view_modal"></div>
			<div
				ref={coachingEditModalRef}
				data-bs-toggle="modal"
				data-bs-target="#coaching_edit_modal"></div>
			<div
				ref={correctiveActionModalRef}
				data-bs-toggle="modal"
				data-bs-target="#corrective-action-from-employee-card-information"></div>
			<AttendanceViewModal attendance={currentAttendance} />
			{!!currentAttendance && <AttendanceModal
				attendance={currentAttendance}
				employee={employee}
				onClose={() => setCurrentAttendance(null)}
				modalId='edit-attendance-modal-from-employee-card-information'
				mode="edit"
				refresh={refresh} />}
			<CoachingViewModal coaching={currentCoaching} />
			<CoachingEditModal
				editCoaching={currentCoaching}
				refresh={refresh} />
			<DisciplineModal
				refresh={() => {
					setShowNewDiscipline(false);
					refresh();
				}}
				show={showNewDiscipline}
				disciplineId={currentDiscipline?.id}
				employee={(employee as unknown as Employee)} />
		</div>
		{showDeleteDialog && (
			<SkeDialogDelete
			onConfirm={(errorToast, successToast) => {
				handleConfirmDelete(typeToRemove, errorToast, successToast )
			}}
			onCancel={handleResetDeleteProcess}
			message='Are you sure you want to delete this record?'
		  />
		)}
	</>
	);
}
