import axios from 'axios'
import { CategoryModel, CategorySubtypeEnum } from '../../features/category/interfaces/category.model'

const API_URL = process.env.REACT_APP_API_URL
export const PERFORMANCE_CATEGORIES_URL = `${API_URL}/performance-categories`

// Performance Categories
export function createPerformanceCategory(data: {name: string; subtype: CategorySubtypeEnum },token: string) {
    return axios({
        method: 'post',
        url: PERFORMANCE_CATEGORIES_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function getPerformanceCategories(isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${PERFORMANCE_CATEGORIES_URL}?isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function updatePerformanceCategory(categoryId: number, data: Partial<CategoryModel>, token: string) {
    return axios({
        method: 'patch',
        url: `${PERFORMANCE_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removePerformanceCategory(categoryId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${PERFORMANCE_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}
