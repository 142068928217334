import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AttendanceType } from '../../../types/AttendanceType'
import { CoachingType } from '../../../types/CoachingType'
import { DisciplineType } from '../../../types/DisciplineType'
import { EmployeeType } from '../../../types/EmployeeType'
import { AllFilterType } from '../../../types/FilterType'
import { RecognitionType } from '../../../types/RecognitionType'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const searchActionTypes = {
  SetAllFilter: '[Set AllFilter] Action',
  SetEmployees: '[Set Employees] Action',
  SetDisciplines: '[Set Disciplines] Action',
  SetAttendances: '[Set Attendances] Action',
  SetPerformances: '[Set Performances] Action',
  SetCoachings: '[Set Coachings] Action',
  SetRecognitions: '[Set Recognitions] Action',
  ClearSearch: '[Clear Search] Action'
}

const initialSearchState: ISearchState = {
  allFilter: {
    contains: '',
    start: null,
    end: null
  },
  employees: [],
  disciplines: [],
  attendances: [],
  coachings: [],
  recognitions: []
}

export interface ISearchState {
  allFilter?: AllFilterType
  employees?: EmployeeType[]
  disciplines?: DisciplineType[]
  attendances?: AttendanceType[]
  coachings?: CoachingType[]
  recognitions?: RecognitionType[]
}

export const reducer = persistReducer(
  { storage, key: 'secchi-app-search', whitelist: ['allFilter', 'employees', 'disciplines', 'attendances', 'performances', 'coachings', 'recognitions'] },
  (state: ISearchState = initialSearchState, action: ActionWithPayload<ISearchState>) => {
    switch (action.type) {
      case searchActionTypes.SetAllFilter: {
        const allFilter = action.payload?.allFilter
        return { ...state, allFilter }
      }

      case searchActionTypes.SetEmployees: {
        const employees = action.payload?.employees
        return { ...state, employees }
      }

      case searchActionTypes.SetDisciplines: {
        const disciplines = action.payload?.disciplines
        return { ...state, disciplines }
      }

      case searchActionTypes.SetAttendances: {
        const attendances = action.payload?.attendances
        return { ...state, attendances }
      }

      case searchActionTypes.SetCoachings: {
        const coachings = action.payload?.coachings
        return { ...state, coachings }
      }

      case searchActionTypes.SetRecognitions: {
        const recognitions = action.payload?.recognitions
        return { ...state, recognitions }
      }

      case searchActionTypes.ClearSearch: {
        return initialSearchState
      }

      default:
        return state
    }
  }
)

export const searchActions = {
  setAllFilter: (allFilter: AllFilterType) => ({ type: searchActionTypes.SetAllFilter, payload: { allFilter } }),
  setEmployees: (employees: EmployeeType[]) => ({ type: searchActionTypes.SetEmployees, payload: { employees } }),
  setDisciplines: (disciplines: DisciplineType[]) => ({ type: searchActionTypes.SetDisciplines, payload: { disciplines } }),
  setAttendances: (attendances: AttendanceType[]) => ({ type: searchActionTypes.SetAttendances, payload: { attendances } }),
  setCoachings: (coachings: CoachingType[]) => ({ type: searchActionTypes.SetCoachings, payload: { coachings } }),
  setRecognitions: (recognitions: RecognitionType[]) => ({ type: searchActionTypes.SetRecognitions, payload: { recognitions } }),
  clearSearch: () => ({ type: searchActionTypes.ClearSearch }),
  store: () => ({ type: "def" }),
}

export function* saga() {
  // yield takeLatest(searchActionTypes.SetAllFilter, function* setAllFilterSaga() {
  //   const getToken = (state: any) => state.auth.accessToken;
  //   const token = (yield select(getToken)) as string;
  //   const getAllFilter = (state: any) => state.search.allFilter;
  //   const allFilter = (yield select(getAllFilter)) as AllFilterType;
  //   const { data: employees } = yield getEmployees(allFilter.contains, token);
  //   yield put(searchActions.setEmployees(employees))

  //   const { data } = yield getConnections(allFilter, token);
  //   yield put(searchActions.setConnections(data.items))
  // })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.Verify, function* loginSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const getToken = (state: any) => state.auth.accessToken;
  //   const token = (yield select(getToken)) as string
  //   const { data: user } = yield getUserByToken(token)
  //   yield put(actions.fulfillUser(user))

  //   // try {
  //   //   const result = (yield call(getEmployee, token)) as Array<any>
  //   //   yield put(actions.setEmployees(result));
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // })
}
