import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from '../../../../_library/helpers'
import { FilterButton } from '../../../components/FilterButton';
import { getPagination } from '../../../CRUD/TeamCRUD'
import { removeCoaching } from '../../../CRUD/CoachingCRUD'
import { DropdownUserActions } from '../../../components/dropdown/DropdownUserActions'
import { RootState } from '../../../../setup';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../models'
import { Pagination } from '../../../components/Pagination'
import { CoachingType } from '../../../types/CoachingType'
import { CoachingViewModal } from './CoachingViewModal'
import { CoachingEditModal } from './CoachingEditModal'
import { ConnectionSortModel } from '../../../models/ConnectionSortModel';
import { ConnectionListFilterModal } from '../ConnectionListFilterModal';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';

type Props = {
	className?: string
}

const CoachingsList: React.FC<Props> = ({ className }) => {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const [coachings, setCoachings] = React.useState<any>({ items: [] })
	const [sort, setSort] = React.useState<ConnectionSortModel>({ field: 'date', order: 'DESC' })
	const [selectedCoaching, setSelectedCoaching] = React.useState<any>({})
	const [startDate, setStartDate] = useState<string>(moment().subtract(1, 'years').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState<string>(moment().add(1, 'days').format('YYYY-MM-DD'));
	const [pageLimit, setPageLimit] = useState(24)
	const [currentPageNumber, setCurrentPageNumber] = useState(1)
	const [teamId, setTeamId] = useState(0)
	const [supervisorId, setSupervisorId] = useState(0)
	const viewModalRef = React.useRef<HTMLAnchorElement>(null)
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);



	const applyFilterView = (e: React.MouseEvent, start_date: string, end_date: string, teamId: number, supervisorId: number) => {
		setStartDate(start_date);
		setEndDate(end_date);
		setTeamId(teamId);
		setSupervisorId(supervisorId)
		console.log(supervisorId, "SUPERVISOR ID")

		getPagination(`api/coachings?limit=${pageLimit}&fromDate=${start_date}&toDate=${end_date}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&coacherId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
			.then(({ data }) => {
				setCoachings(data)
			})
			.catch(err => {
				console.log(err.response)
			})
	}

	useEffect(() => {
		let abortController = new AbortController();
		getPagination(`api/coachings?limit=${pageLimit}&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&coacherId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
			.then(({ data }) => {
				setCoachings(data)
			})
			.catch(err => {
				console.log(err.response)
			})

		return () => {
			abortController.abort();
		}
	}, [supervisorId, teamId,token, pageLimit, endDate, startDate, user.roles, sort])

	const handlePrevious = () => {
		getPagination(coachings.links.previous, token)
			.then(({ data }) => {
				setCurrentPageNumber(currentPageNumber - 1)
				setCoachings(data)
			})
			.catch(err => {
				console.log(err.response)
			})
	}
	const handleNext = () => {
		getPagination(coachings.links.next, token)
			.then(({ data }) => {
				setCurrentPageNumber(currentPageNumber + 1)
				setCoachings(data)
			})
			.catch(err => {
				console.log(err.response)
			})
	}
	const handlePageIndex = (index: number) => {
		getPagination(`api/coachings?page=${index}&limit=${pageLimit}&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&coacherId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
			.then(({ data }) => {
				setCurrentPageNumber(index)
				setCoachings(data)
			})
			.catch(err => {
				console.log(err.response)
			})
	}

	const handlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setPageLimit(parseInt(e.target.value))
	}

	const handleCancelDelete = () => {
		setShowDeleteDialog(false)
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		setShowDeleteDialog(false);
		if (selectedCoaching) {
			removeCoaching(selectedCoaching.id, token)
				.then(() => {
					successToast();
					handlePageIndex(currentPageNumber);
				})
				.catch(err => {
					console.log(err);
					errorToast();
				});
		}
	};

	const removeSelectedCoaching = (coaching: CoachingType) => {
		setShowDeleteDialog(true);
		setSelectedCoaching(coaching);
	};

	return (
		<>
			<div className={`card ${className}`}>
				{/* begin::Header */}
				<div className='card-header border-0 pt-5'>
					<div className='pt-7 d-flex w-200px align-items-center' id='kt_chat_contacts_header'>
						<select
							className="form-select py-2 w-80px text-end"
							data-control="select2"
							data-placeholder="Select an option"
							value={pageLimit}
							onChange={handlePageLimitChange}
						>
							<option value="5">5</option>
							<option value="12">12</option>
							<option value="24">24</option>
							<option value="100">100</option>
						</select>
						<span className='ms-2'>rows per page</span>
					</div>


					<div
						className='card-toolbar'
						data-bs-toggle='tooltip'
						data-bs-placement='top'
						data-bs-trigger='hover'
						title='Click to filter results'
					>
						<div className='d-flex align-items-center py-1 me-4'>
							<div className='me-4'>
								<FilterButton
									toggle='modal'
									target='#coaching_filter_modal' />
							</div>
						</div>
					</div>
				</div>
				<div className='card-body py-3'>
					<a
						ref={viewModalRef}
						href='#'
						className='d-none'
						data-bs-toggle='modal'
						data-bs-target='#coaching_view_modal'
					>
						View
					</a>
					<div className='table-responsive'>
						<table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
							<thead>
							<tr className='fw-bolder text-muted'>
								<th className='min-w-150px'>
									<button
										className='p-0'
										type="button"
										onClick={() => {
											setSort({
												field: 'firstName',
												order: (sort.field !== 'firstName' || sort.order === 'DESC') ? 'ASC' : 'DESC',
											})
										}}
									>
										Member / Supervisor
										{sort.field === 'firstName' &&
											<span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
										}
									</button>
								</th>
								<th className='min-w-140px'>
									<button
										className='p-0'
										type="button"
										onClick={() => {
											setSort({
												field: 'date',
												order: (sort.field !== 'date' || sort.order === 'ASC') ? 'DESC' : 'ASC',
											})
										}}
									>
										Date
										{sort.field === 'date' &&
											<span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
										}
									</button>
								</th>
								<th className='min-w-120px'>
									Department
								</th>
								<th className='min-w-100px text-end'>Actions</th>
							</tr>
							</thead>
							<tbody>
							{coachings.items.length > 0 ?
								(coachings.items as CoachingType[]).map((coaching, i) => {
									return (
										<tr key={i}>
											<td>
												<div className='d-flex align-items-center'>
													<div className='symbol symbol-45px me-5 symbol-circle'>
														<img src={(coaching.employee?.avatarUrl == null || coaching.employee.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : coaching.employee.avatarUrl} alt='avatar' />
													</div>
													<div className='d-flex justify-content-start flex-column'>
														<Link
															to={`/team-member/${coaching?.employee?.teams?.[0]?.id}/show/${coaching.employee?.id}`}
															className='text-dark fw-bolder text-hover-primary fs-6'
														>
															{coaching.employee?.firstName} {coaching.employee?.lastName}
														</Link>
														<span className='text-muted fw-bold fs-7'>{coaching.coacher?.firstName} {coaching.coacher?.lastName}</span>
													</div>
												</div>
											</td>
											<td>
                                                    <span className='text-muted fw-bold d-block fs-7'>
                                                        {coaching.date}
                                                    </span>
											</td>
											<td className='text-end'>
												<div className='d-flex flex-column w-100 me-2'>
													<div className='d-flex flex-stack mb-2'>
														<span className='text-muted me-2 fs-7 fw-bold'>{coaching.team?.department}</span>
													</div>
												</div>
											</td>
											<td className='text-end'>
												{/* {((!!user.roles?.includes('Supervisor') && (attendance.status === 'Pending' || connection.status === 'ChangeRequested')) ||
                                                        !!user.roles?.includes('SuperAdmin')) &&  */}
												<DropdownUserActions
													viewModalId='coaching_view_modal'
													editModalId='coaching_edit_modal'
													handleSelectedIndex={() => { }}
													handleSelectedConnection={() => { setSelectedCoaching(coaching) }}
													removeSelectedConnection={() => removeSelectedCoaching(coaching)}
												/>
												{/* }
                                                    {(!!user.roles?.includes('Supervisor') && connection.status !== 'Pending' && connection.status !== 'ChangeRequested') &&
                                                        <button
                                                            type='button'
                                                            className='btn btn-light btn-sm ms-auto'
                                                            onClick={() => {
                                                                setSelectedConnection(connection)
                                                                viewModalRef.current?.click()
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    } */}
											</td>
										</tr>
									)
								}) :
								<tr><td colSpan={7} className='text-center'>No Data</td></tr>
							}
							</tbody>
						</table>
						<div className='d-flex flex-stack flex-wrap pb-5'>
							<div className='fs-6 fw-bold text-gray-700'></div>

							<Pagination
								data={coachings}
								handlePrevious={handlePrevious}
								handleNext={handleNext}
								handlePageIndex={handlePageIndex}
							/>
						</div>
					</div>
				</div>
			</div>
			<CoachingViewModal coaching={selectedCoaching} />
			{
				Object.keys(selectedCoaching).length > 0 &&
				<CoachingEditModal editCoaching={selectedCoaching} refresh={() => handlePageIndex(currentPageNumber)} />
			}
			{showDeleteDialog && (
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage='Coaching deleted'
					message='Are you sure you want to delete this Coaching?'
				/>
			)}
			<ConnectionListFilterModal
				applyFilter={applyFilterView}
				modalId='coaching_filter_modal' />
		</>
	)
}

export default CoachingsList