import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionTypes } from '../../app/modules/auth';
import { Employee } from '../../app/modules/employee/models/Employee.model';
import { ModalDisplayMode } from '../../common/interfaces/modal.model';
import { Coaching } from './interfaces/coaching.model';

// TODO: figure out better way to set this for state vs action payloads
export interface CoachingModalState {
	showModal: boolean;
	// TODO: as we implement more, these modes should be toggled
	// mode: ModalDisplayMode.Read | undefined;
	mode?: ModalDisplayMode;
	record?: Coaching;
	employee?: Employee;
	recordId?: number;
}

export interface CoachingModalCreateState extends Pick<CoachingModalState, 'employee'> {
	mode: ModalDisplayMode.Create;
	record?: never;
	employee: Employee;
}

export interface CoachingModalReadState extends Pick<CoachingModalState, 'record'> {
	mode: ModalDisplayMode.Read;
	recordId?: number;
	employee?: never;
}

export interface CoachingModalUpdateState extends Pick<CoachingModalState, 'record'> {
	mode: ModalDisplayMode.Update;
	recordId?: number;
	employee: Employee;
}

export interface CoachingModalDeleteState extends CoachingModalState {
	mode: ModalDisplayMode.Delete;
	record?: never;
	recordId: number;
	employee?: never;
}

export interface CoachingModalPrintState extends Pick<CoachingModalState, 'record'> {
	mode: ModalDisplayMode.Print;
	employee?: never;
}

export type CoachingModalStateUnion =
	CoachingModalCreateState
	| CoachingModalReadState
	| CoachingModalUpdateState
	| CoachingModalDeleteState;

const initialState: CoachingModalState = {
	showModal: false,
	mode: undefined,
	record: undefined,
	recordId: undefined,
	employee: undefined,
};

export const coachingModalSlice = createSlice({
	name: 'coachingModal',
	initialState,
	reducers: {
		show: (state, action: PayloadAction<Omit<CoachingModalState, 'showModal'>>) => {
			const {
				mode,
				employee,
				record,
				recordId,
			} = action.payload;
			switch (mode) {
				case ModalDisplayMode.Read:
					state.record = record;
					break;
				case ModalDisplayMode.Update:
				case ModalDisplayMode.Create:
					state.record = undefined;
					state.recordId = undefined;
					break;
				case ModalDisplayMode.Delete:
					state.recordId = recordId;
					break;
			}
			state.mode = mode;
			state.employee = employee;
			state.showModal = true;
			if (recordId) {
				state.recordId = recordId;
			}
			if (!!record) {
				state.record = record;
			}
			return state;
		},
		hide: state => {
			// console.log(`hide reducer (was set to show: ${state.showModal}) - returning init state`, initialState);
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(actionTypes.Logout, () => initialState);
	},
});
