import { Badge, Stack } from 'react-bootstrap';
import { StackDirection } from 'react-bootstrap/Stack';
import { ResponsiveUtilityValue } from 'react-bootstrap/createUtilityClasses';
import { GapValue, Variant } from 'react-bootstrap/types';
import {useEffect, useState} from 'react';
import { concat, findIndex } from 'lodash';
import clsx from 'clsx';

export interface SkePill {
	key: number | string;
	label: string;
	bg?: Variant;
}

interface Props {
	direction: StackDirection;
	gap: ResponsiveUtilityValue<GapValue>;
	pills: SkePill[];
	reset: boolean;
	onChange: (pills: SkePill[]) => void;
}

export function SkePillBox({
														 direction = "horizontal",
														 gap = 2,
														 pills,
														 reset,
														 onChange,
}: Props){
	const [selectedPills, setSelectedPills] = useState<SkePill[]>([]);
	const handlePillToggle = (pill: SkePill)=>{
		const index = findIndex(selectedPills, pl=> pill.key === pl.key);
		const newSelection = index === -1 ? concat(selectedPills, pill) : selectedPills.filter(pl=>pl.key !== pill.key);
		setSelectedPills(newSelection)
		if(!!onChange){
			onChange(newSelection)
		}
	}

	useEffect(() => {
		if(reset){
			setSelectedPills([]);
			onChange([]);
		}
	}, [reset]);

	return(
		<Stack
			direction={direction}
			gap={gap}
		>
			{
				 pills?.map(pill =>{
					return <Badge
						className={clsx('cursor-pointer', {
							'bg-dark': findIndex(selectedPills, pl=> pill.key === pl.key)
						})}
						onClick={()=>handlePillToggle(pill)}
						bg={pill.bg}
					>
						{pill.label}
					</Badge>
				})
			}
		</Stack>
	)
}