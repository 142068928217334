import React from 'react'
import { Recognition } from '../../../../../features/recognition/interfaces/recognition.model';
import { EmployeeType } from '../../../../types/EmployeeType'
import { EmployeeCardContent } from './EmployeeCardContent'
import ReactToPrint from 'react-to-print'
import './EmployeePerformanceCard.scss'
import { AttendanceType } from '../../../../types/AttendanceType'
import { CoachingType } from '../../../../types/CoachingType'
import { DisciplineType } from '../../../../types/DisciplineType'
import { PerformanceReportConfigType } from '../../../../types/PerformanceReportConfigType';

interface Props {
    employee: EmployeeType;
    attendances: AttendanceType[];
    coachings: CoachingType[];
    recognitions: Recognition[];
    disciplines: DisciplineType[];
    config: PerformanceReportConfigType;
}

export const EmployeePerformanceCard = ({
    employee,
     attendances,
     coachings,
     recognitions,
     disciplines,
     config,
}: Props) => {
    const pdfCardRef = React.useRef<HTMLDivElement>(null)

    return (
        <div className='modal fade' id='employee_performance_card' aria-hidden='true'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <ReactToPrint
                            content={() => pdfCardRef.current}
                            trigger={() => <button className="btn btn-primary">Print to PDF!</button>}
                        />
                    </div>
                    <div className='modal-body mx-0'>
                        <EmployeeCardContent ref={pdfCardRef}
                            employee={employee}
                            attendances={attendances}
                            coachings={coachings}
                            recognitions={recognitions}
                            disciplines={disciplines}
                            config={config}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
