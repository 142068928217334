import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as search from '../../pages/advanced_search/redux/SearchRedux';
import * as auth from './redux/AuthRedux';

export function Logout() {
	const dispatch = useDispatch();
	dispatch(search.searchActions.clearSearch());
	dispatch(auth.actions.logout());

	return (
		<Navigate to="/auth/login" />
	);
}
