export enum DisciplineStepTypeEnum {
	'Attendance' = 'Attendance',
	'Performance' = 'Performance'
}

export type DisciplineStepType =
	DisciplineStepTypeEnum.Attendance |
	DisciplineStepTypeEnum.Performance;

export interface DisciplineStep {
	id: number;
	tenant_id: string;
	name: string;
	type: DisciplineStepType;
	stepNumber: number;
	stepColor: string;
	isHRApprovalRequired: boolean;
	isArchived: boolean;
}

// TODO: in own place, DRY'd out
export interface DisciplineStepApiResponse {
	items: DisciplineStep[],
	// meta: {
	// 	totalItems: number,
	// 	itemCount: number;
	// 	itemsPerPage: number;
	// 	totalPages: number;
	// 	currentPage: number;
	// },
	// links: {
	// 	first: string;
	// 	previous: string;
	// 	next: string;
	// 	last: string;
	// }
}
