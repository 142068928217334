import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_library/layout/core';
import { ModalManager } from '../common/modals/ModalManager';
import { AlertContainer } from '../features/activity/components/layout/AlertContainer';
import AuthInit from './modules/auth/redux/AuthInit';
import { MainRoutes } from './routing/MainRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/overrides.scss';

export function App() {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter>
          <AlertContainer />
          <ToastContainer />
          <ModalManager />
          <LayoutProvider>
            <AuthInit>
              <MainRoutes />
            </AuthInit>
          </LayoutProvider>
        </BrowserRouter>
      </Suspense>
    </>
  )
}
