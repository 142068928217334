import React, { FC } from "react";
import { HomeNavbar } from "../components/HomeNavbar";

import './styles/terms.scss'

const EulaPage: FC = () => {

    const [navbarColor, setNavbarColor] = React.useState(0)

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 70) {
                setNavbarColor(1);
            } else {
                setNavbarColor(0)
            }
        });
    }, []);

    return (
        <div>
            <HomeNavbar backgroundOpacity={navbarColor} />
            <div className="terms_container">
                <h2 className="h2_font text-center mb-5">Secchi Master Service Agreement/EULA</h2>

                <h3 className="h3_font mb-3">
                    Secchi End User License Agreement – ("EULA")
                </h3>
                <p className="body_text_font">
                    This EULA, effective as of the date that the Party uses the services herein, is entered into by and between Secchi, Inc., hereinafter referred to as “Company” and the entity or individual(s), as applicable and as defined by the scope of the license offering using the Secchi’s software and services. (“Customer”), shall continue in full force and effect until such time as it is terminated per the terms of this EULA. For purposes of this EULA, Customer and Company shall be referred to collectively as the “Parties” and each individually as a “Party.”
                </p>
                <p className="body_text_font">
                    This EULA, and any modifications or subsequent versions entered into by, shall govern the provision of, access to, and use by Customer of, Company’s software, associated telephone calls, data, files, information, online or electronic documentation, media and any other Company service applicable thereto (collectively, "Software”). This EULA shall be governed, and incorporated into, that certain Master Services Agreement to which it is attached (the “MSA”). To the extent that anything in or associated with this EULA is in conflict or inconsistent with the MSA, this EULA shall take precedence unless otherwise stated to the contrary herein or in the MSA. Any capitalized terms not defined herein shall have the meaning set forth in the MSA.
                </p>
                <p className="body_text_font">
                    About Company: In connection with the Software, Company provides, as specified in an Order Form, online cloud-based software and related platforms including, but not limited to, human resources management, aggregate data reporting and personal information. The Software allows Customer to digitally manage individuals via telephone by using Customer’s personal/business computer and associated broadband Internet connection.
                </p>
                <p className="body_text_font">
                    Assent to be Bound: Customer agrees to be bound by the terms of this EULA, and acknowledges that Customer is entering into a legally binding contract, by one or more of the following methods: (a) executing this EULA; (b) clicking to accept or agree where this option is made available to Customer; or (c) by actually using the Software. Customer should print or save a copy of this EULA for Customer’s records. If Customer does not agree with any part of this EULA, Customer is not authorized to access or use the Software.
                </p>
                <p className="body_text_font">
                    Electronic Signatures: Company’s authorization to provide and bill for the Software may be obtained by way of Customer’s electronic signature or, where applicable, via physical signature and/or voice affirmation, or use of the subject services. Once an electronic signature is submitted: (a) this electronic order constitutes an electronic letter of agency; and (b) Customer hereby agrees to: (i) the use of electronic communication in order to enter into this EULA, place orders and create other records hereunder; and (ii) the electronic delivery of notices, policies and records of transactions initiated or completed through use of the Software. Customer hereby waives any rights or requirements under any laws of electronic records, to the extent permitted under applicable law. Company’s reliance upon Customer’s electronic signature was specifically sanctioned and written into law when the Uniform Electronic Transactions Act and the Electronic Signatures in Global and National Transactions Act were enacted in 1999 and 2000, respectively. Both laws specifically preempt all state laws that recognize only paper and handwritten signatures.
                </p>
                <p className="body_text_font">
                    License and Restrictions/No Granting of Rights to Third Parties: Customer shall not sell, assign, rent, lease, sublease, sublicense, encumber, use the Software (or other Services, as that term is defined in the MSA) in a timesharing or service bureau arrangement, permit simultaneous use of the Software (or other Services) by more than one user, distribute, export, import, act as an intermediary or provider or otherwise grant rights to third parties with regard to the Software (or other Services) unless approved in writing, in advance, by Company. Unless expressly permitted hereunder, Customer shall NOT transfer the Software (or other Services) to any third party under any circumstances. Any such purported transfer shall be null and VOID.
                </p>
                <p className="body_text_font">
                    No Modifications/Reverse Engineering: Customer shall not undertake, cause, permit or authorize any modification, derivative works, translation, reverse engineering, decompiling, disassembling, hacking or other attempt to derive the source code associated with the Software, its related documentation, including translation or localization (code written to published Application Programming Interfaces (“APIs”) for the Software shall not be deemed derivative works), or any part thereof except to the extent permitted by law.
                </p>
                <p className="body_text_font">
                    No Removal of Notices: Customer agrees that Customer shall not remove or alter any trademark, logo, copyright, proprietary notices, legends, symbols and/or any other indication of intellectual property and/or proprietary rights that Company has in or to the Software (or other Services), whether such notice or indications are affixed on, contained in or otherwise connected to any materials associated with the Software (or other Services).
                </p>
                <p className="body_text_font">
                    No Publishing: Customer shall not publish any results of benchmark tests run on any Software to a third party without Company’s prior written approval.
                </p>
                <p className="body_text_font">
                    Third Party Software: The Software may be incorporated into, and/or may incorporate, certain software, plug-ins, applications and other technology provided by, owned and/or controlled by third parties (collectively, “Third Party Software”). Any Third Party Software that may be provided with the Software is made available for use at Customer’s option and at Customer’s own risk. Any such Third Party Software that may be distributed together with the Service and Software may be subject to Customer explicitly accepting license agreement(s), terms and conditions and/or privacy policies as made available by the applicable third party(ies). Company is not responsible for any Third Party Software and shall have no liability whatsoever for Customer’s use of, or inability to use, Third Party Software. Customer acknowledges and agrees that it will proceed solely against the applicable third party, and not to Company or its affiliates, in connection with any dispute regarding the Third Party Software.
                </p>
                <p className="body_text_font">
                    Publicity: Notwithstanding any of the above, Company may mention the Customer’s name and logo and provide a general description of the engagement in Customer’s client lists and marketing materials.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    License Grant
                </h3>
                <p className="body_text_font">
                    License Grant: Company hereby grants to Customer, subject to the terms, conditions, restrictions and limitations set forth in this EULA, a worldwide, non-exclusive, revocable, non-transferable license to use those portions of the Software referred to Secchi Sechi.ip, Secchi app, and/or any other software product, whether or not specifically named as described below, and present and future applications, including derivative products and features produced therefrom. The features associated with each of these Software offerings are as described below:
                </p>
                <p className="body_text_font">
                    Secchi App – is a cloud based human resource management software application that enables Customer to review employee records, attendance and tracked data.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Customer's Responsibilities
                </h3>
                <p className="body_text_font">
                    Lawful Purposes: Customer shall use the Software for lawful purposes only. In this respect, Customer may not, without limitation: (a) intercept or monitor, damage or modify any communication which is not intended for Customer; (b) knowingly transmit content through the Software containing any program, routine or device which is designated to delete, disable, deactivate, interfere with or otherwise harm any software, program, data, device, system, or service from the Software including, without limitation, any ‘time bomb’, virus, drop dead device, spider, malicious logic, Trojan Horse, trap, or back door; (c) send any unsolicited communication not permitted by Applicable Law (as defined in the MSA); (d) expose any party to material which is offensive, harmful to minors, indecent or otherwise objectionable in any way; (e) use the Software to cause or intend to cause embarrassment or distress to, or threaten, harass or invade the privacy of, any third party; (f) use any material or content that is subject to any third party proprietary rights, unless Customer has the requisite license or permission from the owner of such rights to use same; and/or (g) use any caller ID or ANI that is fraudulent, unauthorized or false, and (h) or otherwise use the software in a carrier non-compliant manner. Further, all use shall be subject to Section 6.a of the MSA appended hereto.
                </p>
                <p className="body_text_font">
                    Representations and Warranties: Customer represents and warrants that Customer is authorized to enter into and comply with the terms of this EULA. Furthermore, Customer represents and warrants that Customer shall, at all times, comply with Customer’s obligations as set forth in this EULA, as well as all Applicable Law in connection with Customer’s use of the Software.
                </p>
                <p className="body_text_font">
                    Indemnification: Without limiting the indemnification obligations set forth in the MSA, Customer agrees to indemnify and hold harmless Company and Company’s affiliated entities, officers, directors, employees and agents from and against all costs, expenses, damages or other losses incurred in connection with actual or threatened claims and/or actions brought by any person or authority arising from or connected with: (a) any fraudulent, unlawful (or allegedly fraudulent or unlawful) telephone calls, messages or other communications delivered by Customer by and through the Software; (b) any violation by Customer of Applicable Law; (c) Customer’s violation of any representation, warranty or covenant contained herein; and/or (d) any use by Customer (or anyone else using Customer’s login information) of the Software. If Customer fails to promptly investigate and defend or settle any claim of which Customer is notified, then Company has the right to take sole control over the claim and all negotiations for its settlement or compromise. In such event, Customer shall pay, as they become due, all of the reasonable costs and expenses (including reasonable attorneys’ fees and related costs) incurred by Company in defending or negotiating settlement of the claim, and Customer shall satisfy any related settlement, award or judgment.
                </p>
                <p className="body_text_font">
                    Data & Content: Customer is responsible for, and Customer represents and warrants that it has all right, power and authority to provide and use, Customer’s Content. Customer agrees that Customer will not use any Content that is subject to any third party intellectual property rights, unless Customer has a license or specific permission from the owner to use such Content and to grant Company the license to utilize same as contemplated hereunder. Company reserves the right (but shall have no obligation) to decide whether any Content that Customer uses complies with this EULA. Company may, in its sole discretion, remove such Content and/or terminate this EULA and Customer’s use of the Software, without prior notice to Customer, if Customer uses any Content in a manner that is in breach of this EULA.
                </p>
                <p className="body_text_font">
                    Export Restrictions: Customer acknowledges that the Software is of U.S. origin and agrees to comply with all applicable international and national laws that apply to the Software, including the U.S. Export Administration Regulations, as well as end-user, end-use and destination restrictions issued by the government of the United States and the governments of other nations.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Acceptable Use Policy
                </h3>
                <p className="body_text_font">
                    This Acceptable Use Policy (“AUP” or “Policy”) describes the actions that are prohibited and conduct that is expected when you, or any party acting on your behalf, use Secchi, Inc.’s (“Secchi”) Services (“Services”).  This AUP is designed to complement – not replace – any agreement between us that governs your use of Secchi’s Services, such as the Secchi Master Services Agreement, the Secchi End User License Agreement (“EULA”), or any other agreement between you and Secchi. This Policy is designed to help you understand some of our expectations for responsible and legal use of the Services.  If you do not agree to this Policy, do not use the Services.
                </p>
                <p className="body_text_font">
                    The Services may not be used in any manner that: (i) is illegal; (ii) is non-compliant with accepted industry best practices or applicable guidelines, (iii) disrupts or damages any of Secchi’s computer systems or network or other parties’ computer systems and networks, or (iv) violates any person’s rights.
                </p>
                <p className="body_text_font">
                    Secchi in its sole discretion shall determine whether there has been a violation of this Policy.  Secchi may amend this Policy from time to time.
                </p>
                <p className="body_text_font">
                    The following list provides examples of prohibited uses and is not meant to be comprehensive, and you are ultimately responsible for the messages that you initiate through the Services.
                </p>
                <p className="body_text_font">
                    You agree that your use of the Secchi Services will only be for lawful purposes, and will not include:
                </p>
                <p className="body_text_font">
                    Need to add
                    In addition, you will honor immediately any requests to opt-out or stop further messaging (e.g., any “STOP” messages), and desist from initiating any further message following receipt of any such opt-out or stop request. You should also schedule the timing of your messages responsibly and adhere to any calling time restrictions that may be applicable to your messages.
                </p>
                <p className="body_text_font">
                    Further, the Federal Communications Commission requires that voice service providers such as Secchi cooperate with industry and governmental investigations to identify potential sources of illegal calls, and to otherwise take steps to combat the origination of illegal traffic by customers. Accordingly, you agree to promptly cooperate with any such investigation and provide Secchi any information we may request regarding your use of Secchi’s Services, the legality of your communications, and/or your compliance with this AUP. You further acknowledge and agree that any communications, expressly including the content of your communications, that you send or receive over Secchi’s network are not confidential and are subject to fraud and spam monitoring by Secchi, and potentially other service providers in the communications ecosystem. For the avoidance of doubt, by using Secchi’s Services, you consent to the foregoing practices and Secchi’s right to disclose any relevant information regarding your usage of Secchi’s Services to third parties.
                </p>
                <p className="body_text_font">
                    Violation of this AUP may result in termination or suspension of all Services provided by Secchi and may also result in civil, criminal, or administrative liability or penalties against you. Remember, you are ultimately responsible to make your own informed decisions regarding your use of the Services and you remain fully liable for any use of the Services in violation of any applicable law, as well as this Acceptable Use Policy and any operative agreement(s) between us.
                </p>
                <p className="body_text_font">
                    Any failure to enforce this Policy does not amount to a waiver of Secchi’s rights.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Term, Termination, Customer's Account
                </h3>
                <p className="body_text_font">
                    Term of EULA: Customer’s rights with respect to accessing and using the Software will become effective when both Parties execute the Order Form, this EULA and the MSA. This EULA will remain in effect until terminated by either Company or Customer as set forth below.
                </p>
                <p className="body_text_font">
                    Termination: Company may terminate this EULA where Customer, or anyone using Customer’s account, whether permitted by Customer or not, breaches this EULA. Upon any expiration or termination of this EULA: (a) the rights and licenses granted to Customer under this EULA shall cease; (b) Customer shall immediately cease using the Software; and (c) Customer shall immediately cease using, and return to Company, any and all items and documentation relating to the Software in Customer’s possession or control that are proprietary to Company or contain Confidential Information (as defined in the MSA)
                </p>
                <p className="body_text_font">
                    Payments: Payments made to Company are non-refundable. Customer agrees to post pay 30 days for all Software and other Services provided by Company. Customer agrees to pay for all telephone calls initiated through the Software outside of Customer’s agreed upon calling plan, which becomes effective when Customer pays Customer’s monthly bill.
                </p>
                <p className="body_text_font">
                    Suspension: Customer’s right to use of the Software and other Services may be suspended by Company if Customer payment for same is not received by Company when due. Customer may receive, but Company is under no obligation to send, email reminders sent to Customer’s preferred email address as specified in Customer’s account information, which e-mail address may be updated by Customer by emailing Company at: <b>billing@Secchi.com</b>.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Disclaimer of Warranties and Limitation of Liabilities
                </h3>
                <p className="body_text_font">
                    No Warranties: WITHOUT LIMITING THE DISCLAIMERS SET FORTH IN THE MSA, AND EXCEPT AS EXPRESSLY SET FORTH IN THIS EULA, THERE ARE NO OTHER WARRANTIES, EXPRESS OR IMPLIED HEREUNDER. THE SOFTWARE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. USE OF THE SOFTWARE IS AT CUSTOMER’S OWN RISK. COMPANY DOES NOT WARRANT THAT THE SOFTWARE WILL MEET CUSTOMER’S REQUIREMENTS OR THAT OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED, ERROR FREE, SECURE, ACCURATE, COMPLETE OR CURRENT. WITHOUT LIMITATION, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THIS DISCLAIMER EXTENDS TO IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES ARISING BY STATUTE OR OTHERWISE IN LAW AND/OR FROM COURSE OF DEALING OR USAGE OF TRADE.
                </p>
                <p className="body_text_font">
                    Customer’s Own Risk: Customer acknowledges and agrees that the entire risk arising out of Customer’s use of the Software and other Services remains with Customer, to the maximum extent permitted by Applicable Law.
                </p>
                <p className="body_text_font">
                    Limitation of Liability: Without limiting the limitation of liability provisions contained in the MSA, Customer acknowledges and agrees that Company, its affiliates, licensors and employees will have no liability in connection with or arising from Customer’s use of the Software. Without limiting the foregoing, in no event shall Company, its affiliates, agents or employees be liable, whether in contract, warranty, tort (including negligence), product liability or any other form of liability for any: (a) indirect, incidental, special, consequential (including, without limitation, any loss, or corruption, of data, any interruption, computer failure or pecuniary loss) or punitive damages arising out of the use or inability to use the Software; (b) loss of income, business or profits (whether direct or indirect) arising out of the use or inability to use the Software; and/or (c) loss or damage which may have been incurred by Customer as a result of: (i) any disruptions or delays in any communications when using the Software; (ii) the suspension or termination of this EULA by Customer or by Company for any reason; and/or (iii) the release, or the decision not to release, new versions of the Software to Customer.
                </p>
                <p className="body_text_font">
                    These limitations on Company’s Liability to Customer shall apply whether or not Company, its affiliates or Company employees have been advised of the possibility of such losses or damages.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Miscellaneous
                </h3>
                <p className="body_text_font">
                    Entire Agreement: This EULA, together with the MSA, Order Form(s) and any amendments/attachments hereto/thereto, supersedes and merges all prior agreements, promises, understandings, statements, representations, warranties and covenants and all inducements to the making of this EULA relied on by either Party whether written or oral, and embodies the Parties' complete and entire agreement with respect to the subject matter of this EULA. No statement or agreement, oral or written, made before the assent of this EULA will vary or modify the written terms of this EULA.
                </p>
                <p className="body_text_font">
                    No Waiver: The failure of either Party to enforce or insist upon compliance with any of the terms of this EULA or the waiver of any terms in this EULA does not constitute a general waiver or relinquishment of any other terms of this EULA.
                </p>
                <p className="body_text_font">
                    Confidentiality: The Software is deemed Confidential Information (as defined in the MSA) of Company. Customer will not disclose any use of, or information pertaining to, the Software to any third party without the prior written approval of Company. This prohibition shall include pricing or cost information. Customer shall maintain the confidentiality of the Software with at least the same degree of care that Customer uses to protect Customer’s own confidential and proprietary information, but not less than a reasonable degree of care under the circumstances. Customer will not be liable for the disclosure of any Confidential Information which is: (a) in the public domain other than by a breach of this EULA on Customer’s part; (b) rightfully received from a third party without any obligation of confidentiality; (c) rightfully known to Customer without any limitation on use or disclosure prior to its receipt from Company; or (d) generally made available to third parties by Company without restriction on disclosure.
                </p>
                <p className="body_text_font">
                    Services: REGISTRATION AND SECURITY. As a condition to using some aspects of the Services related to this EULA, you may be required to register with Secchi and set a password and user name (“User ID”). You shall provide Secchi with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of this Agreement, which may result in immediate termination of your account. You may not (i) select or use as a User ID a name of another person with the intent to impersonate that person; or (ii) use as a User ID a name subject to any rights of a person other than you without appropriate authorization. Secchi reserves the right to refuse registration of or cancel a User ID in its discretion. You shall be responsible for maintaining the confidentiality of your password.
                </p>
                <p className="body_text_font">
                    Ownership of Software: All right, title and interest in and to the Software shall at all times remain with Company, and/or its suppliers. Customer agrees to prevent any unauthorized access to Customer’s account. Except as expressly provided herein, Company does not grant any express or implied right to Customer in or to any Company trade secret information.
                </p>
                <p className="body_text_font">
                    Regulations: This EULA is made expressly subject to: (a) all present and future valid orders and regulations of any regulatory body having jurisdiction over the subject matter of this EULA; (b) the laws of the United States of America; or (c) any foreign governmental agency having jurisdiction.
                </p>
                <p className="body_text_font">
                    No Agency: Neither Party is authorized to act as an agent for, or legal representative of, the other Party. Neither Party has the authority to assume or create any obligation on behalf of, or binding on, the other Party.
                </p>
                <p className="body_text_font">
                    No Third Party Beneficiaries: This EULA is made solely for the benefit of Customer and Company. Nothing in this EULA should be interpreted to mean that Customer and Company are partners, joint venturers, co-owners or are otherwise participants in a common undertaking. Neither Party nor its employees are given authority, express or implied, to represent, act for, or otherwise create or assume any obligation on behalf of, or binding on the other Party. Nothing in this EULA will confer any rights or remedies on any third party.
                </p>
                <p className="body_text_font">
                    Notices: Unless otherwise specifically provided for herein, any notice required by this EULA is effective and deemed delivered: (a) three (3) business days after posting with the United States Postal Service when mailed by certified mail, return receipt requested, properly addressed and with the correct postage; (b) one (1) business day after pick up by the courier service when sent by overnight courier, properly addressed; and (c) immediately when sent via facsimile or electronic mail. Notices will be sent to the addresses given at the time that Customer registers for access to the Software, unless Customer notifies Company by emailing support@Secchi.com or billing@Secchi.com of any applicable address change.
                </p>
                <p className="body_text_font">
                    Force Majeure: Other than with respect to payment obligations arising hereunder, if either Party’s performance under this EULA is restricted or interfered with, in whole or part, by causes beyond its reasonable control including, but not limited to, acts of God, fire, explosion, vandalism, cable cut, utility curtailments, power failures, storm or other similar occurrence, any law, order, regulation, tariffs or rates which make it impossible or impractical to provide the Software at the current rates, request of the United States government, or of any agency, court, or other instrumentality or civil or military authority, or by national emergency, insurrection, riot, war, strike, lockout or work stoppage or other labor difficulties, supplier failure, carrier failure or shortage or breach or delay (each, a “Force Majeure Event”), then it is excused from its performance on a day-to-day basis to the extent of this Force Majeure Event. Company’s obligation to provide the Software is subject to, and contingent upon, the Company’s underlying service providers continuing to provide the services needed to provide Software to Customer at the current rates and on the same conditions under which such service providers are currently providing or offering to provide the applicable services to Company. Company’s obligation to provide Services is subject to and contingent on the continuation of Company’s agreements with its underlying service providers to provide the applicable services to Company at the current rates and on the same conditions under which such service providers are currently providing or offering to provide the applicable services to Company. As such, any changes in, or termination of, the services provided by its service providers will relieve the Company of its obligations and any liability arising under this EULA.
                </p>
                <p className="body_text_font">
                    Escalation Procedures:In the event of any disruption of the Software or any problems associated with same, Customer should e-mail support@Secchi.com immediately and ask that the applicable problem be escalated to the Company manager on duty.
                </p>
                <p className="body_text_font">
                    Governing Law and Adjudication of Disputes: This EULA is in all respects governed by the laws of the State of California, without regard to choice of laws. Any disputes that may arise under this EULA shall be resolved in accordance with the laws of the State of California, and shall be adjudicated exclusively in the state or federal courts located in California. Customer specifically consents to the exclusive jurisdiction of the State of California and to the courts located therein.
                </p>
                <p className="body_text_font">
                    Severability: If any terms of this EULA are determined to be illegal, unenforceable or invalid, in whole or in part, for any reason, the terms shall be stricken and will not affect the legality, enforceability or validity of the remainder of this EULA. If any terms of this EULA are stricken as a result of this Section, then the stricken provision shall be deemed replaced, to the extent possible, with legal, enforceable, and valid terms that are as similar in tenor to the stricken provision as is legally permissible. All headings and titles contained in this EULA are used solely to organize the contents of this EULA and will not be used to affect the interpretation of the contents of this document.
                </p>
                <p className="body_text_font">
                    Headings: The headings in this EULA are for convenience only and shall not affect the construction hereof.
                </p>
                <p className="body_text_font">
                    Drafting: Each Party executing this EULA agrees that it has fully participated in the drafting of this EULA and that no Party shall be deemed to be the drafting Party.
                    CUSTOMER EXPRESSLY ACKNOWLEDGES THAT CUSTOMER HAS READ THIS EULA AND UNDERSTANDS THE RIGHTS, OBLIGATIONS, TERMS AND CONDITIONS SET FORTH HEREIN. BY ASSENTING TO THIS EULA BY THE MANNER SPECIFIED ABOVE, INCLUDING USE OF THE SUBJECT SOFTWARE CUSTOMER EXPRESSLY CONSENTS TO BE BOUND BY ITS TERMS AND CONDITIONS AND GRANTS TO COMPANY THE RIGHTS SET FORTH HEREIN.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Personal Information Collected
                </h3>
                <p className="body_text_font">
                    For the purposes of this Privacy Policy, "personal information" shall mean individually identifiable information from or about an individual. We collect personal information from you primarily when you complete a contact form in connection with your request to be contacted directly by Secchi with respect to your use of, or desire to obtain, the Secchi Services. The personal information that you must supply on the Site in order to complete a contact form may include, without limitation: (a) first and last name; (b) e-mail address; (c) telephone number; (d) company name (if applicable); (e) company size (if applicable); (f) any comments that you wish to submit; and (g) any and all other information requested on the applicable form. In addition, where you have otherwise entered into the applicable Services Agreement(s) with Secchi, you may be asked to enter your credit card information in order to make payment for the applicable Secchi Services.
                </p>
            </div>
        </div>
    )
}

export { EulaPage }