import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isString } from 'lodash';
import { toast } from 'react-toastify';
import {
	getAndSetBearerToken,
	reduxTransformErrorResponseHandler,
	SkeResponseApiFormat, SkeResponseApiFormatWithoutResults, skeResponseApiUtilErrorHandler,
} from '../../common/api/common.api';
import {
	Connection,
	CreateAiPerformanceReviewApiRequestModel, CreateOrUpdateConnectionResults,
	DeleteAiPerformanceReviewApiRequestModel,
	GetOneConnectionRequestModel,
	GetOneConnectionResults,
	UpdateAiPerformanceReviewApiRequestModel,
} from './interfaces/connection.model';

export enum ConnectionTagType {
	AiPerformanceReview = 'CX_REVIEW_PERFORMANCE_AI',
}

export const connectionSlice = createApi({
	reducerPath: 'connectionApi',
	tagTypes: [
		ConnectionTagType.AiPerformanceReview,
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/connections`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getOne: builder.query<GetOneConnectionResults, GetOneConnectionRequestModel>({
			query: (args) => {
				return {
					url: `${args.token}/${args.id}`,
				};
			},
			transformResponse: (response: SkeResponseApiFormat<GetOneConnectionResults>) => {
				return response.results;
			},
			transformErrorResponse(response: BaseQueryError<any>): void {
				skeResponseApiUtilErrorHandler(response.data, 'Error loading connection');
			},
			providesTags: (result, error, id) => {
				if (result) {
					return [
						// ...result.map(({ id }) => ({
						// 	type: ConnectionTagType.AiPerformanceReview,
						// 	id,
						// } as const)),
						{
							type: ConnectionTagType.AiPerformanceReview,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: ConnectionTagType.AiPerformanceReview,
						id: 'LIST',
					},
				];
			},
		}),
		createConnection: builder.mutation<Connection, CreateAiPerformanceReviewApiRequestModel>({
			query: (request) => ({
				url: `/${request.args.token}`,
				method: 'post',
				body: request.payload,
			}),
			transformResponse: (response: SkeResponseApiFormat<CreateOrUpdateConnectionResults>): Connection => {
				if (response.success && isString(response.msg)) {
					toast.success(response.msg);
				}
				return response.results.item;
			},
			transformErrorResponse(response: BaseQueryError<any>): SkeResponseApiFormatWithoutResults {
				return {
					...response,
					data: skeResponseApiUtilErrorHandler(response.data, 'Error updating connection'),
					error: response.data.error,
				}
			},
			invalidatesTags: [
				{
					type: ConnectionTagType.AiPerformanceReview,
					id: 'LIST',
				},
			],
		}),
		updateConnection: builder.mutation<Connection, UpdateAiPerformanceReviewApiRequestModel>({
			query: (request) => ({
				url: `/${request.args.token}/${request.args.id}`,
				method: 'PATCH',
				body: request.payload,
			}),
			transformResponse: (response: SkeResponseApiFormat<CreateOrUpdateConnectionResults>): Connection => {
				if (response.success && isString(response.msg)) {
					toast.success(response.msg);
				}
				return response.results.item;
			},
			transformErrorResponse(response: BaseQueryError<any>): SkeResponseApiFormatWithoutResults {
				return {
					...response,
					data: skeResponseApiUtilErrorHandler(response.data, 'Error updating connection'),
					error: response.data.error,
				}
			},
			invalidatesTags: (result, error, payload) => {
				if (result) {
					return [
						{
							type: ConnectionTagType.AiPerformanceReview,
							id: 'LIST',
						},
						{
							type: ConnectionTagType.AiPerformanceReview,
							id: payload.args.id,
						},
					];
				}
				return [];
			},
		}),
		deleteConnection: builder.mutation<void, DeleteAiPerformanceReviewApiRequestModel>({
			query: (request) => ({
				url: `/${request.args.token}/${request.args.id}`,
				method: 'DELETE',
			}),
			transformResponse: (response: SkeResponseApiFormatWithoutResults) => {
				if (response.success && isString(response.msg)) {
					toast.success(response.msg);
				}
				return;
			},
			transformErrorResponse(response: BaseQueryError<any>): void {
				skeResponseApiUtilErrorHandler(response.data, 'Error deleting connection');
			},
			invalidatesTags: (result, error, payload) => {
				if (result) {
					return [
						{
							type: ConnectionTagType.AiPerformanceReview,
							id: 'LIST',
						},
						{
							type: ConnectionTagType.AiPerformanceReview,
							id: payload.args.id,
						},
					];
				}
				return [];
			},
		}),
	}),
});
