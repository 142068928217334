export enum ImportProcesses {
	EmployeeUpload = 'EMPLOYEE_UPLOAD',
	// AttendanceUpload = 'ATTENDANCE_UPLOAD',
}

export let IMPORT_PROCESSES_DETAILS: { [key in ImportProcesses]: ImportProcessDetails };
IMPORT_PROCESSES_DETAILS = {
	[ImportProcesses.EmployeeUpload]: {
		type: ImportProcesses.EmployeeUpload,
		name: 'Employee upload',
		description: `Used to allow you to upload your workforce. This can update base employee details and optionally process other info, like creating teams and assigning/reassigning your workforce to teams.`,
	},
};

export interface ImportProcessDetails {
	type: ImportProcesses;
	name: string;
	description: string;
}
