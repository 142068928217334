import clsx from 'clsx';
import { RequiredDeep, SetOptional } from 'type-fest';
import { Employee } from '../modules/employee/models/Employee.model';
import { Team } from '../modules/team/models/Team.model';
import { EmployeeType, TeamType } from '../types/EmployeeType';
import { Avatar, AvatarSize } from './Avatar';

export enum PersonBadgeOpts {
	Badge = 'BADGE',
	Email = 'EMAIL',
	Phone = 'PHONE',
	Team = 'TEAM',
}
interface Props {
	employee?: RequiredDeep<Pick<(Employee | EmployeeType), 'firstName' | 'lastName' | 'avatarUrl' >> & SetOptional<Pick<(Employee | EmployeeType), 'teams' | 'employeeId' | 'phone'>, 'teams' | 'employeeId' | 'phone'>;
	team?: Required<Pick<(Team | TeamType), 'name'>>;
	size?: AvatarSize;
	opts?: PersonBadgeOpts[];
}

export function EmployeeBadge({ employee, team, size = AvatarSize.lg, opts }: Props) {
	const teamName = team?.name || employee?.teams?.[0]?.name || 'unassigned';
	return (
		<div className={clsx("p-1 ps-2 align-items-center d-flex bg-secondary rounded-1", {
			invisible: !employee,
		})}>
			<Avatar
				size={size}
				alt={`Employee ${employee?.firstName} ${employee?.lastName} avatar`}
				url={employee?.avatarUrl}/>
			<div className="px-3 ms-2">
					<>
						<div className="text-dark">
							{employee?.firstName} {employee?.lastName}
						</div>
						{opts?.includes(PersonBadgeOpts.Badge) && (
							<div className="text-dark">
								ID #: {employee?.employeeId}
							</div>
						)}
						{opts?.includes(PersonBadgeOpts.Phone) && employee?.phone && (
							<div className="text-dark">
								Phone #: {employee?.phone}
							</div>
						)}
					</>
				<div className="text-muted">
					{teamName}
				</div>
			</div>
		</div>
	)
}
