import { toUpper } from 'lodash';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EmployeeType } from '../../../app/types/EmployeeType';

interface Props {
	employee: EmployeeType;
	show?: boolean;
	onClose?: () => void;
	onConfirm?: () => void;
}

export function EmployeeDeleteModal({
																			employee,
																			show,
																			onClose,
																			onConfirm,
																		}: Props) {
	const [confirmButtonShow, setConfirmButtonShow] = useState<boolean>(false);

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleOnConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};

	const handleValidateConfirm = (e: any) => {
		const validation = toUpper(e.target.value).trim();
		if (validation === 'CONFIRM') {
			setConfirmButtonShow(true);
		} else {
			setConfirmButtonShow(false);
		}
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete {employee.firstName} {employee.lastName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="font-monospace">
						By deleting {employee.firstName} {employee.lastName}, the employee and all connections associated with them
						will be <strong>permanently</strong> deleted.
					</p>
					<p className="font-monospace">
						Please review the Connection History on their page to confirm those records can be deleted. Deletion of
						connection data will alter engagement metrics for the supervisor and company.
					</p>
					<p className="font-monospace fw-semibold">
						Typically if you are terminating an employee you should click "edit" on their employee profile and change
						them to terminated status instead of deleting all records.
					</p>
					<p>
						If you want to proceed, please type in <b>"confirm"</b>.
					</p>
					<div className="row mb-6">
						<div className="col-lg-6">
							<div className="col-lg-12">
								<input
									type="text"
									onChange={handleValidateConfirm}
									className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={handleClose}>
						Close
					</Button>
					<Button
						variant="primary"
						disabled={!confirmButtonShow}
						onClick={handleOnConfirm}
					>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

