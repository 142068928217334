import { pick, values } from 'lodash';
import React, { useState, useEffect } from 'react';
import { PerformanceReportConfigType, PerformanceReportOption } from '../../../../types/PerformanceReportConfigType';
import DatePicker from 'react-datepicker';
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import './EmployeePerformanceCardConfig.scss';

interface Props {
	config: PerformanceReportConfigType;
	setConfig: (config: PerformanceReportConfigType) => void;
	onGenerateReport: (config: PerformanceReportConfigType) => void;
}

export const EmployeePerformanceCardConfig = ({
																								config,
																								setConfig,
																								onGenerateReport,
																							}: Props) => {
	const handleOptionToggle = (type: PerformanceReportOption) => {
		let additionalUpdate: {[key: string]: boolean} = {};
		if (type === 'attendance' && config.attendance) {
			additionalUpdate.excused = false;
		}
		if (type === 'showCalendar') {
			additionalUpdate.showCalendar = !config.showCalendar;
		}
		setConfig({
			...config,
			[type]: !config[type],
			...additionalUpdate,
		});
	};

	const isGenerateEnabled = function() {
		return values(pick(config, 'attendance', 'excused', 'coaching', 'discipline', 'recognition')).includes(true);
	};

	const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'years').toDate());
	const [endDate, setEndDate] = useState<Date>(moment().add(1, 'days').toDate());

	// whenever startDate or endDate changes, useEffect updates config
	useEffect(() => {
		if (startDate && endDate) {
		  setConfig({
				...config,
				startDate,
				endDate,
		  });
		}
	}, [startDate, endDate]);

	return (
		<div
			className="modal fade"
			id="employee_performance_card_config"
			aria-hidden="true">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header d-flex justify-content-start">
						<h2>Instant Performance Report</h2>
						{/*<span className="mx-5 badge rounded-pill bg-info">Beta</span>*/}
					</div>
					<div className="modal-body mx-0">
						<h5 className="text-muted subtitle">
							Select connections to include
						</h5>
						<div className="d-flex flex-column col-4">
							<div className="form-check form-check-custom form-check-solid justify-content-between mt-5">
								<label className="align-items-start">Recognition</label>
								<input
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.recognition}
									onChange={() => handleOptionToggle('recognition')}
								/>
							</div>
							<div className="form-check form-check-custom form-check-solid justify-content-between mt-5">
								<label className="align-items-start">Coaching</label>
								<input
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.coaching}
									onChange={() => handleOptionToggle('coaching')}
								/>
							</div>
							<div className="form-check form-check-custom form-check-solid justify-content-between mt-5">
								<label className="align-items-start">Attendance</label>
								<input
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.attendance}
									onChange={() => handleOptionToggle('attendance')}
								/>
							</div>
							<div className="form-check form-check-custom form-check-solid justify-content-between mt-5">
								<label className="align-items-start">Excused</label>
								<input
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.excused}
									onChange={() => handleOptionToggle('excused')}
								/>
							</div>
							<div className="form-check form-check-custom form-check-solid justify-content-between mt-5">
								<label className="align-items-start">Corrective Action</label>
								<input
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.discipline}
									onChange={() => handleOptionToggle('discipline')}
								/>
							</div>
						</div>
						<div className="mt-5">
							<h5>Report Date Range</h5>

								<div className="d-flex flex-row justify-content-between">
									{/* begin::DatePicker */}
									<div className="form-check form-check-custom justify-content-between form-switch form-check-solid mt-2 col-6 date-picker-margin"
									style={{ marginRight: '10px' }}
									>
									<label className="align-items-start">Start Date</label>
									<DatePicker
										className="form-control"
										selected={startDate}
										onChange={(date) => {
											if (date) {
												setStartDate(date);
											}
										}}
									/>
									</div>
									{/* end::DatePicker */}

									{/* begin::DatePicker */}
									<div className="form-check form-check-custom justify-content-between form-switch form-check-solid mt-2 col-6">
									<label className="align-items-start">End Date</label>
									<DatePicker
										className="form-control"
										selected={endDate}
										onChange={(date) => {
											if (date) {
												setEndDate(date);
											}
										}}
									/>
									</div>
									{/* end::DatePicker */}
								</div>
							</div>


						<div className="mt-5">
							<h5>Report View</h5>

							<div className="d-flex flex-row justify-content-between">
								<div className="form-check form-check-custom form-switch form-check-solid mt-2 col-6"
								style={{ marginRight: '10px' }}
								>
									<label className="align-items-start" style={{ marginRight: '10px' }}>Chronological Order</label>
									<input
										className="align-items-end form-check-input"
										type="checkbox"
										checked={config.combineConnections}
										onChange={() => handleOptionToggle('combineConnections')}
									/>
								</div>
								<div className="form-check form-check-custom form-switch form-check-solid mt-2 col-6" >
									<label className="align-items-start" style={{ marginRight: '10px' }}>Show Calendar</label>
										<input
											className="align-items-end form-check-input"
											type="checkbox"
											checked={config.showCalendar}
											onChange={() => handleOptionToggle('showCalendar')}
										/>
								</div>
							</div>
							<div className="mt-3 col-4 align-items-end">
									<button
										type="button"
										className="btn btn-sm btn-light-primary"
										onClick={() => onGenerateReport(config)}
										disabled={!isGenerateEnabled()}
									>
										Generate Report
									</button>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
