import { useState } from 'react'
import { KTSVG } from '../../../../../_library/helpers'
import { disciplineStepSlice } from '../../../../../features/discipline/discipline-step.api'
import { DisciplineStep, DisciplineStepTypeEnum } from '../../../../modules/discipline/models/DisciplineStep.models';
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'

type Props = {
    goNext?: () => void
    setShowPage: (page: string) => void
    setEditStep: (dStep: DisciplineStep) => void
}

export function DisciplineStepsList({ goNext, setShowPage, setEditStep }: Props) {
    const { data: disciplineSteps, isSuccess: disciplineStepsSuccess } = disciplineStepSlice.useGetDisciplineStepsQuery({}, {selectFromResult: (state) => {
        if (state.data?.length) {
            const perf = state.data?.filter(itm => itm.type === DisciplineStepTypeEnum.Performance).sort((a, b) => a.stepNumber - b.stepNumber);
            const att = state.data?.filter(itm => itm.type === DisciplineStepTypeEnum.Attendance).sort((a, b) => a.stepNumber - b.stepNumber);
            return {
                ...state,
                data: [...att, ...perf],
            };
        }
        return state;
    }});
    const [deleteDisciplineStep] = disciplineStepSlice.useDeleteDisciplineStepMutation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedStepId, setSelectedStepId] = useState<number | null>(null);

    const handleEditStep = (step: DisciplineStep) => {
        setEditStep(step)
        setShowPage('edit')
    }

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedStepId !== null) {
            deleteDisciplineStep({ id: selectedStepId })
              .then(() => {
                  successToast()
              })
              .catch(err => {
                  console.log(err);
                  errorToast();
              });
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleRemoveStep = (step: DisciplineStep) => {
        setSelectedStepId(step.id);
        setShowDeleteDialog(true);
    };


    return (
        <>
        <div>
            {goNext && <div className='d-flex flex-stack mb-5'>
                <div className='me-2'>
                    {/* <button
                        onClick={() => { }}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                    >
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                        />
                        Back
                    </button> */}
                </div>

                <div>
                    <button
                        type='button'
                        className='btn btn-md btn-primary me-3'
                        onClick={goNext}
                    >
                        <span className='indicator-label'>
                            Next
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                            />
                        </span>
                    </button>
                </div>
            </div>}
            <div className="d-flex">
                <button
                    type='button'
                    className='link-primary btn btn_add mt-2 px-0'
                    onClick={() => setShowPage('create')}
                >
                    + Add Step
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th className='fw-bolder'>Name</th>
                        <th className='fw-bolder'>Type</th>
                        <th className='fw-bolder'>Number</th>
                        <th className='fw-bolder'>Color</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {disciplineSteps?.map((step: DisciplineStep, index: number) => {
                        return <tr className='action_line' key={index}>
                            <td className='action_name'>{step.name}</td>
                            <td className='action_name'>
                                <span className={step.type === 'Attendance' ? 'badge badge-danger' : 'badge badge-info'}>{step.type}</span>
                            </td>
                            <td className='action_name'>{step.stepNumber}</td>
                            <td className='action_name d-flex align-items-center'>
                                <span className='me-1' style={{
                                    backgroundColor: step.stepColor,
                                    width: '20px',
                                    height: '20px',
                                    display: 'block'
                                }}></span>{step.stepColor}
                            </td>
                            <td className="action_buttons">
                                <span className="btn_edit ms-3"
                                    onClick={() => handleEditStep(step)}
                                >
                                    <i className="fas fa-edit text-primary fs-4"></i>
                                </span>
                                <span className="btn_remove ms-3"
                                    onClick={() => handleRemoveStep(step)}
                                >
                                    <i className="fas fa-trash text-danger fs-4"></i>
                                </span>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        {showDeleteDialog && (
                <SkeDialogDelete
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    successMessage='Step deleted'
                    message={`Are you sure you want to delete this corrective action step?`}
                />
            )}
        </>
    )
}
