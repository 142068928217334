import React from 'react';
import { SHORT_DATE, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { CoachingType } from '../../../types/CoachingType';
import moment from 'moment';

interface Props {
	coaching: CoachingType;
	showDate?: boolean;
	rxKey: string | number;
	onClick?: (coa: CoachingType) => void;
	onActionClick?: (coa: CoachingType, action: 'edit'|'delete') => void;
}

export function ConnectionHistoryCoachingRow({
																							 coaching,
																							 showDate = true,
																							 rxKey,
																							 onActionClick,
																							 onClick,
																						 }: Props) {
	return (
		<tr
			className="cursor-pointer"
			onClick={() => {
				if (onClick) {
					onClick(coaching);
				}
			}}
			key={rxKey}>
			<td>{showDate && moment(coaching.date, SHORT_DATE).format(US_FRIENDLY_DATE)}</td>
			<td>
				<span className="me-2 fs-7 fw-bold badge-light-primary px-3 rounded">
						{coaching.connectionType}
				</span>
			</td>
			<td>{coaching.category?.name}</td>
			<td className="text-break">{coaching.comment}</td>
			<td>{coaching.coacher?.firstName} {coaching.coacher?.lastName}</td>
			<td className="d-flex">
				<button
					className="btn btn-secondary btn-active-light-primary btn-sm"
					onClick={(e) => {
						if (onActionClick) {
							e.stopPropagation();
							onActionClick(coaching, 'edit');
						}
					}}
				><i className="bi bi-pencil-square"></i>
				</button>
				<button
					className="btn btn-sm"
					onClick={(e) => {
						if (onActionClick) {
							e.stopPropagation();
							onActionClick(coaching, 'delete');
						}
					}}
				><i className="fas fa-trash text-danger fs-4"></i></button>
			</td>
		</tr>
	);
}
