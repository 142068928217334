import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import {
	Discipline,
	DisciplineCreate,
	DisciplinesGetAllApiRequestModel,
	DisciplineUpdate,
} from './interfaces/discipline.model';

export enum DisciplineTagTypes {
	Discipline = 'DISCIPLINE',
}

export const disciplineSlice = createApi({
	reducerPath: 'disciplineApi',
	tagTypes: [DisciplineTagTypes.Discipline],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/disciplines`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getDisciplines: builder.query<ApiResponse<Discipline>, DisciplinesGetAllApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponse<Discipline>) => {
				return response;
				// return response.items;
			},
			extraOptions: {
				includeMeta: false,
				includeLinks: false,
			},
			transformErrorResponse: (response: {status: string | number}) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.items.map(({id}) => ({
							type: DisciplineTagTypes.Discipline,
							id,
						} as const)),
						{
							type: DisciplineTagTypes.Discipline,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: DisciplineTagTypes.Discipline,
						id: 'LIST',
					},
				];
			},
		}),
		getOneDiscipline: builder.query<Discipline, {id: number}>({
			query: (args: {id: number}) => {
				let url = `${args.id}`;
				return {
					url,
				};
			},
			transformErrorResponse: (response: {status: string | number}, meta, arg) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						{
							type: DisciplineTagTypes.Discipline,
							id: result.id,
						},
					];
				}
				return [];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		}),
		createDiscipline: builder.mutation<Discipline, DisciplineCreate>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			invalidatesTags: [
				{
					type: DisciplineTagTypes.Discipline,
					id: 'LIST',
				},
			],
		}),
		updateDiscipline: builder.mutation<Discipline, DisciplineUpdate>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: DisciplineTagTypes.Discipline,
							id: 'LIST',
						},
						{
							type: DisciplineTagTypes.Discipline,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteDiscipline: builder.mutation<void, {id: number}>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			transformErrorResponse: (response: {status: string | number}) => response.status,
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: DisciplineTagTypes.Discipline,
						id: 'LIST',
					},
					{
						type: DisciplineTagTypes.Discipline,
						id: args.id,
					},
				];
			},
		}),
	}),
});
