import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import {
  getAndSetBearerToken,
  reduxTransformErrorResponseHandler,
} from '../../common/api/common.api';
import {
  CreateRecognitionCategoryApiRequestModel,
  UpdateRecognitionCategoryApiRequestModel,
  GetRecognitionCategoryApiRequestModel,
  DeleteRecognitionCategoryApiRequestModel,
  RecognitionCategory,
} from './interfaces/recognition-categories.model';

export enum RecognitionCategoriesTagTypes {
  RecognitionCategories = 'RECOGNITION_CATEGORIES',
}

export const recognitionCategoriesSlice = createApi({
  reducerPath: 'recognitionCategoriesApi',
  tagTypes: [RecognitionCategoriesTagTypes.RecognitionCategories],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/recognition-categories`,
    prepareHeaders: getAndSetBearerToken,
  }),
  endpoints: (builder) => ({
    getRecognitionCategories: builder.query<RecognitionCategory[], GetRecognitionCategoryApiRequestModel>({
      query: (args) => {
        return {
          url: '',
          params: args,
        };
      },
      transformResponse: (response: ApiResponseWithoutPagination<RecognitionCategory>) => {
        return response.items;
      },
      transformErrorResponse(response: BaseQueryError<any>): void {
        reduxTransformErrorResponseHandler(response, 'Error getting recognition categories');
      },
      providesTags: (result, error, id) => {
        if (result) {
          return [
            ...result.map(({ id }) => ({
              type: RecognitionCategoriesTagTypes.RecognitionCategories,
              id,
            } as const)),
            {
              type: RecognitionCategoriesTagTypes.RecognitionCategories,
              id: 'LIST',
            },
          ];
        }
        return [
          {
            type: RecognitionCategoriesTagTypes.RecognitionCategories,
            id: 'LIST',
          },
        ];
      },
    }),
    createRecognitionCategory: builder.mutation<RecognitionCategory, CreateRecognitionCategoryApiRequestModel>({
      query: (args) => ({
        url: '',
        method: 'post',
        body: args,
      }),
      transformErrorResponse(response: BaseQueryError<any>): void {
        reduxTransformErrorResponseHandler(response, 'Error creating recognition category');
      },
      invalidatesTags: [
        {
          type: RecognitionCategoriesTagTypes.RecognitionCategories,
          id: 'LIST',
        },
      ],
    }),
    updateRecognitionCategory: builder.mutation<RecognitionCategory, UpdateRecognitionCategoryApiRequestModel>({
      query: (args) => ({
        url: `${args.id}`,
        method: 'PATCH',
        body: omit(args, 'id'),
      }),
      transformErrorResponse(response: BaseQueryError<any>): void {
        reduxTransformErrorResponseHandler(response, 'Error updating recognition category');
      },
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [
            {
              type: RecognitionCategoriesTagTypes.RecognitionCategories,
              id: 'LIST',
            },
            {
              type: RecognitionCategoriesTagTypes.RecognitionCategories,
              id: arg.id,
            },
          ];
        }
        return [];
      },
    }),
    deleteRecognitionCategory: builder.mutation<void, DeleteRecognitionCategoryApiRequestModel>({
      query: (args) => ({
        url: `${args.id}`,
        method: 'delete',
      }),
      transformErrorResponse(response: BaseQueryError<any>): void {
        reduxTransformErrorResponseHandler(response, 'Error deleting recognition category');
      },
      invalidatesTags: (result, error, args) => {
        return [
          {
            type: RecognitionCategoriesTagTypes.RecognitionCategories,
            id: 'LIST',
          },
          {
            type: RecognitionCategoriesTagTypes.RecognitionCategories,
            id: args.id,
          },
        ];
      },
    }),
  }),
});
