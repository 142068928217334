import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../app/models'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'
import { AsideTeamList } from './AsideTeamList'
import LeaderboardIcon from '../../../../images/icons/123.svg';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content py-0'>
          <span className='menu-section text-muted text-uppercase'>Menu</span>
        </div>
      </div>
      {(!!user.roles?.includes('SuperAdmin') || !!user.roles?.includes('Supervisor')) && <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/dashboard.svg'
        title="Dashboard"
        fontIcon='bi-app-indicator'
      />}

      {(user.roles?.includes('SuperAdmin')) && (
        <AsideMenuItem
          to='/leaderboards'
          iconComponent={LeaderboardIcon}
          title="Leaderboard"
          fontIcon='bi-app-indicator'
        />
      )}

      {(!!user.roles?.includes('SuperAdmin') || !!user.roles?.includes('Supervisor')) && <AsideMenuItem
        to='/connections/all'
        title='Connections'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/connection.svg'
      />}

      {!!user.roles?.includes('Provisioner') && <AsideMenuItem
        to='/users'
        title='User Management'
        fontIcon='bi-person'
        icon='/media/icons/duotune/communication/com006.svg'
      />}


      <div className='menu-item'>
        <div className='menu-content px-0 py-1'>
          <div className='separator mx-0 my-7'></div>
        </div>
      </div>

      {!!user.roles?.includes('SuperAdmin') && <AsideMenuItem
        to='/teams/create'
        title='Create Team'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >

      </AsideMenuItem>
      }
      {(!!user.roles?.includes('SuperAdmin') || !!user.roles?.includes('Supervisor')) && <div className='menu-item'>
        <div className='menu-content py-0'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Teams</span>
        </div>
      </div>}
      {(!!user.roles?.includes('SuperAdmin') || !!user.roles?.includes('Supervisor')) && <AsideTeamList />}
    </>
  )
}
