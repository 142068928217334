import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../../shared/models/ApiResponse.model';
import { CompanyDocument } from '../models/CompanyDocument.model';

const API_URL = process.env.REACT_APP_API_URL;

export function getAllDocuments(tenant_id: string, token: string): AxiosPromise<ApiResponse<CompanyDocument>> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/companies/${tenant_id}/documents`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		// params: opts,
	};

	return axios(config)
}
