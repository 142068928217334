import clsx from 'clsx';

interface Props {
	isChecked?: boolean;
	onChange?: (status: boolean | any) => void;
	label?: string;
	wrapperClasses?: string;
	inputClasses?: string;
	labelClasses?: string;
	id?: string;
	disabled?: boolean;
	size?: 'sm' | 'lg';
	inactiveValue?: any;
	activeValue?: any;
	inactiveLabel?: string;
	activeLabel?: string;
}

export function ToggleSwitch({
															 isChecked,
															 id,
															 label,
															 wrapperClasses,
															 labelClasses,
															 inputClasses,
															 onChange,
															 disabled,
															 size,
															 inactiveValue,
															 activeValue,
															 inactiveLabel,
															 activeLabel,
														 }: Props) {

	return (
		<div
			className={clsx('form-check form-check-solid ' +
				' form-switch d-flex align-items-center d-inline-flex ',
				wrapperClasses,
				{
					'form-switch-lg': size === 'lg',
					'form-switch-sm': size === 'sm',
				})}>
			<label
				className={clsx('form-check-label fs-4 fw-light control-label',
					labelClasses)}>
				<input
					className={clsx('form-check-input', inputClasses)}
					type="checkbox"
					role="switch"
					checked={isChecked}
					disabled={disabled}
					onChange={(e) => {
						if (onChange) {
							let val = e.target.checked;
							if (inactiveValue && !val) {
								val = inactiveValue;
							} else if (activeValue && val) {
								val = activeValue;
							}
							onChange(val);
						}
					}}
					id={id} />
				{isChecked && (
					<>
						{!!activeLabel && activeLabel}
						{!activeLabel && !!label && label}
					</>
				)}
				{!isChecked && (
					<>
						{!!inactiveLabel && inactiveLabel}
						{!inactiveLabel && !!label && label}
					</>
				)}
			</label>
		</div>
	);
}
