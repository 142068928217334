import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_library/helpers'
import { PageLink, PageTitle } from '../../../_library/layout/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { updateUser, setUserAvatar, getUser } from './UsersCRUD'
import { useParams } from 'react-router'
import { UserModel } from '../../models'
import { toast } from 'react-toastify';


const editUserBreadCrumbs: Array<PageLink> = [
    {
        title: 'Users',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type RouteParams = {
    userId: string
}

function EditUser() {
    const { userId } = useParams<RouteParams>();
    const navigate = useNavigate();
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [loading, setLoading] = React.useState(false)
    const [avatarFile, setAvatarFile] = React.useState<any>(null)
    const [avatar, setAvatar] = React.useState('')
    const [user, setUser] = React.useState<UserModel | null>(null)
    const [initialValues, setInitialValues] = React.useState({
        firstName: '',
        lastName: '',
        isEmailVerified: false,
        isLockedOut: false,
        role: ''
    })


    const registrationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('First name is required'),
        lastName: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Last name is required'),
        role: Yup.string()
            .required('Role is required'),
    })

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            setAvatarFile(file)
            reader.onloadend = () => {
                if (reader && reader.result) {
                    setAvatar(reader.result as string)
                }
            }
            reader.readAsDataURL(file)
        }
    }

    const removeAvatar = () => {
        setAvatar('')
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: (values, { setSubmitting }) => {
            if (!userId) {
                return;
            }
            setLoading(true)
            setTimeout(() => {
                updateUser(
                    +userId,
                    values.firstName,
                    values.lastName,
                    values.isEmailVerified,
                    values.isLockedOut,
                    values.role.split(','),
                    token
                )
                    .then(() => {
                        if (!!avatarFile) {
                            const requestBody = new FormData();
                            requestBody.append("file", avatarFile);
                            setUserAvatar(+userId, requestBody, token)
                                .then(() => {
                                    setLoading(false)
                                    navigate('/users')
                                })
                                .catch(error => {
                                    console.log(error)
                                    setLoading(false)
                                    navigate('/users')
                                })
                        } else {
                            setLoading(false)
                            navigate('/users')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error(err.response.data.message,{
                            position: 'top-right',
                            theme: 'colored',
                            autoClose: 3500,
                        });
                        setLoading(false)
                        setSubmitting( false)
                    })
            }, 1000)
        },
    })

    useEffect(() => {
        if (!userId) {
            return;
        }
        getUser(+userId, token)
            .then(({ data }) => {
                setUser(data)
                setInitialValues({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    isEmailVerified: data.isEmailVerified,
                    isLockedOut: data.isLockedOut,
                    role: data.roles.toString()
                })
            })
    }, [userId, token])

    return (
        <>
            <PageTitle breadcrumbs={editUserBreadCrumbs}>Edit a User</PageTitle>
            <div className="card edit_user_card">
                <div className="card-header border-0 pt-5"></div>
                <div className='card-body py-3'>
                    <div className='col-lg-12 text-center'>
                        <div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-150px h-150px m-auto">
                            <div
                                className={`image-input image-input-outline w-120px h-120px ${avatar === '' ? 'image-input-empty' : ''}`}
                                data-kt-image-input='true'
                                style={{ backgroundImage: `url(${user?.avatarUrl ? user.avatarUrl : toAbsoluteUrl('/media/avatars/blank.png')})` }}
                            >
                                <div
                                    className='image-input-wrapper no-shadow shadow-none'
                                    style={{ backgroundImage: `url(${toAbsoluteUrl(avatar)})` }}
                                ></div>
                                <label className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                    <i className="bi bi-pencil-fill fs-7"></i>
                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                                    <input type="hidden" name="avatar_remove" />
                                </label>
                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="" data-bs-original-title="Remove avatar" onClick={removeAvatar}>
                                    <i className="bi bi-x fs-2"></i>
                                </span>
                            </div>
                        </div>
                        <div className="form-text mt-5">Set the product thumbnail image. Only
                            *.png, *.jpg and *.jpeg image files
                            are accepted</div>
                    </div>
                    <div className='w-lg-700px p-lg-15 p-10 bg-white rounded mx-auto'>
                        <form
                            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                            noValidate
                            id='kt_login_signup_form'
                            onSubmit={formik.handleSubmit}

                        >
                            <div className='row fv-row mb-7'>
                                <div className='col-xl-6'>
                                    <div className='fv-row'>
                                        <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
                                        <input
                                            placeholder='First Name'
                                            type='text'
                                            autoComplete='off'
                                            {...formik.getFieldProps('firstName')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.firstName && formik.errors.firstName,
                                                },
                                                {
                                                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                                }
                                            )}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.firstName}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-xl-6'>
                                    <div className='fv-row'>
                                        <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
                                        <input
                                            placeholder='Last Name'
                                            type='text'
                                            autoComplete='off'
                                            {...formik.getFieldProps('lastName')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.lastName && formik.errors.lastName,
                                                },
                                                {
                                                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                                }
                                            )}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.lastName}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='fv-row mb-7'>
                                <div className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isEmailVerified')}
                                        checked={formik.values.isEmailVerified}
                                    />
                                    <label className="form-check-label fw-bolder text-dark fs-6" htmlFor="flexCheckDefault">
                                        Verify Email
                                    </label>
                                </div>
                            </div>

                            <div className='fv-row mb-7'>
                                <div className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isLockedOut')}
                                        checked={formik.values.isLockedOut}
                                    />
                                    <label className="form-check-label fw-bolder text-dark fs-6" htmlFor="flexCheckDefault">
                                        Lock Out
                                    </label>
                                </div>
                            </div>

                            <div className='fv-row mb-7'>
                                <label className='form-label fw-bolder text-dark fs-6'>Role</label>
                                <select
                                    {...formik.getFieldProps('role')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        // {
                                        //     'is-invalid': formik.touched.role && formik.errors.role,
                                        // },
                                        // {
                                        //     'is-valid': formik.touched.role && !formik.errors.role,
                                        // }
                                    )}
                                    style={{ appearance: 'auto' }}
                                >
                                    <option value=""></option>
                                    <option value="Supervisor">Supervisor</option>
                                    <option value="Provisioner">Provisioner</option>
                                    <option value="SuperAdmin">SuperAdmin</option>
                                    <option value="SuperAdmin,Provisioner">SuperAdmin & Provisioner</option>
                                </select>
                                {formik.touched.role && formik.errors.role && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.role}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='text-center mt-10'>
                                <button
                                    type='submit'
                                    id='kt_sign_up_submit'
                                    className='btn btn-lg btn-primary min-w-150px mb-5'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='indicator-label'>Update</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { EditUser }
