import React from 'react';
import { teamSlice } from '../../../../features/team/team.api';
import { AsideMenuTeamItem } from './AsideMenuTeamItem';

export function AsideTeamList() {
	const {data: teams} = teamSlice.useGetAllTeamsQuery();
	const sortedTeams = teams?.slice().sort((a, b) => a.name.localeCompare(b.name));

	return (
		<div className="team_list_wrapper">
			{
				sortedTeams?.map(team => {
					return (
						<AsideMenuTeamItem
							to={`/teams/show/${team.id}`}
							title={team.name}
							key={team.id}
							hasBullet={false}
							avatarUrl={team.avatarUrl} />
					);
				})
			}
		</div>
	);
}
