import clsx from 'clsx';
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../setup';
import { UserModel } from '../../models';

type Props = {
	id: number
}

const DropdownTeamMemberActions: FC<Props> = ({ id }) => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px fs-6"
			data-kt-menu="true"
		>
			<div className="menu-item px-2">
				<a
					href="#"
					className="menu-link px-3"
					data-bs-toggle="modal"
					data-bs-target="#import_csv_modal"
				>
					Import
				</a>
			</div>

			{!user.roles?.includes('Supervisor') && <div className="menu-item px-2">
				<a
					href="#"
					className="menu-link px-3"
					data-bs-toggle="modal"
					data-bs-target="#kt_modal_add_user"
				>
					Create Employee
				</a>
			</div>}

			{!!user.roles?.includes('SuperAdmin') && <div className="menu-item px-2">
				<Link
					className={clsx('menu-link px-3')}
					to={`/teams/edit/${id}`}>
					Edit
				</Link>
			</div>}
			{/*// disabled until database team deletion migration processed
			{!!user.roles?.includes('SuperAdmin') && <div className="menu-item px-2 mb-2">
				<a
					href="#"
					className="menu-link px-3"
					data-bs-toggle="modal"
					data-bs-target="#delete_team_modal">
					Delete
				</a>
			</div>}*/}

		</div>
	);
};

export { DropdownTeamMemberActions };
