import { UnknownRecord } from 'type-fest';
import { Company } from '../../../app/modules/company/models/Company.model';
import {User} from '../../../app/modules/user/models/User.model';
import { CategoryModel } from '../../category/interfaces/category.model';
import {DeepPartial} from 'redux';
import { ActionUser } from '../../connection/interfaces/connection.model';

// ConnectionSettingTokenEnum in API
export enum ConnectionSettingTokenEnum {
	Connection = 'CX_GENERIC',
	Attendance = 'CX_ATTENDANCE',
	Coaching = 'CX_COACHING',
	Discipline = 'CX_DISCIPLINE',
	Recognition = 'CX_RECOGNITION',
	AiPerformanceReview = 'CX_REVIEW_PERFORMANCE_AI'
}

export enum WorkflowDisplayMethod {
	Button = 'BUTTON',
	Dropdown = 'DROPDOWN',
}

export enum WorkflowStepToken {
	Draft = 'DRAFT',
	RequestedApproval = 'REQUESTED_APPROVAL',
	ChangesRequired = 'CHANGES_REQUIRED',
	Declined = 'DECLINED',
	Approved = 'APPROVED',
	Issued = 'ISSUED',
	Submitted = 'SUBMITTED',
	Filed = 'FILED',
	Deleted = 'DELETED',
}

export enum WorkflowActionToken {
	Save = 'SAVE',
	RequestApproval = 'REQUEST_APPROVAL',
	Delete = 'DELETE',
	RequireChanges = 'REQUIRE_CHANGES',
	Approve = 'APPROVE',
	Decline = 'DECLINE',
	Issue = 'ISSUE',
	Submit = 'SUBMIT',
	File = 'FILE',
	HardDelete = 'HARD_DELETE',
	Print = 'PRINT',
	Preview = 'PREVIEW',
}
export interface WorkflowResponseModel {
	owners: User[];
	reviewers: User[];
	workflow: Workflow;
}

export interface GetOneWorkflowByTokenApiRequestModel {
	args: {
		token: ConnectionSettingTokenEnum;
	};
	query?: {
		team_id?: number;
		action_id?: number;
		status?: WorkflowStepToken;
		defaultStatus?: boolean;
	}
}

export interface GetOneWorkflowResults {
	workflow: Workflow;
	owners: WorkflowCompactedUser[];
	reviewers: WorkflowCompactedUser[];
	// requires status to be provided
	step?: WorkflowStep;
	// requires action_id to be provided
	action?: WorkflowAction;
	// requires team_id AND either status or defaultStatus: true to be provided
	authorizedUsers?: {
		[key in WorkflowActionToken]: ActionUser
	}
}

export interface WorkflowCompactedUser extends Pick<User,
	| 'id'
	| 'firstName'
	| 'lastName'
	| 'roles'
	| 'email'
> {
	teamIds: number[];
}

export interface Workflow {
	id: number;
	name: string;
	description: string;
	data: unknown;
	category_id: number;
	category: CategoryModel;
	tenant_id: string | null;
	company: Company;
	group_id: string | null;
	tags: string[];
	token: ConnectionSettingTokenEnum
	misc: unknown;
	settings: WorkflowSettings;
	metadata: unknown;
	created_at: string | Date;
	updated_at: string | Date | null;
	deleted_at: string | Date | null;
	steps: WorkflowStep[];
}

export interface WorkflowSettings {
	reviewer: {
		whitelist: WorkflowReviewTags[];
		blacklist: WorkflowReviewTags[];
	};
	owner: {
		whitelist: WorkflowReviewTags[];
		blacklist: WorkflowReviewTags[];
	};
}

export interface WorkflowStep {
	id: number;
	name: string;
	description: string;
	data: unknown;
	sort: string;
	is_first_step: boolean;
	is_last_step: boolean;
	workflow_id: number;
	workflow: Workflow;
	tenant_id: string | null;
	company: Company;
	group_id: string | null;
	tags: string[];
	token: WorkflowStepToken;
	misc: unknown;
	settings: unknown;
	metadata: unknown;
	created_at: string | Date;
	updated_at: string | Date | null;
	deleted_at: string | Date | null;
	actions: WorkflowAction[];
}

export interface WorkflowAction {
	id: number;
	name: string;
	description: string;
	data: unknown;
	sort: number;
	workflow_id: number;
	workflow: Workflow;
	step_id: number;
	step: WorkflowStep;
	next_step_id: number | null;
	next_step: WorkflowStep | null;
	tenant_id: string;
	company: Company;
	group_id: string | null;
	tags: string[];
	token: WorkflowActionToken;
	misc: unknown;
	settings: DeepPartial<WorkflowActionSettings>;
	metadata: unknown;
	created_at: string | Date;
	updated_at: string | Date | null;
	deleted_at: string | Date | null;
}

export interface WorkflowActionSettings {
	is_default: boolean;
	display_method: WorkflowDisplayMethod;
	icon: string;
	save_btn_text: string;
	hint: string;
	text_prompt: boolean;
	disabled: boolean;
	// TODO: enum
	tags: string[];
	review: {
		whitelist: WorkflowReviewTags[];
		blacklist: WorkflowReviewTags[];
	};
}

export interface WorkflowActionSettings {
	is_default: boolean;
	display_method: WorkflowDisplayMethod;
	icon: string;
	save_btn_text: string;
	hint: string;
	text_prompt: boolean;
	disabled: boolean;
	// TODO: enum
	tags: string[];
	review: {
		whitelist: WorkflowReviewTags[];
		blacklist: WorkflowReviewTags[];
	};
}

// has = contains role/value
// is = contains role/value & has a team collision e.g. is a supervisor and on the team
// not = does not have role/value
export enum WorkflowReviewTags {
	HasSuperadmin = 'HAS_SUPERADMIN',
	HasSupervisor = 'HAS_SUPERVISOR',
	HasTeam = 'HAS_TEAM',
	HasSupervisorAndTeam = 'HAS_SUPERVISOR_AND_TEAM',
	NoSuperadmin = 'NO_SUPERADMIN',
	NoSupervisor = 'NO_SUPERVISOR',
	NotTeam = 'NOT_TEAM',
}
