import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { catchError, defer, from, map, Observable, of } from 'rxjs';
import { ApiResponse, ApiResponseWithoutPagination } from '../../shared/models/ApiResponse.model';
import { User } from '../models/User.model';

const API_URL = process.env.REACT_APP_API_URL;
export const COMPANIES_URL = `${API_URL}/companies`
export const USERS_URL = `${API_URL}/users`
const showSechhiNames = process.env.REACT_APP_SHOW_SECCHI_NAMES

export function getSuperAdmins(user: User, token: string): Observable<ApiResponse<User>> {
	const config: AxiosRequestConfig = {
		url: `${COMPANIES_URL}/${user.company.id}/superadmins`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		}
	};
	return from(defer(() => axios(config)))
		.pipe(
			catchError(err => {
				// TODO: trigger toast
				console.error(`Error caught getting company superadmins`, err);
				return of(err);
			}),
			// TODO: see about converting infractions, textFields to object
			// map(res => res.data),
			map((res)=>{
				if(!user.email.includes("secchi.io")){
					if(showSechhiNames === 'false'){
						if(res.data){
							if(res.data.items){
								res.data.items = res.data.items.filter((user: User) => !user.email.includes('secchi.io'))
								return res.data;
							}
						}
					}
				}
				return res.data;

			})
		);
}

export function getUsers(token: string): AxiosPromise<ApiResponseWithoutPagination<User>> {
	return axios({
		method: 'get',
		url: `${USERS_URL}/`,
		headers: {
			'Authorization': `Bearer ${token}`
		}
	})
}
