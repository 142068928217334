export interface ActivityModel {
	id: number;
	user_id?: number[];
	tenant_id?: string[];
	group_id?: string[];
	start_time?: string;
	end_time?: string;
	is_read: boolean;
	created_at: string;
	deleted_at: string;
	activity_type_enum: ActivityTypeEnum;
	message?: string;
	action_type_enum?: ActionTypeEnum[];
	// TODO: strongly-type
	metadata?: any;
	team_id?: number[];
	title?: string;
	created_by?: number;
	target_date?: string;
}

export enum ActionTypeEnum {
	RecognitionNewToEmployee = 'RECOGNITION_NEW_TO_EMPLOYEE',
}

export enum ActivityTypeEnum {
	AttendanceStreakMilestone = 'ATTENDANCE_STREAK_MILESTONE',
	Maintenance = 'MAINTENANCE',
}

export enum ActivityTypeReadable {
	ATTENDANCE_STREAK_MILESTONE = 'Streak',
	MAINTENANCE = 'Maintenance',
}

