import React, { ReactNode, useEffect, useState } from 'react';
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { NotificationDrawer } from '../../app/components/drawer/NotificationDrawer'
import { DrawerComponent, MenuComponent } from '../assets/ts/components'
import { NotificationType } from '../../app/types/NotificationType'
import { getNotifications } from '../../app/CRUD/TeamCRUD'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import 'react-datepicker/dist/react-datepicker.css';

import './styles/masterlayout.scss'
import { DisciplineType } from '../../app/types/DisciplineType'
import {Alert} from "react-bootstrap";

interface MasterLayoutProps {
  onboarding?: boolean;
  children?: ReactNode;
}

const MasterLayout: React.FC<MasterLayoutProps> = ({ children, onboarding }) => {
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const [notifications, setNotifications] = useState<NotificationType[]>([])
  const [notificationDrawerOpened, setNotificationDrawerOpened] = useState(false)

  const refreshNotifications = () => {
    getNotifications(token)
      .then(({ data }) => {
        setNotifications(data.items)
      })
  }
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

    refreshNotifications();
  }, [token])


  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    const noteDrawer = DrawerComponent.getInstance('kt_drawer_notification')
    if (noteDrawer) {
      noteDrawer.on("kt.drawer.hide", function () {
        if (notificationDrawerOpened) {
          setNotificationDrawerOpened(false)
        }
      });
    }
  }, [notificationDrawerOpened, token])

  return (

    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>

        <AsideDefault onboarding={onboarding} />
        <div className={`wrapper ${onboarding ? 'headerwrapper_hidden' : ''} d-flex flex-column flex-row-fluid`} id='kt_wrapper'>
          <HeaderWrapper
            setNotifications={setNotifications}
            setNotificationDrawerOpened={setNotificationDrawerOpened}
            refreshNotifications={refreshNotifications}
            notifications={notifications}
          />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
        </div>
      </div>

      <NotificationDrawer
        notifications={notifications}
        setNotifications={setNotifications}
        refreshNotifications={refreshNotifications}
        />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
