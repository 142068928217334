import React from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { NotificationMaintenance, NotificationType } from '../../../../app/types/NotificationType';
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Search } from '../../../partials'
import { Topbar } from './Topbar'
import { SearchForAdvanced } from '../../../../app/pages/advanced_search/SearchForAdvanced'

type Props = {
  setNotifications: (notifications: NotificationType[]) => void
  notifications: (NotificationType | NotificationMaintenance)[];
  setNotificationDrawerOpened: (active: boolean) => void
  refreshNotifications: () => void
}

export function HeaderWrapper(
  {
    setNotifications,
    setNotificationDrawerOpened,
    refreshNotifications,
    notifications,
  }: Props) {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  const location = useLocation()

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' />
            </Link>
          </div>
        )}

        <div className={`d-flex align-items-stretch ${location.pathname === '/advanced-search' ? 'flex-grow-1' : 'justify-content-between flex-lg-grow-1'}`}>
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className={`d-flex ${location.pathname === '/advanced-search' ? 'flex-grow-1' : ''}`}>
            {location.pathname !== '/advanced-search' && <div className={clsx('d-flex align-items-stretch me-8')}>
              <Search />
            </div>}
            {location.pathname === '/advanced-search' && <div className={clsx('d-flex flex-grow-1 align-items-stretch mx-8')}>
              <SearchForAdvanced />
            </div>}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar
                showNotificationIcon={true}
                setNotifications={setNotifications}
                notifications={notifications}
                setNotificationDrawerOpened={setNotificationDrawerOpened}
                refreshNotifications={refreshNotifications}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
