import { noop } from 'lodash';
import React, { useEffect, useState } from 'react'
import { UserModel } from '../../../../../models'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { updateCompany } from '../../../../../CRUD/SettingsCRUD'
import * as auth from '../../../../../modules/auth/redux/AuthRedux'
import { SkeDialogDelete } from '../../../../../../common/components/dialog-delete'

const SmsSettings: React.FC = () => {
    const dispatch = useDispatch()
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [checked, setChecked] = useState(user.company?.isSmsEnabled)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const customMessage = `Are you sure you want to ${checked ? 'disable' : 'enable'} SMS?`;

    const handleConfirmDelete = async (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false)
        if (user.company) {
            await updateCompany(user.company?.id, {
                isSmsEnabled: !checked,
            }, token)
              .then(() => {
                  successToast();
                  setChecked(!checked);
                  dispatch(auth.actions.setUser({ ...user, company: { ...user.company!, isSmsEnabled: !checked } }));
              })
            .catch(noop);
        }
    }

    const handleCancelDelete = () => {
        setShowDeleteDialog(false)
    }

    const handleChange = () => {
        setShowDeleteDialog(true)
    }

    useEffect(() => {
        let abort = new AbortController()

        return () => {
            abort.abort()
        }
    }, [])

    return (
        <>
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_sms'
                aria-expanded='true'
                aria-controls='kt_account_sms'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>SMS</h3>
                </div>
                <div className='card-toolbar'>
                    <div className='form-check form-check-solid d-flex align-items-center me-20'>
                        <span className="switch_label me-3">Off</span>
                        <label className="form-check form-switch form-check-custom form-check-solid">
                            <input
                                className="form-check-input h-30px w-50px"
                                name="sms"
                                type="checkbox"
                                value=""
                                checked={checked}
                                onChange={handleChange}
                            />
                        </label>
                        <span className="switch_label ms-3">On</span>
                    </div>
                </div>
            </div>

        </div >
        {showDeleteDialog && (
                <SkeDialogDelete
                    title="Confirm Changes"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    successMessage={`SMS ${checked ? 'disabled' : 'enabled'}`}
                    message={customMessage}
                />
            )}
        </>
    )
}

export { SmsSettings }
