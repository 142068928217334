import { LogType, SkeDomain } from '../ske-exception';

const logSettings = {
	items: [
		SkeDomain.Connection,
		SkeDomain.Discipline,
	],
};

export interface LoggerOptions {
	type?: LogType;
	error?: Error;
	misc?: unknown | unknown[];
	trace?: boolean;
}

export function Logger(message: any, item: SkeDomain, opts?: LoggerOptions) {
	const date = new Date();
	const formattedDate = date.toISOString();

	const type = opts?.type || LogType.Normal;
	const trace = opts?.trace || console.trace;

	if (localStorage.getItem('secchi-dev') === 'true' && logSettings.items.includes(item)) {
		switch (type) {
			case LogType.Normal: {
				console.log(`${formattedDate} [${item}] - ${message}`, opts?.misc);
				break;
			}
			case LogType.Warn: {
				console.warn(formattedDate + ' [' + item + '] - ' + message);
				break;
			}
			case LogType.Error: {
				console.error(formattedDate + ' [' + item + '] - ' + message);
				break;
			}
		}
		// if(trace){
		// 	let stack;
		// 	try {
		// 		throw new Error('trace')
		// 	} catch (error: any) {
		// 		stack = error.stack || '';
		// 		console.log('got stacked', typeof stack, stack);
		// 		const path = [];
		// 		stack.split('\n').map((line: string) => {
		// 		// static/js/src_features_connection_pages_ConnectionsList_tsx
		// 			if (/static\/js\/src/.test(line)) {
		// 				console.log('line!', line, line.replace('_tsx', '.tsx').replace('_', '/')
		// 			}
		// 		})
		// 		stack = stack.split('\n').map((line: any) => line.trim());
		// 		console.log('trac info', stack.splice(stack[0] === 'Error' ? 2 : 1));
		// 		console.trace();
		// 	}
		// }
	}

}
