import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { coachingModalSlice } from '../../../../features/coaching/coaching-modal.slice';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { Coaching } from '../../../../features/coaching/interfaces/coaching.model';

interface Props {
	coaching: Coaching;
	modalId?: string;
	onClose?: () => void;
}

export function CoachingViewModal({
																		coaching,
																		modalId = 'coaching_view_modal',
																		onClose
}: Props) {
	const dispatch = useDispatch();
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content p-3">
					<div className="modal-header d-flex flex-wrap-reverse justify-content-between align-items-center">
						<div className="col-12 col-sm-5">
							<EmployeeBadge
								employee={coaching?.employee}
								team={coaching?.team} />
						</div>

						<h1>Coaching</h1>
						<button
							onClick={()=>{
								if (onClose) {
									onClose();
								}
								dispatch(coachingModalSlice.actions.hide());
							}}
							className="btn btn-icon"
							data-bs-dismiss="modal"
						>
							<CloseButtonIcon />
						</button>
					</div>

					<div className="modal-body mx-0 p-0 pt-3">
						<div className="d-flex flex-wrap flex-grow-1">
							<div className="row border-bottom border-bottom-1">
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Date</span>
									<span className="d-block">{coaching?.date}</span>
								</div>
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Category</span>
									<span className="d-block">{coaching?.category?.name}</span>
								</div>
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Coached By</span>
									<span className="d-block">{coaching?.coacher?.firstName} {coaching?.coacher?.lastName}</span>
								</div>
							</div>

							<div className="row mx-2 pt-3">
								<span className="fs-2 fw-bolder d-block">Comments</span>
								<p
									className={clsx({ 'fst-italic': !coaching?.comment })}>
									{coaching?.comment || 'No details provided'}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
