import clsx from 'clsx';
import moment from 'moment';
import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_library/helpers';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import {
    Recognition,
    RecognitionsGetAllApiRequestModel,
} from '../../../../features/recognition/interfaces/recognition.model';
import { recognitionSlice } from '../../../../features/recognition/recognition.api';
import { recognitionModalSlice } from '../../../../features/recognition/recognition-modal.slice';
import { DropdownUserActions } from '../../../components/dropdown/DropdownUserActions';
import { FilterButton } from '../../../components/FilterButton';
import { Pagination } from '../../../components/Pagination';
import { ConnectionSortModel } from '../../../models/ConnectionSortModel';
import { SHORT_DATE } from '../../../modules/date/DateFormat.const';
import { ConnectionListFilterModal } from '../ConnectionListFilterModal';

interface Props {
    className?: string;
}

export function RecognitionsList({ className }: Props) {
    const [sort, setSort] = useState<ConnectionSortModel>({ field: 'date', order: 'DESC' })
    const dispatch = useDispatch();
    const [connectionsFilter, setConnectionsFilter] = useState<RecognitionsGetAllApiRequestModel>({
        fromDate: moment().subtract(1, 'years').format(SHORT_DATE),
        toDate: moment().add(1, 'days').format(SHORT_DATE),
        limit: 24,
        page: 1,
        sort: `${sort.order === 'DESC' ? '-' : ''}${sort.field}`,
    });
    const { data: recognitionResponse
    } = recognitionSlice.useGetRecognitionsQuery(connectionsFilter, {refetchOnMountOrArgChange: true});

    const applyFilterView = (e: MouseEvent, start_date: string, end_date: string, teamId: number, supervisorId: number) => {
        let newFilter: RecognitionsGetAllApiRequestModel = {
        }
        if (teamId !== 0) {
            newFilter.teamId = teamId;
        }
        if (supervisorId !== 0) {
            newFilter.creatorId = supervisorId;
        }
        setConnectionsFilter(prev => ({
            ...prev,
            fromDate: start_date,
            toDate: end_date,
            ...newFilter
        }));
    }

    useEffect(() => {
        setConnectionsFilter(prev => ({
            ...prev,
            sort: `${sort.order === 'DESC' ? '-' : ''}${sort.field}`,
        }))
    }, [sort]);

    const handlePageChange = (newPage: number) => {
        if (recognitionResponse?.meta && newPage > 0 && recognitionResponse.meta.totalPages >= newPage) {
            setConnectionsFilter(prev => ({
                ...prev,
                page: newPage,
            }))
        }
    }

    const handlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setConnectionsFilter(prev => ({
            ...prev,
            limit: +e.target.value,
        }));
    }

    return (
        <>
            <div className={clsx('card', className)}>
                <div className='card-header border-0 pt-5'>
                    <div className='pt-7 d-flex w-200px align-items-center' id='kt_chat_contacts_header'>
                        <select
                            className="form-select py-2 w-80px text-end"
                            data-control="select2"
                            data-placeholder="Select an option"
                            value={connectionsFilter.limit}
                            onChange={handlePageLimitChange}
                        >
                            <option value="5">5</option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="100">100</option>
                        </select>
                        <span className='ms-2'>rows per page</span>
                    </div>


                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to filter results'
                    >
                        <div className='d-flex align-items-center py-1 me-4'>
                            <div className='me-4'>
                              <FilterButton
                                toggle='modal'
                                target='#recognition_filter_modal' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-150px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'firstName',
                                                    order: (sort.field !== 'firstName' || sort.order === 'DESC') ? 'ASC' : 'DESC',
                                                })
                                            }}
                                        >
                                            Member / Supervisor
                                            {sort.field === 'firstName' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-140px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'date',
                                                    order: (sort.field !== 'date' || sort.order === 'ASC') ? 'DESC' : 'ASC',
                                                })
                                            }}
                                        >
                                            Date
                                            {sort.field === 'date' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-120px'>
                                        Department
                                    </th>
                                    <th className='min-w-120px'>
                                        Type
                                    </th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!recognitionResponse?.items?.length ?
                                    (recognitionResponse.items as Recognition[]).map((recognition, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='symbol symbol-45px me-5 symbol-circle'>
                                                            <img src={(recognition.employee?.avatarUrl == null || recognition.employee.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : recognition.employee.avatarUrl} alt='avatar' />
                                                        </div>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <Link
                                                                to={`/team-member/${recognition?.employee?.teams?.[0]?.id}/show/${recognition.employee?.id}`}
                                                                className='text-dark fw-bolder text-hover-primary fs-6'
                                                            >
                                                                {recognition.employee?.firstName} {recognition.employee?.lastName}
                                                            </Link>
                                                            <span className='text-muted fw-bold fs-7'>{recognition.creator?.firstName} {recognition.creator?.lastName}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='text-muted fw-bold d-block fs-7'>
                                                        {recognition.date}
                                                    </span>
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{recognition.team?.department}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{recognition.category.name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end'>
                                                    <DropdownUserActions
                                                        handleViewRecord={() => {
                                                            dispatch(recognitionModalSlice.actions.show({
                                                                mode: ModalDisplayMode.Read,
                                                                record: recognition,
                                                            }))

                                                        }}
                                                        handleEditRecord={() => {
                                                            dispatch(recognitionModalSlice.actions.show({
                                                                mode: ModalDisplayMode.Update,
                                                                record: recognition,
                                                            }))

                                                        }}
                                                        removeSelectedConnection={() => {
                                                            dispatch(recognitionModalSlice.actions.show({
                                                                mode: ModalDisplayMode.Delete,
                                                                recordId: recognition.id,
                                                            }));

                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr><td colSpan={7} className='text-center'>No Data</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className='d-flex flex-stack flex-wrap pb-5'>
                            <div className='fs-6 fw-bold text-gray-700'></div>

                           <Pagination
                                data={recognitionResponse}
                                handlePrevious={(newPage) => handlePageChange(newPage)}
                                handleNext={(newPage) => handlePageChange(newPage)}
                                handlePageIndex={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ConnectionListFilterModal
              applyFilter={applyFilterView}
              modalId='recognition_filter_modal'/>
        </>
    )
}
