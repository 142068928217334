import axios, { AxiosRequestConfig } from 'axios';
import { defer, from, map, Observable } from 'rxjs';
import { axiosTransformErrorResponseHandler } from '../../../../common/api/common.api';
import { DisciplineGetParamsModel } from '../../../models';
import { ApiResponse } from '../../shared/models/ApiResponse.model';
import { Discipline, DisciplineCreate } from '../models/Discipline.models';

const API_URL = process.env.REACT_APP_API_URL;
export const DISCIPLINES_URL = `${API_URL}/disciplines`;

export function getEmployeeDisciplines(employeeId: number,
																			 token: string,
																			 opts?: DisciplineGetParamsModel): Observable<ApiResponse<Discipline>> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/employees/${employeeId}/disciplines`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	return from(defer(() => axios(config)))
		.pipe(
			// catchError(err => {
			// 	// TODO: trigger toast
			// 	console.error(`Error caught getting employee disciplines`, err);
			// 	return of(err);
			// }),
			// TODO: see about converting infractions, textFields to object
			map(res => res.data),
		);
}

export function getDisciplines(token: string,
															 opts?: DisciplineGetParamsModel): Observable<ApiResponse<Discipline>> {
	const config: AxiosRequestConfig = {
		url: DISCIPLINES_URL,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	return from(defer(() => axios(config)))
		.pipe(
			// catchError(err => {
			// 	// TODO: trigger toast
			// 	console.error(`Error caught getting employee disciplines`, err);
			// 	return of(err);
			// }),
			// TODO: see about converting infractions, textFields to object
			map(res => res.data),
			map(res => {
				return res.map((item: Discipline) => {
					return {
						...item,
						// @ts-ignore: need better typing for incoming/outgoing disciplines - stored as JSON in DB
						infractions: (item.infractions) ? JSON.parse(item.infractions) : [],
						textFields: (item.textFields) ? JSON.parse(res.textFields) : {},
					};
				});
			}),
		);
}

export function getOneDiscipline(id: number, token: string,
																 opts?: DisciplineGetParamsModel): Observable<Discipline> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/disciplines/${id}`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	return from(defer(() => axios(config)))
		.pipe(
			// catchError(err => {
			// 	// TODO: trigger toast
			// 	console.error(`Error caught getting employee disciplines`, err);
			// 	return of(err);
			// }),
			// TODO: see about converting infractions, textFields to object
			map(res => res.data),
			map(res => {
				res.infractions = res.infractions ? JSON.parse(res.infractions) : [];
				res.textFields = res.textFields ? JSON.parse(res.textFields) : {};
				return res;
			}),
		);
}

export async function createDiscipline(discipline: Partial<DisciplineCreate>, token: string): Promise<Discipline> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/disciplines`,
		method: 'post',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: discipline,
	};
		return axios.post(`${API_URL}/disciplines`, discipline, config)
								.then(res => res.data)
								.catch(err => axiosTransformErrorResponseHandler(err, `Error creating Corrective Action`));

}

export async function updateDiscipline(id: number, discipline: any, token: string): Promise<Discipline> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/disciplines/${id}`,
		method: 'patch',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: discipline,
	};
	return axios(config)
		.then(res => res.data)
		.catch(err => axiosTransformErrorResponseHandler(err, `Error saving Corrective Action`));
}

export function deleteDiscipline(id: number, token:string): Observable<Discipline> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/disciplines/${id}`,
		method: 'delete',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return from(defer(() => axios(config)))
	.pipe(
		map(res => res.data)
	);
}
