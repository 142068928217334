import { useState } from 'react'
import { recognitionCategoriesSlice } from '../../../../../features/recognition/recognition-categories.api'
import './Recognition.scss'
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'
import { SkeDialogTextPrompt } from '../../../../../common/components/dialog-text-prompt'
import { toast } from 'react-toastify'


export function Recognition() {
    const { data: recognitionCategories = [], refetch } = recognitionCategoriesSlice.useGetRecognitionCategoriesQuery({});
    const [deleteRecognitionCategory] = recognitionCategoriesSlice.useDeleteRecognitionCategoryMutation();
    const [updateRecognitionCategory] = recognitionCategoriesSlice.useUpdateRecognitionCategoryMutation();
    const [createRecognitionCategory] = recognitionCategoriesSlice.useCreateRecognitionCategoryMutation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showTextPrompt, setShowTextPrompt] = useState(false);
    const [textPromptConfig, setTextPromptConfig] = useState({ title: '', inputValue: '', onConfirm: (value: string) => {} });
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);


    const handleAddCategory = () => {
        setTextPromptConfig({
            title: 'Recognition Category Name',
            inputValue: '',
            onConfirm: async (value) => {
                if (value !== '') {
                    createRecognitionCategory({ name: value })
                    .then(() => {
                        setShowTextPrompt(false);
                        toast.success(`Category "${value}" created`);
                    });
                }
            }
        });
        setShowTextPrompt(true);
    };

    const handleEditCategory = (categoryId: number, oldName: string) => {
        setTextPromptConfig({
            title: 'Edit Recognition Category Name',
            inputValue: oldName,
            onConfirm: async (value) => {
                if (value !== '') {
                    updateRecognitionCategory({ id: categoryId, name: value })
                    .then(() => {
                        setShowTextPrompt(false);
                        toast.success(`Category "${oldName}" changed to "${value}"`);
                    });
                }
            }
        });
        setShowTextPrompt(true);
    };


    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedCategoryId !== null) {
            deleteRecognitionCategory({ id: selectedCategoryId })
                .then(() => {
                    successToast();
                    refetch();
                })
            .catch(() => {
                setShowDeleteDialog(false);
                errorToast();
            })
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleRemoveCategory = (id: number) => {
        setSelectedCategoryId(id);
        setShowDeleteDialog(true);
    };


    return (
        <>
        <div className='card coaching_settings_container'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Recognition Categories</h3>
                </div>
            </div>
            <div className="card-body border-top">
                <div className="text-start">
                    <button type='button' className='link-primary btn btn_add mt-2 px-0' onClick={handleAddCategory}>+ Add New</button>
                </div>
                <div className="mb-0 check_wrapper">
                    {recognitionCategories.length > 0 && <div className='table-responsive'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className='text-muted fw-bold min-w-300px'>Name</th>
                                    <th className='text-muted fw-bold w-200px'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {recognitionCategories.map((category, index) => {
                                    return <tr className='action_line' key={index}>
                                        <td className='action_name'>{category.name}</td>
                                        <td className="action_buttons">
                                            <span className="btn_remove"
                                                onClick={() => handleEditCategory(category.id, category.name)}
                                            >
                                                <i className="fas fa-edit text-primary fs-4"></i>
                                            </span>
                                            <span className="btn_remove ms-3"
                                                onClick={() => handleRemoveCategory(category.id)}
                                            >
                                                <i className="fas fa-trash text-danger fs-4"></i>
                                            </span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
                </div>

            </div>
        </div>
        {showTextPrompt && (
            <SkeDialogTextPrompt
                title={textPromptConfig.title}
                initialValue={textPromptConfig.inputValue}
                onConfirm={(value) => {
                    textPromptConfig.onConfirm(value);
                    setShowTextPrompt(false);
                }}
                onCancel={() => {
                    setShowTextPrompt(false);
                }}
            />
        )}
        {showDeleteDialog && (
                <SkeDialogDelete
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    successMessage='Category deleted'
                    message='Are you sure you want to delete this recognition category?'
                />
            )}
        </>
    )
}
