import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../../setup'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css';
import { sendSupervisorInvite, sendProvisionerInvite } from '../../../../CRUD/SettingsCRUD'
import { UserModel } from '../../../../models';
import { toast } from 'react-toastify';

import './Invite.scss'

export function Invite() {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [supervisorEmails, setSupervisorEmails] = React.useState<string[]>([])
    const [provisionerEmails, setProvisionerEmails] = React.useState<string[]>([])
    const [supervisorInviting, setSupervisorInviting] = React.useState(false)
    const [provisionerInviting, setProvisionerInviting] = React.useState(false)



    const emailsLimit = 25

    const handleSupervisorInvite = () => {
        setSupervisorInviting(true)
        setTimeout(async () => {
            try {
                for (let i = 0; i < supervisorEmails.length; i++) {
                    await sendSupervisorInvite(supervisorEmails[i], token)
                }
                toast.success(`${supervisorEmails.length} Supervisor(s) successfully invited`, {
                    autoClose: 1500,
                });
            } catch (error: any) {
                const message = error?.response?.data?.message || 'Failed to invite Supervisor(s)';
                toast.error(message);
            }
            setSupervisorInviting(false)
        }, 1000)
    }

    const handleProvisionerInvite = () => {
        setProvisionerInviting(true);
        setTimeout(async () => {
            try {
                for (let i = 0; i < provisionerEmails.length; i++) {
                    await sendProvisionerInvite(provisionerEmails[i], token);
                }
                toast.success(`${provisionerEmails.length} Supervisor(s) successfully invited`, {
                    autoClose: 1500,
                });
            } catch (error: any) {
                const message = error?.response?.data?.message || 'Failed to invite Provisioner(s)';
                toast.error(message);
            }
            setProvisionerInviting(false);
        }, 1000);
    };


    return (
        <>
        <div className='card invite_settings_container'>
            <div className="card-body">
                <div className="invite_title fs-6 fw-bolder">Invite Supervisor</div>
                <ReactMultiEmail
                    placeholder="Type email"
                    emails={supervisorEmails}
                    onChange={(items) => {
                        if (items.length < emailsLimit + 1) {
                            setSupervisorEmails(items)
                        }
                    }}
                    validateEmail={email => {
                        return isEmail(email) && (document.querySelectorAll('.react-multi-email > .email_item').length < emailsLimit); // return boolean
                    }}
                    getLabel={(
                        email,
                        index,
                        removeEmail,
                    ) => {
                        return (
                            <div data-tag key={index} className="email_item">
                                <span>{email}</span>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                <div className="invite_footer mt-5">
                    <span>Invite <b>{supervisorEmails.length} Supervisor(s)</b> to secchi</span>
                    <button disabled={!supervisorEmails.length} className="btn btn-primary" type="button" onClick={handleSupervisorInvite}>
                        {!supervisorInviting && <span>Invite Supervisors</span>}
                        {supervisorInviting && (
                            <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>

                {!!user.roles?.includes('Provisioner') && <>
                    <div className="invite_title mt-10 fs-6 fw-bolder">Invite Provisioner</div>
                    <ReactMultiEmail
                        placeholder="Type email"
                        emails={provisionerEmails}
                        onChange={(items) => {
                            if (items.length < emailsLimit + 1) {
                                setProvisionerEmails(items)
                            }
                        }}
                        validateEmail={email => {
                            return isEmail(email) && (document.querySelectorAll('.react-multi-email > .email_item').length < emailsLimit); // return boolean
                        }}
                        getLabel={(
                            email,
                            index,
                            removeEmail,
                        ) => {
                            return (
                                <div data-tag key={index} className="email_item">
                                    <span>{email}</span>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                    />
                    <div className="invite_footer mt-5">
                        <span>Invite <b>{provisionerEmails.length} Provisioner(s)</b> to secchi</span>
                        <button disabled={!provisionerEmails.length} className="btn btn-primary" type="button" onClick={handleProvisionerInvite}>
                            {!provisionerInviting && <span>Invite Provisioners</span>}
                            {provisionerInviting && (
                                <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </>}
            </div>
        </div>
        </>
    )
}
