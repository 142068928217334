import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FallbackView } from '../../_library/partials'
import { RootState } from '../../setup';
import { UserModel } from '../models';
import { AuthPage } from '../modules/auth'
import { EulaPage } from "../pages/EulaPage"
import { TermsPage } from "../pages/TermsPage"
import { PolicyPage } from "../pages/PolicyPage"

export function PublicRoutes() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) && !user.roles?.includes('SecchiAdmin')

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        {!isAuthorized && <Route path='*' element={<Navigate to='/auth/login' />} />}
        <Route path='/auth/*' element={<AuthPage />} />
        <Route path='/eula' element={<EulaPage />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/policy' element={<PolicyPage />} />
        <Route path='*' element={<Navigate to='/auth' />} />
      </Routes>
    </Suspense>
  )
}
