import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { coachingModalSlice } from '../../../../features/coaching/coaching-modal.slice';
import { DatePicker } from '../../../components/DatePicker';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { coachingCategorySlice } from '../../../../features/coaching/coaching-categories.api';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { updateCoaching } from '../../../CRUD/CoachingCRUD';
import { RootState } from '../../../../setup';
import { CoachingType } from '../../../types/CoachingType';
import 'react-datepicker/dist/react-datepicker.css';
import './CoachingEditModal.scss';
import { toast } from 'react-toastify';
import { SkeTextbox } from '../../../components/Textbox';

interface CoachingDetails {
    category: string
    comments: string
}

const coachingSchema = Yup.object().shape({
})

interface Props {
    editCoaching: CoachingType;
    refresh?: (updated: CoachingType) => void;
    onClose?: () => void;
    modalId?: string;
}

export function CoachingEditModal ({
                                       editCoaching,
                                       refresh,
                                       onClose,
                                       modalId = 'coaching_edit_modal',
}: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [coachingDate, setCoachingDate] = useState<Date | null>(!!editCoaching.date ? new Date(editCoaching.date + "T00:00:00") : new Date());
    const showActionModalRef = useRef<HTMLButtonElement>(null)
    const cancelButtonRef = useRef<HTMLButtonElement>(null)
    const [initValues, setInitValues] = useState<CoachingDetails>({
        category: editCoaching.category?.id + '',
        comments: editCoaching.comment
    });
    const dispatch = useDispatch();
    const { data: categories } = coachingCategorySlice.useGetCoachingCategoriesQuery({});

    const formik = useFormik<CoachingDetails>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: coachingSchema,
        onSubmit: (values) => {
            updateCoaching(editCoaching.id, {
                coachingCategoryId: parseInt(values.category),
                date: dateConverter(coachingDate!),
                employeeId: editCoaching.employee?.id,
                teamId: editCoaching.team?.id,
                comment: values.comments,
            }, token)
              .then((res) => {
                  cancelButtonRef.current?.click();
                  if (refresh) {
                      refresh(res.data);
                  }
              })
              .catch(err => console.log(err));
        },
    });

    const dateConverter = (date: Date) => {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    };

    const handleCoachingDate = (date: Date | null) => {
        if (!!date) {
            if (date < new Date()) {
                setCoachingDate(date)
            } else {
                toast.error("Please select a correct date",{
                    position: 'top-right',
                    theme: 'colored',
                    autoClose: 3500,
                });
            }
        }
    }

    useEffect(() => {
        let abortController = new AbortController();

        setInitValues({
            category: editCoaching.category?.id + '',
            comments: editCoaching.comment
        })

        if (editCoaching.date) {
            setCoachingDate(new Date(editCoaching.date + "T00:00:00"))
        }
        return () => {
            abortController.abort();
        }
    }, [editCoaching, token])

    return (

      <div
        className="modal fade coaching-modal"
        id={modalId}
        data-bs-backdrop="static"
        aria-hidden="true">
          <form
            onSubmit={formik.handleSubmit}
            noValidate>
              <div className="modal-dialog modal-fullscreen-sm-down">
                  <div className="modal-content">
                      <div className="modal-header d-flex align-items-center justify-content-between">
                          <div>
                              <h1>Coaching</h1>
                              <div className="text-muted fs-5">
                                  {editCoaching.employee?.firstName} {editCoaching.employee?.lastName} | {editCoaching.employee?.employeeId}
                              </div>
                          </div>
                          <button
                            type="button"
                            className="btn close_button"
                            onClick={() => {
                                formik.resetForm()
                                cancelButtonRef.current?.click()
                            }}
                          >
                              <CloseButtonIcon />
                          </button>
                      </div>

                      <div className="modal-body">
                          <div className="card-body p-0">
                              <div className="d-flex">
                                  <div className="flex-wrap col-12">
                                      <div className="col-12 col-xl-6 pb-2">
                                          <div
                                            className="d-flex flex-row-reverse justify-content-end align-items-center">
                                              <DatePicker
                                                label="Event Date"
                                                selectedDate={coachingDate}
                                                onChange={(d) => handleCoachingDate(d)}
                                                dateFormat="MMMM d, yyyy"
                                              />
                                          </div>
                                      </div>

                                      <div className="col-12 col-xl-6 mt-6">
                                          <div
                                            className="'d-flex flex-row-reverse align-items-center justify-content-end ">
                                              <label className="col-form-label fs-4 fw-light form-label">Category</label>
                                              <select
                                                className="form-select form-select-solid form-select-lg form-control"
                                                {...formik.getFieldProps('category')}
                                              >
                                                  <option value=""></option>
                                                  {categories?.length &&
                                                    categories.map((category, i) =>
                                                      <option key={category.id}
                                                              value={category.id}>{category.name}</option>,
                                                    )
                                                  }
                                              </select>
                                              {formik.touched.category && formik.errors.category && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.category}</div>
                                                </div>
                                              )}
                                          </div>
                                      </div>

                                      <div className="col-12 col-xl-6 mt-6">
                                          <div
                                            className="'d-flex flex-row-reverse align-items-center justify-content-end ">
                                              <SkeTextbox
                                                inputId="coaching-comments"
                                                label="Comments"
                                                value={formik.values.comments}
                                                onChange={e => formik.setFieldValue("comments", e)}
                                                name="comment"></SkeTextbox>
                                              {formik.touched.comments && formik.errors.comments && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.comments}</div>
                                                </div>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="modal-footer d-flex justify-content-between">
                          <button
                            type='button'
                            className='btn btn-outline-danger px-6'
                            data-bs-dismiss="modal"
                            onClick={() => {
                                if (onClose) {
                                    onClose();
                                }
                                dispatch(coachingModalSlice.actions.hide());
                            }}
                            ref={cancelButtonRef}
                          >
                              Cancel
                          </button>
                          <button
                            type='button'
                            ref={showActionModalRef}
                            className='d-none'
                          >Show Modal
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary d-flex align-items-center"
                            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                          >
                              Update
                              {formik.isSubmitting && (
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              )}
                          </button>
                      </div>
                  </div>
              </div>
          </form>
      </div>
    )
}
