import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import './ColorPickerComponent.scss';


export interface Props {
	onChange: (color: any) => void;
	initialColor?: string;
}

export function ColorPickerComponent({onChange, initialColor}: Props)  {
		const [defaultSettings, setDefaultSettings] = useState<any>({
			displayClrPkr: false,
			color: initialColor
			// color: {
			// 	r: '215',
			// 	g: '159',
			// 	b: '55',
			// 	a: '1',
			// },
		})


		const stateClose = () => {
			setDefaultSettings({
				...defaultSettings,
				displayClrPkr: false,
			});
		};

		const handleColorChange = (color: any) => {
			setDefaultSettings({
				...defaultSettings,
				color: color.rgb,
			})
			onChange(color)
		};

		const handleSwatchClick = () => {
			setDefaultSettings({
				...defaultSettings,
				displayClrPkr: !defaultSettings.displayClrPkr,
			});
		}

		return (
			<div>
				<div className="swatch"
				style={{ backgroundColor: initialColor }}
				onClick={ handleSwatchClick }>
					<div className="color" />
				</div>
				{ !!defaultSettings.displayClrPkr &&
					<div className="popover">
						<div className="cover" onClick={ stateClose }/>
						<SketchPicker
							color={ defaultSettings.color }
							onChange={e => handleColorChange(e) }

						/>
					</div>
				}

			</div>
		)
}
