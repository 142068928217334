export enum SkeDomain {
	Connection = 'CONNECTION',
	Attendance = 'ATTENDANCE',
	Coaching = 'COACHING',
	Discipline = 'DISCIPLINE',
	Recognition = 'RECOGNITION',
}

export enum LogType {
	Normal = 'NORMAL',
	Warn = 'WARN',
	Error = 'ERROR',
}
