import { FC } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom';
import { Avatar } from '../../../../app/components/Avatar';
import { Icon } from '../../../../app/components/icons/Icon';
import { Building } from '../../../../app/components/icons/IconList';
import { UserModel } from '../../../../app/models'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import * as search from '../../../../app/pages/advanced_search/redux/SearchRedux'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(search.searchActions.clearSearch())
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5 symbol-circle'>
            <Avatar
              url={user.avatarUrl}
              alt="Current user's avatar" />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.firstName} {user.lastName}
            </div>
            <span className="fw-bold text-muted fs-7">
              {user.email}
            </span>
            <div className="fw-light d-flex align-items-center fs-5">
              <Icon
                type="svg"
                size="sm"
                classes="text-muted"
                icon={Building}
                use="decorative"
              />
              {user?.company?.name}
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className='menu-item px-5 my-1'>
        {!!user.roles?.includes('SuperAdmin') && <Link to='/superadmin-account/overview' className='menu-link px-5'>
          Account Settings
        </Link>}
        {!!user.roles?.includes('Supervisor') && <Link to='/supervisor-account/overview' className='menu-link px-5'>
          Account Settings
        </Link>}
      </div>

      <div className='menu-item px-5'>
        <NavLink to='/auth/logout' className='menu-link px-5'>
          Sign Out
        </NavLink>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
