export enum SupportedLanguageEnum {
	English = 'en',
	Spanish = 'es',
}

export enum SupportedLanguageReadableEnum {
	en = 'English',
	es = 'Spanish',
}

export type SupportedLanguageType = SupportedLanguageEnum.English | SupportedLanguageEnum.Spanish;

export interface LanguageModel {
	name: string;
	abbr: SupportedLanguageType;
}
