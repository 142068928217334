import { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { coachingCategorySlice } from '../../../../features/coaching/coaching-categories.api';
import { DatePicker } from '../../../components/DatePicker';

import { shallowEqual, useSelector } from 'react-redux'
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { createCoaching } from '../../../CRUD/CoachingCRUD'
import { EmployeeType } from '../../../types/EmployeeType'
import { TeamType } from '../../../types/EmployeeType'
import { RootState } from '../../../../setup'
import infoIcon from '../../../../images/icons/info-circle.svg';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import './AddCoachingModal.scss'
import submitIcon from '../../../../images/icons/white_floppy.svg'
import { toast } from 'react-toastify';


interface CoachingDetails {
  category: string
  date: string
  comments: string
}

const initialValues: CoachingDetails = {
  category: '0',
  date: '',
  comments: ''
}

const connectionSchema = Yup.object().shape({
  // reason: Yup.string().required('Excuse is required'),
  // superadmin: Yup.string().required('Superadmin is required'),
  // timeMissed: Yup.string().required('Time Missed is required'),
  // comments: Yup.string().required('Comments is required'),
})

interface Props {
  employee: EmployeeType;
  teamInfo: TeamType;
  refresh?: () => void;
}

export function AddCoachingModal ({ employee, teamInfo, refresh }: Props) {
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const [coachingDate, setCoachingDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false)
  const showActionModalRef = useRef<HTMLButtonElement>(null)
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const [showProTips, setShowProTips] = useState<boolean>(false)
  const { data: categories } = coachingCategorySlice.useGetCoachingCategoriesQuery({});


  const formik = useFormik<CoachingDetails>({
    initialValues,
    validationSchema: connectionSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        cancelButtonRef.current?.click()
        createCoaching({
          coachingCategoryId: parseInt(values.category),
          date: dateConverter(coachingDate!),
          employeeId: employee.id,
          teamId: teamInfo.id,
          comment: values.comments,
        }, token)
          .then(() => {
            if (refresh) {
              refresh()
            }
          })
          .catch(err => console.log(err))
      }, 1000)
    },
  })

  const dateConverter = (date: Date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
  };

  const handleCoachingDate = (date: Date | null) => {
    if (!!date) {
      if (date < new Date()) {
        setCoachingDate(date)
      } else {
        toast.error("Pleaes select a correct date",{
          position: 'top-right',
          theme: 'colored',
          autoClose: 3500,
      });
      }
    }
  }

  return (
    <div className='modal fade' id='add_coaching_modal' data-bs-backdrop="static" aria-hidden='true'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-body mx-0'>
            <div className="connection_modal_right">
              <form onSubmit={formik.handleSubmit} noValidate className='connection_form'>
                <div className='card-body p-0'>
                  <div className="connection_modal_header d-flex align-items-center justify-content-between">
                    <div className="header_left">
                      <div className="connection_title">New Coaching Connection</div>
                      <div className="text-muted fs-5">
                        {employee.firstName} {employee.lastName} | {employee.employeeId}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn close_button"
                      onClick={() => {
                        formik.resetForm()
                        cancelButtonRef.current?.click()
                      }}
                    >
                      <CloseButtonIcon />
                    </button>
                  </div>
                  <div className='row d-flex'>
                    <div className='col-sm-12 col-md-6'>
                        <label className='col-form-label form_label'>Event Date</label>
                        <div className='col-lg-12 fv-row'>
                          <DatePicker

                            label="Date"
                            labelClasses="col-form-label fw-light fs-4"
                            id="recognition-add-date"
                            selectedDate={coachingDate}
                            onChange={(d) => handleCoachingDate(d)}
                            dateFormat="MMMM d, yyyy"

                          />
                        </div>
                      <div>
                        <label className='col-form-label form_label'>Category</label>
                        <select
                          className='form-select form-select-solid form-select-lg form_control'
                          {...formik.getFieldProps('category')}
                        >
                          <option value=''>Select one</option>
                          {categories?.length &&
                            categories.map((category, i) =>
                              <option key={category.id} value={category.id}>{category.name}</option>
                            )
                          }
                        </select>
                        {formik.touched.category && formik.errors.category && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.category}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 pt-3'>
                      <div>
                        <button
                          className="btn btn-link"
                          data-bs-toggle="collapse"
                          type="button"
                          onClick={() => setShowProTips(!showProTips)}
                          data-bs-target="#recognition-tips">
                            <span className="fs-4 text-muted d-flex align-items-center">
                              <img
                                className="me-3"
                                src={infoIcon}
                                alt="Icon" />
                              Pro Tips
                            </span>
                        </button>
                      </div>
                      <div
                        id="recognition-tips"
                        className="">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item text-muted">
                            Trust: Ask questions! Inquisitive state of mind to understand their actions. This is an opportunity to build trust and your working relationship
                          </li>
                          <li className="list-group-item text-muted">
                            Focus the conversation on the topic the individual or you would like to see improved
                          </li>
                          <li className="list-group-item text-muted">
                            Action: What is the plan for action, when will you follow up, and what have they committed to do?
                          </li>
                          <li className="list-group-item text-muted">
                            Follow up: When and how will you follow up? What does the future look and feel like when this goes well
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className='row form_row mb-0'>
                    <div className='col-12'>
                      <label className='col-form-label form_label'>Comments</label>
                      <textarea
                        rows={4}
                        className='form_control form-control-lg form-control-solid'
                        {...formik.getFieldProps('comments')}
                      ></textarea>
                      {formik.touched.comments && formik.errors.comments && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.comments}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end border-0 p-0'>
                  <button
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6 d-none'
                    data-bs-dismiss="modal"
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    ref={showActionModalRef}
                    className='d-none'
                  >Show Modal
                  </button>
                  <button type='submit'
                    className='btn btn_submit d-flex align-items-center'
                    disabled={loading}
                  >
                    <img src={submitIcon} width="24" alt="submit" />
                    {!loading && 'SAVE'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
