import { isUndefined, size } from 'lodash';
import { Employee } from '../../../app/modules/employee/models/Employee.model';
import { EmployeeType } from '../../../app/types/EmployeeType';

type EmployeeNav = Pick<(Employee | EmployeeType), 'id' | 'teams'>;

export const getEmployeeProfileRoute = (employee: EmployeeNav): string => {
	if (!employee.id) {
		console.error(`Unable to generate employee URL due to missing employee ID (received: ${employee.id}`);
		return '';
	}
	if (isUndefined(employee.teams)) {
		console.error(`Unable to generate employee URL due to invalid team info, received:`, employee.teams);
		return '';
	}
	if (size(employee.teams) === 0) {
		console.error(`Unable to generate employee URL due to them being unassigned`);
		return '';
	}

	return `/team-member/${employee.teams[0].id}/show/${employee.id}`;
}
