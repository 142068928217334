import { StringKeyOf } from 'type-fest'

export enum QueryOperators {
	Contains = 'IN',
	Like = 'LIKE',
	Equals = 'EQUALS',
	GreaterThan = 'GREATER_THAN',
	LessThan = 'LESS_THAN',
	Between = 'BETWEEN',
}

export interface GridFilterItem<T> {
	operator: QueryOperators;
	value: any;
	field: T;
}

export interface GridSortItem<T> {
	dir: 'asc' | 'desc';
	field: T;
	position?: number;
}

export interface ResponseWithManyNotPaginated<T> {
	items: T[];
}

export interface ResponseWithSingle<T> {
	item: T;
}

export interface ResponseWithManyPaginated<T> {
	items: T[];
	meta: ResponseWithManyMeta<T>;
	// legacy
	// links: PaginateLinks;
}

export interface ResponseWithManyMeta<T> {
	pagination: {
		currentPage: number;
		totalPages: number;
		pageSize: number;
		currentPageItems: number;
		totalItems: number;
	};
	sorting?: GridSortItem<T>;
	filtering?: GridFilterItem<T>;
}

// legacy
export interface PaginateLinks {
	first: string;
	previous: string;
	next: string;
	last: string;
}

export interface SkeBaseResponse {
	success: boolean;
	error: boolean;
	msg: string[];
}

export interface SkeResponseWithManyPaginatedResults<T> extends SkeBaseResponse {
	results: ResponseWithManyPaginated<T>
}

export interface SkeResponseWithManyNotPaginatedResults<T> extends SkeBaseResponse {
	results: ResponseWithManyNotPaginated<T>
}

export interface SkeResponseWithSingleResult<T> extends SkeBaseResponse {
	results: ResponseWithSingle<T>
}

export interface SkeErrorResponse<T> extends SkeBaseResponse {
	detailed?: string[];
	code?: string[];
}
