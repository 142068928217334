import clsx from 'clsx';
import { concat, get } from 'lodash';
import {ChangeEvent, useMemo, useRef, useState} from 'react';
import { Accordion, Form, Spinner } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import { FileDropzone } from '../../../../../../common/components/file-dropzone';
import { companySlice } from '../../../../../../features/company/company.api';
import {
	BatchProcessIssue,
	EmployeeUploadApiResponse,
	TeamReconcileIssue,
} from '../../../../../../features/company/interfaces/company.model';
import { teamSlice, TeamTagTypes } from '../../../../../../features/team/team.api';
import { RootState } from '../../../../../../setup';
import { UserModel } from '../../../../../models';
import { SkeTooltip } from '../../../../../modules/shared/components/Tooltip';
import { IMPORT_PROCESSES_DETAILS, ImportProcesses } from '../constants/imports.models';
import {AgGridReact} from 'ag-grid-react';
import {ColDef} from 'ag-grid-community';

interface EmployeeUploadModel {
	firstName: string;
	lastName: string;
	employeeId: string;
	phone: string;
	hireDate: string;
	terminatedDate: string;
	birthDate: string;
	teamName: string;
}

export function EmployeeUpload() {
	const containerStyle = useMemo(() => ({
		width: '100%',
		height: 400,
	}), []);
	const gridStyle = useMemo(() => ({
		height: '100%',
		width: '100%',
	}), []);
	const gridRef = useRef<AgGridReact<any>>(null);
	const [rowData, setRowData] = useState<EmployeeUploadModel[]>( [] );
	const [showGrid, setShowGrid] = useState<boolean>(false);
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
	const [selectedProcess, setSelectedProcess] = useState<ImportProcesses>();
	const [importCsv, uploadState] = companySlice.useRunProcessMutation();
	const [validSheet, setValidSheet] = useState<boolean>(false);
	const [fileToUpload, setFileToUpload] = useState<File[]>([]);
	const [createTeams, setCreateTeams] = useState<boolean>(false);
	const [assignTeams, setAssignTeams] = useState<boolean>(false);
	const [reassignTeams, setReassignTeams] = useState<boolean>(false);
	const [optInNewEmployeesToTexting, setOptInNewEmployeesToTexting] = useState<boolean>(false);
	const [error, setError] = useState<string>();
	const dispatch = useDispatch();
	const [expandedInstructions, setExpandedInstructions] = useState<boolean>(true);
	const [uploadResults, setUploadResults] = useState<EmployeeUploadApiResponse['results']>();
	const [invalidCells, setInvalidCells] = useState<any[]>([]);
	const [missingColumns, setMissingColumns] = useState<any[]>([]);
	const [unknownColumns, setUnknownColumns] = useState<any[]>([]);

	const handleUploadFile = () => {
		if (!user.company?.id) return console.error('No tenant_id found, unable to proceed');
		if (!selectedProcess) return console.error('No process selected, unable to proceed');

		importCsv({
			tenant_id: user.company.id,
			processType: selectedProcess,
			spreadsheetFiles: fileToUpload,
			createTeams,
			assignTeams,
			reassignTeams,
			optInNewEmployeesToTexting,
		}).then(function(res: any) {
			const {
				error,
				results,
				success,
			} = res.data;
			if (error) {
				setError(error);
				return toast.error('Error processing file');
			}
			if (success && !error) {
				setUploadResults(results);
				dispatch(teamSlice.util?.invalidateTags([
					TeamTagTypes.Teams,
					TeamTagTypes.Employees,
				]));
				toast.success(`Import successfully processed!`);
			}
		});
	};

	const handleDownloadTemplate = () => {
		const sheet = XLSX.utils.json_to_sheet([], {
			header: [
				'First Name',
				'Last Name',
				'Employee ID',
				'Email',
				'Phone',
				'Hire Date',
				'Terminated Date',
				'Birth Date',
				'Team Name',
			],
		});
		const newWorkbook = XLSX.utils.book_new(sheet);
		XLSX.writeFileXLSX(newWorkbook, 'Secchi Employee Upload Template.xlsx')
	};

	const handleSetImportRowData = (files: any) => {
		if (!user.company?.id) return console.error('No tenant_id found, unable to proceed');
		if (!selectedProcess) return console.error('No process selected, unable to proceed');
		importCsv({
			tenant_id: user.company.id,
			processType: selectedProcess,
			spreadsheetFiles: files,
			createTeams,
			assignTeams,
			reassignTeams,
			optInNewEmployeesToTexting,
			validateOnly: true
		}).then(function(res: any) {
			const { error, issues, processing } = res.data;
			if (error) {
				if (issues?.errors.msg) {
					toast.error(res.data.issues.errors.msg);
				}
				const missing = get(issues, 'errors.items', []).filter((itm: any) => itm.type === 'MISSING_REQUIRED_FIELD');
				setMissingColumns(missing);
				setShowGrid(missing.length > 0);
			} else {
				setMissingColumns([]);
			}
			setInvalidCells(get(issues, 'errors.items', []).filter((itm: any) => itm.type === 'INVALID_PROPERTY'));
			setUnknownColumns(get(issues, 'warnings.items', []).filter((itm: any) => itm.type === 'UNKNOWN_COLUMN'));
			if (!!processing) {
				setRowData(processing);
				setShowGrid(true);
				const hadErrors = get(issues, 'errors.codes', []).length > 0;
				setValidSheet(!hadErrors);
				if (!hadErrors) {
					toast.success(`Column names are valid. Review data in grid below before proceeding!`);
				}
			}
		});
	}

	// AG GRID
	const defaultColDef = useMemo<ColDef>(() => {
		return {
			editable: false,
			enablePivot: true,
			enableValue: true,
			filter: true,
			floatingFilter: true,
			// flex: 1,
			width: 105,
			minWidth: 100,
			enableCellChangeFlash: true,
		};
	}, []);

	const columnDefs: ColDef[] = [
		{
			field: 'firstName',
			headerName: 'First Name',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'employeeId',
			width: 120,
			headerName: 'Employee ID',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'phone',
			headerName: 'Phone',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'hireDate',
			headerName: 'Hire Date',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'terminatedDate',
			width: 150,
			headerName: 'Terminated Date',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'birthDate',
			headerName: 'Birth Date',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'teamName',
			width: 150,
			headerName: 'Team Name',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
		},
	];

	return (
		<div className="card mb-5 mb-xl-10">
			<div
				className="card-header border-0 cursor-pointer"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target="#kt_account_profile_details"
				aria-expanded="true"
				aria-controls="kt_account_profile_details"
			>
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">Imports</h3>
				</div>
			</div>

			<div
				id="kt_account_profile_details"
				className="collapse show">
				<div className="card-body border-top p-9">
					<div className="row mb-6">
						<label className="col-lg-4 col-form-label fw-bold fs-6">
							<span className="required">Import Type</span>
						</label>
						<div className="col-lg-8 fv-row">
							<select
								className="form-select form-select-solid form-select-lg fw-bold"
								onChange={(e: ChangeEvent<HTMLSelectElement>) => {
									setSelectedProcess((e.target.value as ImportProcesses));
								}}
							>
								<option value="">Select a Process...</option>
								{
									Object.values(ImportProcesses).map((process, i) => {
										return <option
											key={i}
											value={process}>{IMPORT_PROCESSES_DETAILS[process].name}</option>;
									})
								}
							</select>
							{!!selectedProcess && IMPORT_PROCESSES_DETAILS[selectedProcess].description && (
								<div className="py-3">
									<span><span className="fw-bolder">Description: </span>{IMPORT_PROCESSES_DETAILS[selectedProcess].description}</span>
									{ IMPORT_PROCESSES_DETAILS[selectedProcess].type === ImportProcesses.EmployeeUpload  &&
										<>
											<div className="py-2">
												<button
													className="btn btn-primary"
													onClick={handleDownloadTemplate}>Download template
												</button>
											</div>
											<Accordion
												className="mt-3"
												onClick={() => setExpandedInstructions(!expandedInstructions)}
												activeKey={expandedInstructions ? '0' : '1'}>
												<Accordion.Item eventKey="0">
													<Accordion.Header className="border border-1 border-info"><span className="fw-bold">Import Info</span></Accordion.Header>
													<Accordion.Body className="border border-1 border-info border-top-0">
														<ul className="list-group">
															<li className="list-group-item">
																<span className="fw-bolder">First Name:</span> Required
															</li>
															<li className="list-group-item">
																<span className="fw-bolder">Last Name:</span> Required
															</li>
															<li className="list-group-item">
																<span className="fw-bolder">Employee ID:</span> Required
															</li>
															{/*<li className="list-group-item">
													 <span className="fw-bold">Email:</span> Optional
													 </li>*/}
															<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Phone:</span> Optional
														</span>
																<span className="d-block">
															This is only used if an existing employee has already opted in, or for new employees if you select <span
																	className="fw-bold">Sign up new employees for texting</span> below.
														</span>
															</li>
															<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Hire Date</span> Optional (example date January 2nd, 2024)
														</span>
																<span className="d-block">
															Supported formats: 1/2/24, 1/2/2024, 01/02/2024, 2024-01-02
														</span>
															</li>
															<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Birth Date</span> Optional (example date January 2nd, 2000)
														</span>
																<span className="d-block">
															<span className="fst-italic">We don't store the year of birth dates, so you can provide just month and day for privacy</span>
															<span className="d-block">
																Supported formats: 1/2, 01/02, 1/2/00, 1/2/2000, 01/02/2000, 2000-01-02
															</span>
														</span>
															</li>
															<li className="list-group-item">
														<span className="d-block">
															<span className="fw-bolder">Terminated Date</span> Optional (example date January 2nd, 2024)
														</span>
																<span className="d-block fst-italic">Assumes not terminated if no value entered in this field</span>
																<span className="d-block">
															Supported formats: 1/2/24, 1/2/2024, 01/02/2024, 2024-01-02
														</span>
															</li>
															<li className="list-group-item">
																<span className="fw-bolder">Team Name</span> Optional
																<span
																	className="d-block">Can provide one more multiple team names (separated by commas)</span>
															</li>
														</ul>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</>
									}
								</div>
							)}
							{
								selectedProcess === ImportProcesses.EmployeeUpload && (
									<>
										<FileDropzone
											title="Drag and drop employee roster here"
											onFileUploaded={(files) => {
												setFileToUpload(files);
												handleSetImportRowData(files);
											}} />
										{!!fileToUpload.length && (
											<>
												<p className="fw-bold py-2">
													If you notice data in the grid below missing from your spreadsheet that you uploaded,
													like missing hire dates, terminated dates, etc, please make sure that your column names
													match the above import info.
												</p>
												{missingColumns.length > 0 && (
													<>
														<h5 className="text-danger">
															Missing columns
														</h5>
														<ul>
															{missingColumns.map((itm) => {
																return <li>{itm.msg}</li>;
															})}
														</ul>
													</>
												)}
												{!!unknownColumns.length && (
													<>
														<h5 className="text-warning">
															Unknown Columns
														</h5>
														<ul>
															{unknownColumns.map((item: any, idx: number) => {
																return <li key={idx}>{item.msg}</li>;
															})}
														</ul>
													</>
												)}
												{showGrid && (
													<>
														{!!invalidCells.length && (
															<>
																<h5 className="text-danger">
																	Invalid data
																</h5>
																<ul>
																	{invalidCells.map((item: any, idx: number) => {
																		return <li key={idx}>{item.msg}</li>;
																	})}
																</ul>
															</>
														)}
														<div style={containerStyle}>
															<div
																style={gridStyle}
																className={
																	'ag-theme-quartz ag-theme-acmecorp'
																}
															>
																<AgGridReact<EmployeeUploadModel>
																	ref={gridRef}
																	rowData={rowData}
																	columnDefs={columnDefs}
																	defaultColDef={defaultColDef}
																	rowSelection={'single'}
																	pivotPanelShow={'always'}
																/>

															</div>
														</div>
													</>
												)}
												{ validSheet && (
													<>
														<h5>Options</h5>
														<div className="border-top-1 border-bottom-1">
															<Form.Check
																type="switch"
															>
																<Form.Check.Input
																	checked={optInNewEmployeesToTexting}
																	onChange={e => setOptInNewEmployeesToTexting(e.target.checked)}
																	type="checkbox" />
																<Form.Check.Label>Sign up new employees for texting</Form.Check.Label>
																<Form.Text
																	className="d-block"
																	muted>
																	Employees are assumed to not have opted-in to receiving texts. Toggle this so when you
																	create new employees, they all get their phone entered and are set up to receive text
																	messages. Without this checked, a user would need to manually enter their phone on the
																	employee's profile.
																</Form.Text>
															</Form.Check>
														</div>
														<h5>Advanced Options</h5>
														<h6>Team Handling</h6>
														<span className="fw-bold py-2">
															These options are best used for onboarding new teams or locations.
														</span>
														<div className="py-2">
															<Form.Check
																type="switch"
															>
																<Form.Check.Input
																	checked={createTeams}
																	onChange={e => setCreateTeams(e.target.checked)}
																	type="checkbox" />
																<Form.Check.Label>Create Teams</Form.Check.Label>
																<Form.Text
																	className="d-block"
																	muted>
																	Add a "Team Name" column to create a team if one with that name doesn't exist. Creates
																	a
																	team for each unique value in this column. Best used with the assign/reassign options
																	below.
																</Form.Text>
															</Form.Check>
															<Form.Check
																type="switch"
															>
																<Form.Check.Input
																	checked={assignTeams}
																	onChange={e => setAssignTeams(e.target.checked)}
																	type="checkbox" />
																<Form.Check.Label>Assign new employees to teams</Form.Check.Label>
																<Form.Text
																	className="d-block"
																	muted>When creating a new employee (uses badge number/employee ID to verify they're
																	not in
																	the system), if you have a "Team Name" value, they will be assigned to that team
																	automatically. This option does not update team assignments of employees already in
																	the system.</Form.Text>
															</Form.Check>
															<Form.Check
																type="switch"
															>
																<Form.Check.Input
																	checked={reassignTeams}
																	onChange={e => setReassignTeams(e.target.checked)}
																	type="checkbox" />
																<Form.Check.Label>Reassign existing employees to teams</Form.Check.Label>
																<Form.Text
																	className="d-block"
																	muted>When updating employees (uses badge number/employee ID to verify they're in the
																	system), if you have a "Team Name" value, they will be assigned to that team
																	automatically. This options does not assign new employees that are not in the system.
																	<span className="text-danger">
																This will replace any existing team assignments, including removing them from all teams if no value is set in this column when this option is selected.
															</span>
																</Form.Text>
															</Form.Check>
														</div>
													</>
												)}
											</>
										)}
									</>
								)
							}
							{!!error && (
								<span className="text-danger">{error}</span>
							)}
							{uploadState.isSuccess && !!uploadResults && (
								<>
									<SkeTooltip
										targetElementId="employee-upload-info"
										message="This column acts as confirmation of how many records were processed without known issues.">
									</SkeTooltip>
									<SkeTooltip
										targetElementId="employee-upload-issues"
										message="This confirmation shows how many issues were detected in processing records e.g. incomplete phone number provided.">
									</SkeTooltip>
									<div className="pt-5">
										<table className="table table-bordered border-1">
											<thead>
											<tr>
												<th
													colSpan={5}
													className="text-center text-uppercase fw-bolder"
												>Results
												</th>
											</tr>
											<tr>
												<th className="text-center fw-bold">Area</th>
												<th className="text-center fw-bold">Affected Records</th>
												<th className="text-center fw-bold">Teams Changed</th>
												<th className="text-center fw-bold">Info</th>
												<th className="text-center fw-bold">Issues</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>New Employees</td>
												<td>{uploadResults.employeesCreated.count} Created</td>
												<td>{uploadResults.employeesAssigned.count} Assigned</td>
												<td>{uploadResults.employeesCreated.info.length}</td>
												<td>{uploadResults.employeesCreated.issues.length}</td>
											</tr>
											<tr>
												<td>Existing Employees</td>
												<td>{uploadResults.employeesUpdated.count} Updated</td>
												<td>{uploadResults.employeesReassigned.count} Reassigned</td>
												<td>{uploadResults.employeesUpdated.info.length}</td>
												<td>{uploadResults.employeesUpdated.issues.length}</td>
											</tr>
											<tr>
												<td>New Teams</td>
												<td>{uploadResults.teamsCreated.count} Created</td>
												<td>N/A</td>
												<td>{uploadResults.teamsCreated.info.length}</td>
												<td>{uploadResults.teamsCreated.issues.length}</td>
											</tr>
											</tbody>
										</table>
									</div>
								</>
							)}
							<button
								type="button"
								className={clsx('btn btn-primary mt-2',
									{
										disabled: !fileToUpload.length || uploadState.isLoading || !validSheet,
									},
								)}
								onClick={() => handleUploadFile()}
							>
								{!uploadState.isLoading && 'Upload'}
								{uploadState.isLoading && (
									<>
										Uploading
										<Spinner
											size="sm"
											className="ms-2" />
									</>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
