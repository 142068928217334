import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Avatar } from '../../../../app/components/Avatar';
import { Link } from '../../../../app/components/Link';
import { getNotifications } from '../../../../app/CRUD/TeamCRUD';
import { UserModel } from '../../../../app/models';
import { CompanyDocument } from '../../../../app/modules/company/models/CompanyDocument.model';
import { getAllDocuments } from '../../../../app/modules/company/services/CompanyDocuments.service';
import { SkeTooltip } from '../../../../app/modules/shared/components/Tooltip';
import { SocketService } from '../../../../app/services/SocketService';
import { DisciplineType } from '../../../../app/types/DisciplineType';
import { NotificationMaintenance, NotificationType } from '../../../../app/types/NotificationType';
import { ActivityTypeEnum } from '../../../../features/activity/interfaces/activity.model';
import bellIcon from '../../../../images/icons/bell.svg'
import bellFillIcon from '../../../../images/icons/bell-fill.svg'
import { RootState } from '../../../../setup';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import './Topbar.scss';
import { Icon } from '../../../../app/components/icons/Icon';
import { ArrowUpRightBoxIcon, Bell, BellFill, FolderClosed, SvgIcon } from '../../../../app/components/icons/IconList';

const NEW_NOTIFICATION_EVENT = 'NEW_NOTIFICATION';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
	toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

interface Props {
	showNotificationIcon: boolean,
	setNotifications: (notifications: NotificationType[]) => void,
	setNotificationDrawerOpened: (active: boolean) => void,
	refreshNotifications: () => void,
	notifications: (NotificationType | NotificationMaintenance)[];
}

export function Topbar({
												 showNotificationIcon,
												 setNotificationDrawerOpened,
												 refreshNotifications,
												 notifications,
											 }: Props) {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const notificationDrawerOpener = React.useRef<HTMLDivElement>(null);
	let socket: SocketService;
	const [documents, setDocuments] = useState<CompanyDocument[]>([]);
	const [showDocumentsList, setShowDocumentsList] = useState<boolean>(false);
	const [hasUnreadMaintenanceNotifications, setHasUnreadMaintenanceNotifications] = useState<boolean>(false);
	const [hasUnreadNotifications, setHasUnreadNotifications] = useState<boolean>(false);

	function capitalize(word: string) {
		return word[0].toUpperCase() + word.slice(1).toLowerCase();
	}

	const loadDocuments = () => {
		// @ts-ignore
		getAllDocuments(user.company?.id, token)
		.then(({ data }) => {
			setDocuments(data.items.filter(doc => {
				let found = false;
				doc.roles.map(docRole => {
					if (user.roles?.includes(docRole)) {
						found = true;
					}
				});
				return found;
			}));
		})
	}

	useEffect(() => {
		let unreadNotificationsCount = 0;
		let unreadMaintenanceNotificationsCount = 0;
		// TODO: filtering out non-discipline notifications in NotificationDrawer (those do not have metaData set,
		//  and were spamming users), don't show unread if those aren't shown
		notifications?.filter((ntf: NotificationMaintenance | NotificationType) => !!ntf.metaData)
				.map((ntf: NotificationMaintenance | NotificationType) => {
					if ((ntf as NotificationMaintenance).metaData?.type === ActivityTypeEnum.Maintenance) {
						if (!(ntf as NotificationMaintenance).isRead) {
							unreadMaintenanceNotificationsCount++;
						}
						// TODO: Intentionally checking equal to false because
						//  the app is suppressing spam of new employees notifications that would make it show there's unread that
						//  aren't being displayed in the drawer
					} else if (!ntf.isRead) {
						unreadNotificationsCount++;
					}
				});

		setHasUnreadNotifications(!!unreadNotificationsCount);
		setHasUnreadMaintenanceNotifications(!!unreadMaintenanceNotificationsCount);

	}, [notifications]);

	useEffect(() => {
		if (!socket) {
			socket = new SocketService(token);
		}
		socket.subscribeToEvent(NEW_NOTIFICATION_EVENT, (data: any) => {
			toast.info(data.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
				onClick: () => {
					setNotificationDrawerOpened(true);
					notificationDrawerOpener.current?.click();
				},
			});

			setHasUnreadNotifications(true);
			refreshNotifications();
			return data;
		});

		// loadNotifications();
		loadDocuments();
	}, [token]);

	return (
		<div className="d-flex align-items-stretch flex-shrink-0">
			{/* Notification */}
			{showNotificationIcon && <div className={clsx('d-flex align-items-center me-7', toolbarButtonMarginClass)}>
				<div
					className={clsx(
						'btn btn-icon btn-custom position-relative notification-trigger',
						{
							'has-unread-maintenance-notifications': hasUnreadMaintenanceNotifications,
						},
						toolbarButtonHeightClass,
					)}
					onClick={() => {
						setNotificationDrawerOpened(true);
						notificationDrawerOpener.current?.click();
					}}
				>
					<Icon
						icon={BellFill}
						classes='bell-fill text-muted'
						size='sm'
						type='svg'
						alt="Notification" />
					<Icon
						icon={Bell}
						classes='bell'
						size='sm'
						type='svg'
						alt="Notification" />

					{hasUnreadNotifications &&
						<span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50"></span>}
				</div>
			</div>}
			<div
				id="document-dropdown-container"
				className="dropdown d-flex align-items-center me-7">
					<SkeTooltip
						targetElementId="document-dropdown-container"
						message={documents.length ? "Quick resource links provided by HR" : "No resource links provided by HR"}
					/>
					<button
						data-bs-toggle="dropdown"
						id="documents-dropdown-button"
						aria-haspopup={true}
						disabled={!documents.length}
						aria-expanded={showDocumentsList}
						onClick={() => {
							setShowDocumentsList(!showDocumentsList)}}
						className="btn btn-sm btn-active-light-primary p-0">
						<Icon
							type={'svg'}
							size="md"
							icon={FolderClosed}
							classes="align-items-center d-flex px-1 text-muted cursor-pointer"
							alt="Company documents"
							// title="Company documents"
							use="functional"></Icon>
					</button>
				<ul
					className="dropdown-menu"
					aria-labelledby="dropdownMenuButton1">
					{documents && documents.map(doc => {
						return (
							<li
								key={doc.id}
								title={doc.description}>
								<Link
									type="external"
									label={doc.name}
									classes="text-dark btn-active-light-primary dropdown-item m-0 p-0"
									desc={doc.description}
									url={doc.url}>
									<Icon
										type="svg"
										size='xs'
										classes="ms-1"
										alt=""
										icon={ArrowUpRightBoxIcon}></Icon>
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
			{/* End Notification */}
			<div
				ref={notificationDrawerOpener}
				className="d-none"
				id="kt_drawer_notification_toggle"></div>

			{/* begin::User */}
			<div
				className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
				id="kt_header_user_menu_toggle"
			>
				{/* begin::Toggle */}
				<div
					className={clsx('cursor-pointer symbol symbol-circle d-flex align-items-center topbar_usermenu', toolbarUserAvatarHeightClass)}
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
				>
					<Avatar
						url={user.avatarUrl}
						alt={`Avatar of ${user.firstName} ${user.lastName}`} />
					<div className="d-flex flex-column ms-4">
						<div className="usermenu_name">{!!user.firstName ? capitalize(user.firstName) : ''} {!!user.lastName ? Array.from(user.lastName)[0].toUpperCase() : ''}.</div>
						<div className="usermenu_role">{user.roles?.join(', ')}</div>
					</div>
				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}

			{/* begin::Aside Toggler */}
			{/* {config.header.left === 'menu' && (
				<div
					className="d-flex align-items-center d-lg-none ms-2 me-n3"
					title="Show header menu">
					<div
						className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
						id="kt_header_menu_mobile_toggle"
					>
						<KTSVG
							path="/media/icons/duotune/text/txt001.svg"
							className="svg-icon-1" />
					</div>
				</div>
			)} */}
		</div>
	);
}
