import { useState } from 'react'
import { disciplineStepSlice } from '../../../../../features/discipline/discipline-step.api';
import { DisciplineStepTypeEnum } from '../../../../modules/discipline/models/DisciplineStep.models';
import { AttendanceAutomationType } from '../../../../types/AttendanceAutomationType'
import AttendanceAutomationsList from './AttendanceAutomationsList'
import { CreateAttendanceAutomation } from './CreateAttendanceAutomation'
import { EditAttendanceAutomation } from './EditAttendanceAutomation'

export default function AttendanceAutomations() {
    const [showPage, setShowPage] = useState('list')
    const [editAutomation, setEditAutomation] = useState<AttendanceAutomationType | null>(null)
    const { data: disciplineSteps, isLoading } = disciplineStepSlice.useGetDisciplineStepsQuery({type: DisciplineStepTypeEnum.Attendance})

    return (
        <>
            {showPage === 'list' && <>
                <div className='min-h-300px'>
                    <AttendanceAutomationsList
                      setShowPage={setShowPage}
                      setEditAutomation={setEditAutomation}
                    />
                </div>
            </>}
            {showPage === 'create' && !isLoading && disciplineSteps?.length && (
              <CreateAttendanceAutomation
                setShowPage={setShowPage}
                disciplineSteps={disciplineSteps}
              />
            )}
            {showPage === 'edit' && !isLoading && disciplineSteps?.length && !!editAutomation && (
              <EditAttendanceAutomation
                setShowPage={setShowPage}
                editAutomation={editAutomation}
                disciplineSteps={disciplineSteps}
              />
            )}
        </>
    )
}
