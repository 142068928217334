import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { UserModel } from '../../../../../models'
import { updateUserNotificationSubscriptions } from '../../../../../modules/auth/redux/AuthCRUD'
import * as auth from '../../../../../modules/auth/redux/AuthRedux'

const Notifications: React.FC = () => {
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string

  const [membersEmailChecked, setMembersEmailChecked] = useState<boolean>(false)
  const [membersPhoneChecked, setMembersPhoneChecked] = useState<boolean>(false)
  const [approvalEmailChecked, setApprovalEmailChecked] = useState<boolean>(false)
  const [approvalPhoneChecked, setApprovalPhoneChecked] = useState<boolean>(false)

  const handleMembersEmailChange = () => {
    handleSubscription('NEW_EMPLOYEE_EMAIL')
  }

  const handleMembersPhoneChange = () => {
    handleSubscription('NEW_EMPLOYEE_SMS')
  }

  const handleApprovalEmailChange = () => {
    handleSubscription('CONNECTION_EMAIL')
  }

  const handleApprovalPhoneChange = () => {
    handleSubscription('CONNECTION_SMS')
  }

  const handleSubscription = (subscriptionType: string) => {
    let subscriptions: string[] = []
    if (!user.notificationSubscriptions.includes(subscriptionType)) {
      const subs = [...user.notificationSubscriptions, subscriptionType]
      subscriptions = subs.filter((element, index) => {
        return subs.indexOf(element) === index;
      })
    } else {
      subscriptions = user.notificationSubscriptions.filter(elem => elem !== subscriptionType)
    }

    updateUserNotificationSubscriptions(subscriptions, token)
      .then(() => {
        dispatch(auth.actions.setUser(
          {
            ...user,
            notificationSubscriptions: subscriptions,
          }
        ))
      })
  }

  useEffect(() => {
    setMembersEmailChecked(user.notificationSubscriptions.includes('NEW_EMPLOYEE_EMAIL'))
    setMembersPhoneChecked(user.notificationSubscriptions.includes('NEW_EMPLOYEE_SMS'))
    setApprovalEmailChecked(user.notificationSubscriptions.includes('CONNECTION_EMAIL'))
    setApprovalPhoneChecked(user.notificationSubscriptions.includes('CONNECTION_SMS'))
  }, [user.notificationSubscriptions])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_notifications'
        aria-expanded='true'
        aria-controls='kt_account_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Notifications</h3>
        </div>
      </div>

      <div id='kt_account_notifications' className='collapse show'>
        <form className='form'>
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <div className='table-responsive'>
              <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                <tbody className='fs-6 fw-bold'>
                  {/* <tr>
                    <td className='min-w-250px fs-4 fw-bolder'>Notifications</td>
                    <td className='w-200px'>
                      <div className='form-check form-check-solid d-flex align-items-center'>
                        <span className="switch_label me-3">Off</span>
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <input className="form-check-input h-30px w-50px" name="sms" type="checkbox" value="" checked={notificationChecked} onChange={handleNotificationChange}
                          />
                        </label>
                        <span className="switch_label ms-3">On</span>
                      </div>
                    </td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='kt_settings_notification_phone'
                          defaultChecked={data.notifications.phone}
                          onChange={() =>
                            updateData({
                              notifications: {
                                phone: !data.notifications.phone,
                                email: data.notifications.email,
                              },
                            })
                          }
                        />
                        <label
                          className='form-check-label ps-2'
                          htmlFor='kt_settings_notification_phone'
                        >
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td>Billing Updates</td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          id='billing1'
                          defaultChecked={data.billingUpdates.email}
                          onChange={() =>
                            updateData({
                              billingUpdates: {
                                phone: data.billingUpdates.phone,
                                email: !data.billingUpdates.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='billing1'></label>
                      </div>
                    </td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='billing2'
                          defaultChecked={data.billingUpdates.phone}
                          onChange={() =>
                            updateData({
                              billingUpdates: {
                                phone: !data.billingUpdates.phone,
                                email: data.billingUpdates.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='billing2'></label>
                      </div>
                    </td>
                  </tr> */}

                  <tr>
                    <td>New Team Members</td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={membersEmailChecked}
                          onChange={handleMembersEmailChange}
                        />
                        <label className='form-check-label ps-2' htmlFor='team2'>Email</label>
                      </div>
                    </td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={membersPhoneChecked}
                          onChange={handleMembersPhoneChange}
                        />
                        <label className='form-check-label ps-2' htmlFor='team2'>Phone</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Connection Approval Request</td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={approvalEmailChecked}
                          onChange={handleApprovalEmailChange}
                        />
                        <label className='form-check-label ps-2' htmlFor='team2'>Email</label>
                      </div>
                    </td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={approvalPhoneChecked}
                          onChange={handleApprovalPhoneChange}
                        />
                        <label className='form-check-label ps-2' htmlFor='team2'>Phone</label>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

          {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-white btn-active-light-primary me-2'>Discard</button>
            <button type='button' onClick={click} className='btn btn-primary'>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div> */}
        </form>
      </div>
    </div>
  )
}

export { Notifications }
