import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { DisciplineStep } from '../../app/modules/discipline/models/DisciplineStep.models';
import { omit } from 'lodash';
import { ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import { extractReadableApiError, getAndSetBearerToken } from '../../common/api/common.api';
import {
	GetDisciplineStepsApiRequestModel,
	CreateDisciplineStepApiRequestModel,
	UpdateDisciplineStepApiRequestModel,
	DeleteDisciplineStepApiRequestModel
} from './interfaces/discipline-step.model';

export enum DisciplineStepTagTypes {
	DisciplineSteps = 'DISCIPLINE_STEPS',
}

export const disciplineStepSlice = createApi({
	reducerPath: 'disciplineStepApi',
	tagTypes: [DisciplineStepTagTypes.DisciplineSteps],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/discipline-steps`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getDisciplineSteps: builder.query<DisciplineStep[],GetDisciplineStepsApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				}
			},
			transformResponse: (response: ApiResponseWithoutPagination<DisciplineStep>) => {
				return response.items.sort((a, b) => a.stepNumber - b.stepNumber);
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.map(({ id }) => ({
							type: DisciplineStepTagTypes.DisciplineSteps,
							id,
						} as const)),
						{
							type: DisciplineStepTagTypes.DisciplineSteps,
							id: 'LIST',
						},
					];
				}
				// an error occurred, but still want to refetch this query when `{ type: 'Teams', id: 'LIST' }` is invalidated
				return [
					{
						type: DisciplineStepTagTypes.DisciplineSteps,
						id: 'LIST',
					},
				];
			}
		}),
		createDisciplineStep: builder.mutation<DisciplineStep, CreateDisciplineStepApiRequestModel>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			transformResponse: (response: DisciplineStep) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			// invalidates any DisciplineSteps-typed queries providing the 'LIST' id, as this could show up in any of those lists
			invalidatesTags: [
				{
					type: DisciplineStepTagTypes.DisciplineSteps,
					id: 'LIST',
				},
			],
		}),
		updateDisciplineStep: builder.mutation<DisciplineStep, UpdateDisciplineStepApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			transformResponse: (response: { data: DisciplineStep }) => response.data,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			// invalidates both the list of discipline steps (as we just edited a step), and if this one was called specifically,
			// clear it
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: DisciplineStepTagTypes.DisciplineSteps,
							id: 'LIST',
						},
						{
							type: DisciplineStepTagTypes.DisciplineSteps,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteDisciplineStep: builder.mutation<void, DeleteDisciplineStepApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: DisciplineStepTagTypes.DisciplineSteps,
						id: 'LIST',
					},
					{
						type: DisciplineStepTagTypes.DisciplineSteps,
						id: args.id,
					},
				];
			},
			transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): void {
				toast.error(extractReadableApiError(baseQueryReturnValue, 'Error deleting Corrective Action step'));
			}
		}),
	}),
});
