import { Icon, IconSizeUnion } from './Icon';
import { Trash } from './IconList';

interface Props {
	title?: string;
	alt?: string;
	size?: IconSizeUnion;
	classes?: string;
}

export function DeleteIcon({
														 title = 'Delete item',
														 alt = 'Delete item',
														 size = 'xs',
														 classes = 'm-1',
													 }: Props) {
	return (
		<Icon
			type="svg"
			classes={classes}
			alt={alt}
			icon={Trash}
			title={title}
			size={size}
		/>
	);
}
