import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getEmployee } from '../../../../../app/CRUD/TeamCRUD';
import { Employee } from '../../../../../app/modules/employee/models/Employee.model';
import { ModalDisplayMode } from '../../../../../common/interfaces/modal.model';
import { connectionSlice } from '../../../../connection/connection.api';
import { ConnectionSettingTokenEnum } from '../../../../workflow/interfaces/workflow.model';
import { aiReviewModalSlice, AiReviewModalState, AiReviewModalStateUnion } from '../../ai.review-modal.slice';
import { AIReviewModal } from '../../modal/AIReviewModal';

interface Props {
	state: AiReviewModalStateUnion & Pick<AiReviewModalState, 'showModal' | 'recordId' | 'token'>;
}

export function AiReviewLayout({state}: Props) {
	const [employee, setEmployee] = useState<Employee>();
	const dispatch = useDispatch();
	const previousState = useRef<AiReviewModalStateUnion>();
	const aiReviewModalRef = useRef<HTMLButtonElement>(null);
	const [getOneAiReviewFilter, setGetOneAiReviewFilter] = useState<{id: number}>();
	const {
		data: ai_review,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = connectionSlice.useGetOneQuery(getOneAiReviewFilter ? {
		id: +getOneAiReviewFilter,
		token: ConnectionSettingTokenEnum.AiPerformanceReview,
	} : skipToken);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneAiReviewFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};


	useEffect(() => {
		// @ts-ignore
		if (!!ai_review && ai_review.id === state.recordId) {
			dispatch(aiReviewModalSlice.actions.show({
				...state,
				//@ts-ignore
				record: ai_review,
			}));
		}
	}, [
		ai_review,
		fulfilledTimeStamp,
	]);

	useEffect(() => {
		if (state.employeeId) {
			getEmployee(+state.employeeId, state.token)
				.then(({data}) => {
					setEmployee(data);
				})
				.catch(err => {
					console.log(err.response);
				});
		}
		if (state.recordId && !state.record) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					aiReviewModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
		if (!state.showModal) {
			previousState.current = state;
			return;
		}

	}, [state]);

	return (
		<>
			<button
				className="d-none"
				ref={aiReviewModalRef}
			>
			</button>
			{state.recordId && state.mode === ModalDisplayMode.Read &&
				<AIReviewModal
					token={state.token}
					employee={(employee as unknown as Employee)}
					// @ts-ignore
					reviewId={state.record.id}
					show={state.showModal}
					onClose={() => dispatch(aiReviewModalSlice.actions.hide(true))}
					onSubmit={() => dispatch(aiReviewModalSlice.actions.hide(false))}
				/>
			}
		</>
	);
}
