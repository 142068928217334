import { useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { deleteOneNotification } from '../../CRUD/TeamCRUD';
import { NotificationMaintenance } from '../../types/NotificationType';
import { CloseButtonIcon } from '../icons/CloseButtonIcon';
import './NotificationViewModal.scss';

interface Props {
	notification: NotificationMaintenance | null;
	refreshNotifications: () => void;
}

export function NotificationModalMaintenance({
																							 notification,
																							 refreshNotifications,
																						 }: Props) {
	const closeModalRef = useRef<HTMLButtonElement>(null);
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;

	const handleDismissNotification = () => {
		if (!!notification) {
			deleteOneNotification(notification.id, token)
				.then(() => refreshNotifications())
				.catch(err => console.log(err));
			closeModalRef.current?.click();
		}
	};

	return (
		<>
			<div className="modal fade"
					 id="notification_modal_maintenance"
					 aria-hidden="true">
				<div className="modal-dialog modal-fullscreen-sm-down">
					<div className="modal-content w-lg-700px">
						<div className="modal-header d-flex align-items-center justify-content-evenly">
							<div className="w-100 justify-content-center">
								<h1 className="modal-title text-center">Scheduled Maintenance</h1>
							</div>
							<button
								className="btn"
								data-bs-dismiss="modal"
							>
								<CloseButtonIcon />
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<p>
									<span className="fw-bolder fst-5 pe-2">
										When:
									</span>
									{notification?.metaData?.target_date} from {notification?.metaData?.window_start} to {notification?.metaData?.window_end} Central
																												Time
								</p>
								<p>
									<span className="fw-bolder fst-5 pe-2">
										Details:
									</span>
									{notification?.metaData?.details}
								</p>
							</div>
						</div>

						<div className="modal-footer d-flex justify-content-end">
							<button type="button"
											className="btn btn-outline-danger px-6"
											onClick={handleDismissNotification}>Dismiss
							</button>
						</div>
					</div>
				</div>
				<button type="button"
								ref={closeModalRef}
								data-bs-dismiss="modal"
								className="d-none"
				/>
			</div>
		</>
	);
}
