import axios, { AxiosRequestConfig } from 'axios';
import { catchError, defer, from, map, Observable, of } from 'rxjs';
import { ApiResponse } from '../../shared/models/ApiResponse.model';
import { Attendance } from '../models/Attendance.models';
import { AttendanceGetParamsModel } from '../models/AttendanceGetParamsModel';

const API_URL = process.env.REACT_APP_API_URL;

export function getEmployeeAttendances(employeeId: number,
																			 token: string,
																			 opts?: AttendanceGetParamsModel): Observable<ApiResponse<Attendance>> {
	const config: AxiosRequestConfig = {
		url: `${API_URL}/employees/${employeeId}/attendances`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	return from(defer(() => axios(config)))
		.pipe(
			catchError(err => {
				// TODO: trigger toast
				console.error(`Error caught getting employee attendances`, err);
				return of(err);
			}),
			// TODO: see about converting infractions, textFields to object
			map(res => res.data),
		);
}
