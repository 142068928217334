import clsx from 'clsx';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { recognitionModalSlice } from '../../../../features/recognition/recognition-modal.slice';
import { AvatarSize } from '../../../components/Avatar';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { TranslateIcon } from '../../../components/icons/TranslateIcon';
import { getNameFromLanguageAbbr } from '../../../modules/translation/services/Translation.service';
import { EmployeeType, TeamType } from '../../../types/EmployeeType';

interface Props {
	recognition: Recognition;
	modalId?: string;
	onClose?: ()=> void;
}

export function RecognitionViewModal({
																			 recognition,
																			 modalId = 'recognition_view_modal',
																			 onClose,
}: Props) {
	const dispatch = useDispatch();
	const handleCloseModal = () =>{
		if (onClose) {
			onClose();
		}
		dispatch(recognitionModalSlice.actions.hide());
	}
	return (
		<Modal
			size="lg"
			fullscreen="md-down"
			show={true}
			onHide={handleCloseModal}>
			<Modal.Header
				closeButton={true}
			>
				<div className="d-flex align-items-center w-100">
					<EmployeeBadge
						size={AvatarSize.md}
						employee={recognition?.employee}
						team={recognition?.team} />
					<div className="d-flex flex-grow-1 justify-content-center">
						<Modal.Title className="justify-content-center">
							View Recognition
						</Modal.Title>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-wrap">
					<div className="mx-5 my-3 inline-block">
						<span className="fw-bolder d-block">Date</span>
						<span className="d-block">{recognition.date}</span>
					</div>
					<div className="mx-5 my-3 inline-block">
						<span className="fw-bolder d-block">Category</span>
						<span className="d-block">{recognition.category?.name}</span>
					</div>
					<div className="mx-5 my-3 inline-block">
						<span className="fw-bolder d-block">Recognized By</span>
						<span className="d-block">{recognition?.creator?.firstName} {recognition?.creator?.lastName}</span>
					</div>
				</div>

				<div className="text-wrap mx-5 my-3">
					<span className="fs-5 fw-bolder d-block">Message</span>
					<blockquote
						className={clsx('blockquote shadow-none bg-gray-200 rounded-1 p-2',
							{
								'fst-italic': !recognition.message,
							})}
					>
						{recognition.message || 'No message sent'}
					</blockquote>

					{recognition.translatedMessage && recognition.sourceLanguage && recognition.translatedLanguage && (
						<div className="d-flex mt-3 flex-column">
										<span className="fw-bold fs-6 mb-2 fst-italic">
											<TranslateIcon
												title={`Recognition was translated from ${getNameFromLanguageAbbr(recognition.sourceLanguage)} to ${getNameFromLanguageAbbr(recognition.translatedLanguage)}`}
												alt="Icon indicating content was translated"
												size="sm"
											/>
											Translated from {getNameFromLanguageAbbr(recognition.sourceLanguage)} to {getNameFromLanguageAbbr(recognition.translatedLanguage)}
										</span>
							<blockquote
								className={clsx('blockquote shadow-none bg-gray-200 rounded-1 p-2',
									{
										'fst-italic': !recognition.message,
									})}
							>
								{recognition.translatedMessage}
							</blockquote>
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={() => {
						dispatch(recognitionModalSlice.actions.show({
							mode: ModalDisplayMode.Print,
							record: recognition,
						}));
					}}
				>
					Print PDF
				</Button>
			</Modal.Footer>
		</Modal>
	);

}
