import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isArray, isUndefined } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { ActivityGetApiRequestModel } from './interfaces/activity-api.model';
import { ActivityModel } from './interfaces/activity.model';

export enum ActivityTagTypes {
	Activity = 'ACTIVITY',
	TeamActivity = 'ACTIVITY_FOR_TEAM_ID',
}

export const activitySlice = createApi({
		reducerPath: 'activityApi',
		baseQuery: fetchBaseQuery({
			baseUrl: `${process.env.REACT_APP_API_URL}/activities`,
			prepareHeaders: getAndSetBearerToken,
		}),
		tagTypes: [ActivityTagTypes.Activity, ActivityTagTypes.TeamActivity],
		endpoints: (builder) => ({
			getActivities: builder.query<ActivityModel[], ActivityGetApiRequestModel>({
				query: (args: ActivityGetApiRequestModel) => {
					const params: any = {
						...args,
					};
					if (isArray(args.activity_type_enum)) {
						params.activity_type_enum = args.activity_type_enum.join(',');
					}
					return {
						url: '',
						params,
					};
				},
				transformResponse: (response: ApiResponse<ActivityModel>, meta, arg) => response.items,
				transformErrorResponse: (response: { status: string | number }, meta, arg) => response.status,
				providesTags: (result, error, args) => {
					// successful result
					if (result) {
						// cache this for a specific team
						if (args.team_id) {
							return [
								...result.map(({ id }) => ({
									type: ActivityTagTypes.TeamActivity,
									id: args.team_id,
								} as const)),
								{
									type: ActivityTagTypes.TeamActivity,
									id: 'LIST',
								},
							];
						}
						return [
							...result.map(({ id }) => ({
								type: ActivityTagTypes.Activity,
								id,
							} as const)),
							{
								type: ActivityTagTypes.Activity,
								id: 'LIST',
							},
						];
					}
					// an error occurred, but still want to refetch this query when `{ type: 'Teams', id: 'LIST' }` is invalidated
					return [
						{
							type: ActivityTagTypes.Activity,
							id: 'LIST',
						},
					];
				},
				// TODO: constant for default data to hold
				keepUnusedDataFor: 300,
			}),
			deleteActivity: builder.mutation<void, {id: number}>({
				query: (args) => ({
					url: `${args.id}`,
					method: 'delete',
				}),
				invalidatesTags: (result, error, args) => {
					return [
						{
							type: ActivityTagTypes.Activity,
							id: 'LIST',
						},
						{
							type: ActivityTagTypes.TeamActivity,
							id: 'LIST',
						},
					];
				},
			}),
			publicActivities: builder.query<ActivityModel[], void>({
				query: () => {
					return {
						url: 'maintenance',
						headers: new Headers([['Authorization', 'None']])
					};
				},
				transformErrorResponse: (response: { status: string | number }, meta, arg) => response.status,
				// TODO: constant for default data to hold
				keepUnusedDataFor: 300,
			}),
		})
})
