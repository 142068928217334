import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { EmployeeUploadApiRequestModel, EmployeeUploadApiResponse } from './interfaces/company.model';

export const companySlice = createApi({
	reducerPath: 'companyApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/companies`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		runProcess: builder.mutation<EmployeeUploadApiResponse, EmployeeUploadApiRequestModel>({
			query: (args) => {
				const body = new FormData();
				args.spreadsheetFiles.forEach(file => {
					body.append('files', file);
				});
				body.append('createTeams', args.createTeams.toString());
				body.append('assignTeams', args.assignTeams.toString());
				body.append('reassignTeams', args.reassignTeams.toString());
				body.append('optInNewEmployeesToTexting', args.optInNewEmployeesToTexting.toString());
				if(args.validateOnly){
					body.append('validateOnly', args.validateOnly.toString());
				}

				return {
					url: `${args.tenant_id}/process/${args.processType}`,
					method: 'post',
					body,
				};
			},
			transformResponse: (response: any) => {
				return response;
			},
			// TODO: figure out better typing for error handling
			transformErrorResponse(res: any) {
				return {
					msg: res.data?.message || 'Error processing spreadsheet',
				};
			},
		}),
	}),
});
