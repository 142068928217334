import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import { verifyCode } from '../redux/AuthCRUD'
import { UserModel } from '../../../models'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { RootState } from '../../../../setup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'

import './VerifyEmail.scss'
import logo from '../../../../images/Logotype_Black.svg'

const loginSchema = Yup.object().shape({
    codeString: Yup.string()
        .min(6, '6 digits code')
        .max(6, '6 digits code')
        .required('Code is required')
})

const initialValues = {
    codeString: '',
}

export function VerifyEmail() {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            verifyCode(user.email, values.codeString, token)
                .then((result) => {
                    setLoading(false)
                    console.log(result)

                    if (user.roles?.includes('Supervisor')) {
                        dispatch(auth.actions.verify())
                    } else {
                        dispatch(auth.actions.logout())
                        toast.info("Please connect your secchi admin to setup your company.", {
                            position: "top-right",
                            theme: 'colored',
                            autoClose: 6000,
                        })
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setSubmitting(false)
                    console.log(error)
                    setStatus('Verification code is incorrect')
                })
        },
    })

    return (
        <div
            className='auth_container verify_email_container d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <Link to='#' className='mb-12'>
                    <img alt='Logo' src={logo} className='h-45px img_auth_logo' />
                </Link>
                <div className='w-lg-500px p-lg-15 p-10 bg-white rounded shadow-sm mx-auto'>
                    <div className='link_wrapper mb-10'>
                        <button type="button" className='link-primary btn fw-bolder'
                            onClick={() => { dispatch(auth.actions.logout()) }}
                        >
                            <i className="fas fa-arrow-left"></i>
                            Go to Home
                        </button>
                    </div>
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_login_signin_form'
                    >
                        {/* begin::Heading */}
                        <div className='text-center mb-10'>
                            <h1 className='text-dark mb-3 poppins_semibold'>Verify your email</h1>
                        </div>
                        {/* begin::Heading */}

                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )
                            //  : (
                            //   <div className='mb-10 bg-light-info p-8 rounded'>
                            //     <div className='text-info'>
                            //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                            //       continue.
                            //     </div>
                            //   </div>
                            // )
                        }

                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bolder text-dark poppins_semibold'>Verification Code</label>
                            <input
                                placeholder=''
                                {...formik.getFieldProps('codeString')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.codeString && formik.errors.codeString },
                                    {
                                        'is-valid': formik.touched.codeString && !formik.errors.codeString,
                                    }
                                )}
                                type='text'
                                name='codeString'
                                autoComplete='off'
                            />
                            {formik.touched.codeString && formik.errors.codeString && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.codeString}</span>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Action */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn btn-lg btn_black w-100 mb-5'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                {!loading && <span className='indicator-label poppins_medium'>Verify</span>}
                                {loading && (
                                    <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Action */}
                    </form>
                </div>
            </div>
        </div>
    )
}