import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../models'

export function Overview() {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    return (
        <div className="overview_container">
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Profile Details</h3>
                    </div>

                    {/* <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
                        Edit Profile
                    </Link> */}
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{user.firstName} {user.lastName}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Company</label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{user.company?.name}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Email
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{user.email}</span>

                            <span className='badge badge-success'>Verified</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

                        <div className='col-lg-8'>
                            <Link to='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                                {user.company?.website}
                            </Link>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Country
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{user.company?.country}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Role</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>Supervisor</span>
                        </div>
                    </div>

                    {/* <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>Yes</span>
                        </div>
                    </div>

                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>Platform Users</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>User Count</span>
                        </div>
                    </div>

                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>Subscription</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>Free Beta</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}