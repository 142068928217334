export function maskData() {
	const itemKey = buildItemKeyV1();

	return itemKey;
};

function buildItemKeyV1() {
	return 'xxxxxxxx-xxxx-1xxx-yxxx-xxxxxxxxxxxx'
		.replace(/[xy]/g, function(c) {
			const r = Math.random() * 16 | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
}
