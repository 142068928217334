import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast, ToastContent } from 'react-toastify';
import * as Yup from 'yup';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import {
	Recognition,
	RecognitionCreate,
	RecognitionUpdate,
} from '../../../../features/recognition/interfaces/recognition.model';
import { recognitionCategoriesSlice } from '../../../../features/recognition/recognition-categories.api';
import { recognitionSlice } from '../../../../features/recognition/recognition.api';
import { recognitionModalSlice } from '../../../../features/recognition/recognition-modal.slice';
import { RootState } from '../../../../setup';
import { DatePicker } from '../../../components/DatePicker';
import { SkeEmojiPicker } from '../../../components/EmojiPicker';
import { Icon } from '../../../components/icons/Icon';
import { InfoCircle, PrinterFill } from '../../../components/icons/IconList';
import { SkeTextbox } from '../../../components/Textbox';
import { UserModel } from '../../../models';
import { SHORT_DATE } from '../../../modules/date/DateFormat.const';
import { Employee } from '../../../modules/employee/models/Employee.model';
import { Team } from '../../../modules/team/models/Team.model';
import { SupportedLanguageEnum } from '../../../modules/translation/models/Language.model';
import { getNameFromLanguageAbbr, translateText } from '../../../modules/translation/services/Translation.service';
import { TranslateSelector } from '../../../modules/translation/TranslateSelector';
import './RecognitionModal.scss';

const initialValues: Partial<RecognitionCreate> = {
	recognitionCategoryId: 0,
	date: '',
	message: '',
	translatedMessage: null,
	translatedLanguage: null,
	sourceLanguage: null,
};

const connectionSchema = Yup.object().shape({
	recognitionCategoryId: Yup.number().required('Category is required'),
	message: Yup.string().required('Message is required'),
});

interface Props {
	employee: Employee;
	recognition?: Recognition;
	teamInfo?: Team;
	refresh?: (updated: Recognition) => void;
	mode: 'edit' | 'new';
	onClose?: () => void;
	modalId?: string;
}

export function RecognitionModal({
																	 employee,
																	 recognition,
																	 mode,
																	 onClose,
																	 modalId = 'recognition_edit_modal',
																 }: Props) {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [recognitionDate, setRecognitionDate] = useState<Date | null>(new Date());
	// will keep appending signature if editing an existing one
	const [signatureType, setSignatureType] = useState<'initials' | 'fullName' | 'firstName' | 'off'>(mode === 'new' ? 'initials' : 'off');
	const showActionModalRef = useRef<HTMLButtonElement>(null);
	const [showProTips, setShowProTips] = useState<boolean>(false);
	const [fromLanguage, setFromLanguage] = useState<SupportedLanguageEnum>(SupportedLanguageEnum.English);
	const [toLanguage, setToLanguage] = useState<SupportedLanguageEnum>(SupportedLanguageEnum.English);
	const [translatedMessage, setTranslatedMessage] = useState<string>('');
	const [hasStaleTranslation, setHasStaleTranslation] = useState<boolean>(false);
	const exampleMessages = [
		"Bill has been an outstanding performer this week. He has assisted in both ends of the operation as well as taking it upon himself to correct inventory discrepancies. Huge asset to the team, well done!",
		"On 10/28 it had been raining and John took the initiative of opening all the dock doors on the inbound side and pushed all the water out, helping with the general housekeeping of the warehouse. Outstanding work, thanks John.",
		"Jane took great initiative and ran the outbound side of the operation effortlessly while communicating with her fellow teammates in a time sensitive manner. Good job!"
	  ];
	const { data: recognitionCategories } = recognitionCategoriesSlice.useGetRecognitionCategoriesQuery({});
	const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
	const textboxRef: any = useRef(null);
	const dispatch = useDispatch();
	const [ createRecognition ] = recognitionSlice.useCreateRecognitionMutation();
	const [ updateRecognition ] = recognitionSlice.useUpdateRecognitionMutation();

	const formik = useFormik<Partial<RecognitionCreate>>({
		initialValues,
		validationSchema: connectionSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: async (values) => {
			const saveData: RecognitionCreate | RecognitionUpdate = {
				recognitionCategoryId: +values.recognitionCategoryId!,
				date: moment(recognitionDate).format(SHORT_DATE),
				employeeId: employee.id,
				teamId: employee.teams[0].id,
				message: `${values.message}${getSignature()}`,
			};

			if (fromLanguage !== toLanguage) {
				if (hasStaleTranslation) {
					const translation = await translateText(fromLanguage, toLanguage, values.message!, token);
					saveData.translatedMessage = translation.data;
				} else {
					saveData.translatedMessage = translatedMessage;
				}
				saveData.translatedLanguage = toLanguage;
				saveData.sourceLanguage = fromLanguage;
			}

			let promise;
			if (mode === 'new') {
				promise = createRecognition(saveData);
			} else {
				if (!recognition) {
					throw new Error(`Cannot update recognition as it's undefined`);
				}
				promise = updateRecognition({
					...saveData,
					id: recognition.id,
				});

			}

			promise
				.then((res) => {
					if ('error' in res) {
						throw new Error('Error encountered saving recognition');
					}
					let record: Recognition = res.data;

					let msg: string = `Updated recognition`;
					let printBtn: ToastContent;
					const toastType = mode === 'new' ? 'info' : 'success';
					if (mode === 'new') {
						if (!!employee.phone) {
							msg = (!!saveData.translatedMessage) ? 'Recognition translated and sent' : 'Recognition sent'
						} else {
							printBtn = () => (
								<button
									type="button"
									className="btn text-light btn-link"
									onClick={e => {
										e.preventDefault();
										dispatch(recognitionModalSlice.actions.show({
											mode: ModalDisplayMode.Print,
											record,
										}))
									}}>
									<Icon
										type='svg'
										size='sm'
										icon={PrinterFill}
									/>
										Recognition created. Click to print.
								</button>
							)
						}
					}
					dispatch(recognitionModalSlice.actions.hide());
					// blue/info to highlight action to take if created and want to print
					toast[toastType](printBtn || msg, {
						position: 'top-right',
						theme: 'colored',
						icon: false,
						autoClose: 5000,
					});
				})
				.catch(err => {
					console.error(err);
					const message = err?.message || `Error sending recognition`;
					toast.error(message, { autoClose: 3500 });
				});
		},
	});

	const handleEmojiSelect = (emoji: any) => {
		textboxRef.current?.insertTextAtCursor(emoji.native);
	};

	const resetModal = () => {
		formik.resetForm({
			values: initialValues,
		});
	};

	const handleTranslate = () => {
		if (!formik.values.message) {
			return console.error('Unable to translate empty text');
		}
		translateText(
			fromLanguage,
			toLanguage,
			formik.values.message,
			token
		)
		.then(res => {
			setTranslatedMessage(`${res.data}${getSignature()}`);
			setHasStaleTranslation(false);
		})
		.catch(err => {
			console.error('error translating text', err);
		})
	}

	const handleChangeMessage = (val: string) => {
		formik.setFieldValue('message', val);
		// make sure we track if it's changed since the translation has happened
		if (fromLanguage !== toLanguage && translatedMessage) {
			setHasStaleTranslation(true);
		}
	}

	const handleLanguageChange = (from: SupportedLanguageEnum, to: SupportedLanguageEnum) => {
		setFromLanguage(from);
		setToLanguage(to);
		setHasStaleTranslation(from !== to);
	};

	const getSignature = () => {
		switch (signatureType) {
			case 'initials':
				return ` -${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
			case 'fullName':
				return ` -${user.firstName} ${user.lastName}`;
			case 'firstName':
				return ` -${user.firstName}`;
			case 'off':
			default:
				return '';
		}
	};

	useEffect(() => {
		let abortController = new AbortController();
		if (mode === 'edit') {
			if (!!recognition) {
				setRecognitionDate(moment(recognition.date).toDate());
				formik.setValues({
					recognitionCategoryId: recognition.category.id,
					message: recognition.message,
					translatedMessage: recognition.translatedMessage,
					sourceLanguage: recognition.sourceLanguage,
					date: recognition.date,
					translatedLanguage: recognition.translatedLanguage,
					employeeId: recognition.employee.id,
					teamId: recognition.team.id,
					mediaUrl: recognition.mediaUrl || undefined,
				});
				if (!!recognition.translatedLanguage && !!recognition.translatedLanguage && !!recognition.translatedMessage) {
					setFromLanguage(SupportedLanguageEnum[getNameFromLanguageAbbr(recognition.sourceLanguage!)]);
					setToLanguage(SupportedLanguageEnum[getNameFromLanguageAbbr(recognition.translatedLanguage!)]);
					setHasStaleTranslation(false);
					setTranslatedMessage(recognition.translatedMessage);
				}
				setTimeout(() => {}, 1);
			}
		}

		return () => {
			abortController.abort();
			resetModal();
		};
	}, [recognition?.id, recognition, employee]);

	const handleCloseModal = () => {
		dispatch(recognitionModalSlice.actions.hide());
	}

	if (!employee) {
		return null;
	}

	return (
		<Modal
			size="lg"
			fullscreen="md-down"
			show={true}
			onHide={handleCloseModal}
		>
			<Modal.Header
				closeButton={true}
			>
				<Modal.Title>
					<span className="d-block">
						{mode === 'new' ? 'New' : 'Edit'} Recognition
					</span>
					<span className="text-muted fs-5 fw-lighter">
						{employee?.firstName} {employee?.lastName} | {employee?.employeeId}
					</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form
					onSubmit={formik.handleSubmit}
					noValidate>
						<div className="mb-5 d-flex align-items-center justify-content-between">
							<div className="header_left">
							</div>
						</div>

						<div className="row d-flex">
							<div className="col-sm-12 col-md-6">
								<DatePicker
									label="Date"
									labelClasses="col-form-label fw-light fs-4"
									id="recognition-add-date"
									selectedDate={recognitionDate}
									onChange={setRecognitionDate}
									dateFormat="MMMM d, yyyy"
								/>

								<div>
									<label className="col-form-label fw-light fs-4">Category</label>
									<select
										className="form-select form-select-lg sch-form-label col-form-label"
										{...formik.getFieldProps('recognitionCategoryId')}
									>
										<option
											value="0"
											disabled={true}>Select category
										</option>
										{recognitionCategories?.map(type => <option
											key={type.id}
											value={type.id}>{type.name}</option>)}
									</select>

									{formik.touched.recognitionCategoryId && formik.errors.recognitionCategoryId && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block text-danger">{formik.errors.recognitionCategoryId}</div>
										</div>
									)}
								</div>
								<div>
									<button
										className="btn btn-link"
										data-bs-toggle="collapse"
										type="button"
										data-bs-target="#example-messages">
													<span className="text-muted">
														Click for examples
													</span>
									</button>

									<div id="example-messages" className="collapse">
										{exampleMessages.length > 0 && exampleMessages.map((message, index) => (
											<p key={index} className="text-muted">{message}</p>
										))}
									</div>
								</div>
							</div>

							<div className="col-sm-12 col-md-6 pt-3">
								<div>
									<button
										className="btn btn-link"
										data-bs-toggle="collapse"
										type="button"
										onClick={() => setShowProTips(!showProTips)}
										data-bs-target="#recognition-tips">
													<span className="fs-4 text-muted d-flex align-items-center">
														<Icon
															type={'svg'}
															size="md"
															icon={InfoCircle}
															classes="align-items-center d-flex px-1 text-muted cursor-pointer"
															use="decorative"></Icon>
														Pro Tips
													</span>
									</button>
								</div>

								<div
									id="recognition-tips"
									className="show">
									<ul className="list-group list-group-flush">
										<li className="list-group-item text-muted">
											<span className="fw-bolder">S</span>ituation: Describe the context
										</li>
										<li className="list-group-item text-muted">
											<span className="fw-bolder">T</span>ask: Describe the issue
										</li>
										<li className="list-group-item text-muted">
											<span className="fw-bolder">A</span>ction: Describe their actions
										</li>
										<li className="list-group-item text-muted">
											<span className="fw-bolder">R</span>esults: Describe the results and impact
										</li>
										<li className="list-group-item text-muted">
											Personalize it and use their name
										</li>
										<li className="list-group-item text-muted">
											Say "thank you"
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row form_row">
							<div className="col-12">
								<SkeTextbox
									inputId="recognition-message"
									label="Message"
									ref={textboxRef}
									value={formik.values.message}
									onChange={handleChangeMessage}
									name="message"></SkeTextbox>
								<button
									type="button"
									className='btn btn-link text-muted'
									onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
								>
									Emojis
								</button>
								{emojiPickerOpen && <SkeEmojiPicker
									onEmojiSelect={handleEmojiSelect}
								/>}

							</div>
						</div>
						<div className="row d-flex">
							<div className="col-lg-6">
								<div
									className="btn-group-sm mt-5"
									role="radiogroup"
									aria-label="Append name options">
									<input
										type="radio"
										name="signature-type"
										id="initials"
										autoComplete="off"
										checked={signatureType === 'initials'}
										onChange={() => setSignatureType('initials')}
										className="btn-check" />
									<label
										className="btn btn-outline-secondary btn-outline"
										htmlFor="initials">Initials</label>
									<input
										type="radio"
										name="signature-type"
										id="full-name"
										autoComplete="off"
										checked={signatureType === 'fullName'}
										onChange={() => setSignatureType('fullName')}
										className="btn-check" />
									<label
										className="btn btn-outline-secondary btn-outline"
										htmlFor="full-name">Full Name</label>
									<input
										type="radio"
										name="signature-type"
										id="first-name"
										autoComplete="off"
										checked={signatureType === 'firstName'}
										onChange={() => setSignatureType('firstName')}
										className="btn-check" />
									<label
										className="btn btn-outline-secondary btn-outline"
										htmlFor="first-name">First Name</label>
									<input
										type="radio"
										name="signature-type"
										id="no-signature"
										autoComplete="off"
										checked={signatureType === 'off'}
										onChange={() => setSignatureType('off')}
										className="btn-check" />
									<label
										className="btn btn-outline-secondary btn-outline"
										htmlFor="no-signature">Off</label>
								</div>
								<div className="signature-preview pt-1 pb-3">
									{signatureType === 'off' && (
										<span className="text-muted fst-italic signature-preview">No Signature{mode === 'edit' && ' (original signature left in message)'}</span>
									)}
									{signatureType !== 'off' && (
										<div>
											<span className="text-muted fw-bold">Signature: </span><span className="text-muted">{getSignature()}</span>
										</div>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<TranslateSelector
									availableLanguages={[SupportedLanguageEnum.English, SupportedLanguageEnum.Spanish]}
									fromLanguage={fromLanguage}
									toLanguage={toLanguage}
									onChange={handleLanguageChange}
								/>
							</div>
						</div>
						{fromLanguage !== toLanguage && (
							<div className="row">
								<div className="d-flex flex-row">
									<button
										type="button"
										disabled={!formik.values.message}
										onClick={handleTranslate}
										className="btn btn-secondary btn-sm">Preview Translation</button>
									<div className="d-inline-block bg-gray-200 mx-3 p-3 rounded-3 w-100">
										<span>{translatedMessage}</span>
									</div>
								</div>
								<span className="fst-italic text-muted">Text will be translated prior to sending without clicking Preview Translation</span>
							</div>
						)}
				</form>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex pb-0 flex-column justify-content-end">
					<div className="flex-column ms-auto">
						<button
							type="button"
							ref={showActionModalRef}
							className="d-none"
						>Show Modal
						</button>
						<button
							type="button"
							onClick={formik.submitForm}
							className="btn btn-primary d-flex align-items-center"
							disabled={!formik.touched || !formik.isValid || formik.isSubmitting || !formik.values.recognitionCategoryId }
						>
							Send
							{formik.isSubmitting && (
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							)}
						</button>
					</div>
					{!employee?.phone && (
						<div className="flex-column ms-auto">
							<span>Employee has not consented to text messages. Print certificate after saving.</span>
						</div>
					)
					}
				</div>
			</Modal.Footer>
		</Modal>
	);
}
