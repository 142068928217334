import React from 'react';
import { getTodaysTimeOrOlderDate } from '../../modules/date/utils/DateHelpers';
import { DisciplineStatusForHumansEnum, DisciplineStatusType } from '../../modules/discipline/models/Discipline.models';
import { NotificationType } from '../../types/NotificationType'
import moment from 'moment';
import './NotificationItem.scss'
import { toAbsoluteUrl } from '../../../_library/helpers'
import { DeleteIcon } from '../../../app/components/icons/DeleteIcon';

interface Props {
    notification: NotificationType
    deleteNotification: () => void;
}


function NotificationItem({
                              notification,
                              deleteNotification}: Props) {

    return (
        <div
            style={{ background: notification.isRead ? 'transparent' : '#FAFBFC' }}
            className='notification_wrapper d-flex align-items-center'
            title={notification.message}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
        >
            <div>
            <button
                className="btn btn-sm d-inline p-0 bg-hover-light-danger text-hover-danger"
                onClick={(e) => {
                    e.stopPropagation();
                    deleteNotification();
                }}
                >
                <DeleteIcon />
                </button>
            </div>
            <img
                className="notification_icon ms-4"
                width="48"
                src={notification.publisher?.avatarUrl ? notification.publisher?.avatarUrl : toAbsoluteUrl('/media/avatars/blank.png')}
                alt="icon"
            />
            <div className="notification_content d-flex flex-column me-5">
                <div className="notification_creator_name">
                    {notification.publisher?.firstName} {notification.publisher?.lastName}
                </div>
                <div className="notification_message">
                    {DisciplineStatusForHumansEnum[((notification.metaData as any)?.status as DisciplineStatusType)]}
                </div>
            </div>
            <div className="notification_action d-flex flex-column align-items-end ms-auto">
                <div className="notification_date">
                    {getTodaysTimeOrOlderDate(moment(notification.created_at).toDate())}
                </div>
                <span
                    style={{ background: notification.isRead ? 'transparent' : '#EB2525' }}
                    className="notification_mark"
                ></span>
            </div>
        </div>

    )
}

export { NotificationItem }
