import { BaseQueryMeta, BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import {
	RecognitionsGetAllApiRequestModel,
	Recognition,
	RecognitionCreate,
	RecognitionUpdate,
} from './interfaces/recognition.model';

export enum RecognitionTagTypes {
	Recognition = 'RECOGNITION',
}

export const recognitionSlice = createApi({
	reducerPath: 'recognitionApi',
	tagTypes: [RecognitionTagTypes.Recognition],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/recognitions`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getRecognitions: builder.query<ApiResponse<Recognition>, RecognitionsGetAllApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponse<Recognition>) => {
				return response;
				// return response.items;
			},
			extraOptions: {
				includeMeta: false,
				includeLinks: false,
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.items.map(({ id }) => ({
							type: RecognitionTagTypes.Recognition,
							id,
						} as const)),
						{
							type: RecognitionTagTypes.Recognition,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: RecognitionTagTypes.Recognition,
						id: 'LIST',
					},
				];
			},
		}),
		getOneRecognition: builder.query<Recognition, {id: number}>({
			query: (args: { id: number }) => {
				let url = `${args.id}`;
				return {
					url,
				};
			},
			transformErrorResponse: (response: { status: string | number }, meta, arg) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						{
							type: RecognitionTagTypes.Recognition,
							id: result.id,
						},
					];
				}
				return [];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		}),
		createRecognition: builder.mutation<Recognition, RecognitionCreate>({
		  query: (args) => ({
		    url: '',
		    method: 'post',
		    body: args,
		  }),
			// transformResponse: (),
		  // transformErrorResponse: (response: { status: string | number }) => response.status,
		  invalidatesTags: [
		    {
		      type: RecognitionTagTypes.Recognition,
		      id: 'LIST',
		    },
		  ],
		}),
		updateRecognition: builder.mutation<Recognition, RecognitionUpdate>({
		  query: (args) => ({
		    url: `${args.id}`,
		    method: 'PATCH',
		    body: omit(args, 'id'),
		  }),
			// transformResponse(baseQueryReturnValue: BaseQueryResult<BaseQuery>,
			// 									meta: BaseQueryMeta<BaseQuery>,
			// 									arg: QueryArg): Promise<ResultType> | ResultType {}
			// transformErrorResponse: (response: { status: string | number }) => response.status,
		  invalidatesTags: (result, error, arg) => {
		    if (result) {
		      return [
		        {
		          type: RecognitionTagTypes.Recognition,
		          id: 'LIST',
		        },
		        {
		          type: RecognitionTagTypes.Recognition,
		          id: arg.id,
		        },
		      ];
		    }
		    return [];
		  },
		}),
		deleteRecognition: builder.mutation<void, { id: number}>({
		  query: (args) => ({
		    url: `${args.id}`,
		    method: 'delete',
		  }),
		  transformErrorResponse: (response: { status: string | number }) => response.status,
		  invalidatesTags: (result, error, args) => {
		    return [
		      {
		        type: RecognitionTagTypes.Recognition,
		        id: 'LIST',
		      },
		      {
		        type: RecognitionTagTypes.Recognition,
		        id: args.id,
		      },
		    ];
		  },
		}),
	}),
});
