import { toAbsoluteUrl } from '../../_library/helpers';

interface Props {
	url: string | null | undefined;
	size?: AvatarSize;
	alt: string;
}

export enum AvatarSize {
	sm = 16,
	md = 32,
	lg = 64,
	xl = 96,
}

export function Avatar({ url, size = AvatarSize.lg, alt }: Props) {
	const getSize = () => {
		switch (size) {
			case AvatarSize.sm:
				return 16;
			case AvatarSize.md:
				return 32;
			case AvatarSize.lg:
				return 64;
			case AvatarSize.xl:
				return 96;
		}
	}

	const getUrlOrFallback = (src: string | null | undefined) => {
		if (!src || src.includes('media/icons/duotune/general/create-team-icon')) {
			return toAbsoluteUrl('/media/avatars/blank.png');
		}
		return src;
	}

	return (
			<img
				className='rounded-circle avatar'
				src={getUrlOrFallback(url)}
				alt='Avatar'
				aria-describedby={alt}
				width={getSize()}
				height={getSize()}
			/>
	)
}
