import { Icon } from './Icon';
import { XIcon } from './IconList';

interface Props {
	title?: string;
	alt?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export function CloseButtonIcon({
																	title = 'Close',
																	alt = 'Close',
																	size = 'md',
																}: Props) {
	return (
		<Icon
			type="svg"
			alt={alt}
			icon={XIcon}
			title={title}
			size="xl"></Icon>
	);
}
