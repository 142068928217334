import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import {
	CreateAttendanceRuleApiRequestModel,
	UpdateAttendanceRuleApiRequestModel,
	GetAttendanceRulesApiRequestModel,
	DeleteAttendanceRuleApiRequestModel,
	AttendanceRuleModel,
} from './interfaces/attendance-rule.model';

export enum AttendanceRuleTagTypes {
	AttendanceRules = 'ATTENDANCE_RULES',
}

export const attendanceRuleSlice = createApi({
	reducerPath: 'attendanceRuleApi',
	tagTypes: [AttendanceRuleTagTypes.AttendanceRules],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/attendance-rules`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getAttendanceRules: builder.query<AttendanceRuleModel[], GetAttendanceRulesApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponseWithoutPagination<AttendanceRuleModel>) => {
				return response.items;
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
			providesTags: (result, error, id) => {
				console.log(result);
				if (result) {
					return [
						...result.map(({ id }) => ({
							type: AttendanceRuleTagTypes.AttendanceRules,
							id,
						} as const)),
						{
							type: AttendanceRuleTagTypes.AttendanceRules,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: AttendanceRuleTagTypes.AttendanceRules,
						id: 'LIST',
					},
				];
			},
		}),

		createAttendanceRule: builder.mutation<AttendanceRuleModel, CreateAttendanceRuleApiRequestModel>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			transformResponse: (response: AttendanceRuleModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: [
				{
					type: AttendanceRuleTagTypes.AttendanceRules,
					id: 'LIST',
				},
			],
		}),
		updateAttendanceRule: builder.mutation<AttendanceRuleModel, UpdateAttendanceRuleApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			transformResponse: (response: AttendanceRuleModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: AttendanceRuleTagTypes.AttendanceRules,
							id: 'LIST',
						},
						{
							type: AttendanceRuleTagTypes.AttendanceRules,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteAttendanceRule: builder.mutation<void, DeleteAttendanceRuleApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: AttendanceRuleTagTypes.AttendanceRules,
						id: 'LIST',
					},
					{
						type: AttendanceRuleTagTypes.AttendanceRules,
						id: args.id,
					},
				];
			},
		}),
	}),
});
