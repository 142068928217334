import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../app/modules/auth';
import { Employee } from '../../app/modules/employee/models/Employee.model';
import { ModalDisplayMode } from '../../common/interfaces/modal.model';
import { Attendance } from './interfaces/attendance.model';

// TODO: figure out better way to set this for state vs action payloads
export interface AttendanceModalState {
	showModal: boolean;
	// TODO: as we implement more, these modes should be toggled
	// mode: ModalDisplayMode.Read | undefined;
	mode?: ModalDisplayMode;
	record?: Attendance;
	employee?: Employee;
	recordId?: number;
}

export interface AttendanceModalCreateState extends Pick<AttendanceModalState, 'employee'> {
	mode: ModalDisplayMode.Create;
	record?: never;
	employee: Employee;
}

export interface AttendanceModalReadState extends Pick<AttendanceModalState, 'record'> {
	mode: ModalDisplayMode.Read;
	recordId?: number;
	employee?: never;
}

export interface AttendanceModalUpdateState extends Pick<AttendanceModalState, 'record'> {
	mode: ModalDisplayMode.Update;
	recordId?: number;
	employee: Employee;
}

export interface AttendanceModalDeleteState extends AttendanceModalState {
	mode: ModalDisplayMode.Delete;
	record?: never;
	recordId: number;
	employee?: never;
}

export interface AttendanceModalPrintState extends Pick<AttendanceModalState, 'record'> {
	mode: ModalDisplayMode.Print;
	employee?: never;
}

export type AttendanceModalStateUnion =
	AttendanceModalCreateState
	| AttendanceModalReadState
	| AttendanceModalUpdateState
	| AttendanceModalDeleteState;

const initialState: AttendanceModalState = {
	showModal: false,
	mode: undefined,
	record: undefined,
	recordId: undefined,
	employee: undefined,
};

export const attendanceModalSlice = createSlice({
	name: 'attendanceModal',
	initialState,
	reducers: {
		show: (state, action: PayloadAction<Omit<AttendanceModalState, 'showModal'>>) => {
			let {
				mode,
				employee,
				record,
				recordId,
			} = action.payload;
			switch (mode) {
				case ModalDisplayMode.Read:
					state.record = record;
					break;
				case ModalDisplayMode.Update:
				case ModalDisplayMode.Create:
					state.record = undefined;
					state.recordId = undefined;
					break;
				case ModalDisplayMode.Delete:
					state.recordId = recordId;
					break;
			}
			state.mode = mode;
			state.employee = employee;
			state.showModal = true;
			if (recordId) {
				state.recordId = recordId;
			}
			if (!!record) {
				state.record = record;
			}
			return state;
		},
		hide: state => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(actionTypes.Logout, () => initialState);
	},
});
