import React, { FC } from 'react';
import { KTSVG } from '../../../helpers';
import { Link, useNavigate } from 'react-router-dom';
import './styles/search.scss';

const Search: FC = () => {
  const isMobile = window.innerWidth <= 767;
  const navigate = useNavigate();

  const handleIconClick = () => {
    if (isMobile) {
      navigate('/advanced-search');
    }
  }
  return (
    <>
      <div id='kt_header_search' className='d-flex'>
        <div
          className={`d-flex align-items-center ${isMobile ? 'mobile-icon-only' : ''}`}
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
          onClick={handleIconClick}
        >
          {isMobile ? (
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 me-3'
            />
          ) : (
            <Link
              className='search_text_wrapper btn btn-icon btn-active-light-primary w_263px h-50px justify-content-start'
              to='/advanced-search'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 me-3'
              />
              <span className='text-start'>Search...</span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export { Search };
