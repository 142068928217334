import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../_library/layout/core'
import { Overview } from './overview/Overview'
import { AccountHeader } from './AccountHeader'
import { Settings } from './settings/Settings'
import { Tickets } from './tickets/Tickets'
import { AddNewCard } from '../modals/AddNewCard'
import { AddNewAddress } from '../modals/AddNewAddress'
import { Coaching } from './coaching/Coaching'
import { Invite } from './invite/Invite'
import { Disciplines } from './disciplines/Disciplines'
import { Recognition } from './recognition/Recognition'
import { Attendances } from './attendances/Attendances'
import { ImportsPage } from './imports/ImportsPage';

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Account',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export function AccountPage() {
    return (
        <>
            <AccountHeader />
            <Routes>
                <Route path='/' element={<Navigate to='overview' />} />
                <Route path='overview' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Account Overview</PageTitle>
                        <Overview />
                    </>
                }>
                </Route>
                <Route path='/settings' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Account Settings</PageTitle>
                        <Settings />
                    </>
                }>
                </Route>
                <Route path='/imports' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Imports</PageTitle>
                        <ImportsPage />
                    </>
                }>
                </Route>
                <Route path='disciplines' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Discipline</PageTitle>
                        <Disciplines />
                    </>
                }>
                </Route>
                <Route path='attendances' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Attendances</PageTitle>
                        <Attendances />
                    </>
                }>
                </Route>
                <Route path='coaching' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Coaching</PageTitle>
                        <Coaching />
                    </>
                }>
                </Route>
                <Route path='recognition' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Recognition</PageTitle>
                        <Recognition />
                    </>
                }>
                </Route>
                <Route path='invite' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Invite</PageTitle>
                        <Invite />
                    </>
                }>
                </Route>
                <Route path='tickets' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Tickets</PageTitle>
                        <Tickets />
                    </>
                }>
                </Route>
                <Route path='/superadmin-account' element={<Navigate to='superadmin-account/overview' />} />
                <Route element={<Navigate to='overview' />} />
            </Routes>
            <AddNewCard />
            <AddNewAddress />
        </>
    )
}
