import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import {
	CreateCoachingCategoryApiRequestModel,
	UpdateCoachingCategoryApiRequestModel,
	GetCoachingCategoriesApiRequestModel,
	DeleteCoachingCategoryApiRequestModel,
	CoachingCategoryModel,
} from './interfaces/coaching-categories.model';

export enum CoachingCategoryTagType {
	CoachingCategory = 'COACHING_CATEGORY',
}

export const coachingCategorySlice = createApi({
	reducerPath: 'coachingCategoryApi',
	tagTypes: [CoachingCategoryTagType.CoachingCategory],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/coaching-categories`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getCoachingCategories: builder.query<CoachingCategoryModel[], GetCoachingCategoriesApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponseWithoutPagination<CoachingCategoryModel>) => {
				return response.items;
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.map(({ id }) => ({
							type: CoachingCategoryTagType.CoachingCategory,
							id,
						} as const)),
						{
							type: CoachingCategoryTagType.CoachingCategory,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: CoachingCategoryTagType.CoachingCategory,
						id: 'LIST',
					},
				];
			},
		}),

		createCoachingCategory: builder.mutation<CoachingCategoryModel, CreateCoachingCategoryApiRequestModel>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			transformResponse: (response: CoachingCategoryModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: [
				{
					type: CoachingCategoryTagType.CoachingCategory,
					id: 'LIST',
				},
			],
		}),
		updateCoachingCategory: builder.mutation<CoachingCategoryModel, UpdateCoachingCategoryApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			transformResponse: (response: CoachingCategoryModel) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: CoachingCategoryTagType.CoachingCategory,
							id: 'LIST',
						},
						{
							type: CoachingCategoryTagType.CoachingCategory,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteCoachingCategory: builder.mutation<void, DeleteCoachingCategoryApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: CoachingCategoryTagType.CoachingCategory,
						id: 'LIST',
					},
					{
						type: CoachingCategoryTagType.CoachingCategory,
						id: args.id,
					},
				];
			},
		}),
	}),
});
