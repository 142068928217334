import React, { FC } from "react";
import { HomeNavbar } from "../components/HomeNavbar";

import './styles/terms.scss'

const PolicyPage: FC = () => {

    const [navbarColor, setNavbarColor] = React.useState(0)

    const handleWindowScroll = () => {
        if (window.scrollY > 70) {
            setNavbarColor(1);
        } else {
            setNavbarColor(0)
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll);
    }, []);

    return (
        <div>
            <HomeNavbar backgroundOpacity={navbarColor} />
            <div className="terms_container">
                <h2 className="h2_font text-center mb-5">Privacy Policy</h2>

                <p className="body_text_font">
                    Thank you for visiting the Secchi website located at www.Secchi.com (the "Site"). The Site is an Internet property of Secchi ("Secchi," "we," "our" or "us"). This Secchi Privacy Policy ("Privacy Policy") covers our treatment of personal information and other information that we collect when you: (a) access or use the Site and/or Site Offerings; and/or (b) request to be contacted by us in connection with your use of, or desire to obtain, Secchi Services. Capitalized terms not defined herein shall have the meaning set forth in the Secchi Website Terms and Conditions ("Terms and Conditions"). IF YOU DO NOT AGREE TO TERMS OF THIS PRIVACY POLICY IN THEIR ENTIRETY, YOU MAY NOT ACCESS OR OTHERWISE USE THE SITE AND/OR SITE OFFERINGS.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Personal Information Collected
                </h3>
                <p className="body_text_font">
                    For the purposes of this Privacy Policy, "personal information" shall mean individually identifiable information from or about an individual. We collect personal information from you primarily when you complete a contact form in connection with your request to be contacted directly by Secchi with respect to your use of, or desire to obtain, the Secchi Services. The personal information that you must supply on the Site in order to complete a contact form may include, without limitation: (a) first and last name; (b) e-mail address; (c) telephone number; (d) company name (if applicable); (e) company size (if applicable); (f) any comments that you wish to submit; and (g) any and all other information requested on the applicable form. In addition, where you have otherwise entered into the applicable Services Agreement(s) with Secchi, you may be asked to enter your credit card information in order to make payment for the applicable Secchi Services.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Use of Personal Information
                </h3>
                <p className="body_text_font">
                    The personal information that you submit to us remains your property. We will never sell, rent, exchange or barter your personal information to or with any third party for financial gain or marketing purposes. Where you request to be contacted in connection with the Secchi Services, we use the personal information that you provide to: (a) respond to your questions or comments; (b) provide support for the Secchi Services; and (c) send you information, periodically, regarding the Secchi Services and/or Site Offerings.; (d) utilize a third party vendor for the detection of fraudulent sign-ups. In addition, we may file your questions or comments for future reference.
                </p>
                <p className="body_text_font">
                    When you sign up for an account with us, we ask for certain information like your contact details and billing information so we can communicate with you and so you can pay for our products and services. We also collect some information automatically, like your IP address, when you log in to your account or when your software application built on Secchi technology makes requests to our APIs. We use this to understand who is using our services and how, and to detect, prevent and investigate fraud, abuse, or security incidents. Secchi uses a third party vendor for the detection of fraudulent sign ups. Secchi may use automated decision making using a variety of signals derived from account activity to help identify and suspend accounts sending spam or engaged in other abusive or fraudulent activity. Holders of accounts suspended under these circumstances are notified of the suspension and given an opportunity to request a human review of the suspension decision.
                </p>
                <p className="body_text_font">
                    You also agree that we may contact you at any time, via e-mail, telephone or other means, with updates and/or any other information that we may deem appropriate for you to receive in connection with your continued use of the Secchi Services and to market and/or keep you informed of certain other Secchi products and/or services that we think might be of interest to you. If you wish to stop receiving future communications from us, please see the Opt-Out/Unsubscribe section below.
                </p>
                <p className="body_text_font">
                    We may permit our third-party agents, service providers and subcontractors to access your personal information, but they are only permitted to do so in connection with performing services for us and/or to operate the Site, Site Offerings and/or Secchi Services. They are not authorized to use your personal information for their own benefit.
                </p>
                <p className="body_text_font">
                    We reserve the right to release current or past personal information: (i) in the event that we believe that the Site, Site Offerings and/or Secchi Services is/are being or has/have been used in violation of the Terms and Conditions, applicable Services Agreements (including the MSA, EULA and Order Form) or to commit unlawful acts; (ii) if the information is subpoenaed; provided, however, that, where permitted by applicable law, we shall provide you with e-mail notice, and opportunity to challenge the subpoena, prior to disclosure of any personal information pursuant to a subpoena; or (iii) if we are sold, merged or acquired; provided, however, that if we are involved in a merger, acquisition or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on the Site of any change in ownership or uses of your personal information, as well as any choices that you may have regarding your personal information.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Credit Card Transactions
                </h3>
                <p className="body_text_font">
                    Secchi utilizes third party service providers to provide credit card payment processing services. If you choose to make a payment for Secchi Services by and through the Site, the payment information provided will either be collected directly by such third party service provider(s), or we will share that payment information with our contracted third party service provider(s). Secchi requires that its third party payment processing service provider(s) has/have in place privacy policies and practices consistent with this Privacy Policy; provided, however, that we cannot guarantee the privacy practices of our third party payment processing service provider(s).
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Social Media Sites
                </h3>
                <p className="body_text_font">
                    If you choose to interact with Secchi by and through any social media websites, you should be aware that the personal information that you submit by and through such social media websites can be read, collected or used by other users of these forums (depending on your privacy settings associated with such social media websites), and could be used to send you unsolicited messages or otherwise to contact you without your consent or desire. We are not responsible for the personal information that you choose to submit in these forums.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Cookies
                </h3>
                <p className="body_text_font">
                    When an end-user visits the Site, we send one (1) or more cookies and/or gif files (collectively, "Cookies") to assign an anonymous, unique identifier to the applicable end-user's computer or other device. A Cookie is a piece of data stored on your hard drive containing non-personally identifiable information about you. Cookies have many benefits to enhance your experience at the Site. To find out more about Cookies, please visit www.cookiecentral.com. We use Cookies to improve the quality of the Site and/or Site Offerings.
                </p>
                <p className="body_text_font">
                    Most Internet browsers are initially set up to accept Cookies, but you can reset your browser to refuse all Cookies or to indicate when a cookie is being sent. To disable and reject certain Cookies, follow the instructions associated with your Internet browser. Even in the case where a user rejects a Cookie, he or she may still use the Site.
                </p>


                <h3 className="h3_font mt-5 mb-3">
                    Behavioral Tracking/Do Not Track
                </h3>
                <p className="body_text_font">
                    Secchi does not place Cookies or other tracking technology on the Site that track end-users' activities after they leave the Site; provided, however, that third parties such as Google may engage in that form of behavioral tracking of end-users that visit the Site. End-users may be able to disable some or all of this tracking activity by utilizing the "Do Not Track" setting or similar options within most major Internet browsers; provided, however, that, to greatest extend permissible under applicable law, we are not responsible for the tracking practices of third parties in connection with the Site.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Third-Party Websites
                </h3>
                <p className="body_text_font">
                    This Site may contain links to third-party owned and/or operated websites including social media websites. We are not responsible for the privacy practices or the content of such websites. These third-party websites have separate privacy and data collection practices and we have no responsibility or liability relating to them.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Security
                </h3>
                <p className="body_text_font">
                    The privacy of your personal information is very important to us. We endeavor to safeguard and protect our end-users' personal information. When end-users make personal information available to us, their personal information is protected both online and offline (to the extent that we maintain any personal information offline). When our registration process asks end-users to submit sensitive information (such as credit card information), and when we transmit such sensitive information, that sensitive information is encrypted and is protected with SSL encryption software.
                </p>
                <p className="body_text_font">
                    Access to your personal information is strictly limited, and we take reasonable measures to ensure that your personal information is not accessible to the public. The servers that we store personal information in are kept in a secure physical environment. We also have security measures in place to protect the loss, misuse and alteration of personal information under our control.
                </p>
                <p className="body_text_font">
                    Please be advised, however, that while we take every reasonable precaution available to protect your data, no storage facility, technology, software, security protocols or data transmission over the Internet can be guaranteed to be 100% secure. Computer hackers may circumvent our security measures and gain access to certain portions of your personal information, and technological bugs, errors and glitches may cause inadvertent disclosures of your personal information; provided, however, that any attempt to breach the security of the network, our servers, databases or other hardware or software constitutes a crime punishable by law. For the reasons mentioned above, we cannot warrant that your personal information will be absolutely secure. Any transmission of data done on or through the Site is at your own risk.
                </p>
                <p className="body_text_font">
                    Only employees or third party agents who need personal information to perform a specific job function are granted access to our end-users' personal information. Employees not adhering to our internal privacy policies are subject to disciplinary action.
                </p>
                <p className="body_text_font">
                    In compliance with applicable federal and state laws, we shall notify you and any applicable regulatory agencies in the event that we learn of an information security breach with respect to your personal information. You will be notified via e-mail in the event of such a breach. Please be advised that notice may be delayed in order to address the needs of law enforcement, determine the scope of network damage, and to engage in remedial measures.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Minors
                </h3>
                <p className="body_text_font">
                    Visitors under eighteen (18) years of age (or the applicable age of majority, if greater than eighteen (18) years of age) are not permitted to use and/or submit their personal information at the Site. We do not knowingly solicit or collect information from visitors under eighteen (18) years of age (or the applicable age of majority, if greater than eighteen (18) years of age). We encourage parents and guardians to spend time online with their children and to participate and monitor the interactive activities of their children.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Opt-Out/Unsubscribe
                </h3>
                <p className="body_text_font">
                    To opt-out of receiving e-mail communications from us, you can follow the instructions at the end of the applicable e-mail message or email us at support@Secchi.com.
                </p>
                <p className="body_text_font">
                    Notwithstanding the foregoing, we may continue to contact you for purposes of communicating information relating to your request for Secchi Services, as well as to respond to any inquiry or request made by you. To opt-out of receiving Secchi Services-related and inquiry response-related messages from Secchi, you must cease requesting and/or utilizing the Secchi Services and cease submitting inquiries to Secchi, as applicable.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Deleting and Updating Your Information
                </h3>
                <p className="body_text_font">
                    At your request, we will inform you of what personal information we have on file for you. In addition, at your request, we will remove personal information that you have provided to us, or that we have collected. You may do so by contacting us via e-mail at: support@Secchi.com; provided, however, that we ask individuals to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others or would be extremely impractical (for instance, requests concerning information residing on backup tapes).
                </p>
                <p className="body_text_font">
                    Please be advised that deleting your personal information will limit our ability to contact you in connection with the Secchi Services. If you wish to be contacted by us in connection with the Secchi Services, you may not delete the personal information that we have on file for you.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Changes to this Privacy Policy
                </h3>
                <p className="body_text_font">
                    We reserve the right to change or update this Privacy Policy at any time by posting a notice on the Site that we are changing our Privacy Policy. If the manner in which we use personal information changes, we will notify users by: (a) sending the modified policy to our users via email; and/or (b) by any other reasonable means acceptable under applicable state and federal law. You will have a choice as to whether or not we use your information in this different manner and we will only use your information in this different manner where you opt-in to such use.
                </p>

                <h3 className="h3_font mt-5 mb-3">
                    Contact Us
                </h3>
                <p className="body_text_font">
                    If you have any questions about this Privacy Policy, please: (a) call us at: <b>PHONE NUMBER TO ADD</b> (b) email us at: support@Secchi.com
                </p>
            </div>
        </div>
    )
}

export { PolicyPage }