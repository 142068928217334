import axios from 'axios'
import { axiosTransformErrorResponseHandler } from '../../common/api/common.api';
import { UpdateCompanyModel } from '../types/CompanyType'

const API_URL = process.env.REACT_APP_API_URL

export const CREATE_COMPANY_URL = `${API_URL}/companies`
export const COMPANY_URL = `${API_URL}/companies`
export const SEND_INVITE_SUPERADMIN_URL = `${API_URL}/auth/invite-superadmin`
export const SEND_INVITE_SUPERVISOR_URL = `${API_URL}/auth/invite-supervisor`
export const SEND_INVITE_PROVISIONER_URL = `${API_URL}/auth/invite-provisioner`
export const UPLOAD_FILES_URL = `${API_URL}/companies`

/*
export function sendCode(email: string, token: string) {
  return axios({
    method: 'get',
    url: `${GET_CODE_URL}?email=${email}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function sendInvite( email: string, token: string ) {
  return axios({
    method: 'post',
    url: SEND_INVITE_URL,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: {
      "email": email
    }
  })
}
*/


export function createCompany(
    name: string,
    size: string,
    website: string,
    industry: string,
    role: string,
    country: string,
    documents: string[],
    attendanceType: string,
    performanceType: string,
    rollOver: string,
    token: string
) {
    const data = {
        "name": name,
        "size": size,
        "website": website,
        "industry": industry,
        "role": role,
        "country": country,
        "documentUrls": documents,
        "attendanceType": attendanceType,
        "performanceType": performanceType,
        "rollover": rollOver
    };

    return axios({
        method: 'post',
        url: CREATE_COMPANY_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function updateCompany(
    companyId: string,
    data: UpdateCompanyModel,
    token: string
  ) {
    return axios({
      method: 'patch',
      url: `${COMPANY_URL}/${companyId}`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: data
    })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error updating company settings'));
  }

export function updateRollover(companyId: string, rollover: number, token: string) {
    return axios({
        method: 'patch',
        url: `${COMPANY_URL}/${companyId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            rollover: rollover
        }
    })
}

export function sendSuperadminInvite(email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_INVITE_SUPERADMIN_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "email": email
        }
    })
}

export function sendSupervisorInvite(email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_INVITE_SUPERVISOR_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "email": email
        }
    })
}

export function sendProvisionerInvite(email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_INVITE_PROVISIONER_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "email": email
        }
    })
}

export function uploadFiles(companyId: number, files: File[], token: string) {
    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", file)
    })
    return axios({
        method: 'post',
        url: `${UPLOAD_FILES_URL}/${companyId}/documents`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}
