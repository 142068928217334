import { differenceInDays, format, formatRelative, parse } from 'date-fns';
import { isDate } from 'lodash';
import moment from "moment";
import {
  MONTH_NAME_WITH_SHORT_YEAR,
  SHORT_DATE_FOR_DATEPICKER, TIME_HOUR_MIN_AM_PM,
  US_FRIENDLY_DATE,
  YEAR_MONTH_SHORT,
} from '../DateFormat.const';

export const getRollingMonthAbbreviations = (numberOfMonths: number, appendYear: boolean) => {
    let currentDate = moment()
    const months = [];
    for (let i = 0; i < numberOfMonths; i++) {
      const monthAbbr = moment(currentDate).subtract(i, 'months').format(`MMM 'YY`);
      months.push(monthAbbr)
    }
    return months;
}

export const getMonthsForPeriod = (startDate: Date, endDate: Date, order: 'ASC' | 'DESC'): { startDate: Date, yearMonth: string, monthNameShortYear: string}[] => {
  const months = [];
  let oldestMonth = moment(startDate);
  let newestMonth = moment(endDate);

  // be sure we have it consistently going oldest to newest date for proper ordering
  if (oldestMonth > newestMonth) {
    oldestMonth = moment(endDate);
    newestMonth = moment(startDate);
  }

  for (let iterator = oldestMonth.startOf('month'); iterator <= newestMonth.startOf('month'); iterator.add(1, 'month')) {
    months.push(
      {
        startDate: iterator.toDate(),
        yearMonth: iterator.format(YEAR_MONTH_SHORT),
        monthNameShortYear: iterator.format(MONTH_NAME_WITH_SHORT_YEAR),
      }
    )
  }
  if (order === 'DESC') {
    months.reverse();
  }
  return months;
}

export const getRelativeDateInfo = (targetDate: Date, relativeTo: Date = new Date()) => {
  return {
    description: formatRelative(targetDate, relativeTo),
    differenceInDays: differenceInDays(targetDate, relativeTo),
  }
};

/**
 * @desc converts strings in standard YYYY-MM-DD format to a date object.
 * Warning: coerces date strings as a result of postgres/typeorm handling of date (YYYY-MM-DD fields), which returns
 * them as strings when read, while taking them as dates when written:
 * https://github.com/typeorm/typeorm/issues/2176 (issue still open 5 years later in May 2023)
 * @param date 'YYYY-MM-DD' string to parse, or date (if already set to it)
 */
export const parseYmdDatesToDateObject = (date: string | Date): Date => {
  if (date === null) {
    throw new Error('Unable to parse null into date object (expected YYYY-MM-DD format string)');
  }
  if (isDate(date)) {
    return date;
  }
  return parse(date as string, SHORT_DATE_FOR_DATEPICKER, new Date());
};

export const parseDateObjectToNewFormat = (
  date: Date,
  newFormat: string,
): string => {
  return format(date, newFormat);
};

export const getTodaysTimeOrOlderDate = (date: Date, olderDateFormat = US_FRIENDLY_DATE): string =>{
  const dateInMoment = moment(date);

  if (dateInMoment.isAfter(moment().startOf('days'))) {
    return dateInMoment.format(TIME_HOUR_MIN_AM_PM);
  }
  return dateInMoment.format(olderDateFormat);

}
