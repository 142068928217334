import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { Login } from './components/Login'
import { LoginWithInvite } from './components/LoginWithInvite'
import './AuthPage.scss'
import leftBackground from '../../../images/auth/login_background.png'
import simpleLogo from '../../../images/logos/pure_simple_logo.png'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='auth_container d-flex flex-lg-row flex-column'>
      <div className="mobile_logo_container d-lg-none d-md-flex align-items-center justify-content-center">
        <img src={simpleLogo} alt="Logo" className="img_mobile_logo" />
      </div>
      <div className="auth_left d-none d-lg-flex align-items-center justify-content-center">
        <img src={leftBackground} alt="background" />
        <img src={simpleLogo} alt="Logo" className="img_large_logo" />
      </div>
      <div className="auth_right d-flex flex-grow-1 align-items-center justify-content-center">
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/login-with-invite' element={<LoginWithInvite />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/resetpassword' element={<ResetPassword />} />
          <Route path='*' element={<Navigate to='login' />} />
        </Routes>
      </div>
    </div>
  )
}
