import React, { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { FallbackView } from '../../_library/partials'
import { LeaderboardDashboard } from '../modules/leaderboard/pages/LeaderboardDashboard';
import { NotificationLengthEnum } from '../modules/notification/Notification.const';
import { MasterLayout } from '../../_library/layout/MasterLayout'
import { SupervisorDashboard } from '../pages/dashboard/SupervisorDashboard';
import { CreateTeam } from '../pages/team/components/CreateTeam'
import { EditTeam } from '../pages/team/components/EditTeam'
import { VerifyEmail } from '../modules/auth/components/VerifyEmail'
import { UserModel } from '../models'
import { RootState } from '../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { sendCode } from '../modules/auth/redux/AuthCRUD'
import { TeamMemberView } from '../pages/team/TeamMemberView'
import { AccountPage as SuperAdminAccountPage } from '../pages/superadmin/account/AccountPage'
import { SupervisorAccountPage } from '../pages/supervisor/SupervisorAccountPage'
import { EditUser } from '../pages/provisioner/EditUser'
import { AdvancedSearch } from '../pages/advanced_search/AdvancedSearch'
import { useIdleTimer } from "react-idle-timer";
import * as auth from '../modules/auth/redux/AuthRedux'
import { toast } from 'react-toastify';
import './PrivateRoutes.scss'
import { ConnectionsList } from '../pages/connections/ConnectionsList';

export function PrivateRoutes() {
  const SuperAdminDashboard = lazy(() => import('../pages/dashboard/SuperAdminDashboard'))
  const TeamMemberList = lazy(() => import('../pages/team/TeamMemberList'))
  const Users = lazy(() => import('../pages/provisioner/Users'))
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const isEmailVerified: boolean = user.isEmailVerified
  const isSetOnboarding: boolean = !!user.company ? user.company.rollover > 0 : false

  const redirectUrl: string = !!user.roles?.includes('SuperAdmin') || !!user.roles?.includes('Supervisor') ? '/dashboard'
    : !!user.roles?.includes('Provisioner') && !user.roles?.includes('SuperAdmin') ? '/users' : ''

  if (!isEmailVerified) {
    sendCode(user.email, token)
      .then(() => {
        console.log('Sent successfully')
      })
      .catch(() => {
        console.log('Bad email or Unauthorized')
      })
  }

  const notify = () => {
    toast.error('Logged out due to inactivity', {
      position: "top-center",
      theme: 'colored',
      autoClose: NotificationLengthEnum.ExtraLong,
    })
  }

  const onIdle = () => {
    dispatch(auth.actions.logout());
    notify();
    // remove backdrop/modal so user doesn't have to refresh to log in after idle kick-out. Having to put in a
    // timeout as the "old modals" need this patch, but the new system via ModalManager.tsx will break the app if
    // this runs before their state triggers them to close.
    setTimeout(() => {
      document.querySelector('body')?.classList.remove('modal-open');
      document.querySelector('.modal-backdrop')?.remove();
    }, 0);
  }

  useIdleTimer({
    onIdle,
    // in ms: 15 minute timeout
    timeout: 900000,
    throttle: 500,
    eventsThrottle: 500,
  })

  return (
  <>
    {/*<ModalManager />*/}
      {
        !isEmailVerified &&
        <Suspense
          fallback={<FallbackView />}
        >
          <Routes>
            <Route
              path='/verify'
              element={<VerifyEmail />}
            />
            <Route path="*" element={<Navigate to="/verify" />} />
          </Routes>
        </Suspense>
      }
      {
        isEmailVerified && isSetOnboarding &&
        <MasterLayout>
          <Suspense
            fallback={<FallbackView />}
          >
            <Routes>
              {
                user.roles?.includes('SuperAdmin') &&
                (
                  <>
                    <Route
                      path='/superadmin-account/*'
                      element={<SuperAdminAccountPage />}
                    />
                    <Route
                      path='/dashboard'
                      element={<SuperAdminDashboard />}
                    />
                    <Route
                      path='/leaderboards'
                      element={<LeaderboardDashboard />}
                    />
                  </>
                )
              }
              {
                user.roles?.includes('Supervisor') && (
                  <>
                    <Route
                      path='/supervisor-account/*'
                      element={<SupervisorAccountPage />}
                    />
                    <Route
                      path='/dashboard'
                      element={<SupervisorDashboard />}
                    />
                  </>
                )
              }
              {(user.roles?.includes('SuperAdmin') || user.roles?.includes('Supervisor')) && (
                <>
                  <Route path='/connections/:tab' element={<ConnectionsList />} />
                  <Route path='/advanced-search' element={<AdvancedSearch />} />
                  <Route path='/teams/create' element={<CreateTeam />} />
                  <Route path='/teams/show/:id/*' element={<TeamMemberList />} />
                  <Route path='/teams/edit/:id' element={<EditTeam />} />
                  <Route path='/team-member/:teamId/show/:id' element={<TeamMemberView />} />
                </>
              )}
              {user.roles?.includes('Provisioner') && (
                <>
                  <Route
                    path='/users'
                    element={<Users />}
                  />
                  <Route
                    path='/edit-user/:userId'
                    element={<EditUser />}
                  />
                </>
                )
              }
              <Route path='/auth/*' element={<Navigate to={redirectUrl} />} />
              <Route path='/onboarding' element={<Navigate to={redirectUrl} />} />
              <Route path='/verify' element={<Navigate to={redirectUrl} />} />
              <Route path='/' element={<Navigate to={redirectUrl} />} />
              <Route element={<Navigate to='error/404' />} />
            </Routes>
          </Suspense>
        </MasterLayout>
      }
    </>
  )
}
