import axios from 'axios'
import { DisciplineModel } from '../models'

const API_URL = process.env.REACT_APP_API_URL

export const DISCIPLINES_URL = `${API_URL}/disciplines`

// Disciplines
export function createDiscipline(
  data: DisciplineModel,
  token: string
) {
  return axios({
    method: 'post',
    url: DISCIPLINES_URL,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  })
}

export function getDisciplines(limit: number, teamId: number, employeeId: number, token: string) {
  return axios({
    method: 'get',
    url: `${DISCIPLINES_URL}?limit=${limit}&teamId=${teamId}&employeeId=${employeeId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function getOneDiscipline(disciplineId: number, token: string) {
  return axios({
    method: 'get',
    url: `${DISCIPLINES_URL}/${disciplineId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function updateDiscipline(disciplineId: number, data: DisciplineModel, token: string) {
  return axios({
    method: 'patch',
    url: `${DISCIPLINES_URL}/${disciplineId}`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: data
  })
}

export function removeDiscipline(disciplineId: number, token: string) {
  return axios({
    method: 'delete',
    url: `${DISCIPLINES_URL}/${disciplineId}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
}
