import axios, { AxiosRequestConfig } from 'axios';
import { defer, from, map, Observable } from 'rxjs';
import { CompanyLeaderboard } from "../models/RecognitionLeaderboard.model";
// import { KpiModel, KpiGetParamsModel } from '../models/kpi.model';

const API_URL = process.env.REACT_APP_API_URL;

export function getCompanyLeaderboards(companyId: string,
																			 token: string,
																			 opts: any): Observable<CompanyLeaderboard> {
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	// setup this way to correctly type the response
	return from(defer(() => {
		return axios.get<any>(`${API_URL}/kpi/company/${companyId}/leaderboards`, config)
								.catch(val => val.response);
	}))
		.pipe(
			map(res => res.data),
			map(res => {
				if (res.error) {
					throw new Error(res.message || `Error retrieving company Leaderboards`);
				}
				return res;
			})
		);
}

// export function getTeamKpis(teamId: number,
// 														token: string,
// 														opts: KpiGetParamsModel): Observable<KpiModel> {
// 	const config: AxiosRequestConfig = {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 		params: opts,
// 	};
// 	// setup this way to correctly type the response
// 	return from(defer(() => {
// 		return axios.get<KpiModel>(`${API_URL}/kpi/team/${teamId}`, config)
// 								.catch(val => val.response);
// 	}))
// 		.pipe(
// 			map(res => res.data),
// 			map(res => {
// 				if (res.error) {
// 					throw new Error(res.message || `Error retrieving team KPI's`);
// 				}
// 				return res;
// 			})
// 		);
// }
