import { DisciplineStepTypeEnum } from './DisciplineStep.models';
import { DisciplineTemplate } from './DisciplineTemplate.model';

export const templatesList: DisciplineTemplate[] = [
	{
		id: 1,
		type: DisciplineStepTypeEnum.Attendance,
		category: 'Attendance',
		template: {
			incidentDetails: `{employeeFullName} is being issued a {currentDisciplineStepName} for violation of company policy, excessive absenteeism/tardiness. {employeeFirstName} had an attendance occurrence on {latestAttendanceDate} which put him/her at a total point value of {currentAttendancePoints}. {employeeFirstName} needs to seek immediate improvement in his/her attendance in order to maintain satisfactory standing within the Company.`,
			expectations: `It is expected that {employeeFirstName} avoid attendance occurrences by being on time to work, by working the duration of the shift, and by attending work on all scheduled days. It is {employeeFirstName}’s responsibility to manage his/her attendance points and keep them below 8 points in order to maintain his/her employment with the Company.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements on his/her attendance, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Attendance',
	},
	{
		id: 2,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Safety',
		template: {
			incidentDetails: `On {currentDisciplineDate}, {employeeFullName} was witnessed near {locationName} viewing, listening to music, and/or using his/her cell phone while on company time. This is a direct violation of the Company’s cell phone policy & procedures.`,
			expectations: `{employeeFirstName}, it is expected that you keep your cell phone stowed away during work hours. It is prohibited to answer your cell phone, listen to music, and/or view content on the cell phone screen while you are working on the workshop floor as this action is distracting and creates a safety hazard. An employee is only allowed to use his/her cell phone during breaktimes in designated break areas.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Cell Phone Policy Violation',
	},
	{
		id: 3,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Production/Quality',
		template: {
			incidentDetails: `On {currentDisciplineDate}, it was identified that {employeeFullName} has illustrated the following unsatisfactory performance:\n\n{Insert itemized list of occurrences: date, time, and length of gap time as applicable}\n\nWhen {employeeFirstName} was questioned by the supervisor as to why he/she had unacceptable levels of gap time, he/she failed to provide a reasonable explanation. The Company considers this misuse of company time and unsatisfactory behavior.`,
			expectations: `{employeeFirstName}, it is expected that you remain focused and on task at all times while on paid Company time. Any gap time in excess of 10 minutes will be questioned and could be subject to documented corrective action. Should you encounter a problem relating to the work task that you cannot find a viable solution for, you are to seek the assistance of your lead or supervisor. You are to avoid any behaviors that could be a distraction to your work.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\n Should {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Poor Productivity/Gap Times',
	},
	{
		id: 4,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Unsatisfactory Conduct',
		template: {
			incidentDetails: `{employeeFullName}, on {currentDisciplineDate}, you took an extended break. Your break should have been {Insert length of break} minutes long, but instead you took {Insert length of actual break} minutes. This behavior is misuse of paid company time and will not be tolerated.`,
			expectations: `{employeeFirstName}, it is expected that you stay within the perimeters of scheduled breaktimes as allocated by the Company. You are not to exceed these times. If you have a personal situation that you need to take care of, which might cause a delay in your return to workstation, you are to notify your supervisor in advance and seek prior approval.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Taking long breaks',
	},
	{
		id: 5,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Unsatisfactory Conduct',
		template: {
			incidentDetails: `{Date}\n\n{Location}\n\n{Details of incident}`,
			expectations: `{employeeFullName}, it is expected that when you are given a legitimate work order from a lead, supervisor, or manager that you try to complete that work task to the best of your ability. Employees do not have the right to refuse to perform a legitimate work instruction, as long as the work task is safe. This is considered a form of insubordination and will not be tolerated.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Failure to follow instructions/Insubordination',
	},
	{
		id: 6,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Unsatisfactory Conduct',
		template: {
			incidentDetails: `{Date}\n\n{Location}\n\n{Details of incident/unsatisfactory conduct; only insert facts}`,
			expectations: `{employeeFullName}, it is expected that you make immediate improvements to your behavior and adhere to the Company's Code of Conduct at all times. {Describe how employee's behavior violates our expectations and how he/she should behave differently}`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\n Should {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Unsatisfactory Conduct',
	},
	{
		id: 7,
		type: DisciplineStepTypeEnum.Performance,
		// TODO: object of performance category
		category: 'Production/Quality',
		template: {
			incidentDetails: `{employeeFullName}’s average for {Choose Efficiency or Quality} during {describe period of time} was at {List Percent}%. This average is too low and is unacceptable according to Company standards.`,
			expectations: `{employeeFirstName}, it is expected that your {Period of Time} average be up to {Percent}% by {Date}. If you have any specific questions as to how you may reach this goal, please consult with your lead, your trainer, or your supervisor for techniques to improve. It is your responsibility to stay focused on the task at hand and make progressive steps to improve your performance levels.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\n Should {employeeFirstName} have any questions about how to make improvements, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Unsatisfactory Performance: Poor Efficiency or Quality',
	},
	{
		id: 8,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Attendance',
		template: {
			incidentDetails: `{employeeFullName} is being issued a {currentDisciplineStepName} for violation of company policy, excessive absenteeism/tardiness. {employeeFirstName} had an attendance occurrence on {latestAttendanceDate} which put him/her at a total point value of {currentAttendancePoints}. {employeeFirstName} needs to seek immediate improvement in his/her attendance in order to maintain satisfactory standing within the Company.`,
			expectations: `It is expected that {employeeFirstName} avoid attendance occurrences by being on time to work, by working the duration of the shift, and by attending work on all scheduled days. It is {employeeFirstName}’s responsibility to manage his/her attendance points and keep them below 8 points in order to maintain his/her employment with the Company.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements on his/her attendance, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Attendance',
	},
	{
		id: 9,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Safety',
		template: {
			incidentDetails: `The following safety incident occurred on {Date} near {Location}\n\n{Insert details around incident}\n\n{List previous trainings, as applicable}\n\nThis safety incident is a direct violation of Company policy. {employeeFullName} should seek to correct his/her behaviors immediately.`,
			expectations: `{employeeFirstName}, you should seek to comply with the Company's safety policies and procedures at all times. {Insert description of behaviors that the employee could have done better}`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements on his/her attendance, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Safety',
	},
	{
		id: 10,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Safety',
		template: {
			incidentDetails: `As a normal part of {employeeFullName}'s daily job duties, he/she is expected to pick up trash, pallet pieces, sweep, and perform other general housekeeping duties in order for {employeeFirstName}, as well as other employees, to stay productive performing duties that are considered value-added. Housekeeping is also important to maintain safety standards. Employees are to follow the "clean as you go" principle. {employeeFirstName} was found not participating in these activities. His/Her area had poor housekeeping which the company considers a safety hazard.`,
			expectations: `{employeeFirstName}, it is expected that you make immediate improvements to your housekeeping efforts. The company follows a "clean as you go" practice. By picking up trash, broken pallets, shrink wrap, sweeping, and other general housekeeping activities, employees are ensuring a safe and orderly work environment. Additionally, housekeeping is a generally responsible way to manage one's time. It is expected that {employeeFirstName} change his/her behavior and conform to the company's "clean as you go" practices.`,
			consequences: `Further abuse of this policy will result in further disciplinary action, up to and including termination.\n\nShould {employeeFirstName} have any questions about how to make improvements on his/her attendance, he/she is welcome to speak his/her supervisor or reach out to HR.`,
		},
		name: 'Housekeeping',
	},
	{
		id: 11,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Code of Conduct/Ethics',
		template: {
			incidentDetails: ``,
			expectations: ``,
			consequences: ``,
		},
		name: 'Custom',
	},
	{
		id: 12,
		type: DisciplineStepTypeEnum.Attendance,
		category: 'Attendance',
		template: {
			incidentDetails: ``,
			expectations: ``,
			consequences: ``,
		},
		name: 'Custom',
	},
	{
		id: 13,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Production/Quality',
		template: {
			incidentDetails: ``,
			expectations: ``,
			consequences: ``,
		},
		name: 'Custom',
	},
	{
		id: 14,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Safety',
		template: {
			incidentDetails: ``,
			expectations: ``,
			consequences: ``,
		},
		name: 'Custom',
	},
	{
		id: 15,
		type: DisciplineStepTypeEnum.Performance,
		category: 'Unsatisfactory Conduct',
		template: {
			incidentDetails: ``,
			expectations: ``,
			consequences: ``,
		},
		name: 'Custom',
	},
];
