import { useState, useEffect, ChangeEvent, useRef, Ref } from 'react';
import InlineEdit from '@atlaskit/inline-edit';

interface Props {
	initialValue: string;
	onSave: (value: string) => void;
	onCancel: () => void;
	onClick: () => void;
	isEditing: boolean;
	type: 'input' | 'textarea';
}

export const SkeTextInlineEditor = ({
																			initialValue,
																			onSave,
																			onCancel,
																			onClick,
																			type,
																			isEditing,
																		}: Props) => {
	const [originalValue, setOriginalValue] = useState(initialValue);
	const [tempValue, setTempValue] = useState(initialValue);
	const inputRef = useRef<HTMLInputElement|HTMLTextAreaElement>(null);
	const prevIsEditingRef = useRef<boolean>();

	useEffect(() => {
		setOriginalValue(initialValue);
		setTempValue(initialValue);
	}, [initialValue]);

	// used to trigger focus when edit mode is enabled as autofocus was triggering the confirm handler and auto-saving
	// the result, throwing an error due to API constraints for supervisors
	useEffect(() => {
		if (!prevIsEditingRef.current && isEditing) {
			inputRef?.current?.focus();
			prevIsEditingRef.current = true;
		}
		prevIsEditingRef.current = isEditing;
	}, [isEditing]);

	const handleConfirm = () => {
		console.log('handleConfirm triggered');
		setOriginalValue(tempValue);
		onSave(tempValue);
	};

	const handleCancel = () => {
		console.log('cancel clicked');
		setTempValue(originalValue);
		onCancel();
	};

	/* not using their fieldProps on editView callback from the example
	 https://atlassian.design/components/inline-edit/examples that can be passed in here. That was throwing a lot of
	  React errors (due to re-use of standard HTML attributes)  and could be an XSS issue due to passing all params
	   without validation. Simple use case right now,  keeping it limited */
	return (
		<InlineEdit
			isEditing={isEditing}
			defaultValue={initialValue}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			// so it doesn't trigger confirm/save when clicking outside and needing to handle if no changes are made and
			// it causes issues
			keepEditViewOpenOnBlur={true}
			readView={() => (
				<div className="cursor-pointer cursor-pointer"
						 onClick={onClick}>
					{originalValue || 'None'}
				</div>
			)}
			editView={() => (
				<>
					{type === 'input' && (
						<input value={tempValue}
										 ref={(inputRef as Ref<HTMLInputElement>)}
										 onChange={(e: ChangeEvent<HTMLInputElement>) => setTempValue(e.target.value)}
						/>
					)}
					{type === 'textarea' && (
						<textarea value={tempValue}
										 ref={(inputRef as Ref<HTMLTextAreaElement>)}
										 onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setTempValue(e.target.value)}
						/>
					)}
				</>
			)}
		/>
	);
};
