import { attendanceReasonsSlice } from '../../../../../features/attendance/attendance-reasons.api'
import { AttendanceReason } from '../../../../modules/attendance/models/AttendanceReason.model';
import { useState } from 'react'
import { toast } from 'react-toastify'
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'


interface Props {
    setShowPage: (page: string) => void;
    setEditReason: (reason: AttendanceReason) => void;
}

export default function ReasonsList({ setShowPage, setEditReason }: Props) {
    const { data: reasons, refetch } = attendanceReasonsSlice.
    useGetAttendanceReasonQuery({ isArchived: false});

    const [deleteReason] = attendanceReasonsSlice.useDeleteAttendanceReasonMutation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedReason, setSelectedReason] = useState<number | null>(null);


    const handleEditReason = (reason: AttendanceReason) => {
        setEditReason(reason)
        setShowPage('edit')
    };

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedReason !== null) {
          deleteReason({ id: selectedReason })
            .unwrap()
            .then(() => {
                successToast();
                refetch();
            })
            .catch(err => {
                console.log(err);
                errorToast();
            });
        }
      };


    const removeSelectedReason = (reasonId: number) => {
        setShowDeleteDialog(true);
        setSelectedReason(reasonId);
    }

    const handleRemoveReason = (reason: AttendanceReason) => {
    removeSelectedReason(reason.id);
    }


    return (
        <>
        <div>
            <div className="d-flex">
                <button
                    type='button'
                    className='link-primary btn btn_add mt-2 px-0'
                    onClick={() => setShowPage('create')}
                >
                    + Add New Attendance Reason
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th className='fw-bolder'>Reason Name</th>
                        <th className='fw-bolder'>Is Excused</th>
                        <th className='fw-bolder'>FMLA</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {reasons?.map((reason, index) => (
                    <tr className='action_line' key={index}>
                        <td className='action_name'>{reason.content}</td>
                        <td className='action_name'>{reason.isExcused ? 'Excused' : 'Unexcused'}</td>
                        <td className='action_name'>{reason.is_fmla ? 'Yes' : 'No'}</td>
                        <td className="action_buttons">
                            <span className="btn_edit ms-3"
                                onClick={() => handleEditReason(reason)}
                            >
                                <i className="fas fa-edit text-primary fs-4"></i>
                            </span>
                            <span className="btn_remove ms-3"
                                onClick={() => handleRemoveReason(reason)}
                            >
                                <i className="fas fa-trash text-danger fs-4"></i>
                            </span>
                        </td>
                    </tr>
                )
                )}
                </tbody>
            </table>
        </div>
        {showDeleteDialog && (
            <SkeDialogDelete
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                successMessage='Attendance reason deleted'
                message='Are you sure you want to delete this attendance reason?'
            />
            )}
        </>
    )
}
