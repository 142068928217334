import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageTitle } from '../../../_library/layout/core';
import { SkeBeta } from '../../../common/components/beta';
import { ConnectionTypeEnumWithCa } from '../../../features/connection/interfaces/connection.model';
import { ConnectionsGrid } from '../../../features/connection/pages/ConnectionsGrid';
import { isAiReviewEnabled } from '../../../features/settings/utils/settings.util';
import { RootState } from '../../../setup';
import { UserModel } from '../../models';
import AttendancesList from './attendance/AttendancesList';
import CoachingsList from './coaching/CoachingsList';
import DisciplinesList from './discipline/DisciplinesList';
import { RecognitionsList } from './recognition/RecognitionsList';

type RouteParams = {
	tab?: string;
	type?: string;
}


export function ConnectionsList() {
	const {tab} = useParams<RouteParams>();
	const [activeTab, setActiveTab] = useState<string>(tab || 'attendance');
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [tabs, setTabs] = useState<string[]>([
		'all',
		'recognition',
		// 'review',
		'coaching',
		'attendance',
		'corrective-action']);

	useEffect(() => {
		if (tab) {
			setActiveTab(tab);
		}
	}, [tab]);

	const betaTabs = ['review'];

	useEffect(() => {
		if (!!user && isAiReviewEnabled(user)) {
			setTabs([
				'all',
				'recognition',
				'review',
				'coaching',
				'attendance',
				'corrective-action']);
		}
	}, [user]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Connections</PageTitle>
			<div>
				<ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
					{tabs.map(tabName => (
						<li
							className="nav-item"
							key={tabName}>
							<NavLink
								to={`../${tabName}`}
								relative="path"
								className={({
															isActive,
															isPending,
														}) =>
									clsx('nav-link',
										{
											pending: isPending,
											active: isActive || tabName === activeTab,
										},
									)}>
								{tabName === 'corrective-action' && 'Corrective Action'}
								{tabName !== 'corrective-action' && tabName.charAt(0).toUpperCase() + tabName.slice(1)}
								{betaTabs.includes(tabName) && (
									<>
										<SkeBeta />
									</>
								)}
							</NavLink>
						</li>
					))}
				</ul>
				<div
					className="tab-content"
					id="connectionsTabContent">
					{activeTab === 'all' && <ConnectionsGrid />}
					{activeTab === 'review' && (
						<>
							<ConnectionsGrid
								connectionType={[ConnectionTypeEnumWithCa.Review]}
							/>
						</>
					)}
					{activeTab === 'attendance' && <AttendancesList />}
					{activeTab === 'recognition' && <RecognitionsList />}
					{activeTab === 'coaching' && <CoachingsList />}
					{activeTab === 'corrective-action' && <DisciplinesList />}
				</div>
			</div>
		</>
	);
};
