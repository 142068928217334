import {ReactNode} from 'react';

interface Props {
	title?: string;
	children: ReactNode;
}
export function SkeLegal({ title, children }: Props) {
	return (
		<div>
			<br></br>
			<h1>{title}</h1>
			<p className="font-monospace">
				{children}
			</p>
		</div>
	);
}