import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../app/modules/auth';
import { Employee } from '../../app/modules/employee/models/Employee.model';
import { ModalDisplayMode } from '../../common/interfaces/modal.model';
import { Discipline } from './interfaces/discipline.model';

// TODO: figure out better way to set this for state vs action payloads
export interface DisciplineModalState {
	showModal: boolean;
	// TODO: as we implement more, these modes should be toggled
	// mode: ModalDisplayMode.Read | undefined;
	mode?: ModalDisplayMode;
	record?: Discipline;
	employee?: Employee;
	recordId?: number;
	mutationRan?: boolean;
}

export interface DisciplineModalCreateState extends Pick<DisciplineModalState, 'employee'> {
	mode: ModalDisplayMode.Create;
	record?: never;
	employee: Employee;
}

export interface DisciplineModalReadState extends Pick<DisciplineModalState, 'record'> {
	mode: ModalDisplayMode.Read;
	recordId?: number;
	employee?: never;
}

export interface DisciplineModalUpdateState extends Pick<DisciplineModalState, 'record'> {
	mode: ModalDisplayMode.Update;
	recordId?: number;
	employee: Employee;
}

export interface DisciplineModalDeleteState extends DisciplineModalState {
	mode: ModalDisplayMode.Delete;
	record?: never;
	recordId: number;
	employee?: never;
}

export interface DisciplineModalPrintState extends Pick<DisciplineModalState, 'record'> {
	mode: ModalDisplayMode.Print;
	employee?: never;
}

export type DisciplineModalStateUnion =
	DisciplineModalCreateState
	| DisciplineModalReadState
	| DisciplineModalUpdateState
	| DisciplineModalDeleteState;

const initialState: DisciplineModalState = {
	showModal: false,
	mode: undefined,
	record: undefined,
	recordId: undefined,
	employee: undefined,
	mutationRan: false,
};

export const disciplineModalSlice = createSlice({
	name: 'disciplineModal',
	initialState,
	reducers: {
		show: (state, action: PayloadAction<Omit<DisciplineModalState, 'showModal'>>) => {
			let {
				mode,
				employee,
				record,
				recordId,
			} = action.payload;
			switch (mode) {
				case ModalDisplayMode.Read:
					state.record = record;
					break;
				case ModalDisplayMode.Update:
				case ModalDisplayMode.Create:
					state.record = undefined;
					state.recordId = undefined;
					break;
				case ModalDisplayMode.Delete:
					state.recordId = recordId;
					break;
			}
			state.mode = mode;
			state.employee = employee;
			state.showModal = true;
			if (recordId) {
				state.recordId = recordId;
			}
			if (!!record) {
				state.record = record;
			}
			state.mutationRan = false;
			return state;
		},
		hide: (state, {payload}) => {
			return {
				...initialState,
				mutationRan: payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(actionTypes.Logout, () => initialState);
	},
});
