import { useState } from 'react'
import { AttendanceReasonModel } from '../../../../../features/attendance/interfaces/attendance-reason.model';
import { CreateReason } from './CreateReason'
import { EditReason } from './EditReason'
import ReasonsList from './ReasonsList'

export default function AttendanceReasons() {
    const [showPage, setShowPage] = useState('list')
    const [editReason, setEditReason] = useState<AttendanceReasonModel | null>(null)
    return (
        <>
            {showPage === 'list' && <>
                <div className='min-h-300px'>
                    <ReasonsList setShowPage={setShowPage} setEditReason={setEditReason} />
                </div>
            </>}
            {showPage === 'create' && <CreateReason setShowPage={setShowPage} />}
            {showPage === 'edit' && !!editReason && <EditReason setShowPage={setShowPage} editReason={editReason} />}
        </>
    )
}
