import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { DeleteIcon } from '../../../../app/components/icons/DeleteIcon';
import { Icon } from '../../../../app/components/icons/Icon';
import { Warning } from '../../../../app/components/icons/IconList';
import { activitySlice } from '../../activity.api';
import { ActivityModel } from '../../interfaces/activity.model';

export function AlertContainer() {
	const [show, setShow] = useState(true);
	const [alertsToShow, setAlertsToShow] = useState<ActivityModel[]>([]);
	const { data: alerts, isSuccess: activitiesSuccess, isLoading: activitiesLoading } = activitySlice.usePublicActivitiesQuery()
	useEffect(() => {
		if (activitiesSuccess) {
			setAlertsToShow(alerts);
		}
	}, [alerts, activitiesLoading, activitiesSuccess]);

	const handleSuppressAlert = (index: number) => setAlertsToShow(prevState => prevState.toSpliced(index, 1));

	return (
		<>
			<div className="position-absolute top-0 z-index-3 w-100">
				{alertsToShow?.map((activity, idx) => {
					return (
						<Alert
							key={idx}
							show={show}
							variant="danger"
							className="py-0 my-0 w-100">
							<div className="d-flex justify-content-between align-middle">
								<div>
									<Icon
										classes="text-danger d-inline-block"
										type={Warning.iconType}
										size="sm"
										icon={Warning}
									/>
									<p className="text-center p-0 m-2 fw-bolder d-inline-block">
										<i className="bi bi-warning-fill fs-7"></i>
										{activity.message}
									</p>
								</div>
								<button
									className="btn btn-sm d-inline p-0 bg-hover-light-danger text-hover-danger ms-auto align-middle"
									onClick={() => handleSuppressAlert(idx)}
								>
									<DeleteIcon />
								</button>
							</div>
						</Alert>
					);
				})
				}
			</div>
			{!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
		</>
	);
}
