import React from 'react';
import { SHORT_DATE, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import {
	Discipline,
	DisciplineStatusEnum,
	DisciplineStatusForHumansEnum,
} from '../../../modules/discipline/models/Discipline.models';
import moment from 'moment';
import {ConnectionDetails, VwConnection} from '../../../../features/connection/interfaces/vw_connection.model';
import {useDispatch} from 'react-redux';
import {disciplineModalSlice} from '../../../../features/discipline/discipline-modal.slice';
import {ModalDisplayMode} from '../../../../common/interfaces/modal.model';

interface Props {
	correctiveAction: Discipline;
	showDate?: boolean;
	rxKey: string | number;
	onClick?: (att: Discipline) => void;
	onActionClick?: (att: Discipline, action: 'delete') => void;
	record?: Discipline;
}

export function ConnectionHistoryCorrectiveActionRow({
																											 correctiveAction,
																											 showDate = true,
																											 rxKey,
																											 onActionClick,
																											 onClick,
																											 record,
																										 }: Props) {
	const dispatch = useDispatch();

	let statusCell;
	switch (correctiveAction.status) {
		case DisciplineStatusEnum.Draft:
		case DisciplineStatusEnum.RequestedChange:
		case DisciplineStatusEnum.RequestedApproval:
		case DisciplineStatusEnum.Approved:
		case DisciplineStatusEnum.IssuedToEmployee:
		case DisciplineStatusEnum.SubmittedToHr:
		case DisciplineStatusEnum.ReceivedByHr:
			statusCell = (
				<span className="fw-light badge badge-light-danger text-dark rounded" >
					{DisciplineStatusForHumansEnum[correctiveAction.status]}
				</span>
			);
			break;
		case DisciplineStatusEnum.DisciplineDeclined:
		case DisciplineStatusEnum.DocumentationFiled:
			statusCell = (
				<span className="fw-light badge bg-light text-dark rounded">
					{DisciplineStatusForHumansEnum[correctiveAction.status]}: {correctiveAction.closed_at};
				</span>
			);
			break;
		default:
			statusCell = null;
			break;
	}

	return (
		<tr
			className="cursor-pointer"
			onClick={() => {
				if(record){
					dispatch(disciplineModalSlice.actions.show({
						mode: ModalDisplayMode.Read,
						recordId: record.id,
					}));
				} else {
					if (onClick) {
						onClick(correctiveAction);
					}
				}
			}}
			key={rxKey}>
			<td>{showDate && moment(correctiveAction.date, SHORT_DATE).format(US_FRIENDLY_DATE)}</td>
			<td>
				<span className="me-2 fs-7 fw-bold badge-light-danger px-3 rounded text-uppercase text-nowrap">
						Corrective Action
				</span>
			</td>
			<td className="d-flex flex-column">
				{statusCell}
			</td>
			<td className="text-break">
				{correctiveAction.supervisorNote &&
					<span>{correctiveAction.supervisorNote}</span>
				}
				{!correctiveAction.supervisorNote &&
					<span className="fst-italic">No note</span>
				}
			</td>
			<td>{correctiveAction.creator?.firstName} {correctiveAction.creator?.lastName}</td>
			<td className="d-flex">
				<button
					className="btn btn-sm ms-16"
					onClick={(e) => {
						if (onActionClick) {
							e.stopPropagation();
							onActionClick(correctiveAction, 'delete');
						}
					}}
				><i className="fas fa-trash text-danger fs-4 "></i></button>
			</td>
		</tr>
	);
}
