import React, { FC } from "react";
import { HomeNavbar } from "../components/HomeNavbar";

import './styles/terms.scss'

const TermsPage: FC = () => {

    const [navbarColor, setNavbarColor] = React.useState(0)

    const handleWindowScroll = () => {
        if (window.scrollY > 70) {
            setNavbarColor(1);
        } else {
            setNavbarColor(0)
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll);
    }, []);

    return (
        <div>
            <HomeNavbar backgroundOpacity={navbarColor} />
            <div className="terms_container">
                <h2 className="h2_font text-center">Terms and Conditions</h2>
            </div>
        </div>
    )
}

export { TermsPage }