import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionTypes } from '../../../app/modules/auth';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import { AiPerformanceReviewModel } from './interfaces/ai.performance.review.model';

export interface AiReviewModalState {
	showModal: boolean;
	token: string;
	employeeId?: number;
	mode?: ModalDisplayMode;
	record?: Omit<AiPerformanceReviewModel, 'ai_response' | 'data' | 'subject_id'>;
	recordId?: number;
	mutationRan?: boolean;
}

export interface AiReviewModalCreateState extends Pick<AiReviewModalState, 'employeeId'> {
	mode: ModalDisplayMode.Create;
	record?: never;
	employeeId: number;
}

export interface AiReviewModalReadState extends Pick<AiReviewModalState, 'record' | 'token'> {
	mode: ModalDisplayMode.Read;
	recordId?: number;
	employeeId?: number;
}

export interface AiReviewModalUpdateState extends Pick<AiReviewModalState, 'record'> {
	mode: ModalDisplayMode.Update;
	recordId?: number;
	employeeId: number;
}

export interface AiReviewModalDeleteState extends AiReviewModalState {
	mode: ModalDisplayMode.Delete;
	record?: never;
	recordId?: number;
	employee?: never;
}

export interface AiReviewModalPrintState extends Pick<AiReviewModalState, 'record'> {
	mode: ModalDisplayMode.Print;
	employee?: never;
}

export type AiReviewModalStateUnion =
	AiReviewModalCreateState
	| AiReviewModalReadState
	| AiReviewModalUpdateState
	| AiReviewModalDeleteState;

const initialState: AiReviewModalState = {
	showModal: false,
	mode: undefined,
	token: '',
	record: undefined,
	recordId: undefined,
	employeeId: 0,
	mutationRan: false,
};

export const aiReviewModalSlice = createSlice({
	name: 'aiReviewModal',
	initialState,
	reducers: {
		show: (state, action: PayloadAction<Omit<AiReviewModalState, 'showModal'>>) => {
			let {
				mode,
				token,
				employeeId,
				record,
				recordId,
			} = action.payload;
			switch (mode) {
				case ModalDisplayMode.Read:
					state.record = record;
					break;
				case ModalDisplayMode.Update:
				case ModalDisplayMode.Create:
					state.record = undefined;
					state.recordId = undefined;
					break;
				case ModalDisplayMode.Delete:
					state.recordId = recordId;
					break;
			}
			state.mode = mode;
			state.employeeId = employeeId;
			state.token = token;
			state.showModal = true;
			if (recordId) {
				state.recordId = recordId;
			}
			if (!!record) {
				state.record = record;
			}
			state.mutationRan = false;
			return state;
		},
		hide: (state, {payload}) => {
			return {
				...initialState,
				mutationRan: payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(actionTypes.Logout, () => initialState);
	},
});
