import clsx from 'clsx';
import { ReactNode } from 'react';
import './Link.scss';

interface Props {
	type: 'internal' | 'external';
	label: string;
	classes?: string;
	icon?: ReactNode;
	children?: ReactNode | ReactNode[];
	url: string;
	desc?: string;
}

export function Link({
											 children,
											 url,
											 classes,
											 icon,
											 type,
											 label,
											 desc,
										 }: Props) {

	return (
		<>
			<a
				target={type === 'external' ? '_blank' : undefined}
				className={clsx('ske-link m-3 d-flex align-items-center', classes)}
				aria-label={type === 'external' ? `${desc}, opens in new tab` : desc}
				title={desc}
				href={url}>
				{label}
				{children}
			</a>
		</>
	);
}
