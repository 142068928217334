import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/react';
import { AxiosError, isAxiosError } from 'axios';
import { get, isArray, isString, isUndefined, map, toLower } from 'lodash';
import { toast } from 'react-toastify';

// to be used in the prepareHeaders on CreateApi
export const getAndSetBearerToken = (headers: Headers, api: Pick<BaseQueryApi, 'getState'>): Headers => {
	const state: any = api.getState();
	const { accessToken } = state.auth;
	if (headers.has('Authorization')) {
		// public routes
		if (headers.get('Authorization') === 'None') {
			headers.delete('Authorization');
		}
	} else if (accessToken) {
		headers.set('Authorization', `Bearer ${accessToken}`);
	}

	return headers;
}

export const extractReadableApiError = (err: Error | AxiosError, defaultMsg: string, silent = false): string => {
	if (!silent) {
		console.error(`Handling error response from API call`, err);
	}
	if (isUndefined(err)) {
		return defaultMsg;
	}
	// default Axios error handler says something like "Request failed with response code 400" rather than a nice
	// message provided
	// response.data.message is how axios relays back. data.message is how Redux relays back
	const messages = (isAxiosError(err)) ? get(err, 'response.data.message') : get(err, 'data.message');
	if (isString(messages)) {
		return suppressGenericServerErrors(messages, defaultMsg);
	} else if (isArray(messages) && isString(messages[0])) {
		return suppressGenericServerErrors(messages[0], defaultMsg);
	}
	return defaultMsg;
};

export const suppressGenericServerErrors = (apiError: string, defaultError: string): string => {
	return genericServerErrorMessages.includes(toLower(apiError)) ? defaultError : apiError;
}

// TODO: add where options could be configured on a per-route basis using RTK API options
export const reduxTransformErrorResponseHandler = (response: BaseQueryError<any>, defaultMessage: string) => {
	// TODO: silent in env and place do the console.error in here, not the hoist
	toast.error(extractReadableApiError(response, defaultMessage));
	throw new Error(response);
};

export const skeResponseApiUtilErrorHandler = (response: Omit<SkeResponseApiFormat<void>, 'results'>, defaultMessage: string) => {
	// TODO: silent in env and place do the console.error in here, not the hoist
	toast.error(response.msg ||defaultMessage);
	return response;
};

export const axiosTransformErrorResponseHandler = (error: AxiosError, defaultMessage: string, setError?: (msg: string)=>void) => {
	// TODO: silent in env and place do the console.error in here, not the hoist
	const errorMessage = extractReadableApiError(error, defaultMessage)
	toast.error(errorMessage);
	if(setError)setError(errorMessage)
	throw errorMessage;
};

/**
 * So we can use defaultMessage above rather than a generic, unhelpful message
 * from: node_modules/@nestjs/common/exceptions in the api app
 */
export const defaultApiErrorMessages: string[] = [
	'Internal Server Error',
	'Bad Request',
	'Conflict',
	'Forbidden',
	'HTTP Version Not Supported',
	'Method Not Allowed',
	'Not Acceptable',
	'Not Found',
	'Not Implemented',
	'Payload Too Large',
	'Precondition Failed',
	'Unauthorized',
	'Unprocessable Entity',
	'Unsupported Media Type',
];

/**
 * Split out so we can say there's an issue communicating with the server, rather than some other issue
 * from: node_modules/@nestjs/common/exceptions in the api app
 */
export const apiServerConnectionErrorMessages: string[] = [
	'Bad Gateway',
	'Gateway Timeout',
	'Gone',
	'Misdirected',
	'Request Timeout',
	'Service Unavailable',
];

export const genericServerErrorMessages: string[] = [
	...map(defaultApiErrorMessages, toLower),
	...map(apiServerConnectionErrorMessages, toLower),
]

// for endpoints using the new SkeResponse utility class
export interface SkeResponseApiFormatWithoutResults {
	// API format
	// results?: {
	// 	item?: any;
	// 	items?: any[];
	// 	[key: string]: any | any[];
	// };
	error: boolean;
	success: boolean;
	msg: string;
	issues: {
		warnings: {
			codes: [];
			msg: '';
			msgs: [];
			items: [];
		};
		errors: {
			codes: [];
			msg: '';
			msgs: [];
			items: [];
		};
	}
}

export interface SkeResponseApiFormat<T> extends SkeResponseApiFormatWithoutResults {
	results: T;
}
