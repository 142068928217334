import BoxArrowUpRight from '../../../images/icons/box-arrow-up-right.svg';
import x from '../../../images/connections/x.svg';
import funnelFill from '../../../images/icons/funnel-fill.svg';
import download from '../../../images/icons/download.svg';
import geoAlt from '../../../images/icons/geo-alt.svg';
import people from '../../../images/icons/people.svg';
import folderClosed from '../../../images/icons/folder2.svg';
import folderOpened from '../../../images/icons/folder2-open.svg';
import infoCircle from '../../../images/icons/info-circle.svg';
import translate from '../../../images/icons/translate.svg';
import caretDown from '../../../images/icons/caret-down.svg';
import caretRight from '../../../images/icons/caret-right.svg';
import caretUp from '../../../images/icons/caret-up.svg';
import caretLeft from '../../../images/icons/caret-left.svg';
import clock from '../../../images/icons/clock.svg';
import personBadge from '../../../images/icons/person-badge.svg';
import trash3 from '../../../images/icons/trash3.svg';
import pencil from '../../../images/icons/pencil.svg';
import cake from '../../../images/icons/cake.svg';
import anniversary from '../../../images/icons/bootstrap/envelope-open-heart.svg';
import warning from '../../../images/icons/exclamation-triangle.svg';
import bell from '../../../images/icons/bell.svg';
import bellFill from '../../../images/icons/bell-fill.svg';
import tools from '../../../images/icons/tools.svg';
import printer from '../../../images/icons/printer.svg';
import printerFill from '../../../images/icons/printer-fill.svg';
import refresh from '../../../images/icons/arrow-clockwise.svg';
import search from '../../../images/icons/search.svg';
import binoculars from '../../../images/icons/binoculars.svg';
import binocularsFill from '../../../images/icons/binoculars-fill.svg';
import floppy from '../../../images/icons/floppy.svg';
import floppyFill from '../../../images/icons/floppy-fill.svg';
import building from '../../../images/icons/bootstrap/building.svg';
import buildingFill from '../../../images/icons/bootstrap/building-fill.svg';
import openai from '../../../images/icons/openai.svg';
import clipboardPlus from '../../../images/icons/clipboard-plus.svg';

export interface IconBase {
	iconType: 'svg' | 'font';
}

export interface SvgIcon extends IconBase {
	src: string;
	iconType: 'svg';
	element: 'img' | 'svg';
}

export const ArrowUpRightBoxIcon: SvgIcon = {
	src: BoxArrowUpRight,
	iconType: 'svg',
	element: 'svg',
};

export const XIcon: SvgIcon = {
	src: x,
	iconType: 'svg',
	element: 'svg',
};

export const Filter: SvgIcon = {
	src: funnelFill,
	iconType: 'svg',
	element: 'svg',
};

export const Download: SvgIcon = {
	src: download,
	iconType: 'svg',
	element: 'svg',
};

export const Geo: SvgIcon = {
	src: geoAlt,
	iconType: 'svg',
	element: 'svg',
};

export const Cake: SvgIcon = {
	src: cake,
	iconType: 'svg',
	element: 'svg',
};

export const Anniversary: SvgIcon = {
	src: anniversary,
	iconType: 'svg',
	element: 'svg',
};

export const People: SvgIcon = {
	src: people,
	iconType: 'svg',
	element: 'svg',
};

export const FolderClosed: SvgIcon = {
	src: folderClosed,
	iconType: 'svg',
	element: 'svg',
};

export const FolderOpened: SvgIcon = {
	src: folderOpened,
	iconType: 'svg',
	element: 'svg',
};

export const Clock: SvgIcon = {
	src: clock,
	iconType: 'svg',
	element: 'svg',
};

export const InfoCircle: SvgIcon = {
	src: infoCircle,
	iconType: 'svg',
	element: 'svg',
};

export const Translate: SvgIcon = {
	src: translate,
	iconType: 'svg',
	element: 'svg',
};

export const CaretDown: SvgIcon = {
	src: caretDown,
	iconType: 'svg',
	element: 'svg',
};

export const CaretRight: SvgIcon = {
	src: caretRight,
	iconType: 'svg',
	element: 'svg',
};

export const CaretUp: SvgIcon = {
	src: caretUp,
	iconType: 'svg',
	element: 'svg',
};

export const CaretLeft: SvgIcon = {
	src: caretLeft,
	iconType: 'svg',
	element: 'svg',
};

export const PersonBadge: SvgIcon = {
	src: personBadge,
	iconType: 'svg',
	element: 'svg',
};

export const Trash: SvgIcon = {
	src: trash3,
	iconType: 'svg',
	element: 'svg',
};

export const Pencil: SvgIcon = {
	src: pencil,
	iconType: 'svg',
	element: 'svg',
};

export const Warning: SvgIcon = {
	src: warning,
	iconType: 'svg',
	element: 'svg',
};

export const Bell: SvgIcon = {
	src: bell,
	iconType: 'svg',
	element: 'svg',
};

export const BellFill: SvgIcon = {
	src: bellFill,
	iconType: 'svg',
	element: 'svg',
};

export const Tools: SvgIcon = {
	src: tools,
	iconType: 'svg',
	element: 'svg',
};

export const Printer: SvgIcon = {
	src: printer,
	iconType: 'svg',
	element: 'svg',
};

export const PrinterFill: SvgIcon = {
	src: printerFill,
	iconType: 'svg',
	element: 'svg',
};

export const Refresh: SvgIcon = {
	src: refresh,
	iconType: 'svg',
	element: 'svg',
};

export const Search: SvgIcon = {
	src: search,
	iconType: 'svg',
	element: 'svg',
};

export const Binoculars: SvgIcon = {
	src: binoculars,
	iconType: 'svg',
	element: 'svg',
};

export const BinocularsFill: SvgIcon = {
	src: binocularsFill,
	iconType: 'svg',
	element: 'svg',
};

export const Save: SvgIcon = {
	src: floppy,
	iconType: 'svg',
	element: 'svg',
};

export const SaveFill: SvgIcon = {
	src: floppyFill,
	iconType: 'svg',
	element: 'svg',
};

export const Building: SvgIcon = {
	src: building,
	iconType: 'svg',
	element: 'svg',
};

export const BuildingFill: SvgIcon = {
	src: buildingFill,
	iconType: 'svg',
	element: 'svg',
};

export const OpenAI: SvgIcon = {
	src: openai,
	iconType: 'svg',
	element: 'svg',
};


export const ClipboardPlus: SvgIcon = {
	src: clipboardPlus,
	iconType: 'svg',
	element: 'svg',
};
