import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { KTSVG } from '../../../_library/helpers';
import { RootState } from '../../../setup';
import { UserModel } from '../../models';
import './DropdownActions.scss';

interface Props {
	viewModalId?: string;
	editModalId?: string;
	handleViewRecord?: () => void;
	handleEditRecord?: () => void;
	handleSelectedIndex?: () => void;
	handleSelectedConnection?: () => void;
	removeSelectedConnection?: () => void;
}

export function DropdownUserActions({
																					viewModalId,
																					handleViewRecord,
																					editModalId,
																					handleEditRecord,
																					handleSelectedIndex,
																					handleSelectedConnection,
																					removeSelectedConnection,
																				}: Props) {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		let abort = new AbortController();

		document.body.addEventListener('click', () => {
			setShowMenu(false);
		});

		return () => {
			abort.abort();
		};
	}, []);

	return (
		<div className="d-flex flex-shrink-0 actions_wrapper">
			<button
				type="button"
				className="btn btn-light btn-sm ms-auto"
				onClick={(e) => {
					if (handleSelectedIndex) {
						handleSelectedIndex();
					}
					if (handleSelectedConnection) {
						handleSelectedConnection();
					}
					setShowMenu(true);
					e.stopPropagation();
				}}
				data-kt-menu-placement="right-start"
			> Actions
				<KTSVG
					path="/media/icons/duotune/arrows/arr072.svg"
					className="svg-icon-3"
				/>
			</button>
			{showMenu && (
				<div
					className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-125px"
					data-kt-menu="true"
				>
					<div className="menu-item px-3">
						<a
							href="#"
							className="menu-link px-3"
							data-bs-toggle={viewModalId ? 'modal': null}
							data-bs-target={viewModalId ? `#${viewModalId}` : null}
							onClick={() => !!handleViewRecord && handleViewRecord()}
						>
							View
						</a>
					</div>

					{(user.roles?.includes('Supervisor') || user.roles?.includes('SuperAdmin')) && (
						<div className="menu-item px-3">
							<a
								href="#"
								className="menu-link px-3"
								data-bs-toggle={editModalId ? 'modal': null}
								data-bs-target={editModalId ? `#${editModalId}` : null}
								onClick={() => !!handleEditRecord && handleEditRecord()}
							>
								Edit
							</a>
						</div>
					)}

					{user.roles?.includes('SuperAdmin') && (
						<div className="menu-item px-3">
							<a
								href="#"
								className="menu-link px-3 text-danger"
								onClick={() => {
									if (removeSelectedConnection) {
										removeSelectedConnection();
									}
								}}
							>
								Delete
							</a>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
