import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { recognitionModalSlice } from '../../../../features/recognition/recognition-modal.slice';
import { TranslateIcon } from '../../../components/icons/TranslateIcon';
import { SHORT_DATE, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { getNameFromLanguageAbbr } from '../../../modules/translation/services/Translation.service';

interface Props {
	recognition: Recognition;
	showDate?: boolean;
	rxKey: string | number;
}

export function ConnectionHistoryRecognitionRow({
																									recognition,
																									showDate = true,
																									rxKey,
																								}: Props) {
	let wasTranslated = false;
	let { sourceLanguage, translatedLanguage } = recognition;
	if (sourceLanguage && translatedLanguage) {
		wasTranslated = true;
		sourceLanguage = getNameFromLanguageAbbr(sourceLanguage);
		translatedLanguage = getNameFromLanguageAbbr(translatedLanguage);
	}
	const dispatch = useDispatch();

	return (
		<tr
			className="cursor-pointer"
			onClick={() => {
				dispatch(recognitionModalSlice.actions.show({
					mode: ModalDisplayMode.Read,
					record: recognition,
				}))
			}}
			key={rxKey}>
			<td>{showDate && moment(recognition.date, SHORT_DATE).format(US_FRIENDLY_DATE)}</td>
			<td>
				<span className="me-2 fs-7 fw-bold badge-light-success px-3 rounded">
						{recognition.connectionType}
				</span>
			</td>
			<td>{recognition.category?.name}</td>
			<td className="text-break">
				{recognition.message}
				{wasTranslated && (
					<>
						<span className="fst-italic d-block mt-2">
							<TranslateIcon
								title={`Recognition was translated from ${sourceLanguage} to ${translatedLanguage}`}
								alt="Icon indicating content was translated"
								size="sm"
							/>
							Translated from {sourceLanguage} to {translatedLanguage}
						</span>
					</>
				)}
			</td>
			<td>{recognition.creator?.firstName} {recognition.creator?.lastName}</td>
			<td className="d-flex">
				<button
					className="btn btn-secondary btn-active-light-primary btn-sm"
					onClick={(e) => {
						e.stopPropagation();
						dispatch(recognitionModalSlice.actions.show({
							mode: ModalDisplayMode.Update,
							record: recognition,
						}));
					}}
				><i className="bi bi-pencil-square"></i>
				</button>
				<button
					className="btn btn-sm"
					onClick={(e) => {
						e.stopPropagation();
						dispatch(recognitionModalSlice.actions.show({
							mode: ModalDisplayMode.Delete,
							recordId: recognition.id,
						}));
					}}
				><i className="fas fa-trash text-danger fs-4"></i></button>
			</td>
		</tr>
	);
}
