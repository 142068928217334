import axios from 'axios'
import { CoachingModel } from '../models'

const API_URL = process.env.REACT_APP_API_URL

export const COACHING_URL = `${API_URL}/coachings`

export function createCoaching(coaching: CoachingModel, token: string) {
    return axios({
        method: 'post',
        url: COACHING_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: coaching
    })
}

export function getCoachings(token: string) {
    return axios({
        method: 'get',
        url: COACHING_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

export function updateCoaching(coachingId: number, coaching: CoachingModel, token: string) {
    return axios({
        method: 'patch',
        url: `${COACHING_URL}/${coachingId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: coaching
    })
}

export function removeCoaching(coachingId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${COACHING_URL}/${coachingId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}
