import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CoachingEditModal } from '../../../../app/pages/connections/coaching/CoachingEditModal';
import { CoachingViewModal } from '../../../../app/pages/connections/coaching/CoachingViewModal';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { Logger } from '../../../log/components/Logger';
import { SkeDomain } from '../../../log/ske-exception';
import { coachingModalSlice, CoachingModalState, CoachingModalStateUnion } from '../../coaching-modal.slice';
import { coachingSlice } from '../../coaching.api';

interface Props {
	state: CoachingModalStateUnion & Pick<CoachingModalState, 'showModal' | 'recordId'>;
}

export function CoachingLayout({state}: Props) {
	const dispatch = useDispatch();
	const [deleteCoaching] = coachingSlice.useDeleteCoachingMutation();
	const coachingViewModalRef = useRef<HTMLButtonElement>(null);
	const coachingEditModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<CoachingModalStateUnion>();
	const [getOneCoachingFilter, setGetOneCoachingFilter] = useState<{id: number}>();
	const {
		data: coaching,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = coachingSlice.useGetOneCoachingQuery(getOneCoachingFilter || skipToken);

	useEffect(() => {
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					coachingViewModalRef.current?.click();
					break;
				case ModalDisplayMode.Update:
					coachingEditModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!coaching && coaching.id === state.recordId) {
			dispatch(coachingModalSlice.actions.show({
				...state,
				record: coaching,
			}));
		}
	}, [coaching, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneCoachingFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(coachingModalSlice.actions.hide());
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteCoaching({id: state.recordId})
			.unwrap()
			.then(() => {
				successToast();
				dispatch(coachingModalSlice.actions.hide());
			})
			.catch(() => {
				errorToast();
				dispatch(coachingModalSlice.actions.hide());
			});
	};

	useEffect(() => {
		Logger('CoachingState change detected', SkeDomain.Coaching, {misc: {state}});
	}, [state]);

	return (
		<>
			<button
				className="d-none"
				ref={coachingViewModalRef}
				data-bs-toggle="modal"
				data-bs-target="#connections-coaching-view">
			</button>
			<button
				className="d-none"
				ref={coachingEditModalRef}
				data-bs-toggle="modal"
				data-bs-target="#connections-coaching-edit">
			</button>
			{state.record && state.mode === ModalDisplayMode.Read &&
				<CoachingViewModal
					coaching={state.record}
					modalId="connections-coaching-view"
					onClose={() => dispatch(coachingModalSlice.actions.hide())}
				/>
			}
			{state.record && state.mode === ModalDisplayMode.Update &&
				<CoachingEditModal
					editCoaching={state.record}
					modalId="connections-coaching-edit"
					refresh={() => dispatch(coachingModalSlice.actions.hide())}
					onClose={() => dispatch(coachingModalSlice.actions.hide())}
				/>
			}
			{state.mode === ModalDisplayMode.Delete &&
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage="Coaching deleted"
					message="Are you sure you want to delete this?"
				/>
			}
		</>
	);
}
