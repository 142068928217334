import { ProfileDetails } from './components/ProfileDetails'
import { SmsSettings } from './components/SmsSettings'
import { Notifications } from './components/Notifications'

export function Settings() {
    return (
        <div className="settings_container">
            <ProfileDetails />
            <SmsSettings />
            <Notifications />
        </div>
    )
}
