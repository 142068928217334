import React from 'react'
import { DisciplineStepType } from '../../../../types/DisciplineStepType'
import { DisciplineStepsList } from './DisciplineStepsList'
import { CreateDisciplineStep } from './CreateDisciplineStep'
import { EditDisciplineStep } from './EditDisciplineStep'
import DisciplineCategories from './DisciplineCategories';
import { CategoryModel } from '../../../../../features/category/interfaces/category.model'

export function Disciplines() {
    const [editStep, setEditStep] = React.useState<DisciplineStepType | null>(null);
    const [activeTab, setActiveTab] = React.useState('disciplineSteps');
    const [activeDisciplineTab, setActiveDisciplineTab] = React.useState('list');
    const [categories, setCategories] = React.useState<CategoryModel[]>([]);

    const setShowPage = (page: string) => {
      setActiveDisciplineTab(page);
    };

  return (
    <div className="card">
      <div className="card-body py-10">
        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
          <li className="nav-item cursor-pointer">
            <a
              className={`nav-link ${activeTab === 'disciplineSteps' ? 'active' : ''}`}
              onClick={() => setActiveTab('disciplineSteps')}
            >
              Steps
            </a>
          </li>
          <li className="nav-item cursor-pointer">
            <a
              className={`nav-link ${activeTab === 'disciplineCategories' ? 'active' : ''}`}
              onClick={() => setActiveTab('disciplineCategories')}
            >
              Categories
            </a>
          </li>
        </ul>
        <div className="tab-content" id="DisciplineTabContent">
          <div className={`tab-pane fade ${activeTab === 'disciplineSteps' ? 'active show' : ''}`} role="tabpanel">
            <div className="step_wrapper_3 min-h-300px">
              {activeDisciplineTab === 'list' && <DisciplineStepsList setShowPage={setShowPage} setEditStep={setEditStep} />}
              {activeDisciplineTab === 'create' && <CreateDisciplineStep setShowPage={setShowPage} />}
              {activeDisciplineTab === 'edit' && !!editStep && <EditDisciplineStep setShowPage={setShowPage} editStep={editStep} />}
            </div>
          </div>
            <div className={`tab-pane fade ${activeTab === 'disciplineCategories' ? 'active show' : ''}`} role="tabpanel">
                <DisciplineCategories
                  categories={categories}
                  setCategories={setCategories}
                />
            </div>
        </div>
      </div>
    </div>
  );
}
