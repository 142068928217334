import SocketIOClient from 'socket.io-client';

export class SocketService {
	private socket: any;
	// @ts-ignore
	private endpoint: string = process.env.REACT_APP_SERVER_WEBSOCKET_ENDPOINT;
	private isRegistered: boolean = false;

	constructor(private token: string) {
		this.initSocket();
		this.listenForConnect();
	}

	private initSocket() {
		this.socket = SocketIOClient(this.endpoint, {
			auth: {
				token: `Bearer ${this.token}`
			},
			transports: ['websocket', 'polling'],
		});
	}

	private registerToken() {
		this.socket.emitWithAck('REGISTER_CLIENT', {}, (evt: unknown) => {
			console.log('registered client', evt);
			this.isRegistered = true;
		});
	}

	private listenForConnect() {
		this.socket.on('connect', (evt: unknown) => {
			console.log(`socket connected ${this.socket.id}`);
			this.registerToken();
		});
	}

	public emit(eventName: string, data: any) {
		this.socket.emit(eventName, data);
	}

	public subscribeToEvent(eventName: string, cb: (data: any) => {}) {
		this.socket.on(eventName, (evt: unknown) => {
			console.log('received event emit of type', eventName, 'data:', evt);
			cb(evt);
		})
	}
}
