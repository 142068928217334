import { useState } from 'react'
import { coachingCategorySlice } from '../../../../../features/coaching/coaching-categories.api'
import './Coaching.scss'
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete'
import { SkeDialogTextPrompt } from '../../../../../common/components/dialog-text-prompt'
import { toast } from 'react-toastify'


export function Coaching() {
    const { data: coachingCategories, refetch } = coachingCategorySlice.useGetCoachingCategoriesQuery({});
    const [ deleteCoachingCategory] = coachingCategorySlice.useDeleteCoachingCategoryMutation();
    const [ updateCoachingCategory] = coachingCategorySlice.useUpdateCoachingCategoryMutation();
    const [ createCoachingCategory] = coachingCategorySlice.useCreateCoachingCategoryMutation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showTextPrompt, setShowTextPrompt] = useState(false);
    const [textPromptConfig, setTextPromptConfig] = useState({ title: '', inputValue: '', onConfirm: (value: string) => {} });

    const [selectedCoachingCategory, setSelectedCoachingCategory] = useState<number | null>(null)


    const handleAddCategory = () => {
        setTextPromptConfig({
            title: 'Coaching Category Name',
            inputValue: '',
            onConfirm: async (value: string) => {
                if (value !== '') {
                    createCoachingCategory({ name: value })
                      .then(() => {
                          toast.success('Category updated', {
                              position: 'top-right',
                              theme: 'colored',
                              autoClose: 2000,
                          });
                          setShowTextPrompt(false);
                      })
                      .catch(err => {
                          console.error(err);
                          toast.error('Failed to update category', {
                              position: 'top-right',
                              theme: 'colored',
                              autoClose: 2000,
                          });
                          setShowTextPrompt(false);
                      });
                }
            }
        });
        setShowTextPrompt(true);
    };

    const handleEditCategory = (id: number, beforeName: string) => {
        setTextPromptConfig({
            title: 'Change your coaching category name',
            inputValue: beforeName,
            onConfirm: async (value: string) => {
                if (value !== '') {
                    updateCoachingCategory({ id, name: value })
                      .then(() => {
                          toast.success('Category updated', {
                              position: 'top-right',
                              theme: 'colored',
                              autoClose: 2000,
                          });
                          setShowTextPrompt(false);

                      })
                      .catch(err => {
                          console.error(err);
                          toast.error('Failed to update category', {
                              position: 'top-right',
                              theme: 'colored',
                              autoClose: 2000,
                          });
                          setShowTextPrompt(false);
                      });
                }
            }
        });
        setShowTextPrompt(true);
    };


    const handleCancelDelete = () => {
        setShowDeleteDialog(false)
    }

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedCoachingCategory !== null) {
            deleteCoachingCategory({ id: selectedCoachingCategory })
                .unwrap()
                .then(() => {
                    successToast();
                    refetch();
                })
                .catch(err => {
                    console.log(err);
                    errorToast();
                });
        }
    };


    const handleRemoveCategory = (id: number, beforeName: string) => {
        setShowDeleteDialog(true);
        setSelectedCoachingCategory(id);
    }


    return (
        <>
        <div className='card coaching_settings_container'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Coaching Categories</h3>
                </div>
            </div>
            <div className="card-body border-top">
                <div className="text-start">
                    <button type='button' className='link-primary btn btn_add mt-2 px-0' onClick={handleAddCategory}>+ Add New</button>
                </div>
                <div className="mb-0 check_wrapper">
                    { coachingCategories && coachingCategories?.length > 0 && <div className='table-responsive'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className='text-muted fw-bold min-w-300px'>Name</th>
                                    <th className='text-muted fw-bold w-200px'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {coachingCategories.map((category, index) => {
                                    return <tr className='action_line' key={index}>
                                        <td className='action_name'>{category.name}</td>
                                        <td className="action_buttons">
                                            <span className="btn_remove"
                                                onClick={() => handleEditCategory(category.id, category.name)}
                                            >
                                                <i className="fas fa-edit text-primary fs-4"></i>
                                            </span>
                                            <span className="btn_remove ms-3"
                                                onClick={() => handleRemoveCategory(category.id, category.name)}
                                            >
                                                <i className="fas fa-trash text-danger fs-4"></i>
                                            </span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
        {showTextPrompt && (
            <SkeDialogTextPrompt
                title={textPromptConfig.title}
                initialValue={textPromptConfig.inputValue}
                onConfirm={(value) => {
                    textPromptConfig.onConfirm(value);
                    setShowTextPrompt(false);
                }}
                onCancel={() => {
                    setShowTextPrompt(false);
                }}
            />
        )}
        {showDeleteDialog && (
                <SkeDialogDelete
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    successMessage='Category deleted'
                    message={`Are you sure you want to delete this category?`}
                />
            )}
        </>
    )
}
