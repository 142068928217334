import moment from 'moment';

export const sortDateDescending = (a: any, b: any) => {
	const aDate = a.date || a.closed_at;
	const bDate = b.date || b.closed_at;
	return moment(bDate, 'YYYY-MM-DD').diff(moment(aDate, 'YYYY-MM-DD'));
}

export const sortDateAscending = (a: any, b: any) => {
	const aDate = a.date || a.closed_at;
	const bDate = b.date || b.closed_at;
	return moment(aDate, 'YYYY-MM-DD').diff(moment(bDate, 'YYYY-MM-DD'));
}
