import { useEffect, useRef, useState } from 'react';
import {KTSVG} from '../../../_library/helpers'
import { ActivityTypeEnum } from '../../../features/activity/interfaces/activity.model';
import { NotificationMaintenance, NotificationType } from '../../types/NotificationType';
import {NotificationItem} from './NotificationItem'

import './NotificationDrawer.scss'
import { NotificationItemMaintenance } from './NotificationItemMaintenance';
import { NotificationModalMaintenance } from './NotificationModalMaintenance';
import {NotificationViewModal} from './NotificationViewModal'
import {
	markOneNotificationRead,
	markAllNotificationsAsRead,
	deleteAllNotifications,
	deleteOneNotification,
	getNotifications,
} from '../../CRUD/TeamCRUD'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import { toast } from 'react-toastify'


interface Props {
	notifications: NotificationType[]
	setNotifications: (nots: NotificationType[]) => void
	refreshNotifications: () => void
}

export function NotificationDrawer({
	notifications,
	setNotifications,
	refreshNotifications,
}: Props) {
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string
	const btnCloseRef = useRef<HTMLDivElement>(null)
	const [todayNotifications, setTodayNotifications] = useState<NotificationType[]>([])
	const [pastNotifications, setPastNotifications] = useState<NotificationType[]>([])
	const [selectedNotification, setSelectedNotification] = useState<NotificationType | null>(
		null
	)
	const [maintenanceNotifications, setMaintenanceNotifications] = useState<NotificationType[]>([])
	const openNotificationViewModalButton = useRef<HTMLButtonElement>(null)

	const handleAll = () => {
		setTodayNotifications(
			notifications.filter((notification) => {
				// @ts-ignore: TODO: need to rebuild the interface for notifications to not have the metaData be set to type, and
				// instead show the actual implementations of objects
				if (notification.metaData?.type === ActivityTypeEnum.Maintenance) {
					return false;
				}
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate >= detDate
			})
		)

		setPastNotifications(
			notifications.filter((notification) => {
				// @ts-ignore: TODO: need to rebuild the interface for notifications to not have the metaData be set to type, and
				// instead show the actual implementations of objects
				if (notification.metaData?.type === ActivityTypeEnum.Maintenance) {
					return false;
				}
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate < detDate
			})
		)

		setMaintenanceNotifications(
			// @ts-ignore: TODO: need to rebuild the interface for notifications to not have the metaData be set to type, and
			// instead show the actual implementations of objects
			notifications.filter(not => not.metaData?.type === ActivityTypeEnum.Maintenance)
		)
	}

	const handleMarkAllRead = () => {
		markAllNotificationsAsRead(token).then(() => {
			refreshNotifications()
		})
	}

	const handleDeleteAll = () => {
		deleteAllNotifications(token).then(() => {
			toast.success("All notifications deleted!", {
				position: "top-right",
				theme: "colored",
				autoClose: 2000,
			})
			refreshNotifications()
		})
	}

	const handleUnread = () => {
		setTodayNotifications(
			notifications.filter((notification) => {
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate >= detDate && !notification.isRead
			})
		)

		setPastNotifications(
			notifications.filter((notification) => {
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate < detDate && !notification.isRead
			})
		)
	}

	const handleClickNotification = (notification: NotificationType) => {
		if (notification) {
			setSelectedNotification(notification)
			setTimeout(() => {
				openNotificationViewModalButton.current?.click();
			}, 1);

			// Check if the notification is unread before making an API call
			if (!notification.isRead) {
				markOneNotificationRead(notification.id, token)
					.then((response) => {
						const updatedNotifications = notifications.map((n) => {
							if (n.id === notification.id) {
								return {...n, isRead: true}
							}
							return n
						})
						setNotifications(updatedNotifications);
					})
					.catch((error) => {
						console.error('Failed to mark the notification as read', error)
					})
			}
		}
	}

	const deleteNotification = (notificationId: number) => {
		deleteOneNotification(notificationId, token).then(() => {
			setNotifications(notifications.filter(itm => itm.id !== notificationId));
			toast.success("Notification deleted!", {
				position: "top-right",
				theme: "colored",
				autoClose: 2000,
			})
		})
	}

	useEffect(() => {
		console.log('notifications in notification drawer', notifications);
		setTodayNotifications(
			notifications.filter((notification) => {
				if ((notification as unknown as NotificationMaintenance).metaData) {
					return false;
				}
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate >= detDate
			})
		)

		setPastNotifications(
			notifications.filter((notification) => {
				const noteDate = new Date(notification.created_at)
				const today = new Date()
				const detDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				return noteDate < detDate
			})
		)

		// @ts-ignore
		console.log(notifications.filter(not => not.metaData?.type === ActivityTypeEnum.Maintenance))
		setMaintenanceNotifications(
			// @ts-ignore: TODO: need to rebuild the interface for notifications to not have the metaData be set to type, and
			// instead show the actual implementations of objects
			notifications.filter(not => not.metaData?.type === ActivityTypeEnum.Maintenance)
		)
	}, [notifications])

	return (
        <>
            <div
              id="kt_drawer_notification"
              className="bg-white"
              data-kt-drawer="true"
              data-kt-drawer-name="notification"
              data-kt-drawer-activate="true"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'100%', 'sm': '403px'}"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_drawer_notification_toggle"
              data-kt-drawer-close="#kt_drawer_notification_close"
            >
                <div className="card w-100 rounded-0"
                     id="kt_drawer_chat_messenger">
                    <div className="card-header pe-5"
                         id="kt_drawer_chat_messenger_header">
                        <div className="card-title">
                            <span className="">Notifications</span>
                        </div>

                        <div className="card-toolbar">
                            <div className="me-2 d-block d-lg-none">
                            </div>

                            <div ref={btnCloseRef}
                                 className="btn btn-sm btn-icon btn-active-light-primary"
                                 id="kt_drawer_notification_close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg"
                                       className="svg-icon-2" />
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className='sticky-actions'>
                        <div className="buttons_wrapper">
                            <div className='item_title'>Filters:</div>
                            <button className="btn_all btn"
                                onClick={handleAll}
                            >All Notifications
                            </button>
                            <button className="btn_unread btn"
                                onClick={handleUnread}
                            >Unread
                            </button>
                            <div className='item_title'>Actions:</div>
                            <button className="btn_all btn"
                                onClick={handleMarkAllRead}
                            >Mark All Read
                            </button>
                            <button className="btn_unread btn"
                                onClick={handleDeleteAll}
                            >Delete All Notifications
                            </button>
                        </div>
                        </div>

                        <div className="scrollable-notifications">
                        <div className="item_title text-uppercase">Maintenance</div>
													{
														maintenanceNotifications?.map(notification => {
															return (
																<li className="notification_item"
																				 onClick={() => {
																					 handleClickNotification(notification);
																				 }}
																				 key={notification.id}>
																<NotificationItemMaintenance notification={notification}
																									deleteNotification={() => deleteNotification(notification.id)} />

															</li>);
														})
													}
                        <div className="item_title text-uppercase">Today</div>
                        <ul className="notifications_container ps-0">
                            {
                                // hotfix: suppress non-discipline notification spam (e.g. employee created)
                                todayNotifications.filter(notification => !!notification.metaData).map((notification) => {
                                    return <li className="notification_item"
                                               onClick={() => {
                                                   handleClickNotification(notification);
                                               }}
                                               key={notification.id}>
                                        <NotificationItem notification={notification}
                                                          deleteNotification={() => deleteNotification(notification.id)} />
                                    </li>;
                                })
                            }
                        </ul>
                        <div className="item_title text-uppercase">Past Week</div>
                        <ul className="notifications_container ps-0">
                            {
                                // hotfix: suppress non-discipline notification spam (e.g. employee created)
                                pastNotifications.filter(notification => !!notification.metaData).map((notification) => {
                                    return <li className="notification_item"
                                               onClick={(e) => {
                                                   handleClickNotification(notification);
                                               }}
                                               key={notification.id}>
                                        <NotificationItem notification={notification}
                                                          deleteNotification={() => deleteNotification(notification.id)} />
                                    </li>;
                                })
                            }
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            <NotificationViewModal
							notification={selectedNotification}
							refreshNotifications={refreshNotifications}
						/>
					<NotificationModalMaintenance
						notification={(selectedNotification as unknown as NotificationMaintenance)}
						refreshNotifications={refreshNotifications}
					/>
					<button
						// @ts-ignore
						data-bs-target={`#${selectedNotification?.metaData.type === ActivityTypeEnum.Maintenance ? 'notification_modal_maintenance' : 'notification_view_modal'}`}
						className='d-none'
						data-bs-toggle="modal"
						ref={openNotificationViewModalButton}
					/>
        </>
      )
  }
