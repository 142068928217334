import clsx from 'clsx';
import { KTSVG } from '../../../_library/helpers'

interface Props {
  className: string;
  metric: string | number;
  title: string;
  description: string;
  path: string;
  // https://getbootstrap.com/docs/5.0/utilities/background/#background-color
  // bgColor?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'body' | 'white' | 'transparent';
  bgColor?: string;
  textColor?: string;
	onClick?: () => void;
}

export function SupervisorStaticWidget({
                                  className,
                                  metric,
                                  title,
                                  description,
                                  path,
                                  bgColor,
                                  textColor = 'white',
                                  onClick,
                                }: Props) {
  return (
    <div
      className={clsx('col px-6 py-3 rounded-2', `bg-${bgColor}`, className, {
        'cursor-pointer': !!onClick,
        'cursor-default': !onClick,
      })}
      style={{'backgroundColor': bgColor, color: textColor}}
      onClick={onClick}
    >
      <h2 className="text-center"
          style={{color: textColor}}
      >{title}</h2>
      <div className="d-flex justify-content-between">
        <KTSVG
          path={path}
          className="svg-icon-3x d-block my-2 svg-icon-white"
        />
        <h1 className="fs-3x fx-bolder lh-1"
            style={{color: textColor}}>{metric}</h1>
      </div>
      <button
        style={{color: textColor}}
        className="w-100 text-center fw-bold fs-6 border-0 p-0 cursor-pointer bg-transparent">
        {description}
      </button>
    </div>
  );
}
