import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { NotificationLengthEnum } from '../../notification/Notification.const';
import { requestPassword } from '../redux/AuthCRUD'
import { toast } from 'react-toastify'


const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorText, setErrorText] = useState<string>('')
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email.toLowerCase())
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
            toast.success('Password reset request sent. Please check your email.', {
              position: 'top-right',
              theme: 'colored',
              autoClose: NotificationLengthEnum.Normal,
          })
            navigate('/auth/login')
          })
          .catch((error) => {
            console.log(error.response)
            setErrorText(error.response.data.message)
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            // setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
      <div className='w-lg-500px p-lg-15 p-10 bg-white mx-auto'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='form_title'>Forgot Password ?</div>
          <div className='form_subtitle'>Enter your email to reset your password.</div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {errorText}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row form_control_wrapper position-relative'>
            <i className="bi bi-envelope fs-2 position-absolute"></i>
            <input
              type='email'
              placeholder='Email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form_control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn_submit fw-bolder me-4'
            >
              {!loading && <span className='indicator-label poppins_medium'>Submit</span>}
              {loading && (
                <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}
