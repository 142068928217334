import clsx from 'clsx';
import { FilterIcon } from './icons/FilterIcon';

interface Props {
	id?: string;
	label?: string;
	target?: string;
	alt?: string;
	title?: string;
	onClick?: () => void;
	menuTrigger?: string;
	menuPlacement?: string;
	menuFlip?: string;
	toggle?: string;
}

export function FilterButton({
															 id,
															 label = 'Filter',
															 alt = 'Filter results',
															 title = 'Filter results',
															 target,
															 onClick,
															 menuTrigger = 'click',
															 menuPlacement = 'bottom-end',
															 menuFlip = 'top-end',
															 toggle,
														 }: Props) {
	return (
		<button
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			id={id}
			data-bs-target={target}
			data-kt-menu-trigger={menuTrigger}
			data-kt-menu-placement={menuPlacement}
			data-kt-menu-flip={menuFlip}
			data-bs-toggle={toggle}
			className={clsx('btn btn-sm btn-light-primary')}>
			<FilterIcon
				alt={alt}
				title={title}
				size='xs'
			/>
			{label}
		</button>
	);
}
