import clsx from 'clsx';
import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';

export interface Props {
	label?: string;
	labelClasses?: string;
	inputId: string;
	inputClasses?: string;
	onChange?: (val: string) => void;
	name: string;
	isInvalid?: boolean;
	isTouched?: boolean;
	errorMessage?: string;
	value?: string;
	rows?: number;
	disabled?: boolean;
}

export const SkeTextbox = forwardRef((props: Props, ref) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	};

	useImperativeHandle(ref, () => ({
		insertTextAtCursor(textToInsert: string) {
			if (textareaRef.current) {
				const currentText = textareaRef.current.value;
				const selectionStart = textareaRef.current.selectionStart;
				const selectionEnd = textareaRef.current.selectionEnd;
				const textStart = currentText.slice(0, selectionStart);
				const textEnd = currentText.slice(selectionEnd);
				const newText = `${textStart}${textToInsert}${textEnd}`;
				textareaRef.current.value = newText;
				textareaRef.current.setSelectionRange(selectionEnd + textToInsert.length, selectionEnd + textToInsert.length)
				if (props.onChange) {
					props.onChange(newText);
				}
			}
		}
	}))

	return (
		<div className="d-flex flex-grow-1">
			<div className="input-group-lg has-validation w-100">
				<div className="label-row align-items-start">
					{!!props.label && (
						<label
							htmlFor={props.inputId}
							className={clsx('form-label fs-4 fw-light',
								props.labelClasses)}
						>{props.label}</label>
					)}
					{/*<button className="btn btn-sm btn-link ms-3">Change translation settings</button>*/}
				</div>

				<textarea
					className={clsx('form-control form-control-lg',
						props.inputClasses,
						{
						'is-invalid': props.isTouched && props.isInvalid,
						'is-valid': props.isTouched && !props.isInvalid,
					})}
					disabled={props.disabled}
					ref={textareaRef}
					onChange={handleChange}
					value={props.value}
					rows={props.rows}
					id={props.inputId}></textarea>
				{props.isTouched && props.isInvalid && (
					<div className="invalid-feedback">{props.isInvalid}</div>
				)}
			</div>
		</div>
	);
});
