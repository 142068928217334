import { Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

interface Props {
	title: string;
	onFileUploaded: (files: File[]) => void;
}

export function FileDropzone({
																	title,
																	onFileUploaded,
																}: Props) {
	const {
		getRootProps,
		getInputProps,
		open,
		isDragActive,
		acceptedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		accept: '.csv, .xlsx',
		onDropAccepted: (files, event) => {
			onFileUploaded(files);
		},
	});


	return (
		<div className="upload_content_wrapper">
			<div {...getRootProps({className: 'dropzone'})}>
				<input {...getInputProps()} />
				{
					<div
						className="text-center dropzone_wrapper mb-10 rounded h-300px d-flex align-items-center"
						style={{
							border: `2px ${isDragActive ? 'solid' : 'dashed'} grey`,
						}}>
						<div className="d-flex flex-column align-items-center px-5">
							<img
								src="/media/csv-upload.png"
								width="300"
								alt="Upload"
								className="ms-4 me-2" />
							<div className="ms-1 mt-5">
								<p>Drag a CSV or XLSX file here or upload via the button to import your team members</p>
								{/*<button type="button" className="btn btn-primary btn-sm mt-5 ms-5">*/}
								{/*	Browse to file*/}
								{/*</button>*/}
								<p className="pt-1 text-muted">Max file size is 15MB per file</p>
							</div>
						</div>
					</div>
				}
				{/*<p>Drag 'n' drop some files here</p>*/}
				{/*<button type="button" onClick={open}>*/}
				{/*	Open File Dialog*/}
				{/*</button>*/}
				<aside>
					<h3>File Provided</h3>
					<ul>
						{acceptedFiles.map((file: File, index: number) => (
							<Alert
								variant="success"
								key={index}>
								{file.name} - {file.size} bytes
							</Alert>
						))}
					</ul>
				</aside>
			</div>
		</div>
	);
}
