import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { catchError, defer, from, map, Observable, of } from 'rxjs';
import {
	PerformanceCategory,
	PerformanceCategoryApiResponse,
	PerformanceCategoryGetParams,
} from '../models/PerformanceCategory.models';

const API_URL = process.env.REACT_APP_API_URL;
export const PERFORMANCE_CATEGORY_URL = `${API_URL}/performance-categories`;

export function getPerformanceCategories(token: string,
																	 opts?: PerformanceCategoryGetParams): Observable<PerformanceCategory[]> {
	const config: AxiosRequestConfig = {
		url: PERFORMANCE_CATEGORY_URL,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: opts,
	};
	return from(defer(() => axios(config)))
		.pipe(
			catchError(err => {
				// TODO: trigger toast
				console.error(`Error caught getting performance categories`, err);
				return of(err);
			}),
			map((res: AxiosResponse<PerformanceCategoryApiResponse>) => res.data.items),
			// map((data: DisciplineStepApiResponse) => {
				// return data.items;
				// // TODO: just plugging this in to trim up names
				// data.items = data.items.map(item => {
				// 	return {
				// 		...item,
				// 		name: item.name.replace(/(P-|Performance|Attendance)/, ''),
				// 	};
				// });
				// return {
				// 	[DisciplineStepTypeEnum.Attendance]: data.items.filter((itm: DisciplineStep) => itm.type === DisciplineStepTypeEnum.Attendance).sort((a: DisciplineStep, b: DisciplineStep) => a.stepNumber - b.stepNumber),
				// 	[DisciplineStepTypeEnum.Performance]: data.items.filter((itm: DisciplineStep) => itm.type === DisciplineStepTypeEnum.Performance).sort((a: DisciplineStep, b: DisciplineStep) => a.stepNumber - b.stepNumber),
				// };
			// }),
		);
}
