import { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { KTSVG } from '../../../../../_library/helpers'
import { attendanceRuleSlice } from '../../../../../features/attendance/attendance-rule.api'
import {
    AttendanceRuleModel,
    UpdateAttendanceRuleApiRequestModel,
} from '../../../../../features/attendance/interfaces/attendance-rule.model';

interface Props {
    setShowPage: (page: string) => void;
    editRule: AttendanceRuleModel;
}

export function EditRule({ setShowPage, editRule }: Props) {
    const [loading, setLoading] = useState(false)
    const [updateAttendanceRule, { isLoading }] = attendanceRuleSlice.useUpdateAttendanceRuleMutation()


    const initValues: UpdateAttendanceRuleApiRequestModel = {
        name: editRule.name,
        occurrence: editRule.occurrence,
        pointsToAssign: editRule.pointsToAssign
    }

    const ruleSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        occurrence: Yup.string()
            .required('Occurrence is required'),
        pointsToAssign: Yup.number(),
    })

    const handleSubmit = async (values: UpdateAttendanceRuleApiRequestModel, actions: FormikValues) => {
        setLoading(true)
        try {
            await updateAttendanceRule({
                id: editRule.id,
                name: values.name,
                occurrence: values.occurrence,
                pointsToAssign: values.pointsToAssign
            }).unwrap();
            setShowPage('list')
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }


    return (
        <div className='w-100'>
            <Formik
                validationSchema={ruleSchema}
                initialValues={initValues}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isValid, dirty, handleChange, values }) => (
                    <Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Name</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='name'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='name' />
                            </div>
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Occurrence</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='occurrence'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='occurrence' />
                            </div>
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Points To Assign</span>
                            </label>

                            <Field
                                type='number'
                                step={0.5}
                                className='form-control form-control-lg form-control-solid'
                                name='pointsToAssign'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='pointsToAssign' />
                            </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                            <div className='me-2'>
                            </div>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-secondary me-3'
                                    onClick={() => setShowPage('list')}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-sm btn-primary me-3'
                                >
                                    {!loading && <span className='indicator-label'>
                                        Update
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                        />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
