import React, {ReactNode, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {SkeBeta} from '../../components/beta';

interface Props {
	title: string;
	subtitle?: ReactNode | string;
	titleContents?: ReactNode;
	onClose?: (action: boolean)=> void;
	onSubmit?: ()=> void;
	show?: boolean;
	children?: ReactNode;
	showHeader?: boolean;
	headerContents?: ReactNode;
	showFooter?: boolean;
	footerContents?: ReactNode;
	beta?: boolean;
	size?: any;
}

export function SkeModal({
														title,
														subtitle,
														titleContents,
														beta,
														size = "md",
														show,
														onClose,
														onSubmit,
														children,
														showHeader = true,
														headerContents,
														showFooter = true,
														footerContents,
												 }: Props){
	const [showModal, setShowModal] = useState(show);

	const handleClose = ()=>{
		if (onClose) {
			onClose(false);
		}
	}

	const handleOnSubmit = () => {
		if (onSubmit) {
			onSubmit();
		}
	};

	useEffect(() => {
		setShowModal(show);
	}, [show]);

	return(
		<>
			<Modal
				size={size}
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				scrollable={true}
			>
				{showHeader && (
					<>
						{headerContents && (
							<>
								<Modal.Header closeButton>
									<div>
										{headerContents}
									</div>
									<Modal.Title>
											{title} {beta && <span><SkeBeta></SkeBeta></span>}
										{subtitle && (
											<>
												<div>
													<span className="fs-3 text-muted">{subtitle}</span>
												</div>
											</>
										)}
									</Modal.Title>
								</Modal.Header>
							</>
						)}
						{!headerContents && (
							<>
								<Modal.Header closeButton>
									<Modal.Title>
										{title} {beta && <span><SkeBeta></SkeBeta></span>}
										{titleContents}
									</Modal.Title>
								</Modal.Header>
							</>
						)}
					</>
				)}
				<Modal.Body>
					{children}
				</Modal.Body>
				{showFooter && (
					<>
						{footerContents && (
							<>{footerContents}</>
						)}
						{!footerContents && (
							<>
								<Modal.Footer>
									<Button variant="secondary" onClick={handleClose}>
										Cancel
									</Button>
									<Button variant="primary" onClick={handleOnSubmit}>
										Save Changes
									</Button>
								</Modal.Footer>
							</>
						)}
					</>
				)}
			</Modal>
		</>
	);

}
