/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { ReactNode } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch, useRoutes } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux'
import { ModalManager } from '../../common/modals/ModalManager';
import { Logout } from '../modules/auth';
import { PrivateRoutes } from './PrivateRoutes'
import { RootState } from '../../setup'
import { MasterInit } from '../../_library/layout/MasterInit'
import { PublicRoutes } from './PublicRoutes'
import { UserModel } from '../models'
import { ResetExpiredPassword } from '../pages/expired/ResetExpiredPassword'

interface Props {
  children?: ReactNode;
}

export function MainRoutes({ children }: Props) {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) && !user.roles?.includes('SecchiAdmin')
  const location = useLocation();

  return (
    <>
      <Routes>
        {/* Up here so both authenticated and unauthenticated can hit this route */}
        <Route path='/auth/logout' element={<Logout />} />
        {
          !!isAuthorized && (
            <>
              {
                location.pathname === '/auth/login'
                  ? <Route path='*' element={<Navigate to='/dashboard' />} />
                  : <>
                    {user.isPasswordExpired && <Route path='*' element={<ResetExpiredPassword />} />}
                    {!user.isPasswordExpired && <Route path='*' element={<PrivateRoutes />} />}
                  </>
              }
            </>
          )
        }
        {
          !isAuthorized && (
            <Route path='*' element={<PublicRoutes />} />
          )
        }
      </Routes>
      <MasterInit />
    </>
  )
}
