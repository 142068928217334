import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import { UserModel } from '../../../models'
import { TeamType } from '../../../types/EmployeeType'
import {
  getUserByToken
  // , getEmployee
} from "./AuthCRUD";

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  Verify: '[Verify] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetTeams: '[Set Teams] Action',
  // SetEmployees: '[Set Employees] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined, 
  teams: []
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  teams?: TeamType[]
}

export const reducer = persistReducer(
  { storage, key: 'secchi-app-auth', whitelist: ['user', 'accessToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return { accessToken, user: undefined }
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return { accessToken, user: undefined }
      }

      case actionTypes.Verify: {
        return state
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetTeams: {
        const teams = action.payload?.teams
        return { ...state, teams }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  verify: () => ({
    type: actionTypes.Verify,
    payload: {}
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  // setEmployees: (employees: Array<any>) => ({
  //   type: actionTypes.SetEmployees,
  //   payload: { employees }
  // }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  setTeams: (teams: TeamType[]) => ({ type: actionTypes.SetTeams, payload: { teams } }),
  store: () => ({ type: "def" }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Verify, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const getToken = (state: any) => state.auth.accessToken;
    const token = (yield select(getToken)) as string
    const { data: user } = yield getUserByToken(token)
    yield put(actions.fulfillUser(user))

    // try {
    //   const result = (yield call(getEmployee, token)) as Array<any>
    //   yield put(actions.setEmployees(result));
    // } catch (error) {
    //   console.log(error);
    // }
  })
}
