import React from 'react'
import { ProfileDetails } from './components/ProfileDetails'
// import { Notifications } from './components/Notifications'
// import { DeactivateAccount } from './components/DeactivateAccount'

export function Settings() {
    return (
        <div className="settings_container">
            <ProfileDetails />
            {/* <Notifications />
            <DeactivateAccount /> */}
        </div>
    )
}