import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { tap } from "rxjs";
import { RootState } from "../../../../setup";
import { PageTitle } from "../../../../_library/layout/core";
import { UserModel } from "../../../models";
import { LineChart, LineChartDatum } from "../../charts/components/LineChart";
import { VerticalBarChart } from "../../charts/components/VerticalBarChart";
import { getRollingMonthAbbreviations } from "../../date/utils/DateHelpers";
import {
  CompanyLeaderboard, ShiftRecognitionCountRolling,
  SupervisorRecognitionCountRolling,
  TeamRecognitionCountRolling
} from "../models/RecognitionLeaderboard.model";
import { getCompanyLeaderboards } from "../services/leaderboard.service";


export function LeaderboardDashboard() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;

  const [companyLeaderboards, setCompanyLeaderboards] = useState<CompanyLeaderboard>();
  const [hasData, setHasData ] = useState<boolean>(false)
  // const [rollingMonths, setRollingMonths] = useState<string[]>(getRollingMonthAbbreviations(12, true).reverse())

  useEffect(() => {
    if (user && user.company) {
      getCompanyLeaderboards(user.company.id, token, {})
        .pipe(
          tap(setCompanyLeaderboards)
        )
        .subscribe(val => console.log("got leaderboards", val));
    }
  }, []);

  useEffect(() => {
    if(!!companyLeaderboards && Object.keys(companyLeaderboards && companyLeaderboards.recognition.rolling.annual.byTeam).length > 0 ){
      setHasData(true);
    } else {
      setHasData(false);
    }

  }, [companyLeaderboards]);

  const recognitionRollingTeam = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Rolling Recognition by Team"
      }
    }
  };

  const recognitionRollingSupervisor = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Rolling Recognition by Supervisor"
      }
    }
  };

  const recognitionRollingShift = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Rolling Recognition by Shift"
      }
    }
  };

  const getDataAndLabelForTeam = (team: TeamRecognitionCountRolling): LineChartDatum => {
    return {
      data: team.data.map(itm => itm.data),
      label: team.name
    };
  };

  const getDataAndLabelForSupervisor = (supervisor: SupervisorRecognitionCountRolling): LineChartDatum => {
    return {
      data: supervisor.data.map(itm => itm.data),
      label: `${supervisor.firstName} ${supervisor.lastName}`
    };
  };

  const getDataAndLabelForShift = (shift: ShiftRecognitionCountRolling): LineChartDatum => {
    return {
      data: shift.data.map(itm => itm.data),
      label: shift.name
    };
  };

  const getMonthLabels = () => {
    if(hasData){
      return Object.values(companyLeaderboards!.recognition.rolling.annual.byTeam)[0].data.map(itm => itm.label);
    } else {
      return ["nodata"]
    }
  };

  return (
    <>
      <PageTitle>Leaderboard</PageTitle>
      <div className="row">
        {hasData &&
          <div className="d-flex flex-row flex-wrap">
            {!!companyLeaderboards && (
              <div className="col-6">
                <LineChart
                  labels={getMonthLabels()}
                  data={Object.values(companyLeaderboards.recognition.rolling.annual.byTeam).map(getDataAndLabelForTeam)}
                  options={recognitionRollingTeam}
                  isVisible={!!companyLeaderboards}
                />
              </div>
            )}
            {!!companyLeaderboards && (
              <div className="col-6">
                <LineChart
                  labels={getMonthLabels()}
                  data={Object.values(companyLeaderboards.recognition.rolling.annual.bySupervisor).map(getDataAndLabelForSupervisor)}
                  options={recognitionRollingSupervisor}
                  isVisible={!!companyLeaderboards}
                />
              </div>
            )}
            {!!companyLeaderboards && (
              <div className="col-6">
                <LineChart
                  labels={getMonthLabels()}
                  data={Object.values(companyLeaderboards.recognition.rolling.annual.byShift).map(getDataAndLabelForShift)}
                  options={recognitionRollingShift}
                  isVisible={!!companyLeaderboards}
                />
              </div>
            )}
          </div>
        }
        {!hasData &&
          <div className="d-flex justify-content-center flex-column w-100">
            <p className="fs-1 d-block text-center">No data available yet</p>
            <p className="fs-3 d-block text-center">As your team starts making connections, data will show up here.</p>
          </div>
        }

      </div>
    </>
  );
}
