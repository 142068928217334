import clsx from 'clsx';
import { addMonths } from 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { attendanceReasonsSlice } from '../../../../features/attendance/attendance-reasons.api';
import { attendanceRuleSlice } from '../../../../features/attendance/attendance-rule.api';
import { RootState } from '../../../../setup';
import { DatePicker } from '../../../components/DatePicker';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { ToggleSwitch } from '../../../components/ToggleSwitch';
import { SkeTextbox } from '../../../components/Textbox';

import {
	createAttendance,
	createBulkAttendance,
	updateAttendance,
} from '../../../CRUD/AttendanceCRUD';
import { SHORT_DATE } from '../../../modules/date/DateFormat.const';
import { AttendanceType } from '../../../types/AttendanceType';
import { EmployeeType } from '../../../types/EmployeeType';
import './AttendanceModal.scss';
import { noop } from 'lodash'

interface AttendanceDetails {
	occurrence: string;
	pointsAssigned: number;
	reasonId: number;
	employeeStatement?: string;
	supervisorNote?: string;
	// TODO: need this for streamlining validation (less custom-coded logic), but can't send to API
	isExcused: boolean;
	rule: any;
	reason: any;
	fmla_hours: Number;
}


const initialValues: AttendanceDetails = {
	occurrence: '',
	pointsAssigned: 0,
	reasonId: 0,
	rule: null,
	reason: null,
	supervisorNote: '',
	isExcused: false,
	fmla_hours: 8,
};

const attendanceSchema = Yup.object().shape({
	isExcused: Yup.boolean().optional(),
	reasonId: Yup.number().positive().required('Reason is required'),
	supervisorNote: Yup.string().optional(),
	occurrence: Yup.string()
		.when('isExcused', {
			is: false,
			then: schema => schema.required('Select a rule'),
			otherwise: schema => schema.notRequired(),
		}),
	pointsAssigned: Yup.number()
		.when('isExcused', {
			is: false,
			then: schema => schema.required(),
			otherwise: schema => schema.notRequired(),
		}),

});

interface Props {
	employee: EmployeeType;
	attendance?: AttendanceType;
	refresh: () => void;
	onClose?: () => void;
	modalId?: string;
	mode: 'edit' | 'new';
}

const AttendanceModal: FC<Props> = ({
																			employee,
																			refresh,
																			onClose,
																			attendance,
																			modalId = 'attendance-modal',
																			mode,
																		}) => {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [attendanceDate, setAttendanceDate] = useState<Date | null>(new Date());
	const [isExcused, setIsExcused] = useState<boolean>(false);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const reasonRadioRefs = useRef<HTMLInputElement[]>([]);
	const ruleRadioRefs = useRef<HTMLInputElement[]>([]);
	const [isFutureDateSelected, setIsFutureDateSelected] = useState<boolean>(false);
	const [allowFutureDates, setAllowFutureDates] = useState<boolean>(false);
	const { data: reasons } = attendanceReasonsSlice.useGetAttendanceReasonQuery({});
	const { data: rules } = attendanceRuleSlice.useGetAttendanceRulesQuery({});
	const [isMultipleDays, setIsMultipleDays] = useState(false);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const maxExcusedDate = addMonths(new Date(),6);
	const textboxRef: any = useRef(null);
	const [is_fmla, setIsFMLA] = useState<boolean>(false);
	const [fmla_days, setFMLADays] = useState<Number[]>([]);
	const [work_hours, setWorkHours] = useState<number>(8);
	const [weekdays,] = useState<string[]>(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);


	const resetRadioButtons = () => {
		reasonRadioRefs.current.forEach(radio => {
			if (radio) {
				radio.checked = false;
			}
		});
		ruleRadioRefs.current.forEach(radio => {
			if (radio) {
				radio.checked = false;
			}
		});
	};

	const resetModal = () => {
		resetRadioButtons();
		formik.resetForm({
			values: initialValues,
		});
		setAttendanceDate(new Date());
		setIsFutureDateSelected(false);
		setIsMultipleDays(false);
		setIsExcused(false);
		setIsFMLA(false);
	};

	const handleChangeDetails = (val: string) => {
		formik.setFieldValue('supervisorNote', val);
	}

	const handleFMLADays = (day: number) => {
		var idx = fmla_days.indexOf(day)
		if (idx > -1) {
			fmla_days.splice(idx, 1);
		} else {
			fmla_days.push(day);
		}
	}

	useEffect(() => {
		setAllowFutureDates(isExcused);
		// re-set to today's date if switched back to unexcused and was in the future
		if (!isExcused && !!attendanceDate && moment() < moment(attendanceDate)) {
			setAttendanceDate(new Date());
			setIsFutureDateSelected(false);
		}
	}, [isExcused]);

	const formik = useFormik<AttendanceDetails>({
		initialValues,
		validationSchema: attendanceSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: async (values) => {
			// Recalculate FMLA hours just in case anything has been changed
			const baseSaveData = {
				employeeId: employee.id,
				teamId: employee.teams?.[0].id,
				occurrence: values.occurrence,
				reasonId: values.reasonId,
				employeeStatement: values.employeeStatement,
				supervisorNote: values.supervisorNote,
			};
			let saveData;
			if (isMultipleDays && attendanceDate && endDate) {
				saveData = {
					...baseSaveData,
					startDate: moment(attendanceDate).format(SHORT_DATE),
					endDate: moment(endDate).format(SHORT_DATE),
				};

				if (is_fmla && values.fmla_hours && fmla_days.length > 0) {
					saveData = {
						...saveData,
						fmla_dow: fmla_days,
						fmla_hours: +work_hours
					}
				}


				// @ts-ignore: until we rebuild our interfaces, teams may be undefined, so allowing this to pass through
				await createBulkAttendance(saveData, token)
					.then(() => {
						toast.success(`Attendance records successfully created`, {
							position: 'top-right',
							theme: 'colored',
							autoClose: 2000,
						});
						refresh();
					})
					.catch(err => {
						console.error(err);
						toast.error(`Failed to create attendance records`, {
							position: 'top-right',
							autoClose: 3500,
						});
					});
			} else {
				// Handle single day
				saveData = {
					...baseSaveData,
					pointsAssigned: values.pointsAssigned,
					date: moment(attendanceDate).format(SHORT_DATE),
					fmla_hours: (is_fmla && !!work_hours) ? +work_hours : 0
				};

				let pipe;
				if (mode === 'edit' && attendance?.id) {
					pipe = updateAttendance(attendance?.id, saveData, token);
				} else {
					pipe = createAttendance(saveData, token);
				}

				await pipe
					.then(() => {
						toast.success(`Attendance record successfully ${mode === 'edit' ? 'updated' : 'created'}`, {
							position: 'top-right',
							theme: 'colored',
							autoClose: 2000,
						});
					})
					.catch(noop);
			}

			if (refresh) {
				refresh();
			}

			resetModal();
			closeButtonRef.current?.click();
		},
	});


	useEffect(() => {
		let abortController = new AbortController();

		if (mode === 'edit') {
			if (!!attendance && reasons?.length && rules?.length) {
				setAttendanceDate(moment(attendance.date).toDate());
				setIsExcused(attendance.reason.isExcused);
				formik.setValues({
					isExcused: attendance.reason.isExcused,
					supervisorNote: attendance.supervisorNote,
					// TODO: need to update to use rebuilt attendance interface
					pointsAssigned: attendance.pointsAssigned!,
					reasonId: attendance.reason.id,
					occurrence: attendance.occurrence!,
					rule: rules.find(rule => rule.occurrence === attendance.occurrence),
					reason: reasons.find(reason => reason.id === attendance.reason.id),
					employeeStatement: attendance.employeeStatement,
					fmla_hours: +work_hours
				});
				setTimeout(() => { }, 1);
			}
		} else {
			setAttendanceDate(new Date());
		}
		return () => {
			abortController.abort();
			resetModal();
		};
	}, [attendance?.id, attendance, employee, rules, reasons]);

	return (
		<div
			className="modal fade attendance-modal"
			id={modalId}
			data-bs-backdrop="static"
			aria-hidden="true">
			<form
				onSubmit={formik.handleSubmit}
				noValidate>
				<div className="modal-dialog modal-fullscreen-sm-down">
					<div className="modal-content">
						<div className="modal-header d-flex align-items-center justify-content-between">
							<div>
								<h1>{mode === 'new' ? 'New' : 'Edit'} Attendance</h1>
								<div className="text-muted fs-5">
									{employee.firstName} {employee.lastName} | {employee.employeeId}
								</div>
							</div>
							<button
								type="button"
								className="btn close_button"
								data-bs-dismiss="modal"
								ref={closeButtonRef}
								onClick={() => {
									resetModal();
									if (onClose) {
										onClose();
									}
								}}
							>
								<CloseButtonIcon />
							</button>
						</div>

						<div className="modal-body">
							<div className="card-body p-0">
								<div className="row d-flex">
									<div className="col-sm-12 col-md-6 pb-2">
										<div className="d-flex justify-content-end align-items-center flex-column">
											<DatePicker
												label={!isExcused ? "Date" : (isMultipleDays ? "Start" : "Date")}
												labelClasses="col-form-label fw-light fs-4 me-2"
												wrapperClasses="py-2"
												id="recognition-add-date"
												allowFutureDates={allowFutureDates}
												maxDate={allowFutureDates ? maxExcusedDate : new Date()}
												selectedDate={attendanceDate}
												onChange={(date) => {
													setAttendanceDate(date);
													setIsFutureDateSelected(!!date && moment() < moment(date));
												}}
												dateFormat="MMMM d, yyyy"
											/>
											{isExcused && isMultipleDays && (
												<>
													<DatePicker
														label="End"
														selectedDate={endDate}
														wrapperClasses="py-2"
														onChange={setEndDate}
														allowFutureDates={true}
														labelClasses="col-form-label fw-light fs-4 me-2"
														dateFormat="MMMM d, yyyy"
													/>
													<span className="fst-italic text-muted d-inline-block ms-3 mt-1">
													A record will be created for each day, including the start/end dates
												</span>
												</>
											)}
										</div>
										{isFutureDateSelected && (
											<span className="fst-italic text-danger d-inline-block ms-3 mt-1">Future date selected for excused</span>
										)}
									</div>

									<div className="col-sm-12 col-md-6 mt-6 d-flex justify-content-between flex-wrap align-items-center">
										<ToggleSwitch
											size="lg"
											isChecked={isExcused}
											onChange={e => {
												setIsExcused(e);
												formik.setValues({
													...initialValues,
													isExcused: e,
													supervisorNote: formik.values.supervisorNote,
												});
												resetRadioButtons();
											}}
											inputClasses="me-2 mb-4"
											label="Excused"
										/>
										{isExcused && (
											<>
												<ToggleSwitch
													size="lg"
													isChecked={isMultipleDays}
													onChange={e => {
														if (e) {
															// push pre-selected end date to be the same as the start date
															setEndDate(attendanceDate);
														}
														setIsMultipleDays(e);
													}}
													inputClasses="me-2 mb-4"
													label="Multiple days"
												/>
											</>
										)}
									</div>
									{isExcused && is_fmla && (
										<div className="justify-content-between d-flex border border-1 rounded-2">
											<div className="d-flex flex-column col-12">
												<span className="text-center fs-3">FMLA Options</span>
												<div className="col-12 d-flex flex-row justify-content-between align-items-center">
													<label className="">Hours per Day</label>
													<input
														type="text"
														className="form-control w-25"
														placeholder="Hours"
														defaultValue={work_hours}
														onChange={(e) => setWorkHours(Number(e.target.value))}
													/>
												</div>
												{isMultipleDays && (
													<div className="flex-row flex-column col-12 pb-2">
														<label className="d-block ms-auto align-items-center">Workdays</label>
														<div
															className="d-block d-flex flex-row flex-wrap justify-content-evenly"
														>
															{weekdays?.length && weekdays.map((item, i) => {
																return (
																	<div
																		key={i}
																		className="d-flex flex-column p-2 cursor-pointer"
																	>
																		<label
																			className="cursor-pointer"
																			htmlFor={`fmla-day-${i}`}>
																			{item}
																		</label>
																		<input
																			className="form-check-input cursor-pointer"
																			type="checkbox"
																			id={`fmla-day-${i}`}
																			onChange={() => handleFMLADays(i)}
																		/>
																	</div>);
															})
															}
														</div>
													</div>
												)}
											</div>
										</div>
									)}
									<div className="col-12 mt-3">
										<div className="px-1">
											<span className="d-block fs-4 mb-1 fs-light text-center">Reason</span>
											<div
												role="group"
												aria-label="Reason for attendance occurrence"
												className="btn-group btn-group-vertical w-100">
												{reasons?.length && reasons
													.filter(reason => reason.isExcused === isExcused)
													.map((reason, i) => {
														return (
															<Fragment key={i}>
																<input
																	type="radio"
																	className="btn-check att-reason"
																	name="reason"
																	checked={formik.values.reasonId === reason.id}
																	value={reason.id}
																	ref={ref => reasonRadioRefs.current[i] = ref!}
																	onChange={() => {
																		formik.setFieldValue('reasonId', reason.id);
																		setIsFMLA(reason.is_fmla)
																	}}
																	id={`att-reason-${reason.id}`}
																	autoComplete="off" />

																<label
																	htmlFor={`att-reason-${reason.id}`}
																	className="btn btn-outline btn-outline-primary att-reason">
																	{reason.content}
																</label>
															</Fragment>
														);
													})
												}
											</div>

										</div>

										{!isExcused && (
											<>
												<div className="px-1 unexcused-rules">
													<span className="d-block fs-4 fs-light mt-2 mb-1 text-center">Rule</span>
													<div
														role="group"
														aria-label="Reason for attendance occurrence"
														className="btn-group btn-group-vertical w-100">
														{rules?.length && rules.map((rule, i) => {
															return (
																<Fragment key={i}>
																	<input
																		type="radio"
																		className="btn-check"
																		ref={ref => ruleRadioRefs.current[i] = ref!}
																		// defaultChecked={attendance && !attendance.reason.isExcused && attendance.occurrence === rule.occurrence}
																		checked={!formik.values.isExcused && formik.values.occurrence === rule.occurrence}
																		name="rule"
																		onChange={() => {
																			formik.setValues({
																				...formik.values,
																				occurrence: rule.occurrence,
																				pointsAssigned: rule.pointsToAssign,
																			});
																		}}
																		id={`att-rule-${rule.id}`}
																		autoComplete="off" />

																	<label
																		htmlFor={`att-rule-${rule.id}`}
																		className="btn btn-outline btn-outline-primary d-flex justify-content-between">
																		<span className="occurrence-name">{rule.occurrence}</span>
																		<span
																			className={clsx('occurrence-points',
																				{
																					positive: rule.pointsToAssign > 0,
																				})}>{rule.pointsToAssign} {rule.pointsToAssign !== 1 ? 'points' : 'point'}</span>
																	</label>
																</Fragment>
															);
														})
														}
													</div>
												</div>
											</>
										)}
									</div>
									<div className="row">
										<div className="col-12">
											<SkeTextbox
												inputId="attendance-note"
												label="Details"
												ref={textboxRef}
												value={formik.values.supervisorNote}
												onChange={handleChangeDetails}
												name="supervisorNote"></SkeTextbox>

											{formik.touched.supervisorNote && formik.errors.supervisorNote && (
												<div className="">
													<div className="text-danger">{formik.errors.supervisorNote}</div>
												</div>
											)}
										</div>
									</div>

								</div>
							</div>
						</div>

						<div className="modal-footer d-flex justify-content-end">
							<button
								type="submit"
								className="btn btn-primary d-flex align-items-center"
								disabled={!formik.isValid || formik.isSubmitting}
							>
								{mode === 'new' ? 'Save' : 'Update'}
								{formik.isSubmitting && (
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								)}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export { AttendanceModal };
