import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../../modules/auth/redux/AuthCRUD'

import logo from '../../../images/Logotype_Black.svg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import * as auth from '../../modules/auth/redux/AuthRedux'
import { HeaderUserMenu } from '../../../_library/partials'
import { NotificationLengthEnum } from '../../modules/notification/Notification.const'
import { toast } from 'react-toastify'


import './ResetExpiredPassword.scss'

const initialValues = {
    password: '',
}

const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Minimum 8 letters')
        .max(50, 'Maximum 50 letters')
        .required('Password is required')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Must include capital letter,' +
          ' special character and number'),
})

export function ResetExpiredPassword() {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [isShowPwd, setIsShowPwd] = React.useState(false)
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.logout())
    }

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                if (token) {
                    resetPassword(values.password, token)
                        .then(({ data: { result } }) => {
                            setHasErrors(false)
                            setLoading(false)
                            dispatch(auth.actions.verify())
                            toast.success('Your new password has successfully changed.', {
                            position: 'top-right',
                            theme: 'colored',
                            autoClose: NotificationLengthEnum.Normal,
                        })
                        })
                        .catch((error) => {
                            console.log(error.response)
                            setError(error.response?.data.message)
                            setHasErrors(true)
                            setLoading(false)
                            setSubmitting(false)
                            // setStatus('The login detail is incorrect')
                        })
                } else {
                    setHasErrors(true)
                    setLoading(false)
                    setSubmitting(false)
                }
            }, 1000)
        },
    })

    return (
        <div
            className='auth_container d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <Link to='#' className='mb-12'>
                    <img alt='Logo' src={logo} className='h-45px img_auth_logo' />
                </Link>
                <div className='w-lg-500px p-lg-15 p-10 bg-white rounded shadow-sm mx-auto'>
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_password_reset_form'
                        onSubmit={formik.handleSubmit}
                    >
                        <div className='text-center mb-10'>
                            {/* begin::Title */}
                            <h1 className='text-dark mb-3'>Reset Password</h1>
                            {/* end::Title */}

                            {/* begin::Link */}
                            <div className='text-gray-400 fw-bold fs-4'>Your old password has expired. Please enter your new password.</div>
                            {/* end::Link */}
                        </div>

                        {/* begin::Title */}
                        {hasErrors === true && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>
                                    {error}
                                </div>
                            </div>
                        )}

                        {hasErrors === false && (
                            <div className='mb-10 bg-light-info p-8 rounded'>
                                <div className='text-info'>Your new password has successfully changed.</div>
                            </div>
                        )}
                        {/* end::Title */}

                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type={isShowPwd ? 'text' : 'password'}
                                    placeholder=''
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        { 'is-invalid': formik.touched.password && formik.errors.password },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                                <button
                                    type="button"
                                    className='position-absolute btn p-0 btn_show_password'
                                    onClick={() => setIsShowPwd(!isShowPwd)}
                                >
                                    {
                                        isShowPwd ?
                                            <i className="bi bi-eye-slash-fill fs-3"></i> :
                                            <i className="bi bi-eye-fill fs-3"></i>
                                    }
                                </button>
                            </div>
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                            <button
                                type='submit'
                                id='kt_password_reset_submit'
                                className='btn btn-lg btn_black fw-bolder me-4'
                            >
                                {!loading && <span className='indicator-label poppins_medium'>Reset</span>}
                                {loading && (
                                    <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_password_reset_form_cancel_button'
                                    className='btn btn-lg btn-light fw-bolder'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    onClick={logout}
                                >
                                    Cancel
                                </button>
                            </Link>{' '}
                        </div>
                        {/* end::Form group */}
                    </form>
                </div>
            </div>
        </div>
    )
}
