import React, {ReactNode, useRef, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface Props {
	message?: string;
	body?: ReactNode;
	title?: string;
	cancelLabel?: string;
	confirmLabel?: string;
	onCancel?: () => void;
	onConfirm?: (errorToast: Function, successToast: Function ) => void;
	successMessage?: string;
	errorMessage?: string;
	onError?: (toast: Function) => void;
	mode?: 'DEFAULT' | 'CHECKBOX' | 'INPUT'
	confirmationString?: string;
	// modalId?: string | null
}

export function SkeDialogDelete({
																	title = 'Confirm Delete',
																	message = 'Are you sure you want to delete this?',
																	body,
																	cancelLabel = 'Cancel',
																	confirmLabel = 'Confirm',
																	onCancel,
																	onConfirm,
																	onError,
																	successMessage = 'Item successfully deleted',
																	errorMessage = 'Item failed to delete',
																	mode = 'DEFAULT',
																	confirmationString = '',
																	// modalId = null
																}: Props) {
	const [show, setShow] = useState(true);

	const handleClose = () => {
		setShow(false);
		onCancel && onCancel();
	};

	const successToast = () => {
		toast.success(successMessage, {
			position: 'top-right',
			theme: 'colored',
			autoClose: 2000,
		});
	}
	const errorToast = () => {
		toast.error(errorMessage, {
			position: 'top-right',
			theme: 'colored',
			autoClose: 3500,
		});
	}

	const handleConfirm = () => {
		setShow(false);
		if (onConfirm) {
			onConfirm(errorToast, successToast );
		}
	};

	return (
		<Modal
			// id={modalId}
			show={show}
			onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			{body && (
				<>
					This is the body {body}
				</>
			)}
			{mode === 'INPUT' && confirmationString !== '' && (
				<>
					<input
						type="text"
						autoComplete="off"

					/>
				</>
			)}
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={handleClose}>
					{cancelLabel}
				</Button>
				<Button
					variant="danger"
					onClick={handleConfirm}>
					{confirmLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
