import clsx from "clsx";
import ReactDatePicker from "react-datepicker";
import "./DatePicker.scss";

interface Props {
	selectedDate?: Date | null;
	onChange?: (date: Date | null) => void;
	label?: string;
	inputClasses?: string;
	labelClasses?: string;
	wrapperClasses?: string;
	id?: string;
	disabled?: boolean;
	required?: boolean;
	dateFormat?: string;
	allowFutureDates?: boolean;
	maxDate?: Date | null;
	size?: 'sm' | 'lg';
}

export function DatePicker({
	selectedDate,
	onChange,
	label,
	inputClasses,
	labelClasses,
	wrapperClasses,
	id,
	disabled = false,
	required = false,
	allowFutureDates = false,
	maxDate,
	dateFormat = "MMMM d, yyyy",
	size = 'lg',
}: Props) {

	const getMaxDate = () => {
		if (maxDate) {
			return maxDate;
		}

		if (allowFutureDates) {
			return null;
		}

		return new Date();
	}

	return (
		<div className={clsx('ske-date-picker', wrapperClasses)}>
			<label
				htmlFor={id}
				className={clsx("col-form-label fw-light fs-4 ske-form-label", labelClasses)}>
				{!!label && label}
			</label>
			<ReactDatePicker
				selected={selectedDate}
				onChange={(e) => {
					if (onChange) {
						onChange(e);
					}
				}}
				onInputClick={() => {}}
				id={id}
				maxDate={getMaxDate()}
				disabled={disabled}
				required={required}
				dateFormat={dateFormat}
				className={clsx("ske-form-control form-control datepicker", inputClasses,
					{
						'form-control-lg': size === 'lg',
						'form-control-sm': size === 'sm',
					})}
				shouldCloseOnSelect
			/>
		</div>
	);
}
