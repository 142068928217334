import React, { FC } from "react";
import { Link } from "react-router-dom";

import logo from '../../images/Logo_Black.svg'
import phone from '../../images/phone.svg'
import './HomeNavbar.scss'


const HomeNavbar: FC<{ backgroundOpacity: number }> = (props) => {
    const { backgroundOpacity } = props;

    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-light home_navbar"
            style={{
                backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`,
                paddingTop: `${25 - backgroundOpacity * 10}px`,
                paddingBottom: `${25 - backgroundOpacity * 10}px`
            }}
        >
            <div className="container-fluid padding0">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerHome" aria-controls="navbarTogglerHome" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link to="#" className="navbar-brand">
                    <img src={logo} alt="Secchi" height="32" />
                </Link>
                <div className="collapse navbar-collapse" id="navbarTogglerHome">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="#">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="#">Product</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="#">Contact</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="#">About</Link>
                        </li>
                        {/* <li className="nav-item">
                                <a className="nav-link" href="#">Link</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">Disabled</Link>
                            </li> */}
                    </ul>
                    <form className="d-flex">
                        <Link to="#" className="btn">
                            <img src={phone} alt="Phone" width="33" />
                        </Link>
                        <Link className="btn btn_login" to="/auth">Login</Link>
                        <Link className="btn btn_book" to="#">Book a Demo</Link>
                    </form>
                </div>
            </div>
        </nav>
    )
}

export { HomeNavbar }