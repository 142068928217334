import clsx from 'clsx';
import React, { FC } from 'react'

interface Props {
    data: any
    handlePrevious: (newPage: number) => void
    handleNext: (newPage: number) => void
    handlePageIndex: (index: number) => void
}

// TODO: refactor Pagination to receive just the data.meta object
const Pagination: FC<Props> = ({ data, handlePrevious, handleNext, handlePageIndex }) => {
    return (
        <ul className="pagination">
            <li className={`page-item previous ${data?.meta?.currentPage === 1 || !data?.meta ? 'disabled' : ''}`}>
                <button type="button" className="page-link"
                    onClick={() => handlePageIndex(1)}
                >
                    <i className="bi bi-chevron-bar-left fs-4 first"></i>
                </button>
            </li>
            <li className={`page-item previous ${data?.meta?.currentPage === 1 || !data?.meta  ? 'disabled' : ''}`}>
                <button type="button" className="page-link"
                    onClick={() => handlePrevious(data?.meta?.currentPage - 1)}
                >
                    <i className="previous"></i>
                </button>
            </li>
            {
                data?.meta?.totalPages < 6 &&
                Array.from(Array(data?.meta?.totalPages).keys()).map((x, i) =>
                    <li className={`page-item ${data?.meta?.currentPage === i + 1 ? 'active' : ''}`} key={i}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(i + 1)}
                        >
                            {i + 1}
                        </button>
                    </li>
                )
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage < 4 &&
                Array.from(Array(4).keys()).map((x, i) =>
                    <li className={`page-item ${data?.meta?.currentPage === i + 1 ? 'active' : ''}`} key={i}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(i + 1)}
                        >
                            {i + 1}
                        </button>
                    </li>
                )
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage < 4 &&
                <span className="page-three-dots">
                    <i className="bi bi-three-dots"></i>
                </span>
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage >= 4 && data?.meta?.currentPage <= data?.meta?.totalPages - 3 &&
                <span className="page-three-dots">
                    <i className="bi bi-three-dots"></i>
                </span>
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage >= 4 && data?.meta?.currentPage <= data?.meta?.totalPages - 3 &&
                <>
                    <li className={`page-item`}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(data?.meta?.currentPage - 1)}
                        >
                            {data?.meta?.currentPage - 1}
                        </button>
                    </li>
                    <li className={`page-item active`}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(data?.meta?.currentPage)}
                        >
                            {data?.meta?.currentPage}
                        </button>
                    </li>
                    <li className={`page-item`}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(data?.meta?.currentPage + 1)}
                        >
                            {data?.meta?.currentPage + 1}
                        </button>
                    </li>
                </>
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage >= 4 && data?.meta?.currentPage <= data?.meta?.totalPages - 3 &&
                <span className="page-three-dots">
                    <i className="bi bi-three-dots"></i>
                </span>
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage > data?.meta?.totalPages - 3 &&
                <span className="page-three-dots">
                    <i className="bi bi-three-dots"></i>
                </span>
            }
            {
                data?.meta?.totalPages >= 6 && data?.meta?.currentPage > data?.meta?.totalPages - 3 &&
                Array.from(Array(4).keys()).map((x, i) =>
                    <li className={`page-item ${data?.meta?.currentPage === data?.meta?.totalPages - 3 + i ? 'active' : ''}`} key={i}>
                        <button type="button" className="page-link"
                            onClick={() => handlePageIndex(data?.meta?.totalPages - 3 + i)}
                        >
                            {data?.meta?.totalPages - 3 + i}
                        </button>
                    </li>
                )
            }
            <li className={clsx('page-item', {
              'disabled': data?.meta?.totalPages === 0 || data?.meta?.currentPage === data?.meta?.totalPages
            })}>
                <button type="button" className="page-link"
                    onClick={() => handleNext(data?.meta?.currentPage + 1)}
                >
                    <i className="next"></i>
                </button>
            </li>
            <li className={clsx('page-item next', {
              'disabled': data?.meta?.totalPages === 0 || data?.meta?.currentPage === data?.meta?.totalPages
            })}>
                <button type="button" className="page-link"
                    onClick={() => handlePageIndex(data?.meta?.totalPages)}
                >
                    <i className="bi bi-chevron-bar-right fs-4 last"></i>
                </button>
            </li>
        </ul>
    )
}

export { Pagination }
