import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import {
	Attendance,
	AttendanceCreate,
	AttendancesGetAllApiRequestModel,
	AttendanceUpdate,
} from './interfaces/attendance.model';

export enum AttendanceTagTypes {
	Attendance = 'ATTENDANCE',
}

export const attendanceSlice = createApi({
	reducerPath: 'attendanceApi',
	tagTypes: [AttendanceTagTypes.Attendance],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/attendances`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getAttendances: builder.query<ApiResponse<Attendance>, AttendancesGetAllApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					params: args,
				};
			},
			transformResponse: (response: ApiResponse<Attendance>) => {
				return response;
			},
			extraOptions: {
				includeMeta: false,
				includeLinks: false,
			},
			transformErrorResponse: (response: {status: string | number}) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						...result.items.map(({id}) => ({
							type: AttendanceTagTypes.Attendance,
							id,
						} as const)),
						{
							type: AttendanceTagTypes.Attendance,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: AttendanceTagTypes.Attendance,
						id: 'LIST',
					},
				];
			},
		}),
		getOneAttendance: builder.query<Attendance, {id: number}>({
			query: (args: {id: number}) => {
				let url = `${args.id}`;
				return {
					url,
				};
			},
			transformErrorResponse: (response: {status: string | number}, meta, arg) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						{
							type: AttendanceTagTypes.Attendance,
							id: result.id,
						},
					];
				}
				return [];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		}),
		createAttendance: builder.mutation<Attendance, AttendanceCreate>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			invalidatesTags: [
				{
					type: AttendanceTagTypes.Attendance,
					id: 'LIST',
				},
			],
		}),
		updateAttendance: builder.mutation<Attendance, AttendanceUpdate>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: AttendanceTagTypes.Attendance,
							id: 'LIST',
						},
						{
							type: AttendanceTagTypes.Attendance,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		deleteAttendance: builder.mutation<void, {id: number}>({
			query: (args) => ({
				url: `${args.id}`,
				method: 'delete',
			}),
			transformErrorResponse: (response: {status: string | number}) => response.status,
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: AttendanceTagTypes.Attendance,
						id: 'LIST',
					},
					{
						type: AttendanceTagTypes.Attendance,
						id: args.id,
					},
				];
			},
		}),
	}),
});
