import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Emoji } from 'emoji-mart';

interface EmojiPickerProps {
	onEmojiSelect: (emoji: Emoji) => void;
	exceptEmojis?: string[];
}

export const SkeEmojiPicker = ({
															onEmojiSelect,
															exceptEmojis = ['peach', 'eggplant', 'gun'],
														}: EmojiPickerProps) => {

	const handleEmojiSelect = (emoji: any) => {
		console.log('Selected Emoji:', emoji.native);
		onEmojiSelect(emoji);
	};

	console.log(exceptEmojis);
	return (
		<Picker
			data={data}
			onEmojiSelect={handleEmojiSelect}
			exceptEmojis={exceptEmojis} />
	);
};
